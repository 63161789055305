'use client';

import {useMutation, UseMutationOptions, useQueryClient} from '@tanstack/react-query';
import {AxiosError, AxiosResponse} from 'axios';
import keysResolver from '@mgp-fe/shared/core-api/keysResolver.ts';
import endpoints from '@mgp-fe/shared/core-api/endpoints';
import coreApiClient from '@mgp-fe/shared/core-api/client.ts';
import {User} from '@mgp-fe/shared/core-api/domain/user.ts';
import {AUTH_LOCAL_STORAGE_KEY, HydraError} from '@mgp-fe/shared/core-api/domain/base.ts';
import {z} from 'zod';
import useLocalStorage from '@mgp-fe/shared/hooks/useLocalStorage.ts';

export default function useUpdateConsentSettingsMutation(props?: UseUpdateConsentSettingsMutationProps) {
	const queryClient = useQueryClient();
	const [, setToken] = useLocalStorage<string|null>(AUTH_LOCAL_STORAGE_KEY, null);

	return useMutation<AxiosResponse<User>, AxiosError<HydraError>, UpdateConsentSettingsMutationData>(
		keysResolver(endpoints.me.index, 'patch'),
		d => coreApiClient.patch<User>(endpoints.me.index, d),
		{
			...props,
			onSuccess: async (result, variables, context) => {
				result.data.signToken && setToken(result.data.signToken);
				await queryClient.invalidateQueries(keysResolver(endpoints.me.index));
				props?.onSuccess?.(result, variables, context);
			},
		},
	);
}

type UseUpdateConsentSettingsMutationProps = UseMutationOptions<
    AxiosResponse<User>,
    AxiosError<HydraError>,
    UpdateConsentSettingsMutationData
>;

export interface UpdateConsentSettingsMutationData {
    contactConsent?: {
        transactionalEmails?: boolean,
        marketingEmails?: boolean,
        transactionalSms?: boolean,
        marketingSms?: boolean,
        transactionalCalls?: boolean,
        marketingCalls?: boolean,
		timezone?: string,
		preferredLanguage?: string,
    }
}

export const UpdateConsentSettingsMutationDataSchema = z.object({
	contactConsent: z.object({
		transactionalEmails: z.boolean(),
		marketingEmails: z.boolean(),
		transactionalSms: z.boolean(),
		marketingSms: z.boolean(),
		transactionalCalls: z.boolean(),
		marketingCalls: z.boolean(),
		timezone: z.string().optional(),
		preferredLanguage: z.string().optional(),
	}).optional(),
});