/* eslint-disable */
// @ts-nocheck
import type * as Types from '@mgp-frontend/shared/cms-graphql/domain/types';

import { gql } from '@apollo/client';
export type ReviewsFragment = { __typename?: 'ComponentContentBlocksReviews', id: string, title?: string | null, reviewsVariant: Types.Enum_Componentcontentblocksreviews_Variant, reviews?: Array<{ __typename?: 'ComponentContentBlocksReviewsReview', id: string, reviewerName?: string | null, reviewerTitle?: string | null, rating?: number | null, reviewText?: string | null, reviewerPhoto?: { __typename?: 'UploadFileEntityResponse', data?: { __typename?: 'UploadFileEntity', attributes?: { __typename?: 'UploadFile', alternativeText?: string | null, caption?: string | null, name: string, url: string, mime: string } | null } | null } | null } | null> | null };

export const ReviewsFragmentDoc = gql`
    fragment Reviews on ComponentContentBlocksReviews {
  id
  reviewsVariant: variant
  title
  reviews {
    id
    reviewerName
    reviewerTitle
    reviewerPhoto {
      data {
        attributes {
          alternativeText
          caption
          name
          url
          mime
        }
      }
    }
    rating
    reviewText
  }
}
    `;