import {useQuery, UseQueryOptions} from '@tanstack/react-query';
import {AxiosResponse} from 'axios';
import keysResolver from '@mgp-fe/shared/core-api/keysResolver.ts';
import endpoints from '@mgp-fe/shared/core-api/endpoints.ts';
import coreApiClient from '@mgp-fe/shared/core-api/client.ts';
import {CardsListResponse} from '@mgp-fe/shared/core-api/domain/stripe.ts';

export default function useStripePartnerCheckoutCardsListQuery({locationId, options = {}}: ScanRequestsListQueryProps) {
	return useQuery({
		queryKey: keysResolver(endpoints.stripe.partnerCheckoutCards(locationId || ''), 'get', {locationId, options}),
		queryFn: () => coreApiClient.get(endpoints.stripe.partnerCheckoutCards(locationId || '')),
		...(options),
		enabled: locationId !== undefined,
	});
}

interface ScanRequestsListQueryProps {
	locationId?: string;
	options?: UseQueryOptions<AxiosResponse<{
		partnerLocation?: CardsListResponse,
		partnerOrganization?: CardsListResponse,
	}>, {error: string}>;
}