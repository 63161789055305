import React from 'react';
import MemberZoneLayout from '@mgp-fe/customer/modules/common/ui/MemberZoneLayout.tsx';
import useVouchersListQuery from '@mgp-fe/shared/core-api/queries/voucher/list.ts';
import VoucherCard, {VoucherCardSkeleton} from '@mgp-fe/shared/modules/vouchers/components/VoucherCard.tsx';
import {Alert, AlertDescription, AlertTitle} from '@mgp-fe/shared/ui/alert.tsx';
import {Link} from 'react-router-dom';
import routes from '@mgp-fe/customer/router/routes.ts';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button} from '@mgp-fe/shared/ui/button.tsx';

export default function VouchersPage() {
	const vouchersQuery = useVouchersListQuery();

	return <MemberZoneLayout>
		<main className='container grid lg:grid-cols-2 gap-medium'>
			<Button asChild className='w-fit pl-0 pb-0 text-muted' variant='link'>
				<Link to={routes.index}>
					<FontAwesomeIcon icon='chevron-left' className='mr-2'/>
					Back to overview
				</Link>
			</Button>
			<h2 className='md:col-span-2'>Vouchers</h2>

			{!vouchersQuery.data && vouchersQuery.isFetching ? [...Array(3)].map((_, i) => <VoucherCardSkeleton key={i}/>) : ''}

			{vouchersQuery.data?.data['hydra:member'].map(v => <VoucherCard voucher={v} key={v.id}/>)}
			{vouchersQuery.data?.data['hydra:totalItems'] === 0 ? <NoVouchersAlert/> : ''}
		</main>
	</MemberZoneLayout>;
}


export function NoVouchersAlert() {
	return <Alert className='mt-0 p-medium'>
		<AlertTitle className='text-muted/80'>
			You don&apos;t have any unused vouchers in your account.
		</AlertTitle>
		<AlertDescription>
			<p>Ask your coach / doctor to assign you a voucher.</p>
		</AlertDescription>
	</Alert>;
}