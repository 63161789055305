import React, {useEffect, useMemo, useState} from 'react';
import useCartMyQuery from '@mgp-fe/shared/core-api/queries/cart/my.tsx';
import useAuth from '@mgp-fe/shared/modules/auth/hooks/useAuth.ts';
import {Card} from '@mgp-fe/shared/ui/card.tsx';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {UserWithAddresses} from '@mgp-fe/shared/core-api/domain/user.ts';
import useCartCheckoutShippingMutation from '@mgp-fe/shared/core-api/mutations/cart/shipping.ts';
import {Button} from '@mgp-fe/shared/ui/button';
import {useNavigate} from 'react-router-dom';
import {AddressModel} from '@mgp-fe/shared/core-api/domain/address.ts';
import {ShippingMethodCollectionModel} from '@mgp-fe/shared/core-api/domain/shipping/method.ts';
import useCartMyShippingMethodsListQuery from '@mgp-fe/shared/core-api/queries/cart/my-shipping-methods.ts';
import MoneyFormatted from '@mgp-fe/shared/modules/formatters/MoneyFormatted.tsx';
import Skeleton from '@mgp-fe/shared/ui/skeleton.tsx';
import routes from '@mgp-fe/shared/modules/shop/components/checkout/routes.ts';
import CheckoutAddressesList from '@mgp-fe/shared/modules/shop/components/checkout/CheckoutAddressesList.tsx';
import {cn} from '@mgp-fe/shared/utils';
import {orderBy} from 'lodash';
import {Alert} from '@mgp-fe/shared/ui/alert.tsx';
import useNotify from '@mgp-fe/shared/ui/notifications/use-notify';

export default function ShippingStepPage() {
	const navigate = useNavigate();
	const {notifySuccess} = useNotify();
	const {user} = useAuth<UserWithAddresses>();
	const myCartQuery = useCartMyQuery();
	const order = useMemo(() => myCartQuery.data?.data.order, [myCartQuery.data?.data.order]);

	const shippingMethodsQuery = useCartMyShippingMethodsListQuery({
		options: {
			keepPreviousData: true,
			staleTime: Infinity,
		},
	});

	const shippingMutation = useCartCheckoutShippingMutation({
		onSuccess: () => navigate(routes.billing.index, {replace: true}),
	});

	const [address, setAddress] = useState<AddressModel | null>(order?.shippingAddress || user?.defaultShippingAddress || null);
	const [shippingMethod, setShippingMethod] = useState<ShippingMethodCollectionModel | null>(order?.shippingMethod || null);

	useEffect(() => {
		!address && setAddress(order?.shippingAddress || user?.defaultShippingAddress || null);
	}, [address, order?.shippingAddress, user?.defaultShippingAddress]);

	useEffect(() => {
		shippingMethodsQuery.refetch();
	}, [address]);

	useEffect(() => {
		if (shippingMethod) return;
		const cheapestShippingMethod = orderBy(shippingMethodsQuery.data?.data['hydra:member'].filter(s => !!s.cost), 'cost.amount', 'asc')[0];
		setShippingMethod(order?.shippingMethod || cheapestShippingMethod || null);
	}, [shippingMethodsQuery]);

	useEffect(() => {
		if (!order?.orderItems?.every(oi => oi.product?.virtual)) return;
		notifySuccess({message: 'Your order has only virtual products, shipping step is skipped.'});
		navigate(routes.billing.index, {replace: true});
	}, [myCartQuery.data]);


	return <div className='grid md:grid-cols-4 gap-small'>
		<section className='col-span-4 md:col-span-2 flex flex-col gap-medium'>
			<h3>Delivery Address</h3>
			{(!user?.defaultShippingAddress && !myCartQuery.isFetching && !myCartQuery.data?.data.order.partnerLocation) ?
				<Alert variant='warning'>
					You don&apos;t have a default shipping address, please add one bellow.
				</Alert> : ''}
			<CheckoutAddressesList
				selectAddress={setAddress}
				selectedAddress={address || order?.shippingAddress || user?.defaultShippingAddress}/>
		</section>

		<section className='col-span-4 md:col-span-2 flex flex-col gap-medium'>
			<h3>Shipping method</h3>
			{shippingMethodsQuery.data?.data['hydra:member'].map(sm => <ShippingMethodCard
				key={sm.id}
				shippingMethod={sm}
				selected={shippingMethod}
				onSelect={setShippingMethod}
				shippingMethodsQuery={shippingMethodsQuery}/>)}

			{!shippingMethodsQuery.data && shippingMethodsQuery.isFetching
				? [...Array(3)].map((_, i) => <ShippingMethodCardSkeleton key={i}/>) : ''}
		</section>

		<Button
			onClick={() => navigate('/cart')}
			variant='link'
			icon={<FontAwesomeIcon icon='chevron-left' className='mr-2'/>}
			className='w-full sm:w-fit md:text-left pl-0 text-muted/70 col-span-4 sm:col-span-2 sm:mt-medium'>
			Back to cart
		</Button>
		<Button
			onClick={() => address && shippingMethod && shippingMutation.mutate({
				shippingAddress: address['@id'],
				shippingMethod: shippingMethod['@id'],
			})}
			disabled={!address || !shippingMethod || shippingMethodsQuery.isFetching}
			state={shippingMutation.status}
			className='w-full sm:w-fit flex-row-reverse gap-mini col-span-4 sm:col-span-2 sm:mt-medium sm:ml-auto'
			icon={<FontAwesomeIcon icon='chevron-right' className='mr-2'/>}>
			Confirm
		</Button>
	</div>;
}

export function ShippingMethodCard({
	shippingMethod,
	selected,
	onSelect,
	shippingMethodsQuery,
}: ShippingMethodCardProps) {
	return <Card
		variant={selected?.id === shippingMethod.id ? 'secondary' : 'default'}
		spacing='sm'
		key={shippingMethod.id}
		onClick={onSelect && shippingMethod.cost ? () => onSelect(shippingMethod) : undefined}
		className={cn('flex flex-wrap gap-medium items-center', onSelect && shippingMethod.cost ? 'hover:bg-secondary-500/20 cursor-pointer' : 'cursor-not-allowed')}>

		{onSelect ? <FontAwesomeIcon
			icon={selected?.id === shippingMethod.id ? 'circle-check' : 'circle'}
			className={selected?.id === shippingMethod.id ? 'text-primary' : 'text-secondary opacity-20'}
			size='lg'/> : ''}
		<div className='grow'>
			<h4 className='flex items-center justify-between w-full'>
				{shippingMethod.name}
			</h4>
			{shippingMethod.description ? <p>{shippingMethod.description}</p> : ''}
		</div>

		<div className='w-full md:w-auto flex items-center gap-small'>
			<span className='paragraph shrink-0 grow'>Up to {shippingMethod.shippingTime} days</span>
			<span className='!text-muted h5 shrink-0'> {shippingMethodsQuery.isFetching
				? <Skeleton className='w-10 h-4'/>
				: (shippingMethod.cost?.amount ?? 1) === 0 ? 'FREE'
					: <MoneyFormatted missingLabel={<FontAwesomeIcon icon='xmark-circle' className='text-warning'/>}
									  money={shippingMethod.cost}/>}
			</span>
		</div>
	</Card>;
}

export function ShippingMethodCardSkeleton() {
	return <Card
		spacing='sm'
		className='flex gap-medium items-center'>
		<Skeleton className='w-6 h-6 rounded-full'/>

		<div className='w-full'>
			<Skeleton className='h-6 mb-2'/>
			<Skeleton className='h-3'/>
		</div>

		<Skeleton className='w-20 h-4'/>
		<Skeleton className='w-10 h-4'/>
	</Card>;
}

interface ShippingMethodCardProps {
	shippingMethod: ShippingMethodCollectionModel,
	selected?: ShippingMethodCollectionModel | null,
	onSelect?: (sm: ShippingMethodCollectionModel) => void,
	shippingMethodsQuery: ReturnType<typeof useCartMyShippingMethodsListQuery>,
}