'use client';

import React, {useEffect, useRef, useState} from 'react';
import {PartnerLocationPublicCollectionModel} from '@mgp-fe/shared/core-api/domain/partner/location.ts';
import loader from '@mgp-fe/shared/utils/google-maps-loader.ts';
import {
	PartnerLocationsSearchFormValues,
} from '@mgp-fe/shared/modules/partner-locator/components/PartnerLocationsSearchForm.tsx';
import colors from '@mgp-fe/shared/ui/constants/colors.ts';
import {radiusOptions} from '@mgp-fe/shared/modules/partner-locator/constants.ts';

export default function PartnerLocationsMap({
	locations = undefined,
	formValues,
}: PartnerLocationsMapProps) {
	const mapRef = useRef<HTMLDivElement>(null);
	const [googleMap, setGoogleMap] = useState<google.maps.Map | undefined>();
	const [googleMapMarkers, setGoogleMapMarkers] = useState<google.maps.Marker[]>([]);
	const [googleMapCircle, setGoogleMapCircle] = useState<google.maps.Circle | undefined>();

	useEffect(() => {
		if (!mapRef.current || googleMap) return;
		loader.importLibrary('maps')
			.then(({Map}) => {
				if (!mapRef.current || googleMap) return;
				setGoogleMap(new Map(mapRef.current!, {
					center: {lat: -34.397, lng: 150.644},
					...mapOptions,
				}));
			}).catch(() => {
				alert('Error loading Google Maps API');
			});
	}, [googleMap, mapRef]);

	// setup markers
	useEffect(() => {
		if (!googleMap) return;

		loader.importLibrary('marker').then(({Marker}) => {
			googleMapMarkers.forEach(m => m.setMap(null));

			if (!formValues || !formValues?.zipCode || !formValues?.radius) {
				const bounds = new google.maps.LatLngBounds();
				locations?.filter(p => p.address.lat && p.address.long)
					.forEach(p => bounds.extend(new google.maps.LatLng(p.address.lat!, p.address.long)));
				googleMap.fitBounds(bounds);
			}

			setGoogleMapMarkers(locations?.filter(p => p.address.lat && p.address.long)
				.map(p => new Marker({
					map: googleMap,
					position: new google.maps.LatLng(p.address.lat || -34.397, p.address.long || 150.644),
					title: p.name,
					icon: {
						url: 'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTciIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAxNyAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTcuNTgzNzkgMTkuNTk2NUMxLjQxNTQ2IDExLjM2ODQgMC4yNzA1MDggMTAuNTIzOSAwLjI3MDUwOCA3LjVDMC4yNzA1MDggMy4zNTc4NSAzLjkxOTgxIDAgOC40MjE0NyAwQzEyLjkyMzEgMCAxNi41NzI0IDMuMzU3ODUgMTYuNTcyNCA3LjVDMTYuNTcyNCAxMC41MjM5IDE1LjQyNzUgMTEuMzY4NCA5LjI1OTE2IDE5LjU5NjVDOC44NTQzNyAyMC4xMzQ1IDcuOTg4NTQgMjAuMTM0NSA3LjU4Mzc5IDE5LjU5NjVaTTguNDIxNDcgMTAuNjI1QzEwLjI5NzIgMTAuNjI1IDExLjgxNzcgOS4yMjU5IDExLjgxNzcgNy41QzExLjgxNzcgNS43NzQxIDEwLjI5NzIgNC4zNzUgOC40MjE0NyA0LjM3NUM2LjU0NTc3IDQuMzc1IDUuMDI1MjQgNS43NzQxIDUuMDI1MjQgNy41QzUuMDI1MjQgOS4yMjU5IDYuNTQ1NzcgMTAuNjI1IDguNDIxNDcgMTAuNjI1WiIgZmlsbD0iI2EzZGUwMCIvPgo8cGF0aCBkPSJNNy41ODM3OSAxOS41OTY1QzEuNDE1NDYgMTEuMzY4NCAwLjI3MDUwOCAxMC41MjM5IDAuMjcwNTA4IDcuNUMwLjI3MDUwOCAzLjM1Nzg1IDMuOTE5ODEgMCA4LjQyMTQ3IDBDMTIuOTIzMSAwIDE2LjU3MjQgMy4zNTc4NSAxNi41NzI0IDcuNUMxNi41NzI0IDEwLjUyMzkgMTUuNDI3NSAxMS4zNjg0IDkuMjU5MTYgMTkuNTk2NUM4Ljg1NDM3IDIwLjEzNDUgNy45ODg1NCAyMC4xMzQ1IDcuNTgzNzkgMTkuNTk2NVpNOC40MjE0NyAxMC42MjVDMTAuMjk3MiAxMC42MjUgMTEuODE3NyA5LjIyNTkgMTEuODE3NyA3LjVDMTEuODE3NyA1Ljc3NDEgMTAuMjk3MiA0LjM3NSA4LjQyMTQ3IDQuMzc1QzYuNTQ1NzcgNC4zNzUgNS4wMjUyNCA1Ljc3NDEgNS4wMjUyNCA3LjVDNS4wMjUyNCA5LjIyNTkgNi41NDU3NyAxMC42MjUgOC40MjE0NyAxMC42MjVaIiBmaWxsPSJibGFjayIgZmlsbC1vcGFjaXR5PSIwLjMiLz4KPC9zdmc+Cg==',
						scaledSize: {
							equals: () => true,
							width: 18,
							height: 24,
						},
					},
				})) || []);
		});
	}, [googleMap, locations, formValues]);

	// setup circle
	useEffect(() => {
		if (!googleMap) return;

		if (!formValues || !formValues?.zipCode || !formValues?.radius) {
			googleMapCircle?.setMap(null);
			return;
		}

		Promise.all([
			loader.importLibrary('maps'),
			loader.importLibrary('geocoding'),
		]).then(async ([{Circle}, {Geocoder}]) => {
			(new Geocoder()).geocode({
				address: formValues.zipCode,
				componentRestrictions: {country: 'US'},
			}, (results, status) => {
				if (status !== 'OK' || !results) return;

				googleMap?.setCenter(results![0].geometry.location);

				const newCircle = new Circle({
					fillColor: colors.secondary['500'],
					strokeColor: colors.primary['500'],
					fillOpacity: 0.25,
					strokeOpacity: 0.8,
					strokeWeight: 1,
					map: googleMap,
					center: results![0].geometry.location.toJSON(),
					radius: formValues.radius * 1609.344,
				});
				if (newCircle.getBounds() === null) return;
				googleMapCircle?.setMap(null);
				setGoogleMapCircle(newCircle);
				googleMap.fitBounds(newCircle.getBounds()!);

				googleMap.setZoom(radiusOptions.find(o => o.value === formValues?.radius)?.zoom || 5);
			});
		});

	}, [googleMap, formValues]);

	return <div id='partnerLocationsMap' ref={mapRef} className='w-full h-96'/>;
}

interface PartnerLocationsMapProps {
	locations?: PartnerLocationPublicCollectionModel[];
	formValues?: PartnerLocationsSearchFormValues;
}

const mapOptions: google.maps.MapOptions = {
	fullscreenControl: false,
	mapTypeControl: false,
	scaleControl: false,
	streetViewControl: false,
	zoom: 6,
	zoomControl: true,
	zoomControlOptions: {
		position: 5.0,
	},
	styles: [
		{
			featureType: 'administrative',
			elementType: 'labels.text.fill',
			stylers: [{color: '#38721F'}],
		},
		{
			featureType: 'administrative',
			elementType: 'labels.text.stroke',
			stylers: [{color: '#1e1e1e', brightness: 0.1}],
		},
		{
			featureType: 'administrative',
			elementType: 'all',
			stylers: [{visibility: 'off'}],
		},
		{
			featureType: 'administrative.locality',
			elementType: 'labels',
			stylers: [{visibility: 'on'}],
		},
		{
			featureType: 'administrative.country',
			elementType: 'geometry',
			stylers: [{visibility: 'on'}],
		},
		{
			featureType: 'administrative.province',
			elementType: 'geometry',
			stylers: [{visibility: 'on'}],
		},
		{
			featureType: 'administrative.province',
			elementType: 'labels',
			stylers: [{visibility: 'on'}],
		},
		{
			featureType: 'administrative.neighborhood',
			elementType: 'labels',
			stylers: [{visibility: 'on', color: '#285914'}],
		},
		{
			featureType: 'landscape',
			elementType: 'all',
			stylers: [{color: '#2c2929'}],
		},
		{
			featureType: 'landscape.man_made',
			elementType: 'all',
			stylers: [{color: '#383636'}],
		},
		{
			featureType: 'poi',
			elementType: 'all',
			stylers: [{color: '#2c2929'}],
		},
		{
			featureType: 'poi',
			elementType: 'geometry.stroke',
			stylers: [{color: '#2c2929'}],
		},
		{
			featureType: 'poi',
			elementType: 'labels',
			stylers: [{visibility: 'off'}],
		},
		{
			featureType: 'poi',
			elementType: 'labels.icon',
			stylers: [{visibility: 'off'}],
		},
		{
			featureType: 'poi.park',
			elementType: 'all',
			stylers: [{visibility: 'off'}],
		},
		{
			featureType: 'road',
			elementType: 'labels',
			stylers: [{visibility: 'off'}],
		},
		{
			featureType: 'road',
			elementType: 'all',
			stylers: [{color: '#1c410e'}],
		}, {featureType: 'road', elementType: 'labels', stylers: [{visibility: 'on'}]},
		{
			featureType: 'road',
			elementType: 'labels.icon',
			stylers: [{visibility: 'off'}],
		}, {
			featureType: 'road.highway',
			elementType: 'all',
			stylers: [{visibility: 'simplified'}, {brightness: '0.1'}, {color: '#172f0c'}],
		}, {
			featureType: 'road.highway',
			elementType: 'labels.icon',
			stylers: [{visibility: 'off'}],
		}, {
			featureType: 'road.arterial',
			elementType: 'labels.icon',
			stylers: [{visibility: 'off'}],
		}, {
			featureType: 'transit.line',
			elementType: 'geometry',
			stylers: [{color: 'rgba(100,190,51,0.51)'}],
		}, {
			featureType: 'transit.station.airport',
			elementType: 'geometry',
			stylers: [{hue: '#ffa200'}],
		}, {
			featureType: 'transit.station.airport',
			elementType: 'labels',
			stylers: [{visibility: 'off'}],
		}, {
			featureType: 'transit.station.rail',
			elementType: 'labels.text',
			stylers: [{visibility: 'off'}],
		}, {
			featureType: 'transit.station.airport',
			elementType: 'all',
			stylers: [{visibility: 'off'}],
		}, {
			featureType: 'transit.station.rail',
			elementType: 'labels.icon',
			stylers: [{visibility: 'simplified'}, {color: '#121212'}],
		},
		{
			featureType: 'water',
			elementType: 'all',
			stylers: [{color: '#121212'}, {visibility: 'simplified'}],
		},
	],
} satisfies google.maps.MapOptions;