import React from 'react';
import {Button} from '@mgp-fe/shared/ui/button.tsx';
import {useIsMutating} from '@tanstack/react-query';
import keysResolver from '@mgp-fe/shared/core-api/keysResolver.ts';
import endpoints from '@mgp-fe/shared/core-api/endpoints.ts';
import {
	CreateCartItemMutationRequest,
	useCreateCartItemMutation, useReduceCartItemMutation,
} from '@mgp-fe/shared/core-api/mutations/cart/my-items.ts';
import {OrderItemModel} from '@mgp-fe/shared/core-api/domain/order.ts';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

export default function OrderItemCartControls({orderItem}: ProductControlsProps) {
	const isAddMutating = useIsMutating({
		mutationKey: keysResolver(endpoints.cart.my.items.list),
		predicate: m => (m.state.variables as CreateCartItemMutationRequest).product === orderItem.product['@id']
			&& (m.state.variables as CreateCartItemMutationRequest).mouthguardDesign === orderItem.mouthguard?.mouthguardDesign?.['@id']
			&& (m.state.variables as CreateCartItemMutationRequest).selectedProductOptions?.color === orderItem.selectedProductOptions.color,
	});
	const isDecreaseMutating = useIsMutating({
		mutationKey: keysResolver(endpoints.cart.my.items.list),
		predicate: m => (m.state.variables as OrderItemModel).id === orderItem.id,
	});

	const addItemToCartMutation = useCreateCartItemMutation();
	const reduceCartItem = useReduceCartItemMutation();


	return <div className='bg-primary-400/10 rounded-full w-full h-fit flex items-center justify-stretch py-2 px-2'>
		<Button size='icon' onClick={() => reduceCartItem.mutate(orderItem)}
			state={isDecreaseMutating > 0 ? 'loading' : 'idle'}
			disabled={isDecreaseMutating > 0 || isAddMutating > 0}>
			<FontAwesomeIcon icon={orderItem.product.minimumNumber === orderItem.quantity ? 'trash-can' : 'minus'}/>
		</Button>

		<span className='text-foreground text-2xl text-center grow px-8'>
			{orderItem.quantity}
		</span>

		<Button
			size='icon'
			onClick={() => addItemToCartMutation.mutate({
				product: orderItem.product['@id'],
				mouthguardDesign: orderItem?.mouthguard?.mouthguardDesign?.['@id'],
				selectedProductOptions: orderItem?.selectedProductOptions || undefined,
				mouthguardThickness: orderItem?.mouthguard?.thickness,
			})}
			state={isAddMutating ? 'loading' : 'idle'}
			disabled={isDecreaseMutating > 0 || isAddMutating > 0}>
			<FontAwesomeIcon icon='plus'/>
		</Button>
	</div>;

}

interface ProductControlsProps {
    orderItem: OrderItemModel;
}