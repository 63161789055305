import {useMutation, UseMutationOptions} from '@tanstack/react-query';
import {AxiosError, AxiosResponse} from 'axios';
import keysResolver from '@mgp-fe/shared/core-api/keysResolver.ts';
import endpoints from '@mgp-fe/shared/core-api/endpoints.ts';
import coreApiClient from '@mgp-fe/shared/core-api/client.ts';
import {HydraError} from '@mgp-fe/shared/core-api/domain/base.ts';
import {VoucherCollectionModel, VoucherPublicModel} from '@mgp-fe/shared/core-api/domain/voucher.ts';


export default function useOnboardingAssignVoucherMutation(props?: UseOnboardingAssignVoucherMutationProps) {
	return useMutation({
		mutationKey: keysResolver(endpoints.vouchers.onboardingAssign('', ''), 'patch'),
		mutationFn: data => coreApiClient.patch(
			endpoints.vouchers.onboardingAssign(data.voucher.id, data.voucherCode),
			{},
		),
		...props,
	});
}

type UseOnboardingAssignVoucherMutationProps = UseMutationOptions<
	AxiosResponse<VoucherCollectionModel>,
	AxiosError<HydraError>,
	OnboardingAssignVoucherMutationData
>;

interface OnboardingAssignVoucherMutationData {
	voucher: VoucherPublicModel;
	voucherCode: string;
}