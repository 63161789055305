'use client';

import * as React from 'react';
import { Check, ChevronsUpDown } from 'lucide-react';

import { cn } from '@mgp-fe/shared/utils';
import { Button } from '@mgp-fe/shared/ui/button.tsx';
import {
	Command,
	CommandEmpty,
	CommandGroup,
	CommandInput,
	CommandItem,
} from '@mgp-fe/shared/ui/command.tsx';
import {
	Popover,
	PopoverContent,
	PopoverTrigger,
} from '@mgp-fe/shared/ui/popover.tsx';
import {UseFormReturn} from 'react-hook-form';

export function Combobox(props: ComboboxProps) {
	const [open, setOpen] = React.useState(false);
	const [value, setValue] = React.useState(props.defaultValue || '');

	return (
		<Popover open={open} onOpenChange={setOpen}>
			<PopoverTrigger asChild>
				<Button
					variant='outline'
					role='combobox'
					aria-expanded={open}
					size='md'
					className='w-fit my-small justify-between'
				>
					{value
						? props.options?.find((framework) => framework.value === value)?.label
						: props.placeholder || 'Select an option...'}
					<ChevronsUpDown className='ml-2 h-4 w-4 shrink-0 opacity-50' />
				</Button>
			</PopoverTrigger>
			<PopoverContent className='w-80 md:w-fit p-0 bg-background'>
				<Command>
					<CommandInput placeholder='Search ...' />
					<CommandEmpty>No framework found.</CommandEmpty>
					<CommandGroup className='max-h-96 overflow-auto'>
						{props.options?.map((framework) => (
							<CommandItem
								className=''
								key={framework.value}
								value={framework.value}
								onSelect={(currentValue) => {
									setValue(currentValue === value ? '' : currentValue);
									props.form.setValue(props.name, framework.value);
									setOpen(false);
								}}
							>
								<Check
									className={cn(
										'mr-2 h-4 w-4',
										value === framework.value ? 'opacity-100' : 'opacity-0',
									)}
								/>
								{framework.label}
							</CommandItem>
						))}
					</CommandGroup>
				</Command>
			</PopoverContent>
		</Popover>
	);
}

interface ComboboxProps {
	form: UseFormReturn<any>;
	placeholder?: string;
	name: string;
	defaultValue?: string;
    options?: {
        value: string;
        label: string;
    }[]
}