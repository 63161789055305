import {OrderItemModel} from '@mgp-fe/shared/core-api/domain/order.ts';


export function parsePackageItems(orderItems: OrderItemModel[]) {
	const items = orderItems.filter(i => !i.packageOrderItem);
	const packageItems = orderItems.filter(i => !!i.packageOrderItem);


	return items.map(i => {
		return {...i, pack: packageItems.filter(p => p.packageOrderItem === i['@id'])};
	});
}