import {HydraModel} from '@mgp-fe/shared/core-api/domain/base.ts';
import {User} from '@mgp-fe/shared/core-api/domain/user.ts';

export interface OAuthCollectionModel extends HydraModel {
    oauthId: string;
    user: User;
    type: OAuthType;
    signToken?: string | null;
}

export type OAuthModel = OAuthCollectionModel;

// export const oAuthTypes = ['apple', 'google'];
export const oAuthTypes = ['google'];
export type OAuthType = typeof oAuthTypes[number];