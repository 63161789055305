const routes = {
	index: '/account',
	profile: {
		index: 'profile',
	},
	addressBook: {
		index: 'address-book',
	},
	paymentMethods: {
		index: 'payment-methods',
	},
	privacySettings: {
		index: 'privacy-settings',
	},
};

export default routes;