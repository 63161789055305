import React, {useContext} from 'react';
import LocaleContext from '@mgp-fe/shared/localization/LocaleContext.ts';
import {Money} from '@mgp-fe/shared/core-api/domain/base.ts';

export default function MoneyFormatted({money, missingLabel}: MoneyFormattedProps) {
	const localeContextValue = useContext(LocaleContext);

	if(!money) return missingLabel || 'Missing amount';

	return new Intl.NumberFormat(localeContextValue.locale || window.navigator.language, {
		style: 'currency',
		currency: money.currency,
	}).format(money.amount);
}

interface MoneyFormattedProps {
	money?: Money;
	missingLabel?: string | React.ReactNode;
}