import React, {useEffect, useMemo} from 'react';
import {Link, Outlet, useLocation, useNavigate} from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import routes from './routes.ts';
import useCartMyQuery from '@mgp-fe/shared/core-api/queries/cart/my.tsx';

export default function CheckoutIndexPage() {
	const location = useLocation();
	if(location.pathname === routes.completed) return <Outlet/>;

	return <>
		<CheckoutNavigation />

		<div className='mt-medium'>
			<Outlet/>
		</div>
	</>;
}

function CheckoutNavigation() {
	const location = useLocation();
	const navigate = useNavigate();
	const myCartQuery = useCartMyQuery();
	const order= useMemo(() => myCartQuery.data?.data.order, [myCartQuery.data?.data.order]);

	useEffect(() => {
		if(location.pathname === routes.index) navigate(routes.shipping.index, {replace: true});

		if(location.pathname === routes.completed && order?.checkoutState === 'payment_selected') navigate(routes.summary.index, {replace: true});
		if(location.pathname === routes.billing.index && !order?.orderItems?.every(oi => oi.product?.virtual) && order?.checkoutState === 'cart') navigate(routes.shipping.index, {replace: true});
		if(location.pathname === routes.summary.index && order?.checkoutState === 'shipping_selected') navigate(routes.billing.index, {replace: true});
	}, [location, navigate, order]);

	const steps = useMemo(() => [
		{
			id: '1',
			name: 'Shipping',
			href: routes.shipping.index,
			status: location.pathname === routes.shipping.index ? 'current' : 'complete',
		},
		{
			id: '2',
			name: 'Billing',
			href: routes.billing.index,
			status: location.pathname === routes.billing.index ? 'current' : location.pathname === routes.summary.index ? 'complete' : 'upcoming',
			isAllowed: order?.checkoutState === 'shipping_selected',
		},
		{
			id: '3',
			name: 'Summary',
			href: routes.summary.index,
			status: location.pathname === routes.summary.index ? 'current' : 'upcoming',
			isAllowed: order?.checkoutState === 'payment_selected',
		},
	],
	[location.pathname, order?.checkoutState]);

	return <nav aria-label='Progress'>
		<ol role='list'
			className='divide-y divide-gray-300 rounded-md border border-primary-800 md:flex md:divide-y-0 bg-background'>
			{steps.map((step, stepIdx) => <li key={step.name} className='relative md:flex md:flex-1'>
				{{
					'upcoming': <Link to={step.href} className='group flex items-center'>
						<span className='flex items-center px-6 py-4 text-large font-medium'>
							<span
								className='flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-2 border-gray-300 group-hover:border-gray-400'>
								<span className='text-gray-500 group-hover:text-primary'>{step.id}</span>
							</span>
							<span
								className='ml-4 text-large font-medium text-gray-500 group-hover:text-primary'>{step.name}</span>
						</span>
					</Link>,
					'current': <Link
						to={step.href} className='flex items-center px-6 py-4 text-large font-medium'
						aria-current='step'>
						<span
							className='flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-2 border-primary-600'>
							<span className='text-primary-600'>{step.id}</span>
						</span>
						<span className='ml-4 text-large font-medium text-primary-600'>{step.name}</span>
					</Link>,
					'complete': <Link to={step.href} className='group flex w-full items-center'>
						<span className='flex items-center px-6 py-4 text-large font-medium'>
							<span
								className='flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full bg-primary group-hover:bg-secondary-800'>
								<FontAwesomeIcon icon='check' className='h-6 w-6 text-primary-foreground' aria-hidden='true'/>
							</span>
							<span className='ml-4 text-xl font-semibold !text-primary'>{step.name}</span>
						</span>
					</Link>,
				}[step.status]}

				{stepIdx !== steps.length - 1 ? <ArrowSeparator/> : null}
			</li>)}
		</ol>
	</nav>;
}

function ArrowSeparator() {
	return <div className='absolute right-0 top-0 hidden h-full w-5 md:block' aria-hidden='true'>
		<svg className='h-full w-full text-primary-800' viewBox='0 0 22 80' fill='none' preserveAspectRatio='none'>
			<path d='M0 -2L20 40L0 82' vectorEffect='non-scaling-stroke' stroke='currentcolor' strokeLinejoin='round'/>
		</svg>
	</div>;
}