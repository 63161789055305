import React from 'react';
import ShippingStepPage from './ShippingStepPage.tsx';
import BillingStepPage from './BillingStepPage.tsx';
import SummaryStepPage from './SummaryStepPage.tsx';
import CompletedStepPage from './CompletedStepPage.tsx';
import {Outlet} from 'react-router-dom';

const routeObjects = [
	{
		path: '/checkout',
		element: <Outlet/>,
		children: [
			{
				path: 'shipping',
				element: <ShippingStepPage/>,
			},
			{
				path: 'billing',
				element: <BillingStepPage/>,
			},
			{
				path: 'summary',
				element: <SummaryStepPage/>,
			},
			{
				path: 'completed',
				element: <CompletedStepPage/>,
			},
		],
	},
];

export default routeObjects;