import React, {useEffect, useMemo} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import routes from '@mgp-fe/customer/router/routes.ts';
import {routes as accountRoutes} from '@mgp-fe/shared/modules/account';
import {Link, useLocation, useNavigate} from 'react-router-dom';
import useAuth from '@mgp-fe/shared/modules/auth/hooks/useAuth.ts';
import {Button} from '@mgp-fe/shared/ui/button.tsx';
import LogoText from '@mgp-fe/shared/assets/logo/text-logo.svg';
import useCart from '@mgp-fe/shared/modules/shop/hooks/useCart';
import HeroImage from '@mgp-fe/shared/assets/background/background-hero.png';
import {DefaultFooter, DefaultHeader, resolveEnvVar} from '@mgp-fe/shared/index.ts';
import {Customer} from '@mgp-fe/shared/core-api/domain/user.ts';
import Banner from '@mgp-fe/shared/ui/layout/Banner.tsx';
import {useMouthguardClubComQuery} from '@mgp-fe/shared/cms-graphql/queries/generated/mouthguardclub-single.ts';
import {compact} from 'lodash';
import {NavigationItem} from '@mgp-fe/shared/ui';
import {Badge} from '@mgp-fe/shared/ui/badge.tsx';


export default function MemberZoneLayout(props: MemberZoneLayoutProps) {
	const location = useLocation();
	const navigate = useNavigate();
	const {user, isUserLoaded} = useAuth<Customer>();
	const {totalItems} = useCart();
	const {data: mouthguardClubData} = useMouthguardClubComQuery();

	const navigationItems = useMemo<NavigationItem[]>(() => [
		{id: 'dashboard', label: 'Dashboard', url: routes.index},
		{id: 'orders', label: 'Orders', url: routes.orders.index},
		{id: 'garddesigns', label: 'GARD designs', url: routes.gardDesigns.index},
		{id: 'designer', label: 'Designer', url: routes.gardDesigns.designer},
		{id: 'scans', label: 'Scans', url: routes.scans.index},
		{id: 'shop', label: 'Shop', url: routes.shop.index, icon: <FontAwesomeIcon icon='shopping-basket'/>},
		{
			id: 'cart',
			label: 'Cart',
			url: routes.shop.cart,
			icon: <FontAwesomeIcon icon='shopping-cart'/>,
			showOnMobile: true,
		},
	], [totalItems]);

	useEffect(() => {
		if (!user && isUserLoaded) navigate(routes.index);
	}, [navigate, user, isUserLoaded]);

	return <div className='flex flex-col min-h-screen relative overflow-hidden'>
		<div
			className='overflow-hidden absolute -z-10 bg-gradient-to-b from-transparent to-background to-90% top-0 left-0 w-screen h-screen'/>
		<div className='overflow-hidden absolute -z-20 w-screen bg-cover h-screen bg-no-repeat bg-top' style={{
			backgroundImage: `url("${HeroImage}")`,
		}}/>
		<DefaultHeader>
			{mouthguardClubData?.mouthguardClubCom?.data?.attributes?.banner && mouthguardClubData?.mouthguardClubCom?.data?.attributes?.banner?.code &&
				<Banner text={mouthguardClubData?.mouthguardClubCom?.data?.attributes?.banner?.messageText}
					code={mouthguardClubData?.mouthguardClubCom.data.attributes.banner.code}/>
			}
			<DefaultHeader.TopBar>
				<Button asChild variant='link' size='sm' className='my-2 text-muted/80 pl-0'>
					<a href={resolveEnvVar('webAppUrl')}>
						<FontAwesomeIcon icon='chevron-left'/>
						Back to website
					</a>
				</Button>

				<Button
					variant='ghost'
					size='sm'
					icon={<FontAwesomeIcon icon='user-cog'/>}
					asChild>
					<Link to={accountRoutes.index}>
						<FontAwesomeIcon icon='user-cog'/>
						{user?.name || 'My Profile'}
					</Link>
				</Button>
			</DefaultHeader.TopBar>

			<DefaultHeader.Navigation
				currentPath={location.pathname}
				items={navigationItems}
				LogoComponent={<Link to={routes.index} className='flex flex-col items-center mr-6'>
					<LogoText className='fill-primary h-auto w-42'/>
					<span
						className='mt-1 text-xs lg:text-lg tracking-widest text-secondary uppercase'>Member zone</span>
				</Link>}
				renderItem={(item, className) => {
					if (item?.url === routes.shop.cart) {
						return <Button asChild variant='outline' size='md'
									   className='flex items-center gap-small relative'>
							<Link to={item?.url} key={item?.url}>
								{item?.icon}
								<Badge className='absolute -top-2 right-0 z-20 md:hidden'>{totalItems}</Badge>
								<span className='hidden md:inline-block'>
									{totalItems > 0 ? <>{totalItems} item{totalItems > 1 ? 's' : ''}</> : 'Cart'}
								</span>
							</Link>
						</Button>;
					}
					return <Link to={item?.url ? item?.url : ''} className={className}
								 key={item.url}>{item.icon}{item.label}</Link>;
				}}
			/>
		</DefaultHeader>

		<div className='grow h-full self-stretch'>
			{props.children}
		</div>

		{mouthguardClubData ? <DefaultFooter
			footerLinkItems={compact(mouthguardClubData?.mouthguardClubCom?.data?.attributes?.footerLinkItems)}
			socials={compact(mouthguardClubData?.mouthguardClubCom?.data?.attributes?.socials)}
			termsAndConditionsUrl={mouthguardClubData?.mouthguardClubCom?.data?.attributes?.termsAndConditionsUrl ? mouthguardClubData?.mouthguardClubCom?.data?.attributes?.termsAndConditionsUrl : ''}
		/> : ''}
	</div>;
}

interface MemberZoneLayoutProps {
	children: React.ReactNode;
}