import React, {useState} from 'react';
import useMouthguardDesignsListQuery from '@mgp-fe/shared/core-api/queries/mouthguard-design/list.ts';
import CustomerDesignCard from '@mgp-fe/shared/modules/mouthguard-design/components/CustomerDesignCard.tsx';
import {Button} from '@mgp-fe/shared/ui/button.tsx';
import {Card} from '@mgp-fe/shared/ui/card.tsx';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {MouthguardDesignCollectionModel} from '@mgp-fe/shared/core-api/domain/mouthguard.ts';
import DesignerModal from '@mgp-fe/shared/modules/mouthguard-design/components/DesignerModal.tsx';
import useAuth from '@mgp-fe/shared/modules/auth/hooks/useAuth.ts';
import {Customer} from '@mgp-fe/shared/core-api/domain/user.ts';

export default function CustomerDesignsSection() {
	const {user} = useAuth<Customer>();
	const designsQuery = useMouthguardDesignsListQuery({
		params: {
			order: {
				createdAt: 'desc',
			},
			customer: user?.id,
		},
		options: {
			enabled: !!user?.id,
		},
	});

	const designsTeamQuery = useMouthguardDesignsListQuery({
		params: {
			order: {
				createdAt: 'desc',
			},
			team: user?.teams.map(t => t.id),
		},
		options: {
			enabled: (user?.teams.map(t => t.id).length || 0) > 0,
		},
	});

	// null means create new design
	const [selectedDesign, setSelectedDesign] = useState<MouthguardDesignCollectionModel | null | undefined>(undefined);

	const userDesigns = designsQuery.data?.data['hydra:member'] || [];

	const teamDesigns = designsTeamQuery.data?.data['hydra:member'] || [];

	return <>
		{selectedDesign !== undefined ? <DesignerModal
			onClose={() => setSelectedDesign(undefined)}
			design={selectedDesign}/> : ''}
		<h2 className='uppercase'>My designs</h2>
		<section className='grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-medium mt-small mb-medium'>
			<Button variant='ghost' asChild onClick={() => setSelectedDesign(null)}>
				<Card
					spacing='lg'
					className='min-h-[260px] flex flex-col p-0 rounded-lg !h-60 sm:!h-full justify-center items-center cursor-pointer relative'>
					<FontAwesomeIcon
						icon='circle-plus'
						className='text-secondary opacity-5 -z-10 absolute top-auto left-auto right-auto bottom-auto h-[70%] w-auto m-4'/>
					<h3 className='text-3xl text-primary'>New design</h3>
					<p className='text-foreground/80 px-small text-center'>Create a new design from scratch</p>
				</Card>
			</Button>

			{userDesigns.map(design => <CustomerDesignCard
				key={design.id}
				design={design}
				onEdit={setSelectedDesign}/>)}
		</section>

		<h2 className='uppercase'>Team designs</h2>
		{!designsQuery.isFetching && teamDesigns.length === 0 ?
			<p className='text-foreground/80'>You are not part of any team which has design.</p> : ''}
		<section className='grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-medium mt-small'>
			{teamDesigns.map(design => <CustomerDesignCard
				key={design.id}
				design={design}
				onEdit={setSelectedDesign}
			/>)}
		</section>
	</>;
}