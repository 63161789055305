import React, {useContext, useMemo} from 'react';
import useAuth from '@mgp-fe/shared/modules/auth/hooks/useAuth.ts';
import {Customer} from '@mgp-fe/shared/core-api/domain/user.ts';
import {cn} from '@mgp-fe/shared/utils';
import {OnboardingContext} from '@mgp-fe/shared/modules/onboarding';
import {Button} from '@mgp-fe/shared/ui/button.tsx';


const Stepper = () => {
	const {user} = useAuth<Customer>();
	const onboardingContext = useContext(OnboardingContext);

	const accountEnabled = useMemo(
		() => !!user && !!onboardingContext.selectedDesign,
		[user, onboardingContext.selectedDesign],
	);

	const orderEnabled = useMemo(
		() => user && accountEnabled
			&& !!user.defaultShippingAddress
			&& !!user.defaultBillingAddress
			&& !!user.name
			&& !!user.email
			&& !!user.phone,
		[user, onboardingContext.selectedDesign, accountEnabled],
	);

	return <div className='max-w-full lg:w-[660px] mx-4 md:mx-auto'>
		<div className='flex px-4 sm:px-6 md:px-12 pt-6 pb-2 md:pb-0'>
			<div className='rounded-full p-2 bg-secondary w-[32px] h-[32px] grow-0'>
				<div className={cn(
					'rounded-full p-2 bg-light-blue w-[12px] h-[12px] mx-auto hover:bg-secondary',
					onboardingContext.onboardingStep === 'design' ? 'bg-primary' : 'bg-light',
				)}/>
			</div>

			<div className={cn('border-t-4 grow mt-[14px] transition-all duration-500',
				accountEnabled ? 'border-t-white' : 'border-t-white/50')}/>

			<div className={cn('rounded-full p-2 bg-secondary w-[32px] h-[32px] grow-0',
				accountEnabled ? '' : 'opacity-50')}>
				<div
					className={`rounded-full p-2 bg-light-blue w-[12px] h-[12px] mx-auto hover:bg-secondary ${onboardingContext.onboardingStep === 'account' ? 'bg-primary' : 'bg-light'}`}></div>
			</div>

			<div className={cn(
				'border-t-4 grow mt-[14px] transition-all duration-500',
				orderEnabled ? 'border-t-white' : 'border-t-white/50',
			)}/>

			<div className={cn('rounded-full p-2 bg-secondary w-[32px] h-[32px] grow-0',
				orderEnabled ? '' : 'opacity-50')}>
				<div
					className={`rounded-full p-2 bg-light-blue w-[12px] h-[12px] mx-auto hover:bg-secondary ${onboardingContext.onboardingStep === 'order' ? 'bg-primary' : 'bg-light'}`}></div>
			</div>
		</div>
		<div className='flex place-content-between md:px-8 md:py-4 lg:py-6'>
			<Button
				onClick={() => onboardingContext.setOnboardingStep('design')}
				className={`text-center text-[14px] normal-case !no-underline sm:text-2xl md:text-3xl ${onboardingContext.onboardingStep === 'design' ? 'font-bold  text-primary' : 'text-light font-normal'}`}
				variant='link'
			>
				Design
			</Button>
			<Button
				onClick={accountEnabled ? undefined : (e) => {
					e.preventDefault();
					onboardingContext.setOnboardingStep('design');
				}}
				className={cn(
					'text-center normal-case !no-underline text-[14px] sm:text-2xl md:text-3xl px-5 flex',
					onboardingContext.onboardingStep === 'account' ? 'font-bold  text-primary' : 'font-normal',
					accountEnabled ? 'cursor-pointer text-light' : 'cursor-not-allowed text-light/50',
				)}
				variant='link'
			>
				Account
			</Button>
			<Button
				onClick={orderEnabled ? undefined : (e) => {
					e.preventDefault();
					onboardingContext.setOnboardingStep('design');
				}}
				className={cn(
					'text-center normal-case !no-underline text-[14px] sm:text-2xl md:text-3xl',
					onboardingContext.onboardingStep === 'order' ? 'font-bold  text-primary' : 'font-normal',
					orderEnabled ? 'cursor-pointer text-light' : 'cursor-not-allowed text-light/50',
				)}
				variant='link'
			>
				Order
			</Button>
		</div>
	</div>;
};

export default Stepper;
