import React from 'react';
import {NavLink, useLocation} from 'react-router-dom';
import OrdersCardsList from '@mgp-fe/shared/modules/orders/components/OrdersCardsList.tsx';
import {OrderModel} from '@mgp-fe/shared/core-api/domain/order.ts';

export default function CompletedStepPage() {
	const location = useLocation();
	const order = location.state.order as OrderModel;

	if (!order.orderedAt) return <>Missing order</>;

	return <div className='w-fit mx-auto'>
		<h1>Checkout completed</h1>
		<p>Thank you for your order!</p>

		<OrdersCardsList
			showItems
			className='w-full my-large'
			orders={order ? [order] : []}
			navLinkComponent={({children, ...props}) => <NavLink
				{...props}
				to={`/orders/${props.order.id}`}
			>{children}</NavLink>}
		/>
	</div>;
}
