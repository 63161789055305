import React, {PropsWithChildren, useEffect, useMemo, useState} from 'react';
import OnboardingContext from './OnboardingContext.ts';
import {OnboardingContextState, OnboardingMode, OnboardingStepStates} from './domain.ts';
import {useLocation} from "react-router-dom";

const OnboardingProvider = ({children, initialMode, usingOauth}: PropsWithChildren<OnboardingProviderProps>) => {
	const [selectedDesign, setSelectedDesign] = useState<string | undefined>(undefined);
	const [onboardingStep, setOnboardingStep] = useState<OnboardingStepStates>('design');
	const [mode, setMode] = useState(initialMode);
	const [oauthUsed, setOauthUsed] = useState(usingOauth ?? false);

	const location = useLocation();

	// TODO: Possibly remove useMemo since these operations do not seem expensive unless it prevents context triggered re-renders
	const value = useMemo<OnboardingContextState>(() => ({
		selectedDesign,
		setSelectedDesign,
		onboardingStep,
		setOnboardingStep,
		mode,
		setMode,
		oauthUsed,
		setOauthUsed,
	}), [selectedDesign, setSelectedDesign, onboardingStep, setOnboardingStep, mode, setMode, oauthUsed, setOauthUsed]);

	useEffect(() => {
		const searchParams = new URLSearchParams(location.search);

		if (searchParams.has('usingOauth') && searchParams.get('usingOauth')) {
			setOauthUsed(true);
		}
	}, [location]);

	return <OnboardingContext.Provider value={value}>
		{children}
	</OnboardingContext.Provider>;
};

interface OnboardingProviderProps {
	initialMode: OnboardingMode;
	usingOauth?: boolean;
}

export default OnboardingProvider;
