import {useQuery, UseQueryOptions} from '@tanstack/react-query';
import keysResolver from '@mgp-fe/shared/core-api/keysResolver.ts';
import endpoints from '@mgp-fe/shared/core-api/endpoints.ts';
import coreApiClient from '@mgp-fe/shared/core-api/client.ts';
import {AxiosResponse} from 'axios';
import {HydraListResponse} from '@mgp-fe/shared/core-api/domain/base.ts';
import {AvailablePromotionsCollectionModel} from '@mgp-fe/shared/core-api/domain/promotions.ts';


export default function useGetAvailablePromotionsQuery(props?: AvailablePromotionsQueryProps) {
	return useQuery<AvailablePromotionsQueryResponse>({
		queryKey: keysResolver(endpoints.cart.my.availablePromotions, 'get'),
		queryFn: () => coreApiClient.get(endpoints.cart.my.availablePromotions),
		...props?.options,
	});
}

export interface AvailablePromotionsQueryProps {
	options?: UseQueryOptions<AvailablePromotionsQueryResponse>
}

export type AvailablePromotionsQueryResponse = AxiosResponse<HydraListResponse<AvailablePromotionsCollectionModel>>