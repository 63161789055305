import React from 'react';
import useInvitationsListQuery from '@mgp-fe/shared/core-api/queries/invitation/list.ts';
import {Alert, AlertDescription, AlertTitle} from '@mgp-fe/shared/ui/alert.tsx';
import InvitationCard, {InvitationCardSkeleton} from '@mgp-fe/shared/modules/invitations/components/InvitationCard.tsx';
import {Link} from 'react-router-dom';
import routes from '@mgp-fe/customer/router/routes.ts';
import { Button } from '@mgp-fe/shared/ui/button';

export default function InvitationsSection() {
	const invitationsQuery = useInvitationsListQuery({
		params: {
			status: ['sent', 'resent'],
		},
	});

	return <section className='flex flex-col gap-small'>
		<h3 className='text-secondary mb-0'>Invitations</h3>
		{invitationsQuery.isFetching ? <InvitationCardSkeleton/> : ''}

		{!invitationsQuery.isFetching && invitationsQuery.data?.data['hydra:member'].length === 0 ?
			<Alert className='mt-0 p-medium'>
				<AlertTitle className='text-muted/80'>
					You don&apos;t have any invitations.
				</AlertTitle>
				<AlertDescription>
					<p>
						Contact your coach to invite you to team or your doctor to set up your scan location.
					</p>

					<Button asChild variant='link' size='md' className='!pl-0 !pb-0 mt-small'>
						<Link to={routes.invitations.index}>
							See all invitations
						</Link>
					</Button>
				</AlertDescription>
			</Alert> : ''}

		{invitationsQuery.data?.data['hydra:member']
			.map(i => <InvitationCard key={i.id} invitation={i}/>)}
	</section>;
}
