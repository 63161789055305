import React from 'react';
import {Card} from '@mgp-fe/shared/ui/card.tsx';
import AddressFormatted from '@mgp-fe/shared/modules/formatters/AddressFormatted.tsx';
import {AddressBookCollectionModel} from '@mgp-fe/shared/core-api/domain/address.ts';
import AddressControls from './AddressControls';

export default function AddressesList({addressBook}: AddressesListProps) {
	return <div className='grid md:grid-cols-2 gap-medium'>
		{addressBook.map(ab => <Card spacing='sm' key={ab.id}>
			<AddressFormatted address={ab.address}/>

			<AddressControls addressBookItem={ab}/>
		</Card>)}
	</div>;
}

interface AddressesListProps {
	addressBook: AddressBookCollectionModel[];
}