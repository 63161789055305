import {default as checkoutRoutes} from '@mgp-fe/shared/modules/shop/components/checkout/routes.ts';

export default {
	shop: {
		index: '/shop',
		cart: '/cart',
		checkout: checkoutRoutes,
	},
	productDetails: '/product-details/:id',
	index: '/',
	invitations: {
		index: '/invitations',
	},
	gardDesigns: {
		index: '/gard-designs',
		designer: '/designer',
		designerDetail: '/designer/:id',
		designerDetailLink: (id: string) => `/designer/${id}`,
	},
	onboarding: {
		index: '/onboarding',
	},
	orders: {
		index: '/orders',
		detail: '/orders/:id',
		detailLink: (id: string) => `/orders/${id}`,
	},
	scans: {
		index: '/scans',
	},
	scansRequests: {
		index: '/scans-requests',
	},
	vouchers: {
		index: '/vouchers',
	},
	resetPassword: '/reset-password',
};