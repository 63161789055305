import React from 'react';
import MemberZoneLayout from '@mgp-fe/customer/modules/common/ui/MemberZoneLayout.tsx';
import CustomerDesignsSection from '@mgp-fe/shared/modules/mouthguard-design/components/CustomerDesignsSection.tsx';

export default function MouthguardDesignsIndexPage() {
	return <MemberZoneLayout>
		<main className='container'>
			<CustomerDesignsSection />
		</main>
	</MemberZoneLayout>;
}