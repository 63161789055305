import React, {ReactNode} from 'react';
import {Tooltip, TooltipContent, TooltipProvider, TooltipTrigger} from '@mgp-fe/shared/ui/tooltip.tsx';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

export default function InfoTooltip({className, children}: InfoTooltipProps) {
	return <TooltipProvider>
		<Tooltip delayDuration={0}>
			<TooltipTrigger className={className}><FontAwesomeIcon icon='info-circle' fontVariant='far'/></TooltipTrigger>
			<TooltipContent className='border-muted bg-background rounded-md max-w-64 p-4 text-center'>
				{children}
			</TooltipContent>
		</Tooltip>
	</TooltipProvider>;
}

interface InfoTooltipProps {
	className?: string;
	children: ReactNode;
}