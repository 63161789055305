import React from 'react';
import useAuth from '@mgp-fe/shared/modules/auth/hooks/useAuth.ts';
import {Button} from '@mgp-fe/shared/ui/button';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import CustomerPartnerLocationCard
	from '@mgp-fe/shared/modules/partner-locator/components/CustomerPartnerLocationCard.tsx';
import Modal from '@mgp-fe/shared/ui/Modal.tsx';
import useToggle from '@mgp-fe/shared/hooks/useToggle.ts';
import ChangePasswordForm from '@mgp-fe/shared/modules/account/components/profile/ChangePasswordForm.tsx';
import UpdateProfileForm from '@mgp-fe/shared/modules/account/components/profile/UpdateProfileForm.tsx';
import PhoneNumberFormatted from '@mgp-fe/shared/modules/formatters/PhoneNumberFormatted.tsx';
import OAuthConnections from "@mgp-fe/shared/modules/account/components/profile/OAuthConnections.tsx";


export default function ProfilePage() {
	const {user} = useAuth();
	const diplayUpdateProfileModal = useToggle({initialState: false});
	const displayChangePasswordModal = useToggle({initialState: false});

	const initialFirstName = user?.name?.split(' ')?.[0]?.[0] || null;
	const initialLastName = user?.name?.split(' ')?.[1]?.[0] || null;

	return <div className='flex flex-col gap-medium'>
		<section className='grid grid-cols-5'>
			<h2 className='mt-0 mb-mini w-full col-span-5'>My profile</h2>

			<div className='flex flex-col items-center justify-center gap-small col-span-5 sm:col-span-2'>
				<div
					className='w-32 h-32 bg-muted/20 font-semibold text-3xl tracking-wider rounded-full flex items-center justify-center'>
					{initialFirstName
						? <>{initialFirstName}.{initialLastName ? `${initialLastName}.` : ''}</>
						: <FontAwesomeIcon icon='user' className='text-muted'/>}
				</div>
				{/*TODO*/}
				{/*<Button variant='link' className='text-muted' size='sm'*/}
				{/*	icon={<FontAwesomeIcon icon='image-portrait' className='mr-mini'/>}>*/}
				{/*	{user?.profilePhotoUrl ? 'Edit' : 'Add'} profile image*/}
				{/*</Button>*/}
			</div>

			<div className='heading-text-secondary [&>h3]:mt-small col-span-5 sm:col-span-3 max-w-fit mx-auto'>
				<h3>Your name</h3>
				<p>{user?.name || <i>Missing</i>}</p>

				<h3>E-mail address</h3>
				<p>{user?.email || <i>Missing</i>}</p>

				<h3>Phone number</h3>
				<p className='flex gap-mini mt-mini items-center'>
					<PhoneNumberFormatted phoneNumber={user?.phone} showCountryFlag={true} />
				</p>

				<div className='flex flex-col md:flex-row flex-wrap justify-start items-center mt-medium'>
					<Button
						className='w-full sm:w-auto md:w-1/2 lg:w-auto'
						size='md' onClick={diplayUpdateProfileModal.on}>
						Edit profile
					</Button>
					<Modal
						isOpen={diplayUpdateProfileModal.state}
						onClose={diplayUpdateProfileModal.off}
						title='Edit profile'>
						<UpdateProfileForm onSuccess={diplayUpdateProfileModal.off}/>
					</Modal>

					<Button
						className='w-full sm:w-auto md:w-1/2 xl:w-auto'
						size='md'
						variant='link'
						icon={<FontAwesomeIcon icon='key'/>}
						onClick={displayChangePasswordModal.on}>
						Change password
					</Button>
					<Modal
						isOpen={displayChangePasswordModal.state}
						onClose={displayChangePasswordModal.off}
						title='Change password'>
						<ChangePasswordForm onSuccess={displayChangePasswordModal.off}/>
					</Modal>
				</div>
			</div>
		</section>

		{user && user['@type'] === 'Customer' ? <section>
			<OAuthConnections/>
		</section> : ''}

		{user && user?.['@type'] === 'Customer'
			? <div className='max-w-xl'>
				<h3 className='mb-small'>My partner location</h3>
				<CustomerPartnerLocationCard/>
			</div> : ''}
	</div>;
}
