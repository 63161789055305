import React from 'react';
import useInvitationsListQuery from '@mgp-fe/shared/core-api/queries/invitation/list.ts';
import InvitationCard, {InvitationCardSkeleton} from '@mgp-fe/shared/modules/invitations/components/InvitationCard.tsx';
import MemberZoneLayout from '@mgp-fe/customer/modules/common/ui/MemberZoneLayout.tsx';
import {Button} from '@mgp-fe/shared/ui/button.tsx';
import routes from '@mgp-fe/customer/router/routes';
import { Link } from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

export default function InvitationsPage() {
	const invitationsQuery = useInvitationsListQuery();

	return <MemberZoneLayout>
		<main className='container grid md:grid-cols-2 gap-medium'>
			<Button asChild className='w-fit pl-0 pb-0 text-muted' variant='link'>
				<Link to={routes.index}>
					<FontAwesomeIcon icon='chevron-left' className='mr-2'/>
					Back to overview
				</Link>
			</Button>
			<h2 className='col-span-2 m-0 p-0 leading-none flex flex-col'>
				My invitations
			</h2>

			{invitationsQuery.isFetching ? [...Array(3)].map((_, i) => <InvitationCardSkeleton key={i}/>) : ''}

			{invitationsQuery.data?.data['hydra:member']
				.map(i => <InvitationCard key={i.id} invitation={i}/>)}
		</main>
	</MemberZoneLayout>;
}
