import React, {useEffect, useState} from 'react';
import MemberZoneLayout from '@mgp-fe/customer/modules/common/ui/MemberZoneLayout.tsx';
import useOrdersListQuery from '@mgp-fe/shared/core-api/queries/order/list.ts';
import OrdersCardsList, {OrdersListSkeleton} from '@mgp-fe/shared/modules/orders/components/OrdersCardsList.tsx';
import {Link, NavLink, Outlet, useNavigate, useParams, useSearchParams} from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import routes from '@mgp-fe/customer/router/routes.ts';
import defaultTheme from '@mgp-fe/shared/ui/theme.ts';
import {Button} from '@mgp-fe/shared/ui/button.tsx';
import {getCollectionSearchParams} from '@mgp-fe/shared/core-api/utils.ts';
import CardsPagination from '@mgp-fe/shared/ui/pagination/CardsPagination.tsx';
import {Alert, AlertDescription, AlertTitle} from '@mgp-fe/shared/ui/alert';

export default function OrdersIndexPage() {
	const navigate = useNavigate();
	const {id} = useParams<{ id: string }>();
	const [searchParams, setSearchParams] = useSearchParams();

	const itemsPerPage = 5;
	const page = parseInt(searchParams.get('page') || '1');
	const ordersQuery = useOrdersListQuery({
		params: {page, itemsPerPage},
		options: {keepPreviousData: true},
	});

	const [isLargeScreen, setIsLargeScreen] = useState(false);
	useEffect(() => {
		const handleResize = () => setIsLargeScreen((window?.innerWidth || 0) > parseInt(defaultTheme.screens.lg.replace('px', '')));
		handleResize();
		window.addEventListener('resize', handleResize);

		return () => window.removeEventListener('resize', handleResize);
	}, []);

	useEffect(() => {
		if (!ordersQuery.data?.data['hydra:member'][0]) return;

		if (!id && isLargeScreen) {
			navigate(routes.orders.detail.replace(':id', ordersQuery.data.data['hydra:member'][0].id));
		}
	}, [id, isLargeScreen, navigate, ordersQuery.data]);

	const lastPage = parseInt(getCollectionSearchParams('last', ordersQuery.data?.data).get('page') || '1');


	if(!ordersQuery.isFetching && ordersQuery.data?.data['hydra:totalItems'] === 0) {
		return <MemberZoneLayout>
			<main className='container'>
				<Alert variant='warning'>
					<AlertTitle>
						You have no orders yet.
					</AlertTitle>
					<AlertDescription>
						<Link to={routes.shop.index}>Start shopping</Link> to create your first order.
					</AlertDescription>
				</Alert>
			</main>
		</MemberZoneLayout>;
	}

	return <MemberZoneLayout>
		<main className='container flex flex-wrap'>
			{(isLargeScreen || !id) ? <div className='flex-col gap-small w-full shrink-0 lg:w-3/12'>
				{ordersQuery.isFetching
					? <OrdersListSkeleton
						itemsCount={page >= lastPage ? (ordersQuery.data?.data['hydra:totalItems'] || 0) % itemsPerPage : itemsPerPage}
					/>
					: <OrdersCardsList
						className='w-full'
						orders={ordersQuery.data?.data['hydra:member']}
						activeOrderId={id}
						navLinkComponent={({children, ...props}) => <NavLink
							{...props}
							to={`${routes.orders.detailLink(props.order.id)}?${searchParams.toString()}`}
						>{children}</NavLink>}
					/>}

				<CardsPagination
					page={page}
					lastPage={lastPage}
					changePageHandler={p => setSearchParams({page: p.toString()})}
					labels={{previous: 'Newer', next: 'Older'}}/>
			</div> : ''}

			<div className='w-full shrink-0 lg:w-9/12 lg:pl-medium mt-0'>
				{(id && !isLargeScreen) ? <Button asChild size='md' className='mb-small'>
					<Link to={routes.orders.index}><FontAwesomeIcon icon='chevron-left'/> Back to list</Link>
				</Button> : ''}

				<Outlet/>
			</div>
		</main>
	</MemberZoneLayout>;
}


