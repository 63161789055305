import useLocalStorage from '@mgp-fe/shared/hooks/useLocalStorage.ts';
import {AUTH_LOCAL_STORAGE_KEY} from '@mgp-fe/shared/core-api/domain/base.ts';
import React, {useContext, useEffect} from 'react';
import {OnboardingContext} from '@mgp-fe/shared/modules/onboarding';

export default function LoginWithToken() {
	const onboardingContext = useContext(OnboardingContext);
	const [, setToken] = useLocalStorage<string | null>(AUTH_LOCAL_STORAGE_KEY, null);

	useEffect(() => {
		if (!onboardingContext.mode.emailSignToken) return;
		setToken(onboardingContext.mode.emailSignToken || '');

		onboardingContext.setMode(prevState => {
			return {...prevState, emailSignToken: undefined};
		});
	}, []);

	return <></>;
}