import React, {useMemo} from 'react';
import useAuth from '@mgp-fe/shared/modules/auth/hooks/useAuth.ts';
import {UserWithAddresses} from '@mgp-fe/shared/core-api/domain/user.ts';
import {Alert, AlertDescription, AlertTitle} from '@mgp-fe/shared/ui/alert.tsx';
import {Button} from '@mgp-fe/shared/ui/button.tsx';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Card, CardContent, CardFooter, CardHeader, CardTitle} from '@mgp-fe/shared/ui/card.tsx';
import AddressFormatted from '@mgp-fe/shared/modules/formatters/AddressFormatted.tsx';
import AddressControls, {AddressCardControls} from './AddressControls';
import {AddressBookCollectionModel} from '@mgp-fe/shared/core-api/domain/address.ts';
import Skeleton from '@mgp-fe/shared/ui/skeleton';

export default function DefaultAddressCard({
	type,
	addressBook,
	addNewHandler,
	addressControls = ['edit', 'delete', 'makeDefault'],
	title,
	forUser,
}: DefaultAddressCardProps) {
	const {user} = useAuth<UserWithAddresses>();
	const customer = useMemo(
		() => forUser ? forUser : user,
		[forUser, user],
	);

	const addressBookItem = useMemo(
		() => addressBook ? addressBook.find(ab => ab.address.id === customer?.[type]?.id) : undefined,
		[addressBook, type, customer],
	);

	if (!customer) return <></>;

	const typeView = type.replace('default', '').replace('Address', '');
	const titleView = title || typeView;

	if (!customer[type]) {
		return <Alert variant='warning' className='m-0'>
			<AlertTitle className='capitalize'>{titleView}</AlertTitle>
			<AlertDescription>
				{forUser ? 'User has not set ' : 'You have not set '} default {typeView} address.
			</AlertDescription>
			{addNewHandler ? <Button
				onClick={() => addNewHandler(type)}
				className='mt-small rounded-sm'
				size='sm'
				variant='outline'
				icon={<FontAwesomeIcon icon='plus'/>}>
				Add new address
			</Button> : ''}
		</Alert>;
	}

	return <Card variant='secondary'>
		<CardHeader>
			<CardTitle className='capitalize'>{titleView}</CardTitle>
		</CardHeader>
		<CardContent>
			<AddressFormatted address={customer[type]!}/>
		</CardContent>
		<CardFooter>
			{addressBookItem ? <AddressControls showControls={addressControls} addressBookItem={addressBookItem}/> : ''}
		</CardFooter>
	</Card>;
}

export function DefaultAddressCardSkeleton() {
	return <Card variant='secondary'>
		<CardHeader>
			{/* Placeholder for CardTitle */}
			<Skeleton className='h-5 w-1/4'/>
		</CardHeader>

		<CardContent>
			{/* Placeholder for AddressFormatted component */}
			<Skeleton className='h-4 w-1/2 mb-2'/>
			<Skeleton className='h-4 w-2/3 mb-2'/>
			<Skeleton className='h-4 w-3/4 mb-2'/>
			<Skeleton className='h-4 w-1/3'/>
		</CardContent>

		<CardFooter>
			{/* Placeholder for AddressControls */}
			<Skeleton className='h-6 w-1/4'/>
		</CardFooter>
	</Card>;
}

interface DefaultAddressCardProps {
	title?: React.ReactNode;
	forUser?: UserWithAddresses | undefined | null;
	type: defaultAddressType;
	addressBook?: AddressBookCollectionModel[];
	addNewHandler?: (type: defaultAddressType) => void;
	addressControls?: AddressCardControls[];
}

type defaultAddressType = 'defaultShippingAddress' | 'defaultBillingAddress';