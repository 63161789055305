/* eslint-disable */
// @ts-nocheck
import type * as Types from '@mgp-frontend/shared/cms-graphql/domain/types';

import { gql } from '@apollo/client';
export type HeaderNavigationItemsFragment = { __typename?: 'ComponentNavigationNavigationItem', id: string, label?: string | null, url?: string | null };

export const HeaderNavigationItemsFragmentDoc = gql`
    fragment HeaderNavigationItems on ComponentNavigationNavigationItem {
  id
  label
  url
}
    `;