import React, {useEffect, useState} from 'react';
import useProductDetailQuery from '@mgp-fe/shared/core-api/queries/product/item.ts';
import MemberZoneLayout from '@mgp-fe/customer/modules/common/ui/MemberZoneLayout.tsx';
import {useParams} from 'react-router-dom';
import getClientEnv from '@mgp-fe/shared/utils/env-var-resolver.ts';
import ProductDetailsHeroCard, {
	ProductDetailsHeroCardSkeleton,
} from '@mgp-fe/shared/modules/shop/components/ProductDetailsHeroCard.tsx';
import {IframeSkeleton} from '@mgp-fe/shared/ui/IframeSkeleton.tsx';

export default function ProductDetailPage() {
	const params = useParams();
	const [iframeHeight, setIframeHeight] = useState(0);
	const [loading, setLoading] = useState(true);
	const productQuery = useProductDetailQuery({
		id: params.id || '',
	});
	const pageUrl = new URL('/', getClientEnv('webAppUrl'));
	pageUrl.pathname = `/product-detail-content/${productQuery?.data?.data.id}`;
	pageUrl.searchParams.set('embeded', 'true');

	const handleResizeMessage = (e: MessageEvent) => {
		if (e.data?.event === 'resize') {
			setIframeHeight(e.data.frameHeight);
		}
	};

	useEffect(() => {
		window.addEventListener('message', handleResizeMessage);

		return () => window.removeEventListener('message', handleResizeMessage);
	}, []);

	return <MemberZoneLayout>
		<main className='container my-10'>
			{productQuery?.isFetching && <ProductDetailsHeroCardSkeleton/>}
			{productQuery?.data?.data && <ProductDetailsHeroCard productQuery={productQuery?.data?.data} locale='en'/>}
			{loading && <IframeSkeleton/>}
			<iframe
				id='myIFrame'
				src={pageUrl.toString()}
				className='relative max-w-[83rem] w-full h-fit bg-transparent overflow-hidden'
				style={{height: iframeHeight + 'px'}}
				onLoad={() => setLoading(false)}
			/>
		</main>
	</MemberZoneLayout>
	;
}