import React from 'react';

export default function DateFormatted({
	date,
	showDateOnly = false,
	dateTimeOptions = {
		dateStyle: 'short',
		timeStyle: 'short',
	},
	missingDateLabel,
}: DateFormattedProps) {
	if(!date) return missingDateLabel || 'Missing date';
	if(typeof date !== 'object') return 'Invalid date';

	return showDateOnly ? date.toLocaleDateString()
		: date.toLocaleString(undefined, dateTimeOptions);
}

export interface DateFormattedProps {
	date?: Date | null;
	showDateOnly?: boolean;
	dateTimeOptions?: Intl.DateTimeFormatOptions;
	missingDateLabel?: React.ReactNode;
}