import {useMutation, UseMutationOptions} from '@tanstack/react-query';
import keysResolver from '@mgp-fe/shared/core-api/keysResolver.ts';
import endpoints from '@mgp-fe/shared/core-api/endpoints.ts';
import coreApiClient from '@mgp-fe/shared/core-api/client.ts';
import {AxiosError, AxiosResponse} from 'axios';


export default function useResetPasswordMutation(props?: UseResetPasswordMutationProps) {
	return useMutation({
		mutationKey: keysResolver(endpoints.authentication.resetPassword),
		mutationFn: d => coreApiClient.put(endpoints.authentication.resetPassword, d),
		...props,
	});
}

type UseResetPasswordMutationProps = UseMutationOptions<
	AxiosResponse<undefined>,
	AxiosError<{ message?: string }>,
	ResetPasswordMutationData
>;

export interface ResetPasswordMutationData {
	password: string;
	tokenId: string;
}