import React from 'react';
import {InputProps, TextInput} from '@mgp-fe/shared/ui/form/TextInput.tsx';

export default function DateInput(props: DateInputProps) {
	//TODO: rework to use from shadcn/ui
	return <div className='relative'>
		<TextInput {...props} type='date' />
		<span className='absolute bottom-2 right-2 w-8 h-8 bg-light -z-10'></span>
	</div> ;
}

type DateInputProps = InputProps;