import React from 'react';
import {OrderModel} from '@mgp-fe/shared/core-api/domain/order.ts';

export default function OrderCustomerSection({order}: OrderCustomerSectionProps) {
	return <>
		{order.partnerOrganization || order.partnerLocation ? <section>
			<h3>Ordered on behalf</h3>
			<ul className='flex flex-col gap-mini mt-mini'>
				{order.partnerOrganization &&
					<li><strong>Organization:</strong> {order.partnerOrganization?.name}</li>}
				{order.partnerLocation && <li><strong>Location:</strong> {order.partnerLocation?.name}</li>}
			</ul>
		</section> : <h3 className='text-secondary'>Customer</h3>}

		<section>
			<p>
				{order.partnerLocation || order.partnerOrganization ?
					<span className='text-secondary'>Ordered by </span> : ''}
				{order.customer.name} <br/>
				{order.customer.email}
				{order.customer.phone && <><br/>{order.customer.phone}</>}
			</p>
		</section>
	</>;
}

interface OrderCustomerSectionProps {
	order: OrderModel;
}