import React, {useMemo} from 'react';
import CartContentTable from '@mgp-fe/shared/modules/shop/components/CartContentTable.tsx';
import AddressFormatted from '@mgp-fe/shared/modules/formatters/AddressFormatted.tsx';
import useCartMyQuery from '@mgp-fe/shared/core-api/queries/cart/my.tsx';
import useStripeMyCardsListQuery from '@mgp-fe/shared/core-api/queries/stripe/my-cards.ts';
import PaymentMethodCard, {
	PaymentMethodCardSkeleton,
} from '@mgp-fe/shared/modules/account/components/payment-methods/PaymentMethodCard.tsx';
import {ShippingMethodCard} from '@mgp-fe/shared/modules/shop/components/checkout/ShippingStepPage.tsx';
import useCartMyShippingMethodsListQuery from '@mgp-fe/shared/core-api/queries/cart/my-shipping-methods.ts';
import {Button} from '@mgp-fe/shared/ui/button.tsx';
import routes from '@mgp-fe/shared/modules/shop/components/checkout/routes.ts';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {useNavigate} from 'react-router-dom';
import useCartCheckoutCompleteMutation, {
	CartCheckoutCompleteRequest,
	CheckoutCompleteOption,
} from '@mgp-fe/shared/core-api/mutations/cart/checkout-complete.ts';
import OrderAmountSummaryCard from '@mgp-fe/shared/modules/orders/components/order-detail/OrderAmountSummaryCard.tsx';
import {useMap} from 'usehooks-ts';
import OrderScanOption from '@mgp-fe/shared/modules/shop/components/checkout/OrderScanOption.tsx';
import usePartnerLocationsListQuery from '@mgp-fe/shared/core-api/queries/partner-location/list.ts';
import useStripePartnerCheckoutCardsListQuery from '@mgp-fe/shared/core-api/queries/stripe/partner-checkout-cards.ts';
import useNotify from '@mgp-fe/shared/ui/notifications/use-notify.ts';
import {Alert, AlertDescription, AlertTitle} from '@mgp-fe/shared/ui/alert.tsx';
import MoneyFormatted from '@mgp-fe/shared/modules/formatters/MoneyFormatted.tsx';
import AppliedCoupons from '@mgp-fe/shared/modules/promotions/AppliedCoupons.tsx';

export default function SummaryStepPage() {
	const navigate = useNavigate();
	const {notifyError} = useNotify();
	const myCartQuery = useCartMyQuery();
	const order = useMemo(() => myCartQuery.data?.data.order, [myCartQuery.data?.data.order]);

	const shippingMethodsQuery = useCartMyShippingMethodsListQuery();
	const myCardsQuery = useStripeMyCardsListQuery({
		options: {keepPreviousData: true},
	});
	const partnerLocationQuery = usePartnerLocationsListQuery();
	const selectedLocation = partnerLocationQuery.data?.data['hydra:member'].find(
		l => myCartQuery.data?.data.order.partnerLocation?.id === l.id,
	);
	const partnerCardsListQuery = useStripePartnerCheckoutCardsListQuery({
		locationId: selectedLocation?.id,
	});
	const completeMutation = useCartCheckoutCompleteMutation({
		onSuccess: data => navigate(routes.completed, {
			replace: true,
			state: {order: data.data.order},
		}),
		onError: e => {
			notifyError({
				title: e.response?.data['hydra:title'] || 'Error',
				message: e.response?.data['hydra:description'] || 'Something went wrong',
			});
			if (e.response?.status === 426) {
				myCartQuery.refetch();
			} else {
				navigate(routes.billing.index, {replace: true});
			}
		},
	});
	const [map, actions] = useMap<
		keyof CartCheckoutCompleteRequest,
		CartCheckoutCompleteRequest[keyof CartCheckoutCompleteRequest]
	>([
		['option', undefined],
		['scan', undefined],
		['dateOfScan', undefined],
	]);

	const paymentMethod = useMemo(
		() => myCartQuery.data?.data.order.partnerLocation ? [
			...(partnerCardsListQuery.data?.data.partnerLocation?.paymentMethods || []),
			...(partnerCardsListQuery.data?.data.partnerOrganization?.paymentMethods || []),
		].find(pm => pm.id === order?.payment?.paymentMethodId) || null
			: myCardsQuery.data?.data.paymentMethods.find(pm => pm.id === order?.payment?.paymentMethodId) || null,
		[myCardsQuery.data?.data.paymentMethods, myCartQuery.data?.data.order.partnerLocation, order?.payment?.paymentMethodId, partnerCardsListQuery.data?.data.partnerLocation?.paymentMethods, partnerCardsListQuery.data?.data.partnerOrganization?.paymentMethods],
	);

	const NoShippingAlert = () => order?.orderItems?.every(oi => oi.product?.virtual) ? <Alert variant='warning'>
		<AlertTitle>
			Shipping step skipped
		</AlertTitle>
		<AlertDescription>
			Shipping address / method is not required as you have only virtual products in your cart.
		</AlertDescription>
	</Alert> : <></>;

	return <div className='flex flex-col gap-medium'>
		<section className='grid md:grid-cols-4 gap-large mx-auto w-full'>
			<address className='col-span-4 md:col-span-2'>
				<h3 className='mb-small'>Shipping Address</h3>
				{order?.shippingAddress ? <AddressFormatted address={order?.shippingAddress}/> : 'No address selected'}
				<NoShippingAlert />
			</address>

			<address className='col-span-4 md:col-span-2'>
				<h3 className='mb-small'>Billing Address</h3>
				{order?.billingAddress ? <AddressFormatted address={order?.billingAddress}/> : 'No address selected'}
			</address>

			<div className='col-span-4 md:col-span-2'>
				<h3 className='mb-small'>Shipping Method</h3>
				{order?.shippingMethod ?
					<ShippingMethodCard shippingMethod={order.shippingMethod}
						shippingMethodsQuery={shippingMethodsQuery}/> : 'No shipping method selected'}
				<NoShippingAlert />
			</div>
			<div className='col-span-4 md:col-span-2 grid gap-small'>
				<h3 >Payment Method</h3>
				{paymentMethod ? <PaymentMethodCard cardProps={{spacing: 'sm'}} controls={[]} pm={paymentMethod}/> : ''}
				{(myCardsQuery.isFetching || partnerCardsListQuery.isFetching) && !paymentMethod && !!myCartQuery.data?.data.order.totalAmount.amount ?
					<PaymentMethodCardSkeleton cardProps={{spacing: 'sm'}}/> : ''}
				{(myCartQuery.data?.data.order.totalAmount.amount ?? 1) === 0
					? <Alert className='mt-0'>
						<AlertTitle>
							No payment method required
						</AlertTitle>
						<AlertDescription>
							Payment method is not required as you used voucher / promo code and amount to pay is <MoneyFormatted money={{amount:0, currency: 'USD'}} />.
						</AlertDescription>
					</Alert>: ''}
			</div>
		</section>

		<h3>Items in cart</h3>
		<CartContentTable controls={false}/>
		<AppliedCoupons controls={false} coupons={myCartQuery.data?.data.order.coupons}/>
		<section className='grid lg:grid-cols-2 gap-medium lg:gap-xlarge'>
			<OrderScanOption
				changeHandler={(k, v) => actions.set(k as keyof CartCheckoutCompleteRequest, v)}
				order={order}
				option={map.get('option') as CheckoutCompleteOption}/>

			{order ? <OrderAmountSummaryCard
				order={order}
				className='w-full border-2 md:border-none p-small md:p-0 rounded  [&>p.total]:text-3xl [&>p.total]:font-semibold [&>p.total]:text-primary [&>p.total]:uppercase'
				cardProps={{variant: 'ghost'}}
			/> : ''}
		</section>

		<section className='grid grid-cols-4 gap-large mx-auto w-full'>
			<Button
				onClick={() => navigate(routes.billing.index, {replace: true})}
				variant='link'
				icon={<FontAwesomeIcon icon='chevron-left' className='mr-2'/>}
				className='w-full sm:w-fit md:text-left pl-0 text-muted/70 col-span-4 sm:col-span-2 sm:mt-medium'>
				Billing
			</Button>
			<Button
				onClick={() => completeMutation.mutate(Object.fromEntries(map) as CartCheckoutCompleteRequest)}
				state={completeMutation.status}
				className='w-full sm:w-fit flex-row-reverse gap-mini col-span-4 sm:col-span-2 sm:mt-medium sm:ml-auto'
				icon={<FontAwesomeIcon icon='chevron-right' className='mr-2'/>}>
				Confirm
			</Button>
		</section>
	</div>;
}
