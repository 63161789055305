import React from 'react';
import MemberZoneLayout from '@mgp-fe/customer/modules/common/ui/MemberZoneLayout.tsx';
import ProductsList from '@mgp-fe/shared/modules/shop/components/ProductsList.tsx';
import useProductsListQuery from '@mgp-fe/shared/core-api/queries/product/list.ts';

export default function ShopPage() {

	const productsQuery = useProductsListQuery({
		params: {
			'channels.name': 'customer',
		},
	});

	return <MemberZoneLayout>
		<main className='container my-10'>
			<ProductsList productsQuery={productsQuery} />
		</main>
	</MemberZoneLayout>;
}
