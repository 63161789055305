import {useMutation, UseMutationOptions, useQueryClient} from '@tanstack/react-query';
import {AxiosError, AxiosResponse} from 'axios';
import {HydraError, Iri} from '@mgp-fe/shared/core-api/domain/base.ts';
import keysResolver from '@mgp-fe/shared/core-api/keysResolver.ts';
import endpoints from '@mgp-fe/shared/core-api/endpoints.ts';
import coreApiClient from '@mgp-fe/shared/core-api/client.ts';
import {OrderCollectionModel} from '@mgp-fe/shared/core-api/domain/order.ts';

export default function useOnboardingOrderSetupMutation(props?: UseOnboardingOrderMutationProps) {
	const queryClient = useQueryClient();

	return useMutation<AxiosResponse<OrderCollectionModel>, AxiosError<HydraError>, UpdateOnboardingOderMutationData>(
		keysResolver(endpoints.cart.my.onboardingOrderSetupCheckout, 'patch'),
		(d) => {
			return coreApiClient.patch<OrderCollectionModel>(endpoints.cart.my.onboardingOrderSetupCheckout, d);
		},
		{
			...props,
			onSuccess: async (result, variables, context) => {
				await queryClient.invalidateQueries(keysResolver(endpoints.cart.my.item));
				props?.onSuccess?.(result, variables, context);
			},
		},
	);
}

type UseOnboardingOrderMutationProps = UseMutationOptions<
    AxiosResponse<OrderCollectionModel>,
    AxiosError<HydraError>,
    UpdateOnboardingOderMutationData
>

export interface UpdateOnboardingOderMutationData {
    shippingAddress: Iri;
    shippingMethod: Iri;
    billingAddress: Iri;
}