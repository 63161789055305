'use client';

import LocaleContext, {LocaleContextValue} from '@mgp-fe/shared/localization/LocaleContext.ts';
import React, {useMemo} from 'react';

export default function LocaleContextProvider(props: LocaleContextProviderProps) {
	const value = useMemo<LocaleContextValue>(() => ({
		locale: props.locale || 'en',
	}), []);

	return <LocaleContext.Provider value={value}>
		{props.children}
	</LocaleContext.Provider>;
}

interface LocaleContextProviderProps {
	children: React.ReactNode | React.JSX.Element | React.JSX.Element[];
	locale?: string;
}