import React from 'react';
import useCartMyQuery from '@mgp-fe/shared/core-api/queries/cart/my.tsx';
import MoneyFormatted from '@mgp-fe/shared/modules/formatters/MoneyFormatted.tsx';
import {Button} from '@mgp-fe/shared/ui/button.tsx';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Table, TableBody, TableCell, TableFooter, TableHead, TableHeader, TableRow} from '@mgp-fe/shared/ui/table';
import {useRemoveItemFromCartMutation} from '@mgp-fe/shared/core-api/mutations/cart/my-items.ts';
import CartContentTableProductCell from '@mgp-fe/shared/modules/shop/components/CartContentTableProductCell.tsx';
import OrderItemCartControls from '@mgp-fe/shared/modules/shop/components/OrderItemCartControls.tsx';
import BottomRightIcon from '@mgp-fe/shared/assets/icons/botom-right-arrow.svg';
import {cn} from '@mgp-fe/shared/utils';

export default function CartContentTable({
	controls = true,
	removeButton = true,
}: CartContentTableProps) {
	const cartQuery = useCartMyQuery();
	const removeItemMutation = useRemoveItemFromCartMutation();

	return <Table className='table-auto rounded-xl'>
		<TableHeader className='border-b-0'>
			<TableRow>
				<TableHead>Product</TableHead>
				<TableHead className='text-right'>Price</TableHead>
				<TableHead className='w-52 text-center'>Quantity</TableHead>
				<TableHead className='text-right'>Total</TableHead>
				{controls && removeButton ? <TableHead className='text-right'/> : ''}
			</TableRow>
		</TableHeader>
		<TableBody>
			{cartQuery.data?.data.order.orderItems.length === 0 ? <TableRow>
				<TableCell colSpan={5} className='h3 text-center'>
                    Your cart is empty
				</TableCell>
			</TableRow> : null}
			{cartQuery.data?.data.order.orderItems.map(oi => <React.Fragment key={oi.id}>
				<TableRow key={oi.id}>
					<CartContentTableProductCell item={oi} controls={controls}/>
					<TableCell className='text-right'><MoneyFormatted money={oi.product.price}/></TableCell>
					<TableCell>
						{controls ? <OrderItemCartControls
							orderItem={oi}
						/> : <p className='text-muted mx-auto text-center'>{oi.quantity}x</p>}
					</TableCell>
					<TableCell className='text-right text-light'>
						{oi.discountedAmount.amount !== oi.originalAmount.amount ?
							<span className={cn('line-through text-sm text-muted/50')}>
								<MoneyFormatted money={oi.originalAmount}/>
								<br/>
							</span> : ''}
						<MoneyFormatted money={oi.discountedAmount}/>
					</TableCell>
					{controls && removeButton ? <TableCell className='align-middle text-right'>
						<Button
							onClick={() => removeItemMutation.mutate(oi)}
							variant='destructive'
							size='iconMd'>
							<FontAwesomeIcon icon='trash' size='xs'/>
						</Button>
					</TableCell> : ''}
				</TableRow>

				{oi.product.packageProducts.length !== 0 && <>{oi.product.packageProducts.map(p => <TableRow key={p.id}>
					<TableCell className='flex flex-nowrap items-center gap-small'>
						<BottomRightIcon className='fill-muted/50 w-7 h-7 mx-5 shrink-0'/>
						<img
							src={p.product.titleImage || ''}
							className={cn(
								'bg-transparent border-2 border-secondary-500/30 w-14 h-14 object-contain object-center aspect-auto rounded-lg',
							)}
							alt={oi.product.name}
						/>
						<div>{p.product.name}</div>
					</TableCell>
					<TableCell className='text-right'></TableCell>
					<TableCell>
						<p className='text-muted mx-auto text-center'>{p.quantity * oi.quantity}x</p>
					</TableCell>
					<TableCell className='text-right text-light'></TableCell>
				</TableRow>)}
				</>}
			</React.Fragment>)}
		</TableBody>
		<TableFooter>
			<TableRow>
				<TableCell className='text-right' colSpan={4}>
					<div className='grid'>
						{cartQuery.data?.data.order.discountedAmount.amount !== cartQuery.data?.data.order.originalAmount.amount ?
							<span className={cn('line-through text-sm text-muted/50')}>
								<MoneyFormatted money={cartQuery.data?.data.order.originalAmount}/>
							</span> : ''}
						<MoneyFormatted money={cartQuery.data?.data.order.discountedAmount}/>
					</div>
				</TableCell>
				{controls && removeButton ? <TableCell/> : ''}
			</TableRow>
		</TableFooter>
	</Table>;
}

export interface CartContentTableProps {
    controls?: boolean;
    removeButton?: boolean;
}