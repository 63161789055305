import React from 'react';
import useVouchersListQuery from '@mgp-fe/shared/core-api/queries/voucher/list.ts';
import VoucherCard, {VoucherCardSkeleton} from '@mgp-fe/shared/modules/vouchers/components/VoucherCard.tsx';
import {Button} from '@mgp-fe/shared/ui/button.tsx';
import {Link} from 'react-router-dom';
import routes from '@mgp-fe/customer/router/routes.ts';
import {NoVouchersAlert} from '@mgp-fe/customer/modules/dashboard/components/VouchersPage.tsx';

export default function VouchersSection() {
	const vouchersQuery = useVouchersListQuery({
		params: {exists: {usedAt: false}},
	});

	if (vouchersQuery.isFetching) return <section>
		<h3 className='mb-small text-secondary'>Vouchers</h3>
		<VoucherCardSkeleton/>
	</section>;

	return <section className='flex flex-col gap-small'>
		<h3 className='mb-0 text-secondary flex items-center justify-between'>
			<span>Vouchers</span>

			<Button variant='link' size='sm' className='pr-0 text-muted/90' asChild>
				<Link to={routes.vouchers.index}>All Vouchers</Link>
			</Button>
		</h3>

		{vouchersQuery.data?.data['hydra:member'].map(v => <VoucherCard voucher={v} key={v.id}/>)}

		{vouchersQuery.data?.data['hydra:totalItems'] === 0 ? <NoVouchersAlert/> : ''}
	</section>;
}
