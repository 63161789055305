import React, {useCallback, useMemo} from 'react';
import {VoucherCollectionModel} from '@mgp-fe/shared/core-api/domain/voucher.ts';
import {Card, CardContent, CardFooter} from '@mgp-fe/shared/ui/card.tsx';
import Skeleton from '@mgp-fe/shared/ui/skeleton';
import DateFormatted from '@mgp-fe/shared/modules/formatters/DateFormatted.tsx';
import {Button} from '@mgp-fe/shared/ui/button';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Badge} from '@mgp-fe/shared/ui/badge.tsx';
import useCart from '@mgp-fe/shared/modules/shop/hooks/useCart.tsx';
import useCartMyQuery from '@mgp-fe/shared/core-api/queries/cart/my.tsx';
import useApplyVoucherMutation from '@mgp-fe/shared/core-api/mutations/cart/apply-voucher.ts';

export default function VoucherCard({voucher}: VoucherCardProps) {
	const myCartQuery = useCartMyQuery();
	const applyVoucher = useApplyVoucherMutation({
		retry: 0,
	});
	const {addProductToCart} = useCart();
	const forProduct = voucher.product.product;

	const orderItemForVoucher = useMemo(
		() => myCartQuery.data?.data?.order.orderItems.find(oi => oi.product['@id'] === forProduct?.['@id']),
		[forProduct, myCartQuery.data?.data?.order.orderItems],
	);

	const useVoucher = useCallback(async () => {
		if(!forProduct) {
			alert('Product not found');
			return;
		}

		if(orderItemForVoucher) {
			applyVoucher.mutate({voucherCode: voucher.code});
			return;
		}
		await addProductToCart(forProduct);
	}, [addProductToCart, applyVoucher, forProduct, orderItemForVoucher, voucher.code]);

	return <Card>
		<CardContent className='flex gap-small items-center pb-0 pt-small'>
			{forProduct?.titleImage ? <img
				src={forProduct.titleImage}
				alt={forProduct.name}
				className='m-auto w-xlarge h-xlarge object-contain z-10'/>
				: <div className='bg-foreground m-auto w-xlarge h-xlarge object-contain z-10 rounded'></div>}

			<ul className='w-full grow'>
				<li><h4 className='leading-tight'>{forProduct?.name}</h4></li>
				<li>
					Code: {voucher.code}
				</li>
			</ul>
		</CardContent>

		<CardFooter className='flex flex-wrap gap-mini items-center justify-between mt-mini'>
			<span>
				{voucher.usedAt
					? <>Used at <span className='text-muted/80'><DateFormatted date={voucher.usedAt}/></span></>
					: <i>{voucher.orderItem ? 'Check cart' : 'Available to use'}</i>}
			</span>

			{voucher.orderItem
				? <Badge>Already applied</Badge>
				: <Button
					size='md'
					state={applyVoucher.status}
					onClick={useVoucher}
					icon={<FontAwesomeIcon icon='cart-plus'/>}>
					Use now
				</Button>}
		</CardFooter>
	</Card>;
}

export function VoucherCardSkeleton() {
	return <Card>
		<CardContent className='flex gap-small items-center pb-0 pt-small'>
			<Skeleton className='m-auto w-xlarge h-xlarge object-contain z-10 rounded'/>

			<ul className='w-full grow'>
				<li><Skeleton className='w-1/2 h-5 my-1'/></li>
				<li><Skeleton className='w-1/3 h-4 my-1'/></li>
			</ul>
		</CardContent>

		<CardFooter className='flex items-center justify-between'>
			<Skeleton className='w-1/4 h-4'/>
			<Skeleton className='w-1/4 h-8'/>
		</CardFooter>
	</Card>;
}

interface VoucherCardProps {
	voucher: VoucherCollectionModel;
}