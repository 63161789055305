import React from 'react';
import Logo from '@mgp-fe/shared/assets/logo/text-logo.svg';
import {Button, buttonVariants} from '@mgp-fe/shared/ui/button.tsx';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import useAuth from '@mgp-fe/shared/modules/auth/hooks/useAuth.ts';

export default function BadUserTypeInAppAlert({app}: BadUserTypeInAppAlertProps) {
	const {user, logout} = useAuth();

	if(!user) return <>This component is for signed user who signed in wrong app.</>;

	console.log(import.meta.env.VITE_APP_FE_AFFILIATE_URL);

	return <div className='w-screen h-screen flex flex-col items-center justify-center gap-8'>
		<Logo className='fill-primary w-52 md:w-72 lg:w-80'/>
		<h1 className='h3 md:h2 lg:h3 uppercase text-secondary text-4xl font-semibold'>This app is for {app}s
			only.</h1>

		<a href={{
			'Admin': import.meta.env.VITE_APP_FE_ADMIN_URL,
			'Customer': import.meta.env.VITE_APP_FE_CUSTOMER_URL,
			'Partner': import.meta.env.VITE_APP_FE_PARTNER_URL,
			'Affiliate': import.meta.env.VITE_APP_FE_AFFILIATE_URL,
		}[user['@type'] || ''] || '#'} className={buttonVariants()} target='_blank' rel='noreferrer'>
			<FontAwesomeIcon icon='arrow-up-right-from-square' className='mr-2'/>
			Go to <span className='uppercase'>{user['@type']}</span> area
		</a>
		<Button variant='outline' onClick={logout}>
			<FontAwesomeIcon icon='sign-out-alt' className='text-base'/>
			Logout
		</Button>
	</div>;
}

interface BadUserTypeInAppAlertProps {
	app: 'customer' | 'partner' | 'affiliate';
}