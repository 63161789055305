'use client';

import React, {useState} from 'react';
import {Button} from '@mgp-fe/shared/ui/button.tsx';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Switch} from '@mgp-fe/shared/ui/switch.tsx';
import {FormProvider, useForm} from 'react-hook-form';
import {TextInput} from '@mgp-fe/shared/ui/form/TextInput.tsx';
import {Alert} from '@mgp-fe/shared/ui/alert.tsx';
import loader from '@mgp-fe/shared/utils/google-maps-loader.ts';
import {radiusOptions} from '@mgp-fe/shared/modules/partner-locator/constants.ts';

export default function PartnerLocationsSearchForm(props: PartnerLocationsSearchFormProps) {
	const form = useForm<PartnerLocationsSearchFormValues>({
		defaultValues: props.defaultValues || defaultValues,
	});
	const [geolocationError, setGeolocationError] = useState<string>();
	const [geolocationLoading, setGeolocationLoading] = useState(false);

	const onLocationCheckedChange = async (checked: boolean) => {
		if (!checked) {
			form.setValue('zipCode', '');
			form.setValue('useMyLocation', false);
			return;
		}
		setGeolocationLoading(true);
		const permissions = await navigator.permissions.query({name: 'geolocation'});
		if (['denied'].includes(permissions.state)) {
			setGeolocationError('Allow geolocation in your browser settings.');
			setGeolocationLoading(false);
			return;
		}

		navigator.geolocation.getCurrentPosition(
			async (p) => {
				const {Geocoder} = await loader.importLibrary('geocoding');
				(new Geocoder).geocode(
					{location: {lat: p.coords.latitude, lng: p.coords.longitude}},
					(results, status) => {
						setGeolocationLoading(false);
						if (status !== google.maps.GeocoderStatus.OK) {
							setGeolocationError(`Geocoder failed due to: ${status}`);
							return;
						}

						if ((results || []).length === 0) {
							setGeolocationError('No ZIP code found');
							return;
						}

						let isInAvailableCountries = true;
						let zipCode;

						// Iterate over the address components of the first result.
						// Find and log the postal code type.
						for (const component of results![0].address_components) {
							if (component.types.includes('country')) {
								isInAvailableCountries = ['US'].includes(component.short_name);
							}
							if (component.types.includes('postal_code')) {
								zipCode = component.short_name;
							}
						}

						if (!isInAvailableCountries) {
							setGeolocationError('We are not available in your country yet.');
							return;
						}

						if (!zipCode) {
							setGeolocationError('We could not find your ZIP code.');
							return;
						}

						form.setValue('zipCode', zipCode);
						form.setValue('useMyLocation', true);
					},
				);
			},
			error => {
				setGeolocationError({
					1: 'User denied the request for Geolocation.',
					2: 'Location information is unavailable.',
					3: 'The request to get user location timed out.',
				}[error.code] || error.message || 'Unknown error');
				setGeolocationLoading(false);
			},
		);
	};

	return <FormProvider {...form}>
		<form
			onSubmit={form.handleSubmit(d => props.onSubmit?.(d))}
			onReset={() => {
				form.reset();
				props.onSubmit?.(defaultValues);
			}}
			className='horizontal-labels flex flex-col gap-4 md:gap-6 lg:gap-8 items-center'>
			<p className='text-secondary text-xl font-medium'>Make a 10 minute appointment for an easy 3D scan of your
				teeth to get
				started.</p>

			<fieldset className='grid grid-cols-1 lg:grid-cols-5 gap-8 items-center justify-start w-full relative'>
				<label className='lg:col-span-3 w-full flex !flex-row flex-wrap !gap-5 items-center justify-start'>
					<Switch
						checked={form.watch('useMyLocation')}
						onCheckedChange={onLocationCheckedChange}
						disabled={geolocationLoading || !!geolocationError}/>
					Use my location

					{geolocationLoading ? <FontAwesomeIcon icon='spinner' spin className='text-primary mt-1'/> : ''}
				</label>

				{geolocationError &&
					<Alert size='xs' variant='destructive' className='lg:absolute lg:top-12 text-left w-fit'>
						<FontAwesomeIcon icon='triangle-exclamation' size='xs' className='mr-2'/>
						{geolocationError}
					</Alert>}

				{/*<label className='hidden lg:col-span-2 w-full items-stretch shrink-0'>*/}
				{/*	<span>Country</span>*/}
				{/*	<select className='w-full' disabled>*/}
				{/*		<option>United States</option>*/}
				{/*	</select>*/}
				{/*</label>*/}
			</fieldset>


			<fieldset className='w-full grid grid-cols-1 lg:grid-cols-10 gap-8 text-left'>
				<TextInput
					disabled={geolocationLoading}
					label='Zip Code'
					placeholder={geolocationLoading ? 'Loading...' : 'Enter ZIP code'}
					name='zipCode'
					type='text'
					className='w-full'
					labelClassName={'lg:col-span-7'}
				/>

				<label className='lg:col-span-3 shrink-0'>
					<span>Radius</span>
					<select className='w-full' {...form.register('radius')}>
						{radiusOptions.map((r, i) => <option key={i} value={r.value}>{r.value} miles</option>)}
					</select>
				</label>
			</fieldset>

			<div className='grid grid-cols-1 lg:grid-cols-10 gap-8 w-full mt-2 relative'>
				<Button size='lg' className='lg:col-span-7 bg-secondary' icon={<FontAwesomeIcon width={20} icon='search'/>}>
					Search
				</Button>
				<Button type='reset' variant={'ghost'} size='sm' className='lg:col-span-3'
					icon={<FontAwesomeIcon width={14} icon='times'/>}>
					Clear
				</Button>
			</div>
		</form>
	</FormProvider>;
}

interface PartnerLocationsSearchFormProps {
	defaultValues?: PartnerLocationsSearchFormValues;
	onSubmit?: (v: PartnerLocationsSearchFormValues) => void;
}

export interface PartnerLocationsSearchFormValues {
	useMyLocation: boolean;
	zipCode: string;
	radius: number;
}

const defaultValues = {zipCode: '', radius: 50, useMyLocation: false};