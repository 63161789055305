import React, {useContext, useEffect, useState} from 'react';
import useAuth from '@mgp-fe/shared/modules/auth/hooks/useAuth.ts';
import {Customer} from '@mgp-fe/shared/core-api/domain/user.ts';
import Stepper from '@mgp-fe/shared/modules/onboarding/components/Stepper.tsx';
import LoginWithToken from '@mgp-fe/shared/modules/onboarding/components/LoginWithToken.tsx';
import {OnboardingContext} from '@mgp-fe/shared/modules/onboarding';
import WelcomeIndexPage from '@mgp-fe/shared/modules/onboarding/components/welcome/IndexPage.tsx';
import AccountIndexPage from '@mgp-fe/shared/modules/onboarding/components/account/IndexPage.tsx';
import OrderIndexPage from '@mgp-fe/shared/modules/onboarding/components/order/IndexPage.tsx';
import CompletedPage from '@mgp-fe/shared/modules/onboarding/components/CompletedPage.tsx';

export default function OnboardingIndexPage() {
	const {user, isUserLoaded} = useAuth<Customer>();
	const onboardingContext = useContext(OnboardingContext);

	const [redirected, setRedirected] = useState(false);

	useEffect(() => {
		const alreadyOnboarded = (user?.orders || [])?.filter(o => o.state !== 'canceled' && o.state !== 'cart').length > 0;

		if (alreadyOnboarded) {
			onboardingContext
				.setOnboardingStep('completed');
			setRedirected(true);
			return;
		}

		if (redirected || !isUserLoaded) return;

		if (onboardingContext.onboardingStep !== 'design') {
			onboardingContext
				.setOnboardingStep('design');
			setRedirected(true);
		}

		if (onboardingContext.onboardingStep === 'design')
			setRedirected(true);

	}, [onboardingContext.onboardingStep, user, isUserLoaded]);

	return <>
		<LoginWithToken />
		<Stepper/>

		<main className='flex flex-col max-w-full lg:w-[970px] mx-mini md:mx-small lg:mx-auto my-medium
			 bg-background-500/80 px-small md:px-medium lg:px-medium py-medium rounded-xl backdrop-blur-3xl relative'>
			{{
				'design': <WelcomeIndexPage />,
				'account': <AccountIndexPage />,
				'order': <OrderIndexPage />,
				'completed': <CompletedPage />,
			}[onboardingContext.onboardingStep]}
		</main>
	</>;
}
