'use client';

import {createContext} from 'react';
import {CartContextValue} from '@mgp-fe/shared/modules/shop/domain.ts';
import {CartItemModel} from '@mgp-fe/shared/core-api/domain/cart.ts';

export default createContext<CartContextValue>({
	totalItems: 0,
	totalAmount: {amount: 0, currency: 'USD'},
	addProductToCart: () => Promise.resolve({} as CartItemModel),
	reduceProductInCart: () => Promise.resolve(null),
});