import React from 'react';
import {Badge} from '@mgp-fe/shared/ui/badge.tsx';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button} from '@mgp-fe/shared/ui/button.tsx';
import {Card, CardProps} from '@mgp-fe/shared/ui/card.tsx';
import {PaymentMethod} from '@stripe/stripe-js/types/api/payment-methods';
import Skeleton from '@mgp-fe/shared/ui/skeleton';
import useRemoveCardMutation from '@mgp-fe/shared/core-api/mutations/stripe/remove-card.ts';
import {useIsMutating} from '@tanstack/react-query';
import keysResolver from '@mgp-fe/shared/core-api/keysResolver.ts';
import endpoints from '@mgp-fe/shared/core-api/endpoints.ts';
import useSetDefaultCardMutation from '@mgp-fe/shared/core-api/mutations/stripe/set-default-card.ts';
import {cn} from '@mgp-fe/shared/utils';

export default function PaymentMethodCard({
	pm,
	isDefault,
	cardProps,
	controls = ['remove', 'make-default'],
	partnerType,
	partnerId,
}: PaymentMethodsListProps) {
	const setDefaultCardMutation = useSetDefaultCardMutation();
	const isSettingDefault = useIsMutating({
		mutationKey: keysResolver(endpoints.stripe.setMyDefaultCard('')),
		predicate: m => (m.state.variables as string) === pm.id,
	});
	const removeCardMutation = useRemoveCardMutation();
	const isRemoving = useIsMutating({
		mutationKey: keysResolver(endpoints.stripe.removeCard('')),
		predicate: m => (m.state.variables as string) === pm.id,
	});

	return <Card
		variant='secondary'
		key={pm.id}
		className='flex flex-col gap-small relative'
		spacing='lg'
		{...cardProps}>
		<ul className='flex justify-between items-center'>
			<li>
				Card type <br/>
				<span className='text-muted uppercase'>
					{pm.card?.brand || 'Unkown brand'}
				</span>
			</li>
			<li>
				Last 4 digits <br/>
				<span className='text-muted'>
								**** {pm.card?.last4}
				</span>
			</li>
			<li>
				Expires <br/>
				<span className='text-muted'>
					{pm.card?.exp_month}/{pm.card?.exp_year}
				</span>
			</li>
		</ul>
		<ul className='flex justify-between items-center'>
			<li>
				Cardholder name <br/>
				<span className='text-muted'>{pm.billing_details.name || 'Unknown'}</span>
			</li>
		</ul>
		<div className={cn(
			'flex justify-between items-center',
			controls?.length === 0 ? 'hidden' : '',
		)}>
			{isDefault ?
				<Badge className='h-fit'>
					<FontAwesomeIcon icon='check' className='mr-mini'/>
					Default
				</Badge> :
				controls.includes('make-default') ?<Button
					onClick={() => setDefaultCardMutation.mutate({
						cardId: pm.id,
						partnerId,
						partnerType,
					})}
					state={isSettingDefault ? 'loading' : 'idle'}
					variant='outline'
					className='h-fit'
					size='sm'
					icon={<FontAwesomeIcon icon='check'/>}>
					Make default
				</Button> : ''}
			{controls.includes('remove') ? <Button
				onClick={() => removeCardMutation.mutate(pm.id)}
				state={isRemoving ? 'loading' : 'idle'}
				variant='destructive'
				className={cn('h-fit',
					/*TODO: implement removing cards on backend and then frontend */
					partnerType ? 'hidden' : '',
				)}
				size='sm'
				icon={<FontAwesomeIcon icon='trash'/>}>
				Remove
			</Button> : ''}
		</div>
	</Card>;
}

export function PaymentMethodCardSkeleton({cardProps} : {cardProps?: CardProps}) {
	return <Card
		variant='secondary'
		className='flex flex-col gap-small relative'
		spacing='lg'
		{...cardProps}>

		<ul className='flex justify-between items-center'>
			<li>
				Card type <br/>
				<Skeleton className='w-32 h-4'/>
			</li>
			<li>
				Last 4 digits <br/>
				<Skeleton className='w-24 h-4'/>
			</li>
			<li>
				Expires <br/>
				<Skeleton className='w-20 h-4'/>
			</li>
		</ul>

		<ul className='flex justify-between items-center'>
			<li>
				Cardholder name <br/>
				<Skeleton className='w-40 h-4'/>
			</li>
		</ul>

		<div className='flex justify-between items-center'>
			<Skeleton className='w-32 h-8 mr-2'/>
			<Skeleton className='w-32 h-8'/>
		</div>
	</Card>;
}

interface PaymentMethodsListProps {
	pm: PaymentMethod;
	isDefault?: boolean;
	controls?: PaymentMethodControl[];
	cardProps?: CardProps;
	partnerType?: 'location' | 'organization';
	partnerId?: string;
}

type PaymentMethodControl = 'remove' | 'make-default';