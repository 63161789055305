'use client';

import * as React from 'react';
import {RegisterOptions, useFormContext, useFormState} from 'react-hook-form';
import {omit} from 'lodash';
import {cn} from '@mgp-fe/shared/utils';
import getErrorMessage from '@mgp-fe/shared/ui/form/error-message.ts';

export interface SelectInputProps
	extends React.InputHTMLAttributes<HTMLSelectElement> {
	label?: string | React.ReactNode;
	name: string;
	labelClassName?: string;
	registerOptions?: RegisterOptions;
	emptyOption?: string;
	options: { [key: string]: string };
}

const SelectInput = (props: SelectInputProps) => {
	const form = useFormContext();
	const {errors} = useFormState({
		control: form.control,
	});
	const inputProps = omit({...props}, ['formControl', 'label', 'labelClassName', 'emptyOption']);

	const errorMessage = getErrorMessage(errors, props.name);

	return <label className={cn(
		'flex flex-col gap-mini mt-small relative w-full',
		props.labelClassName || '',
	)}>
		{props.label ? <span>{props.label}</span> : ''}

		<select
			{...inputProps}
			{...form.register(props.name, props.registerOptions)}
			className={cn(
				...(inputProps.className ? inputProps.className : ''),
				'w-full',
			)}>
			{props.emptyOption ? <option value=''>{props.emptyOption}</option> : ''}
			{Object.keys(props.options).map(key => <option key={key} value={key}>{props.options[key]}</option>)}
		</select>

		{(props.name && errorMessage) ? <span
			className='absolute -top-2 right-0 w-3/4 bg-destructive/80 text-sm text-destructive-foreground px-2 py-1 rounded-md'>
			{errorMessage as string}
		</span> : ''}
	</label>;
};
SelectInput.displayName = 'FormSelectInput';

export {SelectInput};
