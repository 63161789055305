import React, {useContext} from 'react';
import SignUpLinkMode from '@mgp-fe/shared/modules/onboarding/components/welcome/SignUpLinkMode.tsx';
import DefaultMode from '@mgp-fe/shared/modules/onboarding/components/welcome/DefaultMode.tsx';
import useAuth from '@mgp-fe/shared/modules/auth/hooks/useAuth.ts';
import {Customer} from '@mgp-fe/shared/core-api/domain/user.ts';
import RegisterMode from '@mgp-fe/shared/modules/onboarding/components/welcome/RegisterMode.tsx';
import Skeleton from '@mgp-fe/shared/ui/skeleton.tsx';
import InvitationMode from '@mgp-fe/shared/modules/onboarding/components/welcome/InvitationMode.tsx';
import VoucherMode from '@mgp-fe/shared/modules/onboarding/components/welcome/VoucherMode.tsx';
import {OnboardingContext} from '@mgp-fe/shared/modules/onboarding';
import AffiliatePathMode from '@mgp-fe/shared/modules/onboarding/components/welcome/AffiliatePathMode.tsx';

export default function WelcomeIndexPage() {
	const {mode} = useContext(OnboardingContext);
	const {user, isUserLoaded} = useAuth<Customer>();

	if(!isUserLoaded) return <div className='grid md:grid-cols-2 gap-large items-center'>
		<div className='text-center flex flex-col gap-small w-full items-center'>
			<Skeleton className='w-3/4 h-10' />

			<Skeleton className='w-5/6 h-6 mt-mini' />
			<Skeleton className='w-4/5 h-5' />
			<Skeleton className='w-52 h-14 mt-small' />
		</div>
		<div className='text-center flex flex-col gap-small w-full items-center'>
			<Skeleton className='w-3/4 h-10' />
			<Skeleton className='w-1/3 self-start h-6 mt-mini' />
			<Skeleton className='w-full h-10' />
			<Skeleton className='w-1/3 self-start h-6 mt-mini' />
			<Skeleton className='w-full h-10' />
			<Skeleton className='w-1/3 self-start h-6 mt-mini' />
			<Skeleton className='w-full h-10' />
			<Skeleton className='w-1/3 self-start h-6 mt-mini' />
			<Skeleton className='w-full h-10' />
			<Skeleton className='w-full h-14 mt-small rounded-full' />
		</div>
	</div>;

	if(mode.signUpLink) return <SignUpLinkMode />;
	if(mode.affiliatePath) return <AffiliatePathMode />;
	if(mode.invitation) return <InvitationMode />;
	if(mode.voucherId && mode.voucherCode) return <VoucherMode />;

	return user ? <DefaultMode /> : <RegisterMode />;
}
