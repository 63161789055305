import {useQuery, UseQueryOptions} from '@tanstack/react-query';
import {AxiosResponse} from 'axios';
import keysResolver from '@mgp-fe/shared/core-api/keysResolver.ts';
import endpoints from '@mgp-fe/shared/core-api/endpoints.ts';
import coreApiClient from '@mgp-fe/shared/core-api/client.ts';
import {ProductCollectionModel} from '@mgp-fe/shared/core-api/domain/product.ts';

export default function useProductDetailQuery({options, id}: ProductDetailQueryProps) {
	return useQuery<AxiosResponse<ProductCollectionModel>>({
		queryKey: keysResolver(endpoints.products.list, 'get', id),
		queryFn: () => coreApiClient.get(endpoints.products.item(id)),
		enabled: !!id,
		...options,
	});
}

interface ProductDetailQueryProps {
    id: string;
    options?: UseQueryOptions<AxiosResponse<ProductCollectionModel>>;
}