import {useQuery, UseQueryOptions} from '@tanstack/react-query';
import endpoints from '@mgp-fe/shared/core-api/endpoints.ts';
import keysResolver from '@mgp-fe/shared/core-api/keysResolver.ts';
import coreApiClient from '@mgp-fe/shared/core-api/client.ts';
import {AxiosResponse} from 'axios';
import {
	ArchivableListParams,
	CollectionParams,
	HydraListResponse,
	OrderFilter,
} from '@mgp-fe/shared/core-api/domain/base.ts';
import {OrderCollectionModel, OrderState} from '@mgp-fe/shared/core-api/domain/order.ts';

export default function useOrdersListQuery(props?: OrdersListListProps) {
	return useQuery<OrdersListQueryResponse>(
		keysResolver(endpoints.orders.list, 'get', props?.params),
		() => coreApiClient.get(endpoints.orders.list, {
			params: props?.params,
		}),
		{
			staleTime: Infinity,
			...props?.options,
		},
	);
}

export interface OrdersListListProps {
	params?: OrdersListQueryParams;
	options?: UseQueryOptions<OrdersListQueryResponse>;
}

export type OrdersListQueryParams = CollectionParams & ArchivableListParams & {
	state?: OrderState | OrderState[];
	order?: {
		state?: OrderFilter;
		orderedAt?: OrderFilter;
		'totalAmount.amount'?: OrderFilter;
	},
	'customer.type'?: 'customer' | 'partner';
	exists?: {
		partnerLocation?: boolean;
	},
	search_orders?: string | null;
};

export type OrdersListQueryResponse = AxiosResponse<HydraListResponse<OrderCollectionModel>>;