import React from 'react';
import {useParams} from 'react-router-dom';
import useOrderDetailQuery from '@mgp-fe/shared/core-api/queries/order/detail.ts';
import OrderDetail from '@mgp-fe/shared/modules/orders/components/order-detail';
import {OrderDetailSkeleton} from '@mgp-fe/shared/modules/orders/components/order-detail/OrderDetail.tsx';

export default function DetailPage() {
	const {id} = useParams<{ id: string }>();
	const orderQuery = useOrderDetailQuery(id!);

	if (orderQuery.data === undefined) return <OrderDetailSkeleton/>;

	return <OrderDetail order={orderQuery.data.data}/>;
}