import React, {useContext, useEffect, useState} from 'react';
import {useQueryClient} from '@tanstack/react-query';
import useAuth from '@mgp-fe/shared/modules/auth/hooks/useAuth.ts';
import {Customer} from '@mgp-fe/shared/core-api/domain/user.ts';
import useNotify from '@mgp-fe/shared/ui/notifications/use-notify.ts';
import useToggle from '@mgp-fe/shared/hooks/useToggle.ts';
import useSendSignInLinkMutation, {
	SendSignInLinkMutationData,
} from '@mgp-fe/shared/core-api/mutations/user/send-sign-in-link.ts';
import {FormProvider, useForm} from 'react-hook-form';
import keysResolver from '@mgp-fe/shared/core-api/keysResolver.ts';
import endpoints from '@mgp-fe/shared/core-api/endpoints.ts';
import DesignerModal from '@mgp-fe/shared/modules/mouthguard-design/components/DesignerModal.tsx';
import {Button} from '@mgp-fe/shared/ui/button.tsx';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import RegisterCustomerForm from '@mgp-fe/shared/modules/auth/components/RegisterCustomerForm.tsx';
import Modal from '@mgp-fe/shared/ui/Modal.tsx';
import {TextInput} from '@mgp-fe/shared/ui/form/TextInput.tsx';
import {Alert} from '@mgp-fe/shared/ui/alert.tsx';
import {faPaperPlane} from '@fortawesome/free-solid-svg-icons';
import {OnboardingContext} from '@mgp-fe/shared/modules/onboarding';
import getClientEnv from "@mgp-fe/shared/utils/env-var-resolver.ts";

export default function RegisterMode() {
	const onboardingContext = useContext(OnboardingContext);
	const queryClient = useQueryClient();
	const {user, displayLoginModal} = useAuth<Customer>();
	const {notifySuccess} = useNotify();
	const displayDesignerModal = useToggle();
	const signInLinkMutation = useSendSignInLinkMutation();
	const signLinkForm = useForm<SendSignInLinkMutationData>();
	const [signInLinkModalOpened, setSignInLinkModalOpened] = useState(false);

	const onSuccess = async () => {
		notifySuccess({message: user ? 'Invitation accepted.' : 'Account created.'});

		await Promise.all([
			queryClient.invalidateQueries(keysResolver(endpoints.mouthguardDesigns.athleteTeams)),
			queryClient.invalidateQueries(keysResolver(endpoints.mouthguardDesigns.list)),
		]);
		onboardingContext.setOnboardingStep('design');
	};

	useEffect(() => {
		if (onboardingContext.mode.designer) {
			displayDesignerModal.on();
			onboardingContext.setMode({});
		}
	}, []);

	return <div className='flex flex-col items-center md:flex-row mx-3'>
		<div className='grow my-6 md:my-0 md:w-[50%] flex flex-col md:items-center gap-2'>
			<h4 className='text-center mb-4 text-muted'>
				Design your GARD
			</h4>
			<p className='text-center mb-small text-muted/80'>
				You can make design before you create an account. If you like your design, you can save it. <br/>
			</p>
			{displayDesignerModal.state ? <DesignerModal onClose={displayDesignerModal.off}/> : ''}

			<Button
				onClick={displayDesignerModal.on}
				variant='secondary'
				size='lg'
				icon={<FontAwesomeIcon icon='arrow-up-right-from-square'/>}>
				GARD designer
			</Button>
			<p className='text-center text-sm mt-small'>
				Have you saved design already?
				<Button size='sm' variant='link' className='normal-case'
					onClick={() => setSignInLinkModalOpened(true)}>
					Sign in with email link
				</Button>
			</p>
		</div>

		<span
			className='h-0.5 md:h-72 w-full md:w-0.5 bg-gradient-to-r md:bg-gradient-to-b from-transparent via-primary-400 to-transparent  my-6 md:mx-8 lg:mx-10 z-40'/>

		<section className='grow md:w-[50%] relative flex flex-col'>
			<h4 className='text-center text-muted'>Create new account</h4>
			<p className=' text-center'>
				Already have an account? <span
					onClick={displayLoginModal.on}
					className='text-primary-500/80 font-semibold inline-block normal-case p-0 cursor-pointer'>Sign in here</span> or <span
					onClick={() => setSignInLinkModalOpened(true)}
					className='text-primary-500/80 font-semibold inline-block normal-case p-0 cursor-pointer'>
                request sign-in link <span className='paragraph'>(password-less)</span>
				</span>
			</p>
			<RegisterCustomerForm onSuccess={onSuccess}/>

			<Modal
				onClose={() => setSignInLinkModalOpened(false)}
				isOpen={signInLinkModalOpened}
				title='Send sign-in link'>
				<FormProvider {...signLinkForm}>
					<p>
						Enter your e-mail address and we will send you a link to sign in.
					</p>
					<form onSubmit={signLinkForm.handleSubmit((d) => {
						signInLinkMutation.reset();
						signInLinkMutation.mutate({
							...d,
							userType: getClientEnv('appName'),
						});
					})} className='horizontal-labels flex flex-col gap-medium'>
						<TextInput label='Your e-mail' name='email'/>

						{signInLinkMutation.isSuccess
							? <Alert variant='success'>Check your email for sign-in link</Alert>
							: <Button
								state={signInLinkMutation.status}
								disabled={signInLinkMutation.isLoading || signInLinkMutation.isSuccess}
								type='submit'
								iconPosition={'left'}
								icon={<FontAwesomeIcon icon={faPaperPlane} className='mr-2'/>}>
								Send link to e-mail
							</Button>}
					</form>

					{signInLinkMutation.isError && <Alert variant='destructive'>User does not exist.</Alert>}
				</FormProvider>
			</Modal>
		</section>
	</div>;
}
