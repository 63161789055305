'use client';

import {useMutation, UseMutationOptions, useQueryClient} from '@tanstack/react-query';
import {AxiosError, AxiosResponse} from 'axios';
import keysResolver from '@mgp-fe/shared/core-api/keysResolver.ts';
import endpoints from '@mgp-fe/shared/core-api/endpoints';
import coreApiClient from '@mgp-fe/shared/core-api/client.ts';
import {HydraError} from '@mgp-fe/shared/core-api/domain/base.ts';

export default function useSendSignInLinkMutation(props?: UseSendSignInLinkMutationProps) {
	const queryClient = useQueryClient();

	return useMutation<AxiosResponse<SendSignInLinkResponse>, AxiosError<HydraError>, SendSignInLinkMutationData>(
		keysResolver(endpoints.sendSignInLink, 'post'),
		d => coreApiClient.post<SendSignInLinkResponse>(endpoints.sendSignInLink, d),
		{
			...props,
			onSuccess: async (result, variables, context) => {
				await queryClient.invalidateQueries(keysResolver(endpoints.me.index));
				props?.onSuccess?.(result, variables, context);
			},
		},
	);
}

type UseSendSignInLinkMutationProps = UseMutationOptions<
	AxiosResponse<SendSignInLinkResponse>,
	AxiosError<HydraError>,
	SendSignInLinkMutationData
>;

export interface SendSignInLinkMutationData {
	email: string;
	userType: string;
}

interface SendSignInLinkResponse {
	success: boolean;
	message?: string;
}