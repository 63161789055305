import {HydraModel} from '@mgp-fe/shared/core-api/domain/base.ts';
import {PartnerLocationCollectionModel} from '@mgp-fe/shared/core-api/domain/partner/location.ts';
import {Customer} from '@mgp-fe/shared/core-api/domain/user.ts';


export interface ScanRequestCollectionModel extends HydraModel {
	id: string;
	requestedForDate: Date;
	state: ScanRequestState;
	partnerLocation: PartnerLocationCollectionModel;
	customer?: Customer;
	scan?: string;
	resolvedBy?: string;
}

export type ScanRequestModel = ScanRequestCollectionModel;

export type ScanRequestState = 'requested' | 'accepted' | 'rejected' | 'canceled_by_customer' | 'executed';
export type ScanRequestTransition = 'cancel_by_customer' | 'accept' | 'reject' | 'execute';

export const cancellableStates: ScanRequestState[] = ['requested', 'accepted'];