import {FormProvider, useForm, useFormState} from 'react-hook-form';
import PhoneNumberInput from '@mgp-fe/shared/ui/form/PhoneNumberInput.tsx';
import {Button} from '@mgp-fe/shared/ui/button.tsx';
import React from 'react';
import {zodResolver} from '@hookform/resolvers/zod';
import {z} from 'zod';
import useUpdateMeMutation from '@mgp-fe/shared/core-api/mutations/user/me.ts';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {isPossiblePhoneNumber} from 'react-phone-number-input/min';
import {Customer} from '@mgp-fe/shared/core-api/domain/user.ts';
import {parsePhoneNumberFromString} from 'libphonenumber-js';
import useUpdateConsentSettingsMutation from '@mgp-fe/shared/core-api/mutations/user/consent-settings.ts';
import useAuth from '@mgp-fe/shared/modules/auth/hooks/useAuth.ts';

export default function AddPhoneForm(props: AddPhoneFormProps) {
	const {user} = useAuth<Customer>();

	const updateMe = useUpdateMeMutation({
		onSuccess: () => {
			props.onSuccess();
			form.reset();
		},
	});
	const updateConsent = useUpdateConsentSettingsMutation();

	const form = useForm<z.infer<typeof UserPhoneSchema>>({
		resolver: zodResolver(UserPhoneSchema),
		mode: 'onChange',

		defaultValues: async () => {
			return {
				phone: user?.phone ? parsePhoneNumberFromString(user?.phone)?.number : undefined,
				contactConsent: {
					transactionalCalls: user?.contactConsentSettings?.transactionalCalls ?? false,
				},
			} as z.infer<typeof UserPhoneSchema>;
		},
	});
	const { isValid } = useFormState({ control: form.control });
	const onSubmit = async (data: z.infer<typeof UserPhoneSchema>) => {
		await updateConsent.mutateAsync({
			contactConsent: {
				transactionalCalls: data.contactConsent.transactionalCalls,
				transactionalEmails: user?.contactConsentSettings?.transactionalEmails,
				transactionalSms: user?.contactConsentSettings?.transactionalSms,
				marketingCalls: user?.contactConsentSettings?.marketingCalls,
				marketingEmails: user?.contactConsentSettings?.marketingEmails,
				marketingSms: user?.contactConsentSettings?.marketingSms,
			},
		}, {
			onSuccess: async () => {
				await updateMe.mutateAsync({
					phone: data.phone,
				});
			},
		});

	};

	return <FormProvider {...form}>
		<form onSubmit={form.handleSubmit(onSubmit)}>
			<div className='flex flex-col gap-small'>
				<p>Your phone number helps us provide better service by keeping you updated on orders and assisting with
					any questions, all while respecting your privacy.</p>
				<PhoneNumberInput label='Phone number' name='phone'/>

				<label className='inline-flex items-center gap-small text-secondary-500/50'>
					<input
						type='checkbox'
						{...form.register('contactConsent.transactionalCalls')}
						required={true}
					/>
					<span>I accept transactional calls.</span>
				</label>
			</div>

			<div className='flex justify-between mt-medium'>
				<Button
					state={updateMe.status}
					type='submit'
					disabled={!isValid}
				>
					Accept
				</Button>
				<Button
					variant='link'
					icon={<FontAwesomeIcon icon='chevron-right' className='mr-2 text-secondary'/>}
					className='text-secondary'
					iconPosition='right'
					onClick={props.onSuccess}
				>
					Skip
				</Button>
			</div>
		</form>
	</FormProvider>;}

const UserPhoneSchema = z.object({
	phone: z.string().refine(
		v => !v || isPossiblePhoneNumber(v),
		{message: 'Please enter a valid phone number'},
	),
	contactConsent: z.object({
		transactionalCalls: z.boolean(),
	}),
});

interface AddPhoneFormProps {
    onSuccess: () => void;
}