import React, {useState} from 'react';
import MemberZoneLayout from '@mgp-fe/customer/modules/common/ui/MemberZoneLayout.tsx';
import useScanRequestsListQuery from '@mgp-fe/shared/core-api/queries/scan/scan-request.ts';
import ScanRequestCard, {ScanRequestCardSkeleton} from '@mgp-fe/customer/modules/scans/components/ScanRequestCard.tsx';
import {Alert, AlertTitle} from '@mgp-fe/shared/ui/alert.tsx';
import CardsPagination from '@mgp-fe/shared/ui/pagination/CardsPagination.tsx';
import {getCollectionSearchParams} from '@mgp-fe/shared/core-api/utils.ts';


export const ScanRequestsIndexPage = () => {
	const itemsPerPage = 9;
	const [page, setPage] = useState(1);

	const scanRequestsQuery = useScanRequestsListQuery({
		params: {
			itemsPerPage,
			page,
			pagination: true,
		},
	});

	const lastPage = parseInt(getCollectionSearchParams('last', scanRequestsQuery.data?.data).get('page') || '1');

	return <MemberZoneLayout>
		<main className='container'>
			<h2>My scans requests</h2>
			<p>You can see all your scans requests here.</p>

			{scanRequestsQuery.data?.data['hydra:member'].length === 0 ? <Alert variant='warning'>
				<AlertTitle>You have no bookings yet</AlertTitle>
			</Alert> : null}

			<div className='mt-3 grid gap-4 mb-medium md:grid-cols-2 lg:grid-cols-3'>
				{scanRequestsQuery.data?.data['hydra:member'].map(s => <ScanRequestCard scanRequest={s}
					key={s.id}
					scansRequestsRefetch={scanRequestsQuery.refetch}/>)}

				{!scanRequestsQuery.data && scanRequestsQuery.isFetching ? [...Array(3)].map((_, i) =>
					<ScanRequestCardSkeleton key={i}/>) : ''}
			</div>
			<CardsPagination
				page={page}
				lastPage={lastPage}
				changePageHandler={p => setPage(p)}
				labels={{previous: 'Newer', next: 'Older'}}/>

			{scanRequestsQuery.isError ?
				<div className={'h-[660px] flex items-center justify-center'}><h1>Something went wrong</h1>
				</div> : null}
		</main>
	</MemberZoneLayout>;
};

export default ScanRequestsIndexPage;