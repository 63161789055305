import {useMutation, UseMutationOptions, useQueryClient} from '@tanstack/react-query';
import keysResolver from '@mgp-fe/shared/core-api/keysResolver.ts';
import coreApiClient from '@mgp-fe/shared/core-api/client.ts';
import {AxiosError, AxiosResponse} from 'axios';
import endpoints from '@mgp-fe/shared/core-api/endpoints.ts';
import {InvitationCollectionModel, InvitationModel} from '@mgp-fe/shared/core-api/domain/invitation.ts';
import {HydraError} from '@mgp-fe/shared/core-api/domain/base.ts';

export default function useDeclineInvitationMutation(props?: UseDeclineInvitationMutationProps) {
	const queryClient = useQueryClient();

	return useMutation<AxiosResponse<InvitationModel>, AxiosError<HydraError>, InvitationCollectionModel>({
		mutationKey: keysResolver(endpoints.invitations.decline(''), 'put'),
		mutationFn: (data) => coreApiClient.put(endpoints.invitations.decline(data.id), {}),
		...props,
		onSuccess: async (result, variables, context) => {
			await queryClient.invalidateQueries(keysResolver(endpoints.invitations.list));
			props?.onSuccess?.(result, variables, context);
		},
	});
}

type UseDeclineInvitationMutationProps = UseMutationOptions<
	AxiosResponse<InvitationModel>,
	AxiosError<HydraError>,
	InvitationCollectionModel
>;