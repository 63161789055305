const routes = {
	index: '/checkout',
	shipping: {
		index: '/checkout/shipping',
	},
	billing: {
		index: '/checkout/billing',
	},
	summary: {
		index: '/checkout/summary',
	},
	completed: '/checkout/completed',
};

export default routes;