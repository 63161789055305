'use client';

import {HydraListResponse} from '@mgp-fe/shared/core-api/domain/base.ts';
import {useQuery, UseQueryOptions} from '@tanstack/react-query';
import {AxiosResponse} from 'axios';
import keysResolver from '@mgp-fe/shared/core-api/keysResolver.ts';
import endpoints from '@mgp-fe/shared/core-api/endpoints.ts';
import coreApiClient from '@mgp-fe/shared/core-api/client.ts';
import {ShippingMethodCollectionModel} from '@mgp-fe/shared/core-api/domain/shipping/method.ts';

export default function useCartMyShippingMethodsListQuery(props?: CartMyShippingMethodsListQueryProps) {
	return useQuery<AxiosResponse<HydraListResponse<ShippingMethodCollectionModel>>>({
		queryKey: keysResolver(endpoints.cart.my.shippingMethods, 'get'),
		queryFn: () => coreApiClient.get(endpoints.cart.my.shippingMethods),
		...props?.options,
	});
}

interface CartMyShippingMethodsListQueryProps {
	options?: UseQueryOptions<AxiosResponse<HydraListResponse<ShippingMethodCollectionModel>>>;
}