import {CollectionParams, HydraListResponse} from '@mgp-fe/shared/core-api/domain/base.ts';
import {useQuery, UseQueryOptions} from '@tanstack/react-query';
import {AxiosResponse} from 'axios';
import keysResolver from '@mgp-fe/shared/core-api/keysResolver.ts';
import endpoints from '@mgp-fe/shared/core-api/endpoints.ts';
import coreApiClient from '@mgp-fe/shared/core-api/client.ts';
import {OAuthCollectionModel} from "@mgp-fe/shared/core-api/domain/oauth.ts";

export default function useOAuthListQuery(props?: OAuthListQueryProps) {
    return useQuery<OAuthQuery>(
        keysResolver(endpoints.me.oauth.index, 'get', props?.params),
        () => coreApiClient.get(endpoints.me.oauth.index, {
            params: props?.params,
        }),
        props?.options,
    );
}

export interface OAuthListQueryProps {
    params?: CollectionParams
    options?: UseQueryOptions<OAuthQuery>;
}

export type OAuthQuery = AxiosResponse<HydraListResponse<OAuthCollectionModel>>;