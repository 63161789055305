'use client';

import {useQuery, UseQueryOptions} from '@tanstack/react-query';
import {AxiosResponse} from 'axios';
import keysResolver from '@mgp-fe/shared/core-api/keysResolver.ts';
import coreApiClient from '@mgp-fe/shared/core-api/client.ts';
import {SettingKey, SettingModel} from '@mgp-fe/shared/core-api/domain/setting.ts';
import endpoints from '@mgp-fe/shared/core-api/endpoints.ts';


export default function useSettingItemQuery(props: SettingQueryProps) {
	return useQuery<AxiosResponse<SettingModel>>({
		queryKey: keysResolver(endpoints.settings.item(props.key)),
		queryFn: () => coreApiClient.get(endpoints.settings.item(props.key)),
		staleTime: Infinity,
		...props.options,
	});
}

interface SettingQueryProps {
	key: SettingKey;
	options?: UseQueryOptions<AxiosResponse<SettingModel>>;
}