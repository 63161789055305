'use client';


import keysResolver from '@mgp-fe/shared/core-api/keysResolver.ts';
import {useMutation, UseMutationOptions, useQueryClient} from '@tanstack/react-query';
import {AxiosError, AxiosResponse} from 'axios';
import {ScanRequestModel} from '@mgp-fe/shared/core-api/domain/scan-request.ts';
import {HydraError} from '@mgp-fe/shared/core-api/domain/base.ts';
import endpoints from '@mgp-fe/shared/core-api/endpoints.ts';
import coreApiClient from '@mgp-fe/shared/core-api/client.ts';
import useAuth from '@mgp-fe/shared/modules/auth/hooks/useAuth.ts';

export default function useCreateScanRequestMutation(props?: UseCreateScanRequestMutationProps) {
	const queryClient = useQueryClient();
	const {user} = useAuth();

	return useMutation<AxiosResponse<ScanRequestModel>, AxiosError<HydraError>, CreateScanRequestMutationData>({
		mutationKey: keysResolver(endpoints.scanRequests.list, 'post'),
		mutationFn: d => coreApiClient.post<ScanRequestModel>(endpoints.scanRequests.list, {
			...d,
			requestedForDate: d.requestedForDate + 'T00:00:00+00:00',
			customer: user?.['@id'],
		}),
		...props,
		onSuccess: async (result, variables, context) => {
			await queryClient.invalidateQueries(keysResolver(endpoints.scanRequests.list, "get", {state: 'requested'}));
			props?.onSuccess?.(result, variables, context);
		},
	});
}

type UseCreateScanRequestMutationProps = UseMutationOptions<
	AxiosResponse<ScanRequestModel>,
	AxiosError<HydraError>,
	CreateScanRequestMutationData
>;

export interface CreateScanRequestMutationData {
	partnerLocation: string;
	requestedForDate: string;
}