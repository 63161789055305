'use client';

import {Card} from '@mgp-fe/shared/ui/card.tsx';
import {Button} from '@mgp-fe/shared/ui/button.tsx';
import React from 'react';
import ProductCartControls from '@mgp-fe/shared/modules/shop/components/ProductCartControls.tsx';
import {cn, resolveEnvVar} from '@mgp-fe/shared/utils';
import MoneyFormatted from '@mgp-fe/shared/modules/formatters/MoneyFormatted.tsx';
import {ProductCollectionModel} from '@mgp-fe/shared/core-api/domain/product.ts';
import useAuth from '@mgp-fe/shared/modules/auth/hooks/useAuth.ts';
import Skeleton from '@mgp-fe/shared/ui/skeleton.tsx';
import {useProductDetailPagesQuery} from '@mgp-fe/shared/cms-graphql/queries/generated/product-detail-page.ts';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

export default function ProductDetailsHeroCard({productQuery, locale}: ProductDetailHeroCardProps) {
	const {user} = useAuth();
	const designerUrl = resolveEnvVar('customerAppUrl') + (user ? '/gard-designs' : '/onboarding');
	const { data } = useProductDetailPagesQuery({
		variables: {
			productId: productQuery?.id,
			locale: locale,
		},
	});
	const cmsData = data?.productDetailPages?.data?.[0]?.attributes;
	const cmsMedia = cmsData?.productImage?.[0]?.media?.data?.[0]?.attributes?.url ? (cmsData?.productImage?.[0]?.media?.data?.[0]?.attributes?.url.startsWith('http') ? '' : resolveEnvVar('cmsUrl') + cmsData?.productImage?.[0]?.media?.data?.[0]?.attributes?.url) : undefined;

	return <Card className='relative flex flex-col gap-x-medium md:flex-row items-stretch justify-evenly mb-8 p-medium'>
		<div
			className='relative overflow-hidden w-full flex flex-col items-center h-72 md:h-auto'
			style={{boxShadow: '-5px -105px 100% rgba(255, 255, 255, 0.02) inset'}}>
			<div className='z-10 absolute w-full h-4/5 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 aspect-square blur-lg -rotate-45'
				style={{background: 'radial-gradient(ellipse farthest-corner at 50%, rgb(50,50,50), rgb(50,50,50,0.4), rgba(50,50,50,0.05), black)'}}/>

			<div className='h-full w-full flex items-center'>
				<div
					className='z-10 absolute w-full h-4/5 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 aspect-square blur-lg'
					style={{background: 'radial-gradient(ellipse farthest-corner at 50%, rgb(50,50,50), rgb(50,50,50,0.4), rgba(50,50,50,0.05), black)'}}/>
				<img className='max-w-full max-h-full md:max-h-[442px] mx-auto z-20' src={cmsMedia || productQuery?.titleImage || ''} alt={cmsData?.title || ''}/>
			</div>

			<div className='flex flex-wrap absolute z-50 top-2 right-2 w-fit gap-mini'>
				{productQuery?.promotionActions?.map(pa => <div
					key={pa['@id']}
					className='w-fit bg-secondary text-muted/90 py-mini px-small rounded-full'>
					-{pa.percentageDiscount}% Off
				</div>)}
			</div>
		</div>

		<article className='w-full md:w-1/2 shrink-0 p-0 flex flex-col justify-between'>
			<div>
				<h3 className='text-primary font-medium uppercase text-4xl flex flex-col flex-wrap md:flex-row justify-between items-start md:items-center leading-relaxed'>
					{cmsData?.title || productQuery?.name || 'Untitled product'}
				</h3>

				<i className='grid shrink-0 text-2xl font-medium text-muted/90 mt-small'>
					{productQuery?.discountedPrice?.amount !== productQuery?.price?.amount ?
						<span className={cn('line-through text-sm text-muted/50')}>
							<MoneyFormatted money={productQuery.price}/>
							<br/>
						</span> : ''}
					<MoneyFormatted money={productQuery?.discountedPrice}/>
				</i>

				<div className='mt-medium flex items-center'>
					<ReviewStarsSecondary rating={cmsData?.rating || 0}/>
					{cmsData?.reviewCount &&
						<p className='text-lg text-secondary-500/50 ml-2'>{`${cmsData?.reviewCount} ${cmsData?.reviewCount > 1 ? 'reviews' : 'review'}`}</p>}
				</div>


				<p className='text-lg text-[#E6E6E6] font-light mt-medium'>
					{cmsData?.description || productQuery?.description}
				</p>
			</div>

			<div>
				<div className='flex flex-wrap flex-row-reverse justify-between gap-small z-40 w-full mt-medium md:mt-large'>
					<div className='z-40 grow lg:grow-0 w-full xl:w-fit flex items-stretch'>
						<ProductCartControls product={productQuery}/>
					</div>
					{['full_custom_mouthguard', 'simple_mouthguard', 'clear_mouthguard'].includes(productQuery?.productType)
						? <Button
							size='lg'
							variant='outline'
							className='z-40 uppercase font-semibold w-full xl:w-fit flex items-center gap-4 text-base'
							asChild>
							<a href={designerUrl} target={user ? undefined : '_blank'} rel='noreferrer'>
								Design your own!
							</a>
						</Button>
						: ''}
				</div>
			</div>
		</article>
	</Card>;
}

export function ProductDetailsHeroCardSkeleton() {
	return (
		<Card className='relative flex flex-col md:flex-row items-stretch justify-evenly mb-8 p-medium'>

			<div className='relative overflow-hidden w-full flex flex-col items-center h-72 md:h-auto'>
				<Skeleton className='h-full w-full'/>
				<div className='absolute z-50 top-2 right-2 w-fit'>
					<Skeleton className='w-24 h-6 rounded-full'/>
				</div>
			</div>

			<article className='w-full md:w-1/2 shrink-0 p-0 md:p-8'>
				<h3 className='mb-8'>
					<Skeleton className='w-2/3 h-8 mb-4'/>
					<Skeleton className='w-1/4 h-6'/>
				</h3>
				<Skeleton className='w-full h-4 mb-2' />
				<Skeleton className='w-3/4 h-4 mb-2' />
				<Skeleton className='w-1/2 h-4 mb-6' />

				<div className='flex flex-wrap flex-row-reverse justify-between gap-small w-full'>
					<Skeleton className='w-24 h-10' />
					<Skeleton className='w-full md:w-1/3 h-10' />
				</div>
			</article>
		</Card>
	);
}

const ReviewStarsSecondary = ({rating}: { rating: number }) => <div className='flex z-20'>
	{[...new Array(Math.floor(rating))].map((_, index) => <FontAwesomeIcon key={index} icon='star' width={18} height={18} className='text-secondary-500/50 w-fit mr-2'/>)}
	{!!(rating % 1) && <FontAwesomeIcon icon='star-half-stroke' width={18} height={18} className='text-secondary-500/50 w-fit mr-2'/>}
</div>;

interface ProductDetailHeroCardProps {
	productQuery: ProductCollectionModel;
	locale: string;
}