import React, {useEffect} from 'react';
import {AddressBookCollectionModel, AddressModel} from '@mgp-fe/shared/core-api/domain/address.ts';
import useAddressBookCollectionQuery from '@mgp-fe/shared/core-api/queries/address-book/list.ts';
import {Card} from '@mgp-fe/shared/ui/card.tsx';
import Skeleton from '@mgp-fe/shared/ui/skeleton.tsx';
import {Button} from '@mgp-fe/shared/ui/button.tsx';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import useToggle from '@mgp-fe/shared/hooks/useToggle.ts';
import Modal from '@mgp-fe/shared/ui/Modal.tsx';
import AddressForm from '@mgp-fe/shared/modules/account/components/address-book/AddressForm.tsx';
import AddressFormatted from '@mgp-fe/shared/modules/formatters/AddressFormatted.tsx';
import AddressControls from '@mgp-fe/shared/modules/account/components/address-book/AddressControls.tsx';
import {Badge} from '@mgp-fe/shared/ui/badge.tsx';
import useAuth from '@mgp-fe/shared/modules/auth/hooks/useAuth.ts';
import {UserWithAddresses} from '@mgp-fe/shared/core-api/domain/user.ts';
import usePartnerLocationsListQuery from '@mgp-fe/shared/core-api/queries/partner-location/list.ts';
import useCartMyQuery from '@mgp-fe/shared/core-api/queries/cart/my.tsx';
import {PartnerLocationCollectionModel} from '@mgp-fe/shared/core-api/domain/partner/location.ts';

export default function CheckoutAddressesList({selectedAddress, selectAddress}: CheckoutAddressesListProps) {
	const {user} = useAuth<UserWithAddresses>();
	const myCartQuery = useCartMyQuery();
	const addressBookQuery = useAddressBookCollectionQuery({params: {pagination: false}});
	const partnerLocationsQuery = usePartnerLocationsListQuery();

	const displayAddAddressModal = useToggle({initialState: false});

	useEffect(() => {
		if(!user) return;
		const location = partnerLocationsQuery.data?.data['hydra:member'].find(
			pl => pl.id === myCartQuery.data?.data.order.partnerLocation?.id,
		);
		user['@type'] === 'Partner' && location && selectAddress(location.address);
	}, [myCartQuery.data?.data.order.partnerLocation?.id, partnerLocationsQuery.data?.data, user]);

	if ((!addressBookQuery.data && addressBookQuery.isFetching) || partnerLocationsQuery.isFetching) return <>
		{[...Array(3)].map((_, i) => <Card
			key={i}
			spacing='sm'
			className='flex w-full gap-medium items-center'>
			<Skeleton className='w-6 h-5 rounded-full'/>

			<address className='w-full not-italic'>
				<Skeleton className='h-4 mb-2 w-3/4'/>
				<Skeleton className='h-4 mb-2 w-1/2 opacity-70'/>
				<Skeleton className='h-4 mb-2 w-2/3'/>
				<Skeleton className='h-4 w-1/4'/>

				<div className='flex flex-wrap items-center w-full mt-small'>
					<Skeleton className='w-40 h-4'/>
				</div>
			</address>
		</Card>)}
	</>;

	const billedToUser = !myCartQuery.data?.data.order.partnerLocation;
	const addressBook = [
		...(user?.['@type'] === 'Partner' ? partnerLocationsQuery.data?.data['hydra:member']
			.filter(pl => pl.id === myCartQuery.data?.data.order.partnerLocation?.id)
			.map(pl => ({
				address: pl.address,
				location: pl,
			})) || [] : []),
		...(billedToUser ? (addressBookQuery.data?.data['hydra:member'] || []) : []),
	];

	return <>
		{addressBook.map(ab => <Card
			variant={selectedAddress?.id === ab.address.id ? 'secondary' : 'default'}
			spacing='sm'
			key={ab.address.id}
			onClick={() => selectAddress(ab.address)}
			className='flex w-full gap-medium items-center hover:bg-secondary-500/20 cursor-pointer'>
			<FontAwesomeIcon
				icon={selectedAddress?.id === ab.address.id ? 'circle-check' : 'circle'}
				className={selectedAddress?.id === ab.address.id ? 'text-primary' : 'text-secondary opacity-20'}
				size='lg'/>
			<address className='w-full'>
				{(ab as { location: PartnerLocationCollectionModel }).location ? <h5>
					<FontAwesomeIcon icon='shop' className='mr-mini'/>
					{(ab as { location: PartnerLocationCollectionModel }).location?.name}
				</h5> : ''}
				<AddressFormatted address={ab.address}/>

				<div className='flex flex-wrap items-center -ml-small w-full'>
					{Object.keys(ab).length > 2 ? <div className='w-fit'>
						<AddressControls addressBookItem={ab as AddressBookCollectionModel} showControls={['edit']}/>
					</div> : ''}
					{user?.defaultShippingAddress?.id === ab.address.id ?
						<Badge className='mt-small shrink-0' variant='outline'>Default shipping address</Badge> : ''}
					{user?.defaultBillingAddress?.id === ab.address.id ?
						<Badge className='mt-small shrink-0' variant='outline'>Default billing address</Badge> : ''}
				</div>
			</address>
		</Card>)}

		{billedToUser ? <Button
			onClick={displayAddAddressModal.on}
			variant='outline'
			size='md'
			className='md:w-fit mt-small'
			icon={<FontAwesomeIcon icon='plus'/>}>
			Add new address
		</Button> : ''}
		{displayAddAddressModal.state ? <Modal
			isOpen={displayAddAddressModal.state}
			onClose={displayAddAddressModal.off}
			title='Add new address'>
			<AddressForm onSuccess={data => {
				displayAddAddressModal.off();
				selectAddress(data.data.address);
			}}/>
		</Modal> : ''}
	</>;
}

interface CheckoutAddressesListProps {
	selectedAddress?: AddressModel | null;
	selectAddress: (address: AddressModel) => void;
}