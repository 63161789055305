import {useQuery, UseQueryOptions} from '@tanstack/react-query';
import endpoints from '@mgp-fe/shared/core-api/endpoints.ts';
import keysResolver from '@mgp-fe/shared/core-api/keysResolver.ts';
import coreApiClient from '@mgp-fe/shared/core-api/client.ts';
import {AxiosResponse} from 'axios';
import {OrderModel} from '@mgp-fe/shared/core-api/domain/order.ts';

export default function useOrderDetailQuery(id?: string | null, props?: OrderItemQueryProps) {
	return useQuery<AxiosResponse<OrderModel>>(
		keysResolver(endpoints.orders.list, 'get', id),
		() => coreApiClient.get(endpoints.orders.item(id!)),
		{
			staleTime: Infinity,
			enabled: !!id,
			...props?.options,
		},
	);
}

interface OrderItemQueryProps {
	options?: UseQueryOptions<AxiosResponse<OrderModel>>;
}