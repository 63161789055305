/* eslint-disable */
// @ts-nocheck
import type * as Types from '@mgp-frontend/shared/cms-graphql/domain/types';

import { gql } from '@apollo/client';
export type BannerFragment = { __typename?: 'ComponentCommonBanner', id: string, messageText: string, code?: string | null };

export const BannerFragmentDoc = gql`
    fragment Banner on ComponentCommonBanner {
  id
  messageText
  code
}
    `;