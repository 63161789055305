import {useQuery, UseQueryOptions} from '@tanstack/react-query';
import {AxiosError, AxiosResponse} from 'axios';
import keysResolver from '@mgp-fe/shared/core-api/keysResolver.ts';
import endpoints from '@mgp-fe/shared/core-api/endpoints.ts';
import coreApiClient from '@mgp-fe/shared/core-api/client.ts';
import {HydraError} from '@mgp-fe/shared/core-api/domain/base.ts';
import {PromotionDetailedModel} from '@mgp-fe/shared/core-api/domain/promotions.ts';

export default function useGetPromotionDetailedQuery(props: PromotionDetailedQueryProps) {
	return useQuery({
		queryKey: keysResolver(endpoints.promotion.list, 'get', props.id),
		queryFn: () => coreApiClient.get<PromotionDetailedModel>(endpoints.promotion.itemDetailed(props.id!)),
		...props?.options,
		enabled: !!props.id,
	});
}

interface PromotionDetailedQueryProps {
    id?: string;
    options?: UseQueryOptions<AxiosResponse<PromotionDetailedModel>, AxiosError<HydraError>>;
}