import React from 'react';

export default function Banner({text, code}: BannerProps) {
	return <div className= 'flex !w-full bg-primary'>
		<div className='items-center flex flex-col md:flex-row mx-auto'>
			<p className='!text-black !font-semibold py-1.5'>{text}</p>
			<p className='!text-black !font-bold'>{code}</p>
		</div>
	</div>;
}

interface BannerProps {
	text: string;
	code: string;
}