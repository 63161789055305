import React, {useCallback} from 'react';
import {FormProvider, useForm} from 'react-hook-form';
import {TextInput} from '@mgp-fe/shared/ui/form/TextInput.tsx';
import {Button} from '@mgp-fe/shared/ui/button.tsx';
import useApplyVoucherMutation, {ApplyVoucherRequest} from '@mgp-fe/shared/core-api/mutations/cart/apply-voucher.ts';
import {Alert, AlertDescription, AlertTitle} from '@mgp-fe/shared/ui/alert.tsx';
import useNotify from '@mgp-fe/shared/ui/notifications/use-notify.ts';
import QRScanner from '@mgp-fe/shared/modules/common/components/QRScanner.tsx';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

export default function ClaimVoucherForm(props: ClaimVoucherFormProps) {
	const form = useForm<ApplyVoucherRequest>();
	const {notifySuccess, notifyWarning} = useNotify();
	const applyVoucherMutation = useApplyVoucherMutation({
		onSuccess: () => {
			notifySuccess({message: 'Voucher claimed'});
			props.onSuccess();
			form.reset();
		},
	});

	//https://member.mgp.dev/voucher?voucherCode=QL207420&id=13566be6-b977-4d84-a5c8-50cbb7919aec
	const qrToCode = useCallback((voucherUrl: string) => {
		const url = new URL(voucherUrl);
		const voucherCode = url.searchParams.get('voucherCode');
		if (voucherCode) {
			form.getValues('voucherCode') !== voucherCode && notifySuccess({message: 'Voucher code scanned'});
			form.setValue('voucherCode', voucherCode);
		} else {
			notifyWarning({message: 'Invalid QR code'});
		}
	}, []);

	return <FormProvider {...form}>
		<form
			onSubmit={form.handleSubmit(d => applyVoucherMutation.mutate(d))}
			className='flex flex-col gap-small horizontal-labels'>
			<p>
				Scan the QR code on your voucher or enter the voucher code manually.
			</p>
			<div className='w-full h-25 p-small border-2 border-secondary border-dashed rounded-lg relative'>
				<FontAwesomeIcon icon='qrcode' className='m-auto top-0 left-0 right-0 bottom-0 text-secondary w-4/5 h-4/5 opacity-30 absolute ' />
				<QRScanner setValue={qrToCode} />
			</div>

			<TextInput name='voucherCode' label='Voucher Code' required />
			<Button state={applyVoucherMutation.status}>
				Claim & use
			</Button>

			{applyVoucherMutation.isError ? <Alert variant='destructive'>
				<AlertTitle>Voucher not claimed</AlertTitle>
				<AlertDescription>
					{applyVoucherMutation.error?.response?.data['hydra:description'] || 'Unknown error'}
				</AlertDescription>
			</Alert> : null}
		</form>
	</FormProvider>;
}

interface ClaimVoucherFormProps {
	onSuccess: () => void;
}