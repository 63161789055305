import React from 'react';
import {PartnerLocationPublicCollectionModel} from '@mgp-fe/shared/core-api/domain/partner/location.ts';
import PartnerLocationCard from '@mgp-fe/shared/modules/partner-locator/components/PartnerLocationCard.tsx';
import {Alert} from '@mgp-fe/shared/ui/alert.tsx';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

export default function PartnerLocationsList({partnerLocations}: PartnerLocationsListProps) {
	if(!partnerLocations) return <></>;

	if(partnerLocations.length === 0) return <Alert variant='warning' size='xl' className='mx-auto col-span-2'>
		<FontAwesomeIcon icon='exclamation-triangle' className='mr-2'/>
		No locations found
	</Alert>;

	return <main className='grid md:grid-cols-2 gap-6 mt-8 md:mt-12 lg:mt-16'>
		{partnerLocations.map((pl, i) => <PartnerLocationCard key={i} location={pl}/>)}
	</main>;
}

interface PartnerLocationsListProps {
	partnerLocations?: PartnerLocationPublicCollectionModel[];
}