import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button} from '@mgp-fe/shared/ui/button.tsx';
import getClientEnv from '@mgp-fe/shared/utils/env-var-resolver.ts';

export default function CompletedPage() {
	return <div className='text-center my-xlarge'>
		<h3>Your onboarding has been completed!</h3>

		<p>Thank you for your order. We will send you an email with your order confirmation.</p>

		<Button asChild className='mt-medium' variant='default' size='lg'>
			<a href={getClientEnv('customerAppUrl')}>
				Continue to member zone <FontAwesomeIcon icon='chevron-right'/>
			</a>
		</Button>
	</div>;
}
