import {useMutation, UseMutationOptions} from '@tanstack/react-query';
import {AxiosError, AxiosResponse} from 'axios';
import keysResolver from '@mgp-fe/shared/core-api/keysResolver.ts';
import endpoints from '@mgp-fe/shared/core-api/endpoints.ts';
import coreApiClient from '@mgp-fe/shared/core-api/client.ts';

export default function useNewsletterSignUpMutation(props: UseNewsletterSignUpMutationProps) {
	return useMutation<AxiosResponse<undefined>, AxiosError, NewsletterSignUpMutationData>(
		keysResolver(endpoints.newsletter.signup),
		(data: NewsletterSignUpMutationData) => coreApiClient.post(endpoints.newsletter.signup, data),
		props,
	);
}

type UseNewsletterSignUpMutationProps = UseMutationOptions<
    AxiosResponse<undefined>,
    AxiosError,
    NewsletterSignUpMutationData
>;

export interface NewsletterSignUpMutationData {
    email: string;
}