import React from 'react';
import DesignerModal from '@mgp-fe/shared/modules/mouthguard-design/components/DesignerModal.tsx';
import MemberZoneLayout from '@mgp-fe/customer/modules/common/ui/MemberZoneLayout.tsx';
import useToggle from '@mgp-fe/shared/hooks/useToggle.ts';
import useAuth from '@mgp-fe/shared/modules/auth/hooks/useAuth.ts';
import {Customer} from '@mgp-fe/shared/core-api/domain/user.ts';
import {Navigate, useNavigate, useParams} from 'react-router-dom';
import routes from '@mgp-fe/customer/router/routes.ts';
import keysResolver from '@mgp-fe/shared/core-api/keysResolver.ts';
import endpoints from '@mgp-fe/shared/core-api/endpoints.ts';
import {useQueryClient} from '@tanstack/react-query';

export default function DesignerPage() {
	const {user, isUserLoaded} = useAuth<Customer>();
	const {id} = useParams();

	const queryClient = useQueryClient();

	const displayModal = useToggle({initialState: true});

	const navigate = useNavigate();
	
	if(!user && isUserLoaded) return <Navigate to={`${routes.onboarding.index}?designer=1`} replace={true}/>;

	return <MemberZoneLayout>
		{displayModal.state ? <DesignerModal
			design={{id}}
			onClose={() => {
				displayModal.off();
				navigate(routes.gardDesigns.index);
				queryClient.invalidateQueries(keysResolver(endpoints.mouthguardDesigns.list));
			}}/> : ''}
	</MemberZoneLayout>;
}
