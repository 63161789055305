import React from 'react';
import useOrdersListQuery from '@mgp-fe/shared/core-api/queries/order/list.ts';
import OrdersCardsList, {OrdersListSkeleton} from '@mgp-fe/shared/modules/orders/components/OrdersCardsList.tsx';
import {NavLink} from 'react-router-dom';
import {Alert, AlertDescription, AlertTitle} from '@mgp-fe/shared/ui/alert.tsx';

export default function OrdersSection() {
	const ordersQuery = useOrdersListQuery({
		params: {page: 1, itemsPerPage: 1},
		options: {keepPreviousData: true},
	});

	if(ordersQuery.isFetching) return <section>
		<h3 className='mb-small text-secondary'>Latest order</h3>
		<OrdersListSkeleton itemsCount={1}/>
	</section> ;

	return <section>
		<h3 className='mb-small text-secondary'>Latest order</h3>
		<OrdersCardsList
			showItems
			className='w-full'
			orders={ordersQuery.data?.data['hydra:member']}
			navLinkComponent={({children, ...props}) => <NavLink
				{...props}
				to={`/orders/${props.order.id}`}
			>{children}</NavLink>}
		/>

		{ordersQuery.data?.data['hydra:totalItems'] === 0 ?
			<Alert className='mt-0'>
				<AlertTitle className='text-muted/80'>
					You don&apos;t have any orders yet.
				</AlertTitle>
				<AlertDescription>
					<NavLink to='/shop'>Check shop</NavLink> and order something.
				</AlertDescription>
			</Alert> : ''}
	</section>;
}
