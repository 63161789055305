/* eslint-disable */
// @ts-nocheck
import type * as Types from '@mgp-frontend/shared/cms-graphql/domain/types';

import { gql } from '@apollo/client';
export type TabsFragment = { __typename?: 'ComponentContentBlocksTabs', Tab?: Array<{ __typename?: 'ComponentContentBlocksTab', title: string, description: string } | null> | null };

export const TabsFragmentDoc = gql`
    fragment Tabs on ComponentContentBlocksTabs {
  Tab {
    title
    description
  }
}
    `;