import {useQuery, UseQueryOptions} from '@tanstack/react-query';
import {AxiosError, AxiosResponse} from 'axios';
import keysResolver from '@mgp-fe/shared/core-api/keysResolver.ts';
import endpoints from '@mgp-fe/shared/core-api/endpoints.ts';
import coreApiClient from '@mgp-fe/shared/core-api/client.ts';
import {SignUpLinkModel} from '@mgp-fe/shared/core-api/domain/signup-link.ts';
import {HydraError} from '@mgp-fe/shared/core-api/domain/base.ts';

export const useSignUpLinkDetailQuery = (props: SignUpLinkDetailQueryProps) => {
	return useQuery({
		queryKey: keysResolver(endpoints.signUpLinks.item(props.id)),
		queryFn: () => coreApiClient.get<SignUpLinkModel>(endpoints.signUpLinks.item(props.id)),
		...props?.options,
	});
};

interface SignUpLinkDetailQueryProps {
	id: string;
	options?: UseQueryOptions<
		AxiosResponse<SignUpLinkModel>,
		AxiosError<HydraError>
	>;
}