import React from 'react';
import {CouponsCollectionModel} from '@mgp-fe/shared/core-api/domain/coupons.ts';
import {Button} from '@mgp-fe/shared/ui/button.tsx';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import AlertConfirmDialog from '@mgp-fe/shared/ui/AlertConfirmDialog.tsx';
import useRemoveCouponsFromCartMutation from '@mgp-fe/shared/core-api/mutations/cart/remove-coupons.ts';

export default function AppliedCoupons({coupons, controls = true}: AppliedCouponsProps) {
	const removeCouponsMutation = useRemoveCouponsFromCartMutation();

	return <div className='flex gap-mini'>
		{coupons?.map(c =>
			<AlertConfirmDialog
				key={c.id}
				title='Do you want to unapply the voucher?'
				description={null}
				onConfirm={() => controls && removeCouponsMutation.mutate({coupons: [c.id]})}
				triggerButton={<Button
					state={removeCouponsMutation.status}
					size='sm'
					variant='outline'
					className='w-fit'
					disabled={!controls}
					icon={controls ? <FontAwesomeIcon icon='times'/> : undefined}
					iconPosition='right'>
					{c.code}
				</Button>}/>,
		)}
	</div>;
}

interface AppliedCouponsProps {
    coupons?: CouponsCollectionModel[];
    controls?: boolean;
}