import {useQuery, UseQueryOptions} from '@tanstack/react-query';
import {AxiosError, AxiosResponse} from 'axios';
import {HydraError} from '@mgp-fe/shared/core-api/domain/base.ts';
import { VoucherPublicModel} from '@mgp-fe/shared/core-api/domain/voucher.ts';
import keysResolver from '@mgp-fe/shared/core-api/keysResolver.ts';
import endpoints from '@mgp-fe/shared/core-api/endpoints.ts';
import coreApiClient from '@mgp-fe/shared/core-api/client.ts';

export const useVoucherPublicDetailQuery = ({id, code, options}: UseGetVoucherPublicOptions) => {
	return useQuery({
		queryKey: keysResolver(endpoints.vouchers.publicDetail(id, code)),
		queryFn: () => coreApiClient.get(endpoints.vouchers.publicDetail(id, code)),
		...options,
	});
};

type UseGetVoucherPublicOptions = {
	id: string;
	code: string;
	options?: UseQueryOptions<
		AxiosResponse<VoucherPublicModel>,
		AxiosError<HydraError>
	>;
}

