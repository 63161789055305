import React from 'react';
import PrivacySettingsForm from '@mgp-fe/shared/modules/account/components/privacy-settings/PrivacySettingsForm.tsx';
import {SubmitHandler, useForm} from 'react-hook-form';
import useUpdateConsentSettingsMutation, {
	UpdateConsentSettingsMutationData,
	UpdateConsentSettingsMutationDataSchema,
} from '@mgp-fe/shared/core-api/mutations/user/consent-settings.ts';
import {zodResolver} from '@hookform/resolvers/zod';
import useNotify from '@mgp-fe/shared/ui/notifications/use-notify.ts';
import useAuth from '@mgp-fe/shared/modules/auth/hooks/useAuth.ts';
import {Button} from '@mgp-fe/shared/ui/button.tsx';


export default function PrivacySettingsPage() {
	const {notifySuccess, notifyError} = useNotify();
	const {user} = useAuth();

	const form = useForm<UpdateConsentSettingsMutationData>({
		resolver: zodResolver(UpdateConsentSettingsMutationDataSchema),
		...(user ? {
			defaultValues: {
				contactConsent: {
					transactionalEmails: user.contactConsentSettings?.transactionalEmails,
					transactionalSms: user.contactConsentSettings?.transactionalSms,
					transactionalCalls: user.contactConsentSettings?.transactionalCalls,
					marketingEmails: user.contactConsentSettings?.marketingEmails,
					marketingSms: user.contactConsentSettings?.marketingSms,
					marketingCalls: user.contactConsentSettings?.marketingCalls,
					timezone: user.contactConsentSettings?.timezone,
					preferredLanguage: user.contactConsentSettings?.preferredLanguage,
				},
			},
		} : {}),
	});

	const updateMe = useUpdateConsentSettingsMutation({
		onSuccess: () => notifySuccess({message: 'Privacy settings successfully updated'}),
		onError: error => notifyError({
			title: error.name || 'Error, unable to update.',
			message: error.message || 'Privacy settings were not updated...',
		}),
	});
	const onSubmit: SubmitHandler<UpdateConsentSettingsMutationData> = (data) => {
		if (data.contactConsent?.transactionalEmails || data.contactConsent?.transactionalSms) {
			updateMe.mutate(data);
		} else {
			notifyError({message: 'Select at least 1 transaction method'});
		}
	};

	return <form onSubmit={form.handleSubmit(onSubmit)}>
		<PrivacySettingsForm form={form}/>
		<div className='flex justify-end mt-8'>
			<Button type='submit' state={updateMe.status} className='w-fit'>
				Save
			</Button>
		</div>
	</form>;
}

