'use client';

import {useQuery, UseQueryOptions} from '@tanstack/react-query';
import keysResolver from '@mgp-fe/shared/core-api/keysResolver.ts';
import endpoints from '@mgp-fe/shared/core-api/endpoints.ts';
import {ArchivableListParams, CollectionParams, HydraListResponse} from '@mgp-fe/shared/core-api/domain/base.ts';
import {ChannelName} from '@mgp-fe/shared/core-api/domain/channel.ts';
import {AxiosResponse} from 'axios';
import coreApiClient from '@mgp-fe/shared/core-api/client.ts';
import {PartnerLocationPublicCollectionModel} from '@mgp-fe/shared/core-api/domain/partner/location.ts';

export default function usePartnerLocationsListPublicQuery(props?: ProductListPublicQueryProps) {
	return useQuery<AxiosResponse<HydraListResponse<PartnerLocationPublicCollectionModel>>>(
		keysResolver(endpoints.partnerLocations.listPublic),
		() => coreApiClient.get<HydraListResponse<PartnerLocationPublicCollectionModel>>(endpoints.partnerLocations.listPublic, {
			params: props?.params,
		}),
		{
			...props?.options,
		},
	);
}

interface ProductListPublicQueryProps {
	params?: CollectionParams & ArchivableListParams & {
		channels?: {
			name?: ChannelName | ChannelName[];
		};
	};
	options?: UseQueryOptions<AxiosResponse<HydraListResponse<PartnerLocationPublicCollectionModel>>>;
}