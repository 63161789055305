'use client';

import {useQuery, UseQueryOptions} from '@tanstack/react-query';
import keysResolver from '@mgp-fe/shared/core-api/keysResolver.ts';
import endpoints from '@mgp-fe/shared/core-api/endpoints.ts';
import {AxiosResponse} from 'axios';
import coreApiClient from '@mgp-fe/shared/core-api/client.ts';
import {HubspotTimezones} from '@mgp-fe/shared/core-api/domain/hubspot.ts';

export default function useHubspotTimezonesListQuery(props?: HubspotTimezonesListQueryProps) {

	return useQuery({
		queryKey: keysResolver(endpoints.hubspot.timezones.list, 'get', props?.params),
		queryFn: async () => coreApiClient.get<HubspotTimezones>(endpoints.hubspot.timezones.list, {
			params: props?.params,
		}),
		...props?.options,
	});
}

interface HubspotTimezonesListQueryProps {
	params: {
		recaptchaToken: string;
	}
    options?: UseQueryOptions<AxiosResponse<HubspotTimezones>>;
}