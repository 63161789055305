import React, {Dispatch, SetStateAction, useEffect, useState} from 'react';
import Modal from '@mgp-fe/shared/ui/Modal.tsx';
import CustomerDesignsSection from '@mgp-fe/shared/modules/mouthguard-design/components/CustomerDesignsSection.tsx';
import useCartMyQuery from '@mgp-fe/shared/core-api/queries/cart/my.tsx';
import useNotify from '@mgp-fe/shared/ui/notifications/use-notify.ts';

export default function MouthguardDesignAddModal({
	useDisplayState: [selectDesignForMouthguard, setSelectDesignForMouthguard],
}: MouthguardDesignAddModalProps) {
	const {notifySuccess} = useNotify();
	const myCartQuery = useCartMyQuery();
	const [updatedAfterOpened, setUpdatedAfterOpened] = useState<number>();

	useEffect(() => {
		selectDesignForMouthguard !== undefined && setUpdatedAfterOpened(myCartQuery.dataUpdatedAt);
	}, [myCartQuery.dataUpdatedAt]);

	useEffect(() => {
		if(selectDesignForMouthguard === undefined || (updatedAfterOpened && myCartQuery.dataUpdatedAt > updatedAfterOpened)) return;
		notifySuccess('Mouthguard with design added to cart.');
		setSelectDesignForMouthguard(undefined);
	}, [myCartQuery.dataUpdatedAt, updatedAfterOpened, notifySuccess]);

	return <Modal
		isOpen={selectDesignForMouthguard !== undefined}
		onClose={() => setSelectDesignForMouthguard(undefined)}
		title='Select design'
		description='Here you can select a design for your mouthguard.'
		dialogPanelClassName='max-w-7xl'
		zIndex={50}>

		<CustomerDesignsSection />
	</Modal>;
}

interface MouthguardDesignAddModalProps {
	useDisplayState: [string | undefined, Dispatch<SetStateAction<string | undefined>>];
}