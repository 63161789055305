'use client';

import React, {useContext, useMemo, useState} from 'react';
import LocaleContext from '@mgp-fe/shared/localization/LocaleContext.ts';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import LogoText from '@mgp-fe/shared/assets/logo/text-logo.svg';
import {cn} from '@mgp-fe/shared/utils';
import {HeaderNavigationItemsFragment} from '@mgp-fe/shared/cms-graphql/queries/components/generated/headerNavigationItems.ts';

export default function DefaultHeader({children}: DefaultHeaderProps) {
	return <header className='flex flex-col'>
		{children}
	</header>;
}

interface DefaultHeaderProps {
	children: React.ReactNode;
}

DefaultHeader.TopBar = function DefaultHeaderTopBar({children}: DefaultHeaderTopBarProps) {
	return <div className='bg-background/90 backdrop-blur-3xl bg-opacity-25 shadow-inner '>
		<nav className='container flex flex-wrap justify-between items-center text-primary '>
			{children}
		</nav>
	</div>;
};

interface DefaultHeaderTopBarProps {
	children: React.ReactNode;
}

DefaultHeader.Navigation = function DefaultHeaderNavigation({
	items,
	renderItem,
	currentPath,
	LogoComponent,
	menuClassName,
}: NavigationProps) {
	const locale = useContext(LocaleContext);
	const [menuOpened, setMenuOpened] = useState(false);

	const pathNameWithoutLang = useMemo(
		() => currentPath.replace(`/${locale}`, ''),
		[locale, currentPath],
	);

	return <nav className='container flex justify-between items-center py-5 md:py-7 lg:py-9'>
		{LogoComponent || ''}


		<div className='lg:hidden flex gap-small'>
			{items.filter(i => i.showOnMobile)
				.map((item, index) => <React.Fragment key={index}>
					{renderItem(item, '!px-small !py-mini')}
				</React.Fragment>)}

			<button className='p-small' onClick={() => setMenuOpened(true)}>
				<FontAwesomeIcon icon='bars' className='text-primary h-6 md:h-9'/>
			</button>
		</div>

		<menu
			className={cn('flex flex-col items-start lg:flex-row lg:items-center gap-x-10 lg:gap-x-12 gap-y-3 overflow-y-auto lg:flex-wrap absolute lg:static w-screen lg:w-auto h-screen lg:h-auto  top-0 left-0 easy-in duration-300 delay-150 transition-all',
				menuOpened ? 'z-50 backdrop-blur-lg fixed bg-black/80  p-7' : 'invisible lg:visible',
				menuClassName,
			)}>
			<div className='grow lg:hidden'/>

			<LogoText
				className={cn('fill-primary h-8 w-auto z-50 mb-6 lg:hidden easy-in-out duration-150 transition-all',
					menuOpened ? 'opacity-100' : 'opacity-0 lg:opacity-100',
				)}/>

			{items.map((item, index) => {
				const itemHrefWithoutLang = (item?.url ?? '').replace(`/${locale}`, '');
				const isItemActive = (pathNameWithoutLang.startsWith(itemHrefWithoutLang) && itemHrefWithoutLang !== '' && itemHrefWithoutLang !== '/')
					|| itemHrefWithoutLang.replace('/', '') === pathNameWithoutLang.replace('/', '');

				const className = cn(
					'text-xl w-fit py-2 lg:text-xl font-medium border-b-2 hover:text-primary easy-in-out duration-150 transition-all',
					menuOpened ? 'opacity-100' : 'opacity-0 lg:opacity-100',
					item.highlighted ? 'text-primary border-b-primary' : 'text-light border-b-transparent',
					item.icon ? 'flex gap-2 items-center' : '',
					isItemActive ? 'text-primary border-b-primary' : '',
				);

				return <React.Fragment key={index}>{renderItem(item, className)}</React.Fragment>;
			})}

			<button onClick={() => setMenuOpened(false)} className={cn(
				'text-secondary flex gap-2 lg:hidden py-4 my-2',
				'easy-in-out duration-150 transition-all',
				menuOpened ? 'opacity-100' : 'opacity-0',
			)}>
				<FontAwesomeIcon icon='close' className='h-5 lg:h-6 w-auto'/>
				close
			</button>
			<div className='grow lg:hidden'/>

		</menu>
	</nav>;
};

export interface NavigationProps {
	currentPath: string;
	items: (HeaderNavigationItemsFragment & {
		icon?: React.ReactNode;
		highlighted?: boolean;
		showOnMobile?: boolean;
	})[];
	renderItem: (item: HeaderNavigationItemsFragment & { icon?: React.ReactNode; }, className: string) => React.ReactNode;
	LogoComponent?: React.ReactNode;
	menuClassName?: string;
}