import React, {useContext, useEffect} from 'react';
import useNotify from '@mgp-fe/shared/ui/notifications/use-notify.ts';
import {useVoucherPublicDetailQuery} from '@mgp-fe/shared/core-api/queries/voucher/voucher-public.ts';
import useOnboardingAssignVoucherMutation from '@mgp-fe/shared/core-api/mutations/voucher/onboarding-assign.ts';
import useAuth from '@mgp-fe/shared/modules/auth/hooks/useAuth.ts';
import {Customer} from '@mgp-fe/shared/core-api/domain/user.ts';
import AuthSection from '@mgp-fe/shared/modules/onboarding/components/welcome/AuthSection.tsx';
import {Alert} from '@mgp-fe/shared/ui/alert.tsx';
import Skeleton from '@mgp-fe/shared/ui/skeleton.tsx';
import {Button} from '@mgp-fe/shared/ui/button.tsx';
import {OnboardingContext} from '@mgp-fe/shared/modules/onboarding';
import getClientEnv from '@mgp-fe/shared/utils/env-var-resolver.ts';

export default function VoucherMode() {
	const onboardingContext = useContext(OnboardingContext);
	const {user, isUserLoading} = useAuth<Customer>();
	const {notifySuccess} = useNotify();
	const voucherQuery = useVoucherPublicDetailQuery({
		id: onboardingContext.mode.voucherId || '',
		code: onboardingContext.mode.voucherCode || '',
	});
	const assignVoucherPublicMutation = useOnboardingAssignVoucherMutation();



	useEffect(() => {
		if (!user || !voucherQuery.data?.data || !onboardingContext.mode.voucherCode) return;

		assignVoucherPublicMutation.mutate(
			{
				voucher: voucherQuery.data?.data,
				voucherCode: onboardingContext.mode.voucherCode},
			{
				onSuccess: () => {
					onboardingContext.setMode({});
					notifySuccess({message: 'Voucher successfully claimed.'});
				},
			});
	}, [user, voucherQuery.data?.data]);

	const voucher = voucherQuery.data?.data;
	if(voucherQuery.isFetching || isUserLoading) {
		return <div className='grid md:grid-cols-2 gap-large items-center'>
			<section className='flex flex-col items-center'>
				<h4 className='mb-4 text-muted'>
					You have voucher for
				</h4>
				<Skeleton className='w-5/6  h-10 mt-mini' />
				<Skeleton className='w-72  h-48 mt-medium' />

				<Skeleton className='w-5/6  h-7 mt-medium' />
			</section>
			<section className='flex flex-col md:flex-row'>
				<span
					className='h-0.5 md:h-96 w-full md:w-0.5 bg-gradient-to-r md:bg-gradient-to-b from-transparent via-primary to-transparent md:mr-medium my-medium md:my-auto'/>
				<Skeleton className='w-full h-80 mx-auto mt-medium'/>
			</section>
		</div>;
	}
	if(!voucher) return <Alert>Voucher not found</Alert>;

	if (voucher.assignedTo) {
		return <div className='flex flex-col gap-medium items-center'>
			<h3 className='mb-4 mx-auto text-center'>
				This voucher link is no longer valid :(
			</h3>
			<Button onClick={() => onboardingContext.setOnboardingStep('design')} className='w-fit'>
					Continue to onboarding
			</Button>

			<Button asChild variant='outline' className='w-fit'>
				<a href={getClientEnv('customerAppUrl')}>
					Login
				</a>
			</Button>
		</div>;
	}

	return <div className='grid md:grid-cols-2 items-center'>
		<section className='text-center'>
			<h4 className='mb-4 text-muted'>
				You have voucher for
			</h4>
			<h3>{voucher.product.product?.name || undefined}</h3>
			<img
				alt='Product voucher'
				className='mx-auto mt-8 max-h-40'
				src={voucher.product.product?.titleImage || undefined}/>

			<p>
				Register or login to claim your voucher.
			</p>
		</section>

		<section className='w-full flex flex-col md:flex-row'>
			<span
				className='h-0.5 md:h-96 w-full md:w-0.5 bg-gradient-to-r
				md:bg-gradient-to-b from-transparent via-primary to-transparent
				md:mr-medium my-medium md:my-auto'/>

			<AuthSection registerStatus={assignVoucherPublicMutation.isLoading ? 'loading': undefined}/>
		</section>
	</div>;
}
