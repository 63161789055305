import React from 'react';
import {Button} from '@mgp-fe/shared/ui/button.tsx';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
	DropdownMenu,
	DropdownMenuCheckboxItem,
	DropdownMenuContent,
	DropdownMenuTrigger,
} from '@mgp-fe/shared/ui/dropdown-menu.tsx';
import {AddressBookCollectionModel} from '@mgp-fe/shared/core-api/domain/address.ts';
import {UserWithAddresses} from '@mgp-fe/shared/core-api/domain/user.ts';
import useAuth from '@mgp-fe/shared/modules/auth/hooks/useAuth.ts';
import {
	useAddressBookMutation,
	useDeleteAddressBookMutation,
} from '@mgp-fe/shared/core-api/mutations/address/address-book.ts';
import useToggle from '@mgp-fe/shared/hooks/useToggle.ts';
import Modal from '@mgp-fe/shared/ui/Modal.tsx';
import AddressForm from '@mgp-fe/shared/modules/account/components/address-book/AddressForm.tsx';
import {cn} from '@mgp-fe/shared/utils';
import AlertConfirmDialog from '@mgp-fe/shared/ui/AlertConfirmDialog.tsx';

export default function AddressesList({
	addressBookItem,
	showControls = ['edit', 'delete', 'makeDefault'],
}: AddressesListProps) {
	const {user} = useAuth();
	const updateAddressMutation = useAddressBookMutation();
	const deleteAddressMutation = useDeleteAddressBookMutation();

	const displayModal = useToggle({initialState: false});

	const customer = user as UserWithAddresses | undefined | null;

	return <div className='flex flex-wrap items-center justify-between gap-mini mt-small w-full'>
		<Button
			type='button'
			onClick={displayModal.on}
			variant='ghost'
			size='sm'
			className={cn('text-secondary', showControls.includes('edit') ? '' : 'hidden')}
			icon={<FontAwesomeIcon icon='pen'/>}>
			Edit
		</Button>
		<Modal isOpen={displayModal.state} onClose={displayModal.off} title='Update address'>
			<AddressForm
				setAsDefaultBilling={customer?.defaultBillingAddress?.id === addressBookItem.address.id}
				setAsDefaultShipping={customer?.defaultShippingAddress?.id === addressBookItem.address.id}
				editedAddress={addressBookItem}
				onSuccess={displayModal.off}/>
		</Modal>

		{customer?.defaultShippingAddress?.id !== addressBookItem.address.id || customer?.defaultBillingAddress?.id !== addressBookItem.address.id
			? <DropdownMenu>
				<DropdownMenuTrigger asChild>
					<Button
						state={updateAddressMutation.isLoading ? 'loading' : 'idle'}
						variant='ghost'
						size='sm'
						className={cn('text-muted/80', showControls.includes('makeDefault') ? '' : 'hidden')}
						icon={<FontAwesomeIcon icon='check'/>}>
						Make as default
					</Button>
				</DropdownMenuTrigger>
				<DropdownMenuContent>
					<DropdownMenuCheckboxItem
						checked={customer?.defaultShippingAddress?.id === addressBookItem.address.id}
						onCheckedChange={() => updateAddressMutation.mutate({
							data: {setAsDefaultShipping: true},
							update: addressBookItem,
						})}>
						<FontAwesomeIcon icon='house' className='mr-mini'/>
						shipping address
					</DropdownMenuCheckboxItem>
					<DropdownMenuCheckboxItem
						checked={customer?.defaultBillingAddress?.id === addressBookItem.address.id}
						onCheckedChange={() => updateAddressMutation.mutate({
							data: {setAsDefaultBilling: true},
							update: addressBookItem,
						})}>
						<FontAwesomeIcon icon='money-bill' className='mr-mini'/>
						billing address
					</DropdownMenuCheckboxItem>
				</DropdownMenuContent>
			</DropdownMenu>
			: ''}

		<div className='grow'/>

		<AlertConfirmDialog
			description='This will permanently delete this address and will be kept only in orders history.'
			onConfirm={() => deleteAddressMutation.mutate(addressBookItem)}
			triggerButton={<Button
				state={deleteAddressMutation.isLoading ? 'loading' : 'idle'}
				variant='ghost'
				size='sm'
				className={cn('text-destructive-400', showControls.includes('delete') ? '' : 'hidden')}
				icon={<FontAwesomeIcon icon='trash'/>}>
				Delete
			</Button>}/>
	</div>;
}

interface AddressesListProps {
	addressBookItem: AddressBookCollectionModel;
	showControls?: AddressCardControls[];
}

export type AddressCardControls = 'edit' | 'delete' | 'makeDefault';