import React from 'react';
import {FormProvider, useForm} from 'react-hook-form';
import {TextInput} from '@mgp-fe/shared/ui/form/TextInput.tsx';
import {Button} from '@mgp-fe/shared/ui/button.tsx';
import {Alert, AlertDescription, AlertTitle} from '@mgp-fe/shared/ui/alert.tsx';
import useRedeemCouponMutation, {
	RedeemCouponMutationRequest,
} from '@mgp-fe/shared/core-api/mutations/cart/apply-coupon.ts';
import useNotify from '@mgp-fe/shared/ui/notifications/use-notify.ts';
import {UseMutationResult, useQueryClient} from '@tanstack/react-query';
import keysResolver from '@mgp-fe/shared/core-api/keysResolver.ts';
import endpoints from '@mgp-fe/shared/core-api/endpoints.ts';
import {AxiosError, AxiosResponse} from "axios";
import {OrderModel} from "@mgp-fe/shared/core-api/domain/order.ts";
import {HydraError} from "@mgp-fe/shared/core-api/domain/base.ts";

export default function RedeemCouponForm(props: RedeemCouponFormProps) {
	const form = useForm<RedeemCouponMutationRequest>();
	const queryClient = useQueryClient();

	const {notifySuccess} = useNotify();

	const redeemCouponMutation = useRedeemCouponMutation({
		onSuccess: async () => {
			await queryClient.invalidateQueries(keysResolver(endpoints.cart.my.item));
			notifySuccess({message: 'Coupon redeemed'});
			props.onSuccess && props.onSuccess();
			form.reset();
		}
	});

	const buildErrorMessage = (mutation: UseMutationResult<AxiosResponse<OrderModel, any>, AxiosError<HydraError, any>, RedeemCouponMutationRequest, unknown>) => {
		if (mutation.error?.response?.data === undefined) return;

		let errorBody;

		try {
			errorBody = JSON.parse(mutation.error.response.data?.detail ?? '');
		} catch {
			return mutation.error.response.data.detail || 'Unknown error';
		}

		return Object.values(errorBody).join(' ');
	}

	return <FormProvider {...form}>
		<form
			onSubmit={form.handleSubmit(d => redeemCouponMutation.mutate({code: d.code.replaceAll(' ', '')}))}
			className='flex flex-col gap-small horizontal-labels'>
			<p>
				Please enter your promocode.
			</p>

			<TextInput name='code' label='Promo Code' required />
			<Button state={redeemCouponMutation.status}>
				Redeem & use
			</Button>

			{redeemCouponMutation.isError ? <Alert variant='destructive'>
				<AlertTitle>Promocode not redeemed</AlertTitle>
				<AlertDescription>
					{buildErrorMessage(redeemCouponMutation)}
				</AlertDescription>
			</Alert> : null}
		</form>
	</FormProvider>;
}

interface RedeemCouponFormProps {
	onSuccess?: () => void;
}