import React from 'react';
import useOAuthListQuery from "@mgp-fe/shared/core-api/queries/oauth/list.ts";
import OAuthCard, {OAuthCardSkeleton} from "@mgp-fe/shared/modules/account/components/profile/OAuthCard.tsx";
import {oAuthTypes} from "@mgp-fe/shared/core-api/domain/oauth.ts";

export default function OAuthConnections() {
    const oauthQuery = useOAuthListQuery();

    return (
        <div className='max-w-xl'>
            <h3 className='mb-small'>My OAuth connections</h3>
            <div className="flex flex-col gap-4">
                {oauthQuery.isLoading
                    ? Array.from([1,2]).map((i) => <OAuthCardSkeleton key={i}/>)
                    : oAuthTypes.map((oat, index) => {
                        const userOauth = oauthQuery.data?.data['hydra:member'].find((uoa) => uoa.type === oat);

                        return <OAuthCard key={index} oauth={userOauth} oauthType={oat} />
                    })
                }
            </div>
        </div>
    );
};

