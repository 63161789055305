import {useMutation, UseMutationOptions, useQueryClient} from '@tanstack/react-query';
import keysResolver from '@mgp-fe/shared/core-api/keysResolver.ts';
import endpoints from '@mgp-fe/shared/core-api/endpoints.ts';
import {CartModel} from '@mgp-fe/shared/core-api/domain/cart.ts';
import {AxiosError, AxiosResponse} from 'axios';
import {HydraError} from '@mgp-fe/shared/core-api/domain/base.ts';
import coreApiClient from '@mgp-fe/shared/core-api/client.ts';
import {ThicknessType} from '@mgp-fe/shared/core-api/domain/mouthguard.ts';

export default function useCartItemChangeThicknessMutation(props?: UseItemChangeThicknessMutationProps) {
	const queryClient = useQueryClient();

	return useMutation({
		mutationKey: keysResolver(endpoints.cart.my.items.itemChangeThickness('')),
		mutationFn: (rq) => coreApiClient.patch<CartModel>(endpoints.cart.my.items.itemChangeThickness(rq.id), rq),
		...props,
		onSuccess: async (data, variables, context) => {
			await queryClient.invalidateQueries(keysResolver(endpoints.cart.my.item));
			props?.onSuccess?.(data, variables, context);
		},
	});
}

type UseItemChangeThicknessMutationProps = UseMutationOptions<
	AxiosResponse<CartModel>,
	AxiosError<HydraError>,
	CartItemChangeThicknessRequest
>;

export interface CartItemChangeThicknessRequest {
	id: string;
	mouthguardThickness: ThicknessType;
}