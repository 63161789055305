import {AddressModel} from "@mgp-fe/shared/core-api/domain/address.ts";
import {HydraModel} from "@mgp-fe/shared/core-api/domain/base.ts";

const emptyAddress = {
    lineOne: '',
    lineTwo: '',
    city: '',
    region: '',
    countryCode: '',
    postCode: '',
};

export const transformAddressComponentsToAddress = (
    addressComponents: google.maps.GeocoderAddressComponent[]
): Omit<AddressModel, keyof HydraModel> => {
    let newAddress = emptyAddress;

    if ( addressComponents ) {
        for ( const component of addressComponents ) {
            const componentType = component.types[ 0 ];
            switch ( componentType ) {
                case "street_number": {
                    newAddress.lineOne = `${ component.long_name }`;
                    break;
                }
                case "route": {
                    newAddress.lineOne += newAddress.lineOne ? ` ${ component.long_name }` : component.long_name;
                    break;
                }
                case "postal_code": {
                    newAddress.postCode = component.long_name;
                    break;
                }
                case "locality":
                case "sublocality_level_1":
                    newAddress.city = component.long_name;
                    break;
                case "neighborhood":
                case "administrative_area_level_2":
                case "administrative_area_level_3":
                    if ( newAddress.city === "" ) newAddress.city = component.long_name;
                    break;
                case "administrative_area_level_1": {
                    newAddress.region = component.short_name;
                    break;
                }
                case "country":
                    newAddress.countryCode = component.short_name;
                    break;
            }
        }
    }

    return newAddress;
};