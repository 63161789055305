'use client';

import {Loader} from '@googlemaps/js-api-loader';
import getClientEnv from '@mgp-fe/shared/utils/env-var-resolver.ts';

const loader = new Loader({
	apiKey: getClientEnv('googleMapsApiKey'),
	version: 'weekly',
	libraries: ['places'],
});

export default loader;