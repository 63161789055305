import {useMutation, UseMutationOptions, useQueryClient} from '@tanstack/react-query';
import {AxiosError, AxiosResponse} from 'axios';
import {HydraError} from '@mgp-fe/shared/core-api/domain/base.ts';
import keysResolver from '@mgp-fe/shared/core-api/keysResolver.ts';
import endpoints from '@mgp-fe/shared/core-api/endpoints.ts';
import coreApiClient from '@mgp-fe/shared/core-api/client.ts';
import {OrderModel} from '@mgp-fe/shared/core-api/domain/order.ts';


export default function useRemoveCouponsFromCartMutation(props?: UseRemoveCouponFromCartMutationProps){
	const queryClient = useQueryClient();

	return useMutation({
		mutationKey: keysResolver(endpoints.cart.my.removeCoupons, 'patch'),
		mutationFn: data => coreApiClient.patch(endpoints.cart.my.removeCoupons, {
			coupons: data.coupons,
		}),
		...props,
		onSuccess: async () => {
			await queryClient.invalidateQueries(keysResolver(endpoints.cart.my.item));
			await queryClient.invalidateQueries(keysResolver(endpoints.cart.my.availablePromotions));

		},
	});
}

type UseRemoveCouponFromCartMutationProps = UseMutationOptions<
    AxiosResponse<OrderModel>,
    AxiosError<HydraError>,
    RemoveCouponFromCartMutationRequest
>

export type RemoveCouponFromCartMutationRequest = {
    coupons: string[];
}