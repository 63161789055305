/* eslint-disable */
// @ts-nocheck
import type * as Types from '@mgp-frontend/shared/cms-graphql/domain/types';

import { gql } from '@apollo/client';
export type SinglePriceCardFragment = { __typename?: 'ComponentContentBlocksPriceCard', title?: string | null, price?: string | null, pack?: string | null, saving?: string | null, description?: string | null };

export const SinglePriceCardFragmentDoc = gql`
    fragment SinglePriceCard on ComponentContentBlocksPriceCard {
  title
  price
  pack
  saving
  description
}
    `;