import {PaymentMethod} from '@stripe/stripe-js/types/api/payment-methods';
import {Card} from '@mgp-fe/shared/ui/card.tsx';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import Skeleton from '@mgp-fe/shared/ui/skeleton.tsx';
import React from 'react';

export default function SmallPaymentMethodCard({paymentMethod, onClick, isSelected}: {
	paymentMethod: PaymentMethod,
	onClick: () => void,
	isSelected: boolean
}) {
	return <Card
		variant={isSelected ? 'secondary' : 'default'}
		spacing='sm'
		onClick={onClick}
		className='flex flex-wrap gap-medium items-center hover:bg-secondary-500/20 cursor-pointer'>
		<FontAwesomeIcon
			icon={isSelected ? 'circle-check' : 'circle'}
			className={isSelected ? 'text-primary' : 'text-secondary opacity-20'}
			size='lg'/>
		<span className='text-muted/80 uppercase'>
			{paymentMethod.card?.brand || 'Unkown brand'}
		</span>
		<span className='grow'/>
		<span className='text-secondary'>
						**** {paymentMethod.card?.last4}
		</span>
		<span className='text-muted/80 text-sm'>
			Expires <span
				className='text-secondary'>{paymentMethod.card?.exp_month}/{paymentMethod.card?.exp_year}</span>
		</span>
	</Card>;
}

export function SmallPaymentMethodCardSkeleton() {
	return <Card spacing='sm' className='flex gap-medium items-center'>

		<Skeleton className='w-6 h-6 rounded-full'/>
		<Skeleton className='h-4 w-1/4'/>
		<span className='grow'/>
		<Skeleton className='h-4 w-1/6'/>
		<Skeleton className='h-4 w-1/4'/>
	</Card>;
}