'use client';

import {PartnerLocationPublicCollectionModel} from '@mgp-fe/shared/core-api/domain/partner/location.ts';
import {Card, CardContent, CardFooter, CardTitle} from '@mgp-fe/shared/ui/card.tsx';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React from 'react';
import useAuth from '@mgp-fe/shared/modules/auth/hooks/useAuth.ts';
import useToggle from '@mgp-fe/shared/hooks/useToggle.ts';
import ScanRequestDialog from '@mgp-fe/shared/modules/scans/components/ScanRequestDialog.tsx';
import {Button} from '@mgp-fe/shared/ui/button.tsx';
import Skeleton from '@mgp-fe/shared/ui/skeleton.tsx';

export default function PartnerLocationCard({location}: PartnerLocationCardProps) {
	const {user} = useAuth();
	const scanRequestDialog = useToggle();

	return <Card variant='secondary' spacing='sm' className='flex flex-col'>
		<CardContent className='flex flex-col h-full relative'>
			<CardTitle className='mb-2 md:mb-4 lg:mb-6'>
				{location.name}
			</CardTitle>
			<div className='flex flex-wrap w-1/2'>
				<FontAwesomeIcon icon='map-marker-alt' className='shrink text-primary w-[20px]'/>
				<address className='w-[calc(100%-20px)] pl-4'>
					{location.address.lineOne}<br/>
					{location.address.lineTwo ?
						<span className='opacity-90'>{location.address.lineTwo}<br/></span> : ''}
					{location.address.city} {location.address.postCode} <br/>
					{location.address.region ? <>{location.address.region}<br/></> : ''}
				</address>

				<FontAwesomeIcon icon='envelope' className='shrink text-primary w-[20px]'/>
				<span className='w-[calc(100%-20px)] pl-4'>Email: {location.email} </span>

				<FontAwesomeIcon icon='mobile' className='shrink text-primary w-[20px]'/>
				<span className='w-[calc(100%-20px)] pl-4'>Phone: {location.phone} </span>
			</div>
			{location.logoUrl && <img
				src={location.logoUrl}
				className='lg:absolute top-0 bottom-0 left-auto right-0 w-1/2 max-h-28 m-auto object-contain'
				alt={`Logo ${location.name}`}/>}
		</CardContent>
		<CardFooter className='p-0 pl-6'>
			{user?.['@type'] === 'Customer' ? <Button onClick={() => scanRequestDialog.on()} variant='default' size='md'>
				Book scan
			</Button> : ''}
			{scanRequestDialog.state ? <ScanRequestDialog partnerLocation={location} onClose={scanRequestDialog.off} /> : ''}
		</CardFooter>
	</Card>;
}


export function PartnerLocationCardSkeleton() {
	return <div className='flex flex-col gap-small w-full h-[200px] justify-between'>
		<div className='flex justify-between items-center'>
			<Skeleton className='w-40 h-11'/>
			<Skeleton className='w-12 h-5'/>
		</div>

		<div className='flex flex-col gap-4'>
			<Skeleton className='w-full h-7'/>
			<Skeleton className='w-3/4 h-7'/>
			<Skeleton className='w-1/2 h-7'/>
		</div>
	</div>;
}

interface PartnerLocationCardProps {
	location: PartnerLocationPublicCollectionModel;
}