'use client';

import {ArchivableListParams, CollectionParams, HydraListResponse, Iri} from '@mgp-fe/shared/core-api/domain/base.ts';
import {useQuery, UseQueryOptions} from '@tanstack/react-query';
import {AxiosResponse} from 'axios';
import keysResolver from '@mgp-fe/shared/core-api/keysResolver.ts';
import endpoints from '@mgp-fe/shared/core-api/endpoints.ts';
import coreApiClient from '@mgp-fe/shared/core-api/client.ts';
import {ScanRequestCollectionModel, ScanRequestState} from '@mgp-fe/shared/core-api/domain/scan-request.ts';

export default function useScanRequestsListQuery(props?: ScanRequestsListQueryProps) {
	return useQuery<AxiosResponse<HydraListResponse<ScanRequestCollectionModel>>>({
		queryKey: keysResolver(endpoints.scanRequests.list, 'get', props?.params),
		queryFn: () => coreApiClient.get(endpoints.scanRequests.list, {params: props?.params}),
		...props?.options,
	});
}

interface ScanRequestsListQueryProps {
	params?: CollectionParams & ArchivableListParams & {
		partnerLocation?: Iri | Iri[];
		state?: ScanRequestState | ScanRequestState[];
		itemsPerPage?: number;
		page?: number;
		pagination?: boolean;
	};
	options?: UseQueryOptions<AxiosResponse<HydraListResponse<ScanRequestCollectionModel>>>;
}