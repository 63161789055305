import React, {useEffect, useState} from 'react';
import {Link, Outlet, useLocation, useNavigate} from 'react-router-dom';
import {Button} from '@mgp-fe/shared/ui/button.tsx';
import {routes} from '@mgp-fe/shared/modules/account';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {defaultTheme, NavigationItem, SideBarNavigation} from '@mgp-fe/shared/ui';
import useAuth from '@mgp-fe/shared/modules/auth/hooks/useAuth.ts';
import {cn} from '@mgp-fe/shared/utils';

export default function AccountIndexPage() {
	const location = useLocation();
	const navigate = useNavigate();
	const {logout} = useAuth();

	const isSubRoute = location.pathname !== routes.index;

	const [isLargeScreen, setIsLargeScreen] = useState(false);
	useEffect(() => {
		const handleResize = () => setIsLargeScreen((window?.innerWidth || 0) > parseInt(defaultTheme.screens.lg.replace('px', '')));
		handleResize();
		window.addEventListener('resize', handleResize);

		return () => window.removeEventListener('resize', handleResize);
	}, []);

	useEffect(() => {
		if (!isSubRoute && isLargeScreen) {
			navigate(routes.profile.index);
		}
	}, [isSubRoute, isLargeScreen, navigate]);

	const navigation: NavigationItem[] = [
		{
			icon: <FontAwesomeIcon icon='user-cog'/>,
			url: routes.profile.index,
			label: 'My Profile',
			id: 'profile',
		},
		{
			icon: <FontAwesomeIcon icon='address-book'/>,
			url: routes.addressBook.index,
			label: 'Address Book',
			id: 'addressBook',
		},
		{
			icon: <FontAwesomeIcon icon='credit-card'/>,
			url: routes.paymentMethods.index,
			label: 'Payment Methods',
			id: 'paymentMethods',
		},
		{
			icon: <FontAwesomeIcon icon='shield-halved'/>,
			url: routes.privacySettings.index,
			label: 'Privacy Settings',
			id: 'privacySettings',
		},
		{
			url: '#',
			label: 'Logout',
			id: 'logout',
		},
	];
	return <main className='container flex flex-wrap w-full'>
		{(isLargeScreen || !isSubRoute) ?
			<div className='flex flex-col gap-small w-full shrink-0 lg:w-3/12 my-small lg:my-0'>
				<SideBarNavigation
					currentPath={location.pathname}
					navigationItems={navigation}
					renderItem={(item, className) => {
						if (item.label === 'Logout') return <a
							onClick={async (e) => {
								e.preventDefault();
								await logout();
							}}
							className={cn(className, 'w-fit text-destructive-400 !pl-0 !pt-0 cursor-pointer')}>
							<FontAwesomeIcon icon='right-from-bracket'/>
                            Sign Out
						</a>;


						return <Link to={item.url || ''} key={item.url} className={className}>
							{item.icon}
							{item.label}
						</Link>;
					}}/>
			</div> : ''}

		<div className='w-full shrink-0 lg:w-9/12 lg:pl-medium mt-0'>
			{(isSubRoute && !isLargeScreen) ?
				<Button variant='outline' size='lg' asChild className='my-small text-muted w-full'>
					<Link to={routes.index}>
						<FontAwesomeIcon icon='chevron-left'/>
						<span className='ml-small grow'>Back to Account</span>
					</Link>
				</Button> : ''}

			<Outlet/>
		</div>
	</main>;
}