import React from 'react';
import {OrderModel} from '@mgp-fe/shared/core-api/domain/order.ts';
import {Alert} from '@mgp-fe/shared/ui/alert.tsx';
import {humanizeEnumValue} from '@mgp-fe/shared/utils/humanize-string.ts';
import {Badge} from '@mgp-fe/shared/ui/badge.tsx';

export default function OrderShippingSection({order}: OrderShippingSectionProps) {
	return <>
		<section>
			<h4 className='mt-small'>Delivery method</h4>
			{order.shippingMethod
				? <p>
					{order.shippingMethod.name} ({order.shippingMethod.shippingTime} days)
				</p>
				: <Alert variant='destructive'>Shipping method is not specified.</Alert>}
		</section>

		<section>
			<h4 className='mt-mini'>Shipment{(order.shipments.length || 0) > 1 && 's'}</h4>
			{order.shipments.map(shipment => <ul key={shipment.id}>
				<li className='mb-2 flex flex-wrap gap-mini justify-between items-center'>
					<span>
						{shipment.shippingCarrier ? humanizeEnumValue(shipment.shippingCarrier) : 'Unknown carrier'}
					</span>
					<span>{shipment.shippingTrackingNumber || 'Tracking number N/A'}</span>
					<Badge variant='outline'>{humanizeEnumValue(shipment.state)}</Badge>
				</li>
			</ul>)}
			{(order.shipments.length || 0) === 0 ?
				<p className='mt-2 text-warning'>Order has not been shipped yet.</p> : ''}
		</section>
	</>;
}

interface OrderShippingSectionProps {
	order: OrderModel;
}