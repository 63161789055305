import React from 'react';
import {PartnerLocationPublicCollectionModel} from '@mgp-fe/shared/core-api/domain/partner/location.ts';
import Modal from '@mgp-fe/shared/ui/Modal.tsx';
import {FormProvider, useForm} from 'react-hook-form';
import DateInput from '@mgp-fe/shared/ui/form/DateInput.tsx';
import useCreateScanRequestMutation, {
	CreateScanRequestMutationData,
} from '@mgp-fe/shared/core-api/mutations/scan-request/create.ts';
import {Button} from '@mgp-fe/shared/ui/button.tsx';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Dialog} from '@headlessui/react';

export default function ScanRequestDialog({partnerLocation, onClose}: ScanRequestDialogProps) {
	const form = useForm<CreateScanRequestMutationData>({
		defaultValues: {
			partnerLocation: partnerLocation['@id'],
		},
	});
	const createMutation = useCreateScanRequestMutation({
		onSuccess: () => onClose(),
	});

	return <Modal onClose={onClose} isOpen={true} title={'Book your scan'}>
		<Dialog.Description>
			After you request for scan, you will be contacted by partner location to arrange appointment.
		</Dialog.Description>
		<FormProvider {...form}>
			<form
				className='horizontal-labels flex flex-col gap-6 mt-4'
				onSubmit={form.handleSubmit(d => createMutation.mutate(d))}>
				<label>
					Partner location
					<span className='text-light'>{partnerLocation.name}</span>
					<input type='hidden' {...form.register('partnerLocation')} />
				</label>

				<DateInput label='Date' name='requestedForDate'/>

				<Button
					state={createMutation.status}
					icon={<FontAwesomeIcon icon='calendar-check' className='text-base'/>}>
					Register
				</Button>
			</form>
		</FormProvider>
	</Modal>;
}

interface ScanRequestDialogProps {
	partnerLocation: PartnerLocationPublicCollectionModel;
	onClose: () => void;
}