import React from 'react';
import {OrderModel} from '@mgp-fe/shared/core-api/domain/order.ts';
import {Badge} from '@mgp-fe/shared/ui/badge.tsx';
import {OrderStateBadgeVariantMap} from '@mgp-fe/shared/modules/orders/ui/constants.ts';
import {humanizeEnumValue} from '@mgp-fe/shared/utils/humanize-string.ts';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import DateFormatted from '@mgp-fe/shared/modules/formatters/DateFormatted.tsx';
import {Card, CardContent, CardHeader, CardTitle} from '@mgp-fe/shared/ui/card.tsx';
import {Alert} from '@mgp-fe/shared/ui/alert.tsx';
import AddressFormatted from '@mgp-fe/shared/modules/formatters/AddressFormatted.tsx';
import PaymentDetailCard from './PaymentDetailCard.tsx';
import OrderItemsTable from './OrderItemsTable.tsx';
import OrderAmountSummaryCard from './OrderAmountSummaryCard.tsx';
import OrderCustomerSection from './OrderCustomerSection.tsx';
import OrderShippingSection from './OrderShippingSection.tsx';
import Skeleton from '@mgp-fe/shared/ui/skeleton.tsx';
import ScanCard from '@mgp-fe/shared/modules/scans/components/ScanCard.tsx';
import {uniqBy} from 'lodash';
import AppliedCoupons from '@mgp-fe/shared/modules/promotions/AppliedCoupons.tsx';

export default function OrderDetail({order}: OrderDetailProps) {
	const scans = uniqBy(
		order.orderItems.filter(oi => oi.mouthguard?.scan !== undefined),
		'scan.id',
	).map(oi =>  <ScanCard key={oi.id} scan={oi.mouthguard!.scan!}/>);

	return <main className='flex flex-col gap-medium heading-text-secondary'>
		<section className='flex flex-col items-center justify-between gap-mini md:flex-row'>
			<h2 className='flex flex-wrap w-full items-center gap-mini !text-muted'>
				#{order.internalId || order.id}
				<Badge variant={OrderStateBadgeVariantMap[order.state]} className='ml-2 shrink-0'>
					{humanizeEnumValue(order.state)}
				</Badge>
			</h2>

			<ul className='flex w-full items-center justify-between'>
				<li>
					<h5><FontAwesomeIcon icon='calendar-check' size='sm'/> Ordered at</h5>
					<p className='text-muted'><DateFormatted date={order.orderedAt}/></p>
				</li>
				<li>
					<h5>Last update</h5>
					<p>{order.updatedAt ? <DateFormatted date={order.updatedAt}/>
						: <span className='text-warning'>any updates yet</span>}
					</p>
				</li>
			</ul>
		</section>

		<section className='grid gap-small md:grid-cols-2'>
			<Card variant='ghost' className='flex flex-col gap-small'>
				<OrderCustomerSection order={order}/>
				<OrderShippingSection order={order}/>
			</Card>

			<PaymentDetailCard payment={order.payment} paymentMethodType={order.paymentMethodType}/>
		</section>

		<section className='grid gap-small md:grid-cols-2'>
			<Card>
				<CardHeader>
					<CardTitle>Shipping address</CardTitle>
				</CardHeader>
				<CardContent>
					{order.shippingAddress
						? <AddressFormatted address={order.shippingAddress}/>
						: <Alert variant='destructive'>
							Shipping address is not specified.
						</Alert>}
				</CardContent>
			</Card>
			<Card>
				<CardHeader>
					<CardTitle>Billing address</CardTitle>
				</CardHeader>
				<CardContent>
					{order.billingAddress
						? <AddressFormatted address={order.billingAddress}/>
						: <Alert variant='destructive'>
							Billing address is not specified.
						</Alert>}
				</CardContent>
			</Card>
		</section>

		<section>
			<h3 className='mb-small'>Order items</h3>
			<OrderItemsTable orderItems={order.orderItems}/>
		</section>

		{order.coupons.length ?
			<section>
				<h3 className='mb-small'>Promo-codes</h3>
				<AppliedCoupons coupons={order.coupons} controls={false}/>
			</section> : ''}

		<section className='grid grid-cols-2 gap-small'>
			<div className='flex w-full flex-col gap-small'>
				{scans.length > 0 ? <h4>Scan</h4> : ''}
				{scans}
			</div>
			<div className='flex w-full flex-col gap-small'>
				<h4 className='ml-auto'>Total</h4>
				<OrderAmountSummaryCard order={order} className='ml-auto w-full md:w-auto'/>
			</div>
		</section>
	</main>;
}

export function OrderDetailSkeleton() {
	return <main className='flex flex-col gap-medium heading-text-secondary'>
		<section className='flex flex-col items-center justify-between gap-mini md:flex-row'>
			<h2 className='flex flex-wrap w-full items-center gap-mini !text-muted'>
				<Skeleton className='h-6 w-20'/> {/* Placeholder for order ID */}
				<Skeleton className='ml-2 h-6 w-30'/> {/* Placeholder for order state badge */}
			</h2>

			<ul className='flex w-full items-center justify-between'>
				<li>
					<h5><FontAwesomeIcon icon='calendar-check' size='sm'/> Ordered at</h5>
					<Skeleton className='h-6 w-40'/> {/* Placeholder for ordered at date */}
				</li>
				<li>
					<h5>Last update</h5>
					<Skeleton className='h-6 w-40'/> {/* Placeholder for last update */}
				</li>
			</ul>
		</section>

		<section className='grid gap-small md:grid-cols-2'>
			<Skeleton className='h-48 w-full'/> {/* Placeholder for customer & shipping details */}
			<Skeleton className='h-48 w-full'/> {/* Placeholder for payment details */}
		</section>

		<section className='grid gap-small md:grid-cols-2'>
			<Card>
				<CardHeader>
					<CardTitle>Shipping address</CardTitle>
				</CardHeader>
				<CardContent>
					<Skeleton className='h-48 w-full'/> {/* Placeholder for shipping address */}
				</CardContent>
			</Card>
			<Card>
				<CardHeader>
					<CardTitle>Billing address</CardTitle>
				</CardHeader>
				<CardContent>
					<Skeleton className='h-48 w-full'/> {/* Placeholder for billing address */}
				</CardContent>
			</Card>
		</section>

		<section>
			<h3 className='mb-small'>Order items</h3>
			<Skeleton className='h-96 w-full'/> {/* Placeholder for order items table */}
		</section>

		<Skeleton className='h-48 w-full'/> {/* Placeholder for order amount summary */}
	</main>;
}

interface OrderDetailProps {
	order: OrderModel;
}