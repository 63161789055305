import React, {ReactNode} from 'react';
import {OrderCollectionModel} from '@mgp-fe/shared/core-api/domain/order.ts';
import {Badge} from '@mgp-fe/shared/ui/badge.tsx';
import {humanizeEnumValue} from '@mgp-fe/shared/utils/humanize-string.ts';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import DateFormatted from '@mgp-fe/shared/modules/formatters/DateFormatted.tsx';
import {OrderStateBadgeVariantMap} from '@mgp-fe/shared/modules/orders/ui/constants.ts';
import {cn} from '@mgp-fe/shared/utils';
import MoneyFormatted from '@mgp-fe/shared/modules/formatters/MoneyFormatted.tsx';
import {Card} from '@mgp-fe/shared/ui/card.tsx';
import Skeleton from '@mgp-fe/shared/ui/skeleton.tsx';
import {random} from 'lodash';
import useNotify from '@mgp-fe/shared/ui/notifications/use-notify.ts';

export default function OrdersCardsList({orders, activeOrderId, className, navLinkComponent, showItems = false}: OrdersListProps) {
	const {notifyInfo} = useNotify();
	return <div className={cn('flex flex-col gap-small', className)}>
		{orders?.map(o => <Card
			key={o.id}
			variant={activeOrderId === o.id ? 'secondary' : 'default'}
			spacing='sm'
			className='hover:bg-secondary-500/20 w-full flex flex-col gap-4'>
			<h4 className={cn('flex flex-wrap items-center justify-between gap-3',
				showItems ? 'h3' : '')}>
				<span className='flex-1 truncate cursor-pointer' onClick={() => {
					navigator.clipboard.writeText(o.internalId || o.id);
					notifyInfo('Order ID copied to clipboard');
				}}>
					{o.internalId || o.id}
				</span>

				<Badge variant={OrderStateBadgeVariantMap[o.state]} className='shrink-0'>
					{humanizeEnumValue(o.state)}
				</Badge>
			</h4>

			{showItems ? <ul className='flex flex-col gap-mini grow'>
				{o.orderItems.map(i => <li key={i.id}>
					{i.quantity}x {i.product.name}
				</li>,
				)}
			</ul> : null}

			<ul className={cn(
				'flex flex-wrap gap-y-mini items-end justify-between',
				showItems ? '[&>li]:text-muted/90 [&>li]:text-lg [&>li]:font-medium' : '')}>
				<li className='w-1/2 md:w-auto shrink-0  pr-mini'>
					<FontAwesomeIcon icon='calendar-alt' className='mr-2'/>
					<DateFormatted date={o.orderedAt} showDateOnly/>
				</li>
				<li className='w-1/2 md:w-auto shrink-0 text-right xl:text-left'>
					<FontAwesomeIcon icon='receipt' className='mr-2'/>
					<MoneyFormatted money={o.totalAmount}/>
				</li>
				<li>
					{navLinkComponent && navLinkComponent({
						order: o,
						children: <>
							Detail
							<FontAwesomeIcon icon='chevron-right' className='ml-2'/>
						</>,
					})}
				</li>
			</ul>
		</Card>)}
	</div>;
}

export function OrdersListSkeleton({className, itemsCount, showItems}: OrdersListSkeletonProps) {
	return <div className={cn('flex flex-col gap-small', className)}>
		{[...Array(itemsCount || 5)].map((_, i) => <Card
			key={i}
			spacing='sm'
			className={cn('flex flex-col gap-mini w-full justify-between', showItems ? '' : 'h-[118px]')}>
			<h4 className='flex justify-between items-center'>
				<Skeleton className='w-40 h-11'/>
				<Skeleton className='w-12 h-5'/>
			</h4>

			{showItems ? <ul className='flex flex-col gap-mini'>
				{[...Array(random(1, 3))].map((_, l) => <li key={l}>
					<Skeleton className='w-24 h-4'/>
				</li>)}
			</ul> : null}

			<div className='grow'/>
			<div className='flex justify-between'>
				<Skeleton className='w-24 h-7'/>
				<Skeleton className='w-20 h-7'/>
				<Skeleton className='w-20 h-7'/>
			</div>
		</Card>)}
	</div>;
}

interface OrdersListProps {
	orders?: OrderCollectionModel[];
	activeOrderId?: string;
	className?: string;
	navLinkComponent?: (props: NavLinkComponentProps) => ReactNode;
	showItems?: boolean;
}

interface OrdersListSkeletonProps {
	className?: string;
	itemsCount?: number;
	showItems?: boolean;
}

interface NavLinkComponentProps {
	order: OrderCollectionModel;
	children: ReactNode;

	[key: string]: unknown;  // This allows for additional props to be passed.
}