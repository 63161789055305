import React from 'react';
import useAuth from '@mgp-fe/shared/modules/auth/hooks/useAuth.ts';
import MemberZoneLayout from '@mgp-fe/customer/modules/common/ui/MemberZoneLayout.tsx';
import TeamsSection from './TeamsSection';
import InvitationsSection from './InvitationsSection';
import OrdersSection from '@mgp-fe/customer/modules/dashboard/components/OrdersSection.tsx';
import VouchersSection from '@mgp-fe/customer/modules/dashboard/components/VouchersSection.tsx';
import {Customer} from '@mgp-fe/shared/core-api/domain/user.ts';


export default function DashboardIndexPage() {
	const {user} = useAuth<Customer>();

	return <MemberZoneLayout>
		<main className='container'>
			<h2 className='mb-medium'><span className='text-muted'>Welcome,</span> {user?.name || 'member'}</h2>

			<div className='grid md:grid-cols-2 gap-medium'>
				<OrdersSection/>
				<VouchersSection/>
				<TeamsSection/>
				<InvitationsSection/>
			</div>
		</main>
	</MemberZoneLayout>;
}