import React from 'react';
import {
	AlertDialog,
	AlertDialogAction,
	AlertDialogCancel,
	AlertDialogContent,
	AlertDialogDescription,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogTitle,
	AlertDialogTrigger,
} from '@mgp-fe/shared/ui/alert-dialog.tsx';

export default function AlertConfirmDialog({
	title = 'Are you sure?',
	description = 'This action cannot be undone.',
	actionMessage = 'Confirm',
	onConfirm,
	triggerButton,
}: AlertConfirmDialogProps) {

	return<AlertDialog>
		<AlertDialogTrigger asChild>
			{triggerButton}
		</AlertDialogTrigger>
		<AlertDialogContent>
			<AlertDialogHeader>
				<AlertDialogTitle>{title}</AlertDialogTitle>
				{actionMessage !== null ? <AlertDialogDescription>
					{description}
				</AlertDialogDescription> : ''}
			</AlertDialogHeader>
			<AlertDialogFooter>
				<AlertDialogCancel>Cancel</AlertDialogCancel>
				<div className='grow'></div>
				<AlertDialogAction onClick={onConfirm}>
					{actionMessage}
				</AlertDialogAction>
			</AlertDialogFooter>
		</AlertDialogContent>
	</AlertDialog>;
}

interface AlertConfirmDialogProps {
	title?: string;
	description?: string | null;
	actionMessage?: string;
	onConfirm: () => void;
	triggerButton: React.ReactNode;
}