import React, {useContext} from 'react';
import useNotify from '@mgp-fe/shared/ui/notifications/use-notify.ts';
import {useSignUpLinkDetailQuery} from '@mgp-fe/shared/core-api/queries/signup-link/item.ts';
import useUtilizeSignUpLinkMutation from '@mgp-fe/shared/core-api/mutations/signup-link/create-customer.ts';
import useAuth from '@mgp-fe/shared/modules/auth/hooks/useAuth.ts';
import {Customer} from '@mgp-fe/shared/core-api/domain/user.ts';
import useLocalStorage from '@mgp-fe/shared/hooks/useLocalStorage.ts';
import {AUTH_LOCAL_STORAGE_KEY} from '@mgp-fe/shared/core-api/domain/base.ts';
import {useQueryClient} from '@tanstack/react-query';
import CustomerDesignCard from '@mgp-fe/shared/modules/mouthguard-design/components/CustomerDesignCard.tsx';
import AuthSection from '@mgp-fe/shared/modules/onboarding/components/welcome/AuthSection.tsx';
import {Button} from '@mgp-fe/shared/ui/button.tsx';
import {Alert, AlertDescription, AlertTitle} from '@mgp-fe/shared/ui/alert.tsx';
import Skeleton from '@mgp-fe/shared/ui/skeleton.tsx';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {OnboardingContext} from '@mgp-fe/shared/modules/onboarding';
import getClientEnv from '@mgp-fe/shared/utils/env-var-resolver.ts';

export default function SignUpLinkMode() {
	const onboardingContext = useContext(OnboardingContext);
	const queryClient = useQueryClient();
	const {notifySuccess, notifyError} = useNotify();
	const {user} = useAuth<Customer>();
	const [, setToken] = useLocalStorage<string | null>(AUTH_LOCAL_STORAGE_KEY, null);

	const signUpLinkQuery = useSignUpLinkDetailQuery({
		id: onboardingContext.mode.signUpLink || '',
		options: {enabled: !!onboardingContext.mode.signUpLink},
	});

	const utilizeSignUpLinkMutation = useUtilizeSignUpLinkMutation({
		onSuccess: async (rs) => {
			notifySuccess({message: user ? 'Invitation accepted.' : 'Account created.'});
			const createdUser = rs.data;
			createdUser.signToken && setToken(createdUser.signToken);
			await queryClient.refetchQueries({predicate: p => p.isActive()});
			//navigate('/onboarding/design', {replace: true});
			onboardingContext.setMode({});
		},
		onError: (e) => {
			notifyError({message: e.response?.data?.['hydra:description'] || 'Error'});
		},
	});

	const signUpLink = signUpLinkQuery.data?.data;
	const gardDesigns = signUpLinkQuery.data?.data.team?.designs || [];

	const UtilizeSection = () => <section className='w-full flex flex-col md:flex-row'>
		<span
			className='h-0.5 md:h-96 w-full md:w-0.5 bg-gradient-to-r
				md:bg-gradient-to-b from-transparent via-primary to-transparent
				md:mr-medium my-medium md:my-auto'/>

		{(user && signUpLink) ? <div className='flex flex-col gap-small items-center w-full justify-center'>
			<Button
				onClick={() => utilizeSignUpLinkMutation.mutate({id: signUpLink.id, request: {}})}
				state={utilizeSignUpLinkMutation.status}>
				Accept
			</Button>
			<Button asChild variant='linkWarning'>
				{/*TODO: Fix routing*/}
				<a href={getClientEnv('customerAppUrl')}>
					<FontAwesomeIcon icon='right-to-bracket' className='mr-2' />
					Go to Customer area
				</a>
			</Button>
		</div>
			: <AuthSection
				registerHandler={d => utilizeSignUpLinkMutation.mutateAsync({
					id: signUpLink?.id || '',
					request: d,
				})}
				registerStatus={signUpLink ? utilizeSignUpLinkMutation.status : 'loading'}/>}
	</section>;

	if (signUpLinkQuery.isFetching) {
		return <div className='grid md:grid-cols-2'>
			<section className='mx-auto text-center md:pr-medium my-auto'>
				<h5 className='text-muted/90'>
					Welcome, <br/>You can use this link to
				</h5>

				<Skeleton className='w-full h-10 mx-auto mt-medium'/>
			</section>
			<UtilizeSection/>
		</div>;
	}

	if (signUpLinkQuery.isError || !signUpLink) {
		return <Alert>
			<AlertTitle>{signUpLinkQuery.error?.response?.data['hydra:title'] || 'Error'}</AlertTitle>
			<AlertDescription>
				{signUpLinkQuery.error?.response?.data['hydra:description'] || 'Unknown error.'}
			</AlertDescription>
		</Alert>;
	}

	return <div className='grid md:grid-cols-2 items-center'>
		<section className='mx-auto text-center md:pr-medium my-auto'>
			<h5 className='text-secondary'>
				Welcome, <br/> <span className='text-muted/90'>You can use this link to</span>
			</h5>

			{signUpLink.team?.name && <> <br/>
				join team <br/><h4>{signUpLink.team?.name}</h4></>}

			<div className='grid md:grid-cols-2 gap-mini md:gap-small lg:gap-medium mt-medium'>
				{gardDesigns.map(d => <CustomerDesignCard
					design={d}
					key={d.id}
					cartControls={false}
					onEdit={undefined}/>)}
			</div>

			{signUpLink.partnerLocation?.name && <p className='mt-6'>
				set up your location<br/><h4>{signUpLink.partnerLocation?.name}</h4>
			</p>}

			{signUpLink.partnerLocation?.logoUrl && <div className='hover:bg-light/60'><img
				src={signUpLink.partnerLocation.logoUrl}
				alt='Partner logo'
				className='object-contain object-center mx-auto h-fit w-4/5 md:w-3/4 rounded-2xl'
				draggable={false}/></div>}

			{signUpLink.description && <p
				className={'italic text-gray-400 font-light mt-small'}>{signUpLink.description}</p>}
		</section>
		<UtilizeSection/>
	</div>;
}
