import {useMutation, UseMutationOptions} from '@tanstack/react-query';
import {AxiosError, AxiosResponse} from 'axios';
import {HydraError} from '@mgp-fe/shared/core-api/domain/base.ts';
import keysResolver from '@mgp-fe/shared/core-api/keysResolver.ts';
import endpoints from '@mgp-fe/shared/core-api/endpoints.ts';
import coreApiClient from '@mgp-fe/shared/core-api/client.ts';
import {OrderModel} from '@mgp-fe/shared/core-api/domain/order.ts';


export default function useRedeemCouponMutation(props?: UseRedeemCouponMutationProps){

	return useMutation({
		mutationKey: keysResolver(endpoints.cart.my.applyCoupon, 'patch'),
		mutationFn: data => coreApiClient.patch(endpoints.cart.my.applyCoupon, {
			code: data.code,
		}),
		...props,
	});
}

type UseRedeemCouponMutationProps = UseMutationOptions<
    AxiosResponse<OrderModel>,
    AxiosError<HydraError>,
	RedeemCouponMutationRequest
>

export type RedeemCouponMutationRequest = {
    code: string;
}