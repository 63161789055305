'use client';

import React, {useState} from 'react';
import {Button} from '@mgp-fe/shared/ui/button.tsx';
import Modal from '@mgp-fe/shared/ui/Modal.tsx';
import useNewsletterSignUpMutation, {
	NewsletterSignUpMutationData,
} from '@mgp-fe/shared/core-api/mutations/newsletter/signup.ts';
import {FormProvider, useForm} from 'react-hook-form';
import {TextInput} from '@mgp-fe/shared/ui/form/TextInput.tsx';

const NewsletterSignUp = () => {

	const form = useForm<NewsletterSignUpMutationData>();

	const newsletterSignUp = useNewsletterSignUpMutation({});

	const [isOpen, setIsOpen] = useState(false);

	const onSubmit = (data: NewsletterSignUpMutationData) => {
		newsletterSignUp.mutate(data);
		setIsOpen(false);
	};

	return <>
		<Button size='lg' className='uppercase mt-8' onClick={() => setIsOpen(true)}>
        Sign up
		</Button>

		<Modal isOpen={isOpen} onClose={() => setIsOpen(false)} title={'Sign up to our newsletter'}>
			<FormProvider {...form}>
				<form className='grid' onSubmit={form.handleSubmit(onSubmit)}>
					<TextInput name='email' label='E-mail' type='email' required/>
					<Button className='mx-auto mt-8'>Sign Up</Button>
				</form>
			</FormProvider>
		</Modal>
	</>;
};

export default NewsletterSignUp;
