import React from 'react';
import {OrderItemModel} from '@mgp-fe/shared/core-api/domain/order.ts';
import {Table, TableBody, TableCell, TableHead, TableHeader, TableRow} from '@mgp-fe/shared/ui/table.tsx';
import {cn} from '@mgp-fe/shared/utils';
import {MouthguardDesignModel} from '@mgp-fe/shared/core-api/domain/mouthguard.ts';
import MoneyFormatted from '@mgp-fe/shared/modules/formatters/MoneyFormatted.tsx';
import {Badge} from '@mgp-fe/shared/ui/badge';
import BottomRightIcon from '@mgp-fe/shared/assets/icons/botom-right-arrow.svg';
import {parsePackageItems} from '@mgp-fe/shared/utils/parse-package-items.ts';

export default function OrderItemsTable({orderItems}: OrderItemsTableProps) {
	const parsedItems = parsePackageItems(orderItems);
	return <Table className='table-auto'>
		<TableHeader>
			<TableRow>
				<TableHead className='whitespace-nowrap pr-small'>Product</TableHead>
				<TableHead className='whitespace-nowrap text-right'>Unit price</TableHead>
				<TableHead className='w-52 text-center'>Quantity</TableHead>
				<TableHead className='text-right'>Subtotal</TableHead>
			</TableRow>
		</TableHeader>

		<TableBody>
			{parsedItems.map(oi => <React.Fragment key={oi.id}>
				<TableRow key={oi.id}>
					<TableCell className='whitespace-nowrap flex flex-nowrap gap-small items-center'>
						<img
							src={(oi.mouthguard?.mouthguardDesign?.screenshotUrl || oi.product.titleImage || '') + `?v=${oi.mouthguard?.mouthguardDesign?.updatedAt}`}
							className={cn(
								'bg-transparent border-2 border-secondary-500/30 rounded-md w-20 h-20 object-contain object-center aspect-auto',
								oi.mouthguard?.mouthguardDesign?.screenshotUrl ? 'object-cover' : 'object-contain',
							)}
							alt={oi.product.name}
						/>
						<div className='whitespace-nowrap grow'>
							<h5 className='whitespace-nowrap'>{oi.product.name}</h5>
							{oi.selectedProductOptions?.color ? <p className='flex items-center gap-mini capitalize'><div
								className='w-4 h-4 rounded-full border border-primary/30'
								style={{
									backgroundColor: oi.selectedProductOptions.color,
								}}/> {oi.selectedProductOptions.label.replaceAll('_', ' ')}</p> : ''}
							{(oi.mouthguard?.mouthguardDesign as MouthguardDesignModel)?.canvasData?.data
								.find(cd => cd.cacheKey === 'playerName')?.text || oi.mouthguard?.mouthguardDesign?.name || ''}
							{oi.mouthguard?.mouthguardDesign?.parent ? <span className='text-foreground pl-1'>
									(team design <span
									className='text-foreground/10'>{oi.mouthguard.mouthguardDesign.parent.name}</span>)
							</span> : null}

							{oi.vouchers?.length ? <p className='mt-mini w-full flex flex-wrap items-center gap-mini'>
							Used vouchers: {oi.vouchers.map(v => <Badge
									key={v.id}
									variant='outline'
									className='text-foreground/10'>{v.code}</Badge>)}
							</p>: ''}
						</div>
					</TableCell>
					<TableCell className='text-right'><MoneyFormatted money={oi.product.price}/></TableCell>
					<TableCell className='text-center'>{oi.quantity}x</TableCell>
					<TableCell className='text-right'>
						<div className='grid'>
							{oi.discountedAmount.amount !== oi.originalAmount.amount ? <span className={cn('line-through text-sm text-muted/50')}>
								<MoneyFormatted money={oi.originalAmount}/>
							</span> : ''}
							<MoneyFormatted money={oi.discountedAmount}/>
						</div>
					</TableCell>
				</TableRow>

				{oi.pack && <>{oi.pack.map(p => <TableRow key={p.id}>
					<TableCell className='flex flex-nowrap items-center gap-small'>
						<BottomRightIcon className='fill-muted/50 w-7 h-7 mx-5 shrink-0'/>
						<img
							src={p.product.titleImage || ''}
							className={cn(
								'bg-transparent border-2 border-secondary-500/30 w-14 h-14 object-contain object-center aspect-auto rounded-lg',
							)}
							alt={oi.product.name}
						/>
						<div>{p.product.name}</div>
					</TableCell>
					<TableCell className='text-right'></TableCell>
					<TableCell>
						<p className='text-muted mx-auto text-center'>{p.quantity}x</p>
					</TableCell>
					<TableCell className='text-right text-light'></TableCell>
				</TableRow>)}
				</>}
			</React.Fragment>)}
		</TableBody>
	</Table>;
}

interface OrderItemsTableProps {
	orderItems: OrderItemModel[];
}