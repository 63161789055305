import {CollectionParams, HydraListResponse} from '@mgp-fe/shared/core-api/domain/base.ts';
import {useQuery, UseQueryOptions} from '@tanstack/react-query';
import {AxiosResponse} from 'axios';
import {TeamCollectionModel} from '@mgp-fe/shared/core-api/domain/team.ts';
import keysResolver from '@mgp-fe/shared/core-api/keysResolver.ts';
import endpoints from '@mgp-fe/shared/core-api/endpoints.ts';
import coreApiClient from '@mgp-fe/shared/core-api/client.ts';

export default function useTeamsListQuery(props?: TeamListQueryProps) {
	return useQuery<TeamQuery>({
		queryKey: keysResolver(endpoints.teams.list, 'get', props?.params),
		queryFn: () => coreApiClient.get(endpoints.teams.list, {
			params: props?.params,
		}),
		...props?.options,
	});
}

export interface TeamListQueryProps {
	params?: TeamListParams
	options?: UseQueryOptions<TeamQuery>;
}

export type TeamQuery = AxiosResponse<HydraListResponse<TeamCollectionModel>>;

export type TeamListParams = CollectionParams & {
	name?: string;
	search_team?: string;
}