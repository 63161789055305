import React from 'react';
import useVouchersListQuery from '@mgp-fe/shared/core-api/queries/voucher/list.ts';
import {Alert, AlertDescription, AlertTitle} from '@mgp-fe/shared/ui/alert.tsx';
import VoucherCard from '@mgp-fe/shared/modules/vouchers/components/VoucherCard.tsx';
import useCartMyQuery from '@mgp-fe/shared/core-api/queries/cart/my.tsx';

export default function AvailableVouchersAlert() {
	const cartQuery = useCartMyQuery();
	const vouchersQuery = useVouchersListQuery({
		params: {exists: {usedAt: false, orderItem: false}, 'product.product': cartQuery?.data?.data?.order?.orderItems?.map((item) => item.product.id)},
	});

	if(cartQuery?.data?.data?.order?.orderItems?.length === 0) return <></>;

	if ((vouchersQuery.data?.data['hydra:totalItems'] || 0) === 0) return <></>;

	return <Alert variant='warning' className='my-0'>
		<AlertTitle className='flex justify-between gap-small items-center flex-wrap pb-0'>
			Available vouchers
		</AlertTitle>
		<AlertDescription className='flex flex-col gap-mini'>
			You have available vouchers, which you can use for this order.
		</AlertDescription>
		<div className='grid lg:grid-cols-2 xl:grid-cols-3 mt-small'>
			{vouchersQuery.data?.data['hydra:member'].map(v => <VoucherCard voucher={v} key={v.id}/>)}
		</div>
	</Alert>;
}