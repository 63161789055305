'use client';

import {ArchivableListParams, CollectionParams, HydraListResponse} from '@mgp-fe/shared/core-api/domain/base.ts';
import {useQuery, UseQueryOptions} from '@tanstack/react-query';
import {AxiosResponse} from 'axios';
import {ScanCollectionModel, ScanProvider, ScanState} from '@mgp-fe/shared/core-api/domain/scan.ts';
import keysResolver from '@mgp-fe/shared/core-api/keysResolver.ts';
import endpoints from '@mgp-fe/shared/core-api/endpoints.ts';
import coreApiClient from '@mgp-fe/shared/core-api/client.ts';

export default function useScansListQuery(props?: ScanListQueryProps) {
	return useQuery<AxiosResponse<HydraListResponse<ScanCollectionModel>>>({
		queryKey: keysResolver(endpoints.scans.list, 'get', props?.params),
		queryFn: () => coreApiClient.get(endpoints.scans.list, {params: props?.params}),
		...props?.options,
	});
}

interface ScanListQueryProps {
	params?: CollectionParams & ArchivableListParams & {
		dateOfScan?: string;
		'provider-not'?: ScanProvider | ScanProvider[];
		state?: ScanState | ScanState[];
	};
	options?: UseQueryOptions<AxiosResponse<HydraListResponse<ScanCollectionModel>>>;
}