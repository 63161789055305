import {useMutation, UseMutationOptions, useQueryClient} from '@tanstack/react-query';
import keysResolver from '@mgp-fe/shared/core-api/keysResolver.ts';
import endpoints from '@mgp-fe/shared/core-api/endpoints.ts';
import coreApiClient from '@mgp-fe/shared/core-api/client.ts';
import {AxiosError, AxiosResponse} from 'axios';

export default function useRemoveCardMutation(props?: SetDefaultCardRequestMutationProps) {
	const queryClient = useQueryClient();

	return useMutation<AxiosResponse<RemoveCardResponse>, AxiosError<RemoveCardResponse>, string>({
		mutationKey: keysResolver(endpoints.stripe.removeCard('')),
		mutationFn: (cardId: string) => coreApiClient.put(endpoints.stripe.removeCard(cardId)),
		...props,
		onSuccess: async (result, variables, context) => {
			await queryClient.invalidateQueries(keysResolver(endpoints.stripe.myCards));
			await queryClient.invalidateQueries(keysResolver(endpoints.stripe.partnerCheckoutCards('')));
			props?.onSuccess?.(result, variables, context);
		},
	});
}

type SetDefaultCardRequestMutationProps = UseMutationOptions<
	AxiosResponse<RemoveCardResponse>,
	AxiosError<RemoveCardResponse>,
	string
>;

export interface RemoveCardResponse {
	success: boolean;
	error?: string;
}