import * as twColors from 'tailwindcss/colors';

export default {
	border: 'hsl(var(--border))',
	input: 'hsl(var(--input))',
	ring: 'hsl(var(--ring))',
	background: {
		DEFAULT: 'hsl(var(--background))',
		'500': '#09090a',
	},
	foreground: 'hsl(var(--foreground))',
	light: {
		DEFAULT: '#FAFAFA',
		foreground: 'hsla(var(--background), 0.65)',
	},
	primary: {
		DEFAULT: 'hsl(var(--primary))',
		foreground: '#213300',
		'50': '#fcffe4',
		'100': '#f6ffc5',
		'200': '#eaff92',
		'300': '#d9ff53',
		'400': '#c4fb20',
		'500': '#a3de00',
		'600': '#80b500',
		'700': '#618902',
		'800': '#4d6c08',
		'900': '#415b0c',
		'950': '#213300',
	},
	secondary: {
		DEFAULT: 'hsl(var(--secondary))',
		foreground: 'hsl(var(--secondary-foreground))',
		'50': '#f1faeb',
		'100': '#dff4d3',
		'200': '#c2eaac',
		'300': '#9adb7b',
		'400': '#77ca51',
		'500': '#4f9e2e',
		'600': '#418b25',
		'700': '#346b20',
		'800': '#2c551f',
		'900': '#28491e',
		'950': '#12270c',
	},
	destructive: {
		DEFAULT: 'hsl(var(--destructive))',
		foreground: 'hsl(var(--destructive-foreground))',
		'50': '#ffebee',
		'100': '#ffcdd2',
		'200': '#ef9a9a',
		'300': '#e57373',
		'400': '#ef5350',
		'500': '#f44336',
		'600': '#e53935',
		'700': '#d32f2f',
		'800': '#c62828',
		'900': '#b71c1c',
		'950': '#7f0000',
	},
	muted: {
		DEFAULT: 'hsl(var(--muted))',
		foreground: 'hsl(var(--muted-foreground))',
	},
	accent: {
		DEFAULT: 'hsl(var(--accent))',
		foreground: 'hsl(var(--accent-foreground))',
	},
	popover: {
		DEFAULT: 'hsl(var(--popover))',
		foreground: 'hsl(var(--popover-foreground))',
	},
	card: {
		DEFAULT: 'hsl(var(--card))',
		foreground: 'hsl(var(--card-foreground))',
	},
	warning: {
		DEFAULT: twColors.amber['800'],
		foreground: twColors.amber['100'],
		light: twColors.amber['500'],
	},
};