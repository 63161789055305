import React from 'react';
import useStripeMyCardsListQuery from '@mgp-fe/shared/core-api/queries/stripe/my-cards.ts';
import {Card} from '@mgp-fe/shared/ui/card.tsx';
import {Button} from '@mgp-fe/shared/ui/button.tsx';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import useToggle from '@mgp-fe/shared/hooks/useToggle.ts';
import Modal from '@mgp-fe/shared/ui/Modal.tsx';
import PaymentMethodCard, {
	PaymentMethodCardSkeleton,
} from '@mgp-fe/shared/modules/account/components/payment-methods/PaymentMethodCard.tsx';
import AddStripeCardForm from '@mgp-fe/shared/modules/account/components/payment-methods/AddStripeCardForm.tsx';

export default function PaymentMethodsPage() {
	const myCardsQuery = useStripeMyCardsListQuery({
		options: {keepPreviousData: true},
	});
	const displayAddCardModal = useToggle({initialState: false});

	return <div className='flex flex-col gap-medium mb-large'>
		<h2 className='mt-0 mb-mini w-full'>Saved credit cards</h2>

		<section className='grid md:grid-cols-2 gap-medium'>
			<Button className='rounded-lg' variant='ghost' onClick={displayAddCardModal.on} asChild>
				<Card variant='secondary' className='min-h-[230px] h1 relative !text-muted hover:!text-secondary cursor-pointer'>
					<FontAwesomeIcon icon='plus-circle' className='absolute mx-auto left-0 right-0 h-[50%] opacity-10'/>
					add card
				</Card>
			</Button>

			{displayAddCardModal.state ?
				<Modal onClose={displayAddCardModal.off} isOpen={displayAddCardModal.state} title='Add new card'>
					<AddStripeCardForm onSuccess={displayAddCardModal.off}/>
				</Modal> : ''}

			{myCardsQuery.isFetching
				? [...Array(myCardsQuery.data?.data.paymentMethods.length || 2)]
					.map((_, i) => <PaymentMethodCardSkeleton key={i}/>)
				: myCardsQuery.data?.data.paymentMethods.map(pm => <PaymentMethodCard
					key={pm.id}
					pm={pm}
					isDefault={myCardsQuery.data?.data.defaultPaymentMethod === pm.id}/>)}
		</section>
	</div>;
}
