import React from 'react';
import {RouterProvider} from 'react-router-dom';
import AuthContextProvider from '@mgp-fe/shared/modules/auth/state/AuthContextProvider.tsx';
import {Customer} from '@mgp-fe/shared/core-api/domain/user.ts';
import {QueryClient, QueryClientProvider} from '@tanstack/react-query';
import router from '@mgp-fe/customer/router/router.tsx';
import {ReactQueryDevtools} from '@tanstack/react-query-devtools';
import '@mgp-fe/shared/utils/font-awesome.ts';
import {loadStripe} from '@stripe/stripe-js';
import {Elements} from '@stripe/react-stripe-js';
import CartContextProvider from '@mgp-fe/shared/modules/shop/state/CartContextProvider';
import LocaleContextProvider from '@mgp-fe/shared/localization/LocaleContextProvider';
import useLocalStorageRead from '@mgp-fe/shared/hooks/useLocalStorageRead.ts';
import getClientEnv from '@mgp-fe/shared/utils/env-var-resolver.ts';
import {GoogleReCaptchaProvider} from 'react-google-recaptcha-v3';
import NotificationsProvider from '@mgp-fe/shared/ui/notifications/NotificationsProvider.tsx';
import {ApolloProvider} from '@apollo/client';
import cmsClient from '@mgp-fe/shared/cms-graphql/client-react.ts';
import {cn} from '@mgp-fe/shared/utils';
import {GoogleOAuthProvider} from '@react-oauth/google';

const stripePromise = loadStripe(getClientEnv('stripePublicKey'));

export default function App() {
	const locale = useLocalStorageRead<string>('locale');
	const googleOauthConfig = JSON.parse(getClientEnv('oauthGoogle')) as {
		CLIENT_ID: string,
	};

	return <GoogleOAuthProvider clientId={googleOauthConfig.CLIENT_ID}>
		<LocaleContextProvider locale={locale || 'en'}>
			<Elements stripe={stripePromise}>
				<ApolloProvider client={cmsClient}>
					<QueryClientProvider client={queryClient}>
						<GoogleReCaptchaProvider reCaptchaKey={getClientEnv('recaptchaKey')}>
							<NotificationsProvider>
								<AuthContextProvider<Customer> loginModalMode='login_only'>
									<CartContextProvider>
										<RouterProvider router={router}/>
									</CartContextProvider>
								</AuthContextProvider>
							</NotificationsProvider>
						</GoogleReCaptchaProvider>
						{import.meta.env.MODE !== 'production' ? <span className={cn(
							'absolute top-2 left-0 right-0 w-fit mx-auto z-40 bg-primary px-4 py-0 rounded text-primary-foreground font-semibold uppercase',
							import.meta.env.MODE === 'development' ? 'bg-primary' : 'bg-secondary',
						)}>
							{import.meta.env.MODE.replace('development', 'dev')}
						</span> : ''}
						{import.meta.env.DEV ? <>
							<ReactQueryDevtools initialIsOpen={false}/>
						</> : ''}
					</QueryClientProvider>
				</ApolloProvider>
			</Elements>
		</LocaleContextProvider>
	</GoogleOAuthProvider>;
}

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			refetchOnWindowFocus: false,
			staleTime: 5 * 1000,
			retry: 1,
		},
	},
});