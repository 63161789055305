import React from 'react';
import BackgroundTop from '@mgp-fe/shared/assets/background/background-hero.png';
import BackgroundBottom from '@mgp-fe/shared/assets/background/background-footer.png';
import {cn} from '@mgp-fe/shared/utils';

export default function AuthLayout(props: AuthLayoutProps) {
	return <div className={cn(
		'h-screen bg-smoke bg-cover bg-center flex flex-col items-center justify-center relative',
		props.className)}>
		<div className='overflow-hidden absolute -z-20 w-screen bg-cover h-screen bg-no-repeat bg-top' style={{
			backgroundImage: `url("${BackgroundTop}")`,
		}}/>
		<div className='overflow-hidden absolute opacity-20 -z-10 bottom-0 left-0 w-full bg-cover h-[50%] bg-no-repeat bg-bottom' style={{
			backgroundImage: `url("${BackgroundBottom}")`,
		}}/>
		{props.children}
	</div>;
}

interface AuthLayoutProps {
	className?: string;
	children: React.ReactNode;
}