import endpoints from '@mgp-fe/shared/core-api/endpoints.ts';
import {z} from 'zod';
import {useMutation, UseMutationOptions} from '@tanstack/react-query';
import {AxiosError, AxiosResponse} from 'axios';
import keysResolver from '@mgp-fe/shared/core-api/keysResolver.ts';
import coreApiClient from '@mgp-fe/shared/core-api/client.ts';
import {useGetRecaptchaToken} from '@mgp-fe/shared/hooks/useGetRecaptchaToken.ts';

export default function useForgotPasswordMutation(props: UseForgotPasswordMutationProps) {
	const recaptchaToken = useGetRecaptchaToken('forgot_password');

	return useMutation<AxiosResponse<undefined>, AxiosError<{ message?: string }>, ForgotPasswordMutationData>({
		mutationKey: keysResolver(endpoints.authentication.forgotPassword),
		mutationFn: async (data: ForgotPasswordMutationData) => coreApiClient.post(endpoints.authentication.forgotPassword, {
			...data,
			...(!data?.recaptchaToken ? {recaptchaToken: await recaptchaToken()} : {}),
		}),
		...props,
	});
}

type UseForgotPasswordMutationProps = UseMutationOptions<
	AxiosResponse<undefined>,
	AxiosError<{ message?: string; }>,
	ForgotPasswordMutationData
>;

export interface ForgotPasswordMutationData {
	email: string;
	recaptchaToken?: string;
}

export const ForgotPasswordMutationDataSchema = z.object({
	email: z.string().email(),
});