import ProfilePage from './components/profile/ProfilePage.tsx';
import AddressBookPage from './components/address-book/AddressBookPage.tsx';
import PaymentMethodsPage from './components/payment-methods/PaymentMethodsPage.tsx';
import React from 'react';
import routes from './routes.ts';
import PrivacySettingsPage from '@mgp-fe/shared/modules/account/components/privacy-settings/PrivacySettingsPage.tsx';

const routeObjects = [
	{
		path: routes.profile.index,
		element: <ProfilePage />,
	},
	{
		path: routes.addressBook.index,
		element: <AddressBookPage />,
	},
	{
		path: routes.paymentMethods.index,
		element: <PaymentMethodsPage />,
	},
	{
		path: routes.privacySettings.index,
		element: <PrivacySettingsPage/>,
	},
];

export default routeObjects;