import React from 'react';
import useSettingItemQuery from '@mgp-fe/shared/core-api/queries/setting/detail.ts';
import PartnerLocationCard, {
	PartnerLocationCardSkeleton,
} from '@mgp-fe/shared/modules/partner-locator/components/PartnerLocationCard.tsx';
import useAuth from '@mgp-fe/shared/modules/auth/hooks/useAuth.ts';
import {Alert, AlertDescription, AlertTitle} from '@mgp-fe/shared/ui/alert.tsx';
import {Customer} from '@mgp-fe/shared/core-api/domain/user.ts';

export default function CustomerPartnerLocationCard() {
	const {user, isUserLoading} = useAuth();
	const impressionKitLocationQuery = useSettingItemQuery({
		key: 'mgc_impression_kit_location',
	});

	if (impressionKitLocationQuery.isFetching || isUserLoading) return <PartnerLocationCardSkeleton />;

	if (!user || user['@type'] !== 'Customer')  return <>This is for customer only</>;


	if (impressionKitLocationQuery.data?.data.value === (user as Customer).partnerLocation?.id) {
		return <Alert variant='warning' className='mt-0'>
			<AlertTitle>You don&apos;t have orthodontist location.</AlertTitle>
			<AlertDescription>
				Request invitation from nearest partner if you want to use 3D scan instead of impression kit.
			</AlertDescription>
		</Alert>;
	}

	return <PartnerLocationCard location={(user as Customer).partnerLocation}/>;
}