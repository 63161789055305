/* eslint-disable */
// @ts-nocheck
import type * as Types from '@mgp-frontend/shared/cms-graphql/domain/types';

import { gql } from '@apollo/client';
export type VerticalStepperFragment = { __typename?: 'ComponentContentBlocksVerticalStepper', id: string, step: string, finished?: boolean | null, gradient_to: Types.Enum_Componentcontentblocksverticalstepper_Gradient_To, gradient_via?: Types.Enum_Componentcontentblocksverticalstepper_Gradient_Via | null, stepperVariant?: Types.Enum_Componentcontentblocksverticalstepper_Variant | null };

export const VerticalStepperFragmentDoc = gql`
    fragment VerticalStepper on ComponentContentBlocksVerticalStepper {
  id
  step
  finished
  gradient_to
  gradient_via
  stepperVariant: variant
}
    `;