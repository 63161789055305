import React from 'react';
import {Button} from '@mgp-fe/shared/ui/button.tsx';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

export default function CardsPagination({
	page,
	lastPage,
	changePageHandler,
	labels = {
		previous: 'Previous',
		next: 'Next',
	},
}: CardsPaginationProps) {
	return <nav className='mt-small w-full flex flex-wrap gap-mini items-center justify-between'>
		<Button
			variant='link'
			size='sm'
			onClick={() => changePageHandler(page - 1)}
			disabled={page === 1}
			className='pl-0'
			icon={<FontAwesomeIcon icon='chevron-left'/>}>
			{labels?.previous || 'Previous'}
		</Button>
		<span className='text-sm text-muted'>
			Page {page || 1} of {lastPage}
		</span>
		<Button
			variant='link'
			size='sm'
			onClick={() => changePageHandler(page + 1)}
			disabled={page >= lastPage}
			className='pr-0'
			icon={<FontAwesomeIcon icon='chevron-right'/>}
			iconPosition='right'>
			{labels?.next || 'Next'}
		</Button>
	</nav>;
}

interface CardsPaginationProps {
	page: number;
	lastPage: number;
	changePageHandler: (page: number) => void;
	labels?: {
		previous: string;
		next: string;
	};
}