import React from 'react';
import MemberZoneLayout from '@mgp-fe/customer/modules/common/ui/MemberZoneLayout.tsx';
import CheckoutIndexPage from '@mgp-fe/shared/modules/shop/components/checkout/IndexPage.tsx';
import {useLocation} from 'react-router-dom';
import routes from '@mgp-fe/shared/modules/shop/components/checkout/routes.ts';
import AvailableVouchersAlert from '@mgp-fe/customer/modules/shop/components/AvailableVouchersAlert.tsx';

export default function CheckoutPage() {
	const location = useLocation();

	return <MemberZoneLayout>
		<main className='container mb-xlarge'>
			{location.pathname !== routes.completed ? <div className='flex flex-col gap-small'>
				<AvailableVouchersAlert />
				<h1 className='mb-small'>Checkout</h1>
			</div>   : ''}

			<CheckoutIndexPage />
		</main>
	</MemberZoneLayout>;
}
