
import coreApiClient from '@mgp-fe/shared/core-api/client.ts';
import {useMutation, UseMutationOptions, useQueryClient} from '@tanstack/react-query';
import keysResolver from '@mgp-fe/shared/core-api/keysResolver.ts';
import endpoints from '@mgp-fe/shared/core-api/endpoints.ts';
import {CartModel} from '@mgp-fe/shared/core-api/domain/cart.ts';
import {AxiosError, AxiosResponse} from 'axios';
import {HydraError} from '@mgp-fe/shared/core-api/domain/base.ts';


export default function useApplyPromotionMutation(props?: UseApplyPromotionMutationProps) {
	const queryClient = useQueryClient();

	return useMutation({
		mutationKey: keysResolver(endpoints.cart.my.applyPromotion),
		mutationFn: (rq) => coreApiClient.patch<CartModel>(endpoints.cart.my.applyPromotion, rq),
		...props,
		onSuccess: async (data, variables, context) => {
			await queryClient.invalidateQueries(keysResolver(endpoints.cart.my.item));
			await queryClient.invalidateQueries(keysResolver(endpoints.cart.my.availablePromotions));
			props?.onSuccess?.(data, variables, context);
		},
	});
}

type UseApplyPromotionMutationProps = UseMutationOptions<
    AxiosResponse<CartModel>,
    AxiosError<HydraError>,
    ApplyPromotionRequest
>;

export interface ApplyPromotionRequest {
    promotionId: string;
}