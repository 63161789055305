import coreApiClient from '@mgp-fe/shared/core-api/client.ts';
import {AxiosError, AxiosResponse} from 'axios';
import {HydraError, Iri} from '@mgp-fe/shared/core-api/domain/base.ts';
import {useMutation, UseMutationOptions} from '@tanstack/react-query';
import endpoints from '@mgp-fe/shared/core-api/endpoints.ts';
import keysResolver from '@mgp-fe/shared/core-api/keysResolver.ts';

export default function useTransitByIriMutation<T, TTransition>(props: UseTransitByIriMutationProps<T, TTransition>) {
	return useMutation<AxiosResponse<T>, AxiosError<HydraError>, TransitByIdParams<TTransition>>({
		mutationKey: keysResolver(endpoints.transit(''), 'patch', props),
		mutationFn: (params) => coreApiClient.patch<T>(endpoints.transit(params.resourceName), {
			iri: params.iri,
			transition: params.transition,
			stateName: params.stateName,
		}),
		...props,
	});
}

type UseTransitByIriMutationProps<T, TTransition> = UseMutationOptions<
    AxiosResponse<T>,
    AxiosError<HydraError>,
    TransitByIdParams<TTransition>
>

export interface TransitByIdParams<TTransition> {
    iri: Iri;
    transition: TTransition;
    resourceName: ResourceName;
    stateName?: string;
}

type ResourceName = typeof resourceNames[number];

const resourceNames = [
	'shipments/single',
	'mouthguard_designs',
	'scan_requests',
	'scans',
	'order_items',
	'orders',
] as const;