import {createBrowserRouter} from 'react-router-dom';
import routes from '@mgp-fe/customer/router/routes.ts';
import React from 'react';
import IndexProxy from '@mgp-fe/customer/router/IndexProxy.tsx';
import MouthguardDesignsIndexPage from '@mgp-fe/customer/modules/mouthguard-designs/components/IndexPage.tsx';
import ScansIndexPage from '@mgp-fe/customer/modules/scans/components/IndexPage.tsx';
import OrdersIndexPage from '@mgp-fe/customer/modules/orders/components/IndexPage.tsx';
import DetailPage from '@mgp-fe/customer/modules/orders/components/DetailPage.tsx';
import {routeObjects as accountRouteObjects, routes as accountRoutes} from '@mgp-fe/shared/modules/account';
import InvitationsPage from '@mgp-fe/customer/modules/dashboard/components/InvitationsPage.tsx';
import ShopPage from '@mgp-fe/customer/modules/shop/components/ShopPage.tsx';
import CartPage from '@mgp-fe/customer/modules/shop/components/CartPage.tsx';
import CheckoutPage from '@mgp-fe/customer/modules/shop/components/CheckoutPage.tsx';
import ScanRequestsIndexPage from '@mgp-fe/customer/modules/scans/components/ScanRequestsIndexPage.tsx';
import {default as checkoutRouteObjects} from '@mgp-fe/shared/modules/shop/components/checkout/routeObjects.tsx';
import VouchersPage from '@mgp-fe/customer/modules/dashboard/components/VouchersPage.tsx';
import AuthLayout from '@mgp-fe/shared/modules/auth/components/AuthLayout.tsx';
import ResetPasswordForm from '@mgp-fe/shared/modules/auth/components/ResetPasswordForm.tsx';
import DesignerPage from '@mgp-fe/customer/modules/mouthguard-designs/components/DesignerPage.tsx';
import AccountPage from '@mgp-fe/customer/modules/common/components/AccountPage.tsx';
import CustomerOnboardingWrapper from '@mgp-fe/customer/modules/onboarding/CustomerOnboardingWrapper.tsx';
import ProductDetailPage from '@mgp-fe/customer/modules/shop/components/ProductDetailPage.tsx';

const router = createBrowserRouter([
	{
		path: routes.index,
		element: <IndexProxy/>,
	},
	{
		path: routes.invitations.index,
		element: <InvitationsPage/>,
	},
	{
		path: accountRoutes.index,
		element: <AccountPage />,
		children: accountRouteObjects,
	},
	{
		path: routes.shop.index,
		element: <ShopPage/>,
	},
	{
		path: routes.productDetails,
		element: <ProductDetailPage/>,
	},
	{
		path: routes.shop.cart,
		element: <CartPage/>,
	},
	{
		path: routes.shop.checkout.index,
		element: <CheckoutPage/>,
		children: checkoutRouteObjects,
	},
	{
		path: routes.gardDesigns.index,
		element: <MouthguardDesignsIndexPage/>,
	},
	{
		path: routes.gardDesigns.designer,
		element: <DesignerPage/>,
	},
	{
		path: routes.gardDesigns.designerDetail,
		element: <DesignerPage/>,
	},
	{
		path: routes.scans.index,
		element: <ScansIndexPage/>,
	},
	{
		path: routes.scansRequests.index,
		element: <ScanRequestsIndexPage/>,
	},
	{
		path: routes.onboarding.index,
		element: <CustomerOnboardingWrapper />,
	},
	{
		path: routes.orders.index,
		element: <OrdersIndexPage/>,
		children: [
			{
				path: routes.orders.detail,
				element: <DetailPage/>,
			},
		],
	},
	{
		path: routes.vouchers.index,
		element: <VouchersPage/>,
	},
	{
		path: routes.resetPassword,
		element: <AuthLayout><ResetPasswordForm /></AuthLayout>,
	},
]);

export default router;

