'use client';

import React from 'react';
import ProductGridCard, {ProductGridCardSkeleton} from '@mgp-fe/shared/modules/shop/components/ProductGridCard.tsx';
import ProductHeroCard, {ProductHeroCardSkeleton} from '@mgp-fe/shared/modules/shop/components/ProductHeroCard.tsx';
import {UseQueryResult} from '@tanstack/react-query';
import {AxiosResponse} from 'axios';
import {HydraListResponse} from '@mgp-fe/shared/core-api/domain/base.ts';
import {ProductCollectionModel} from '@mgp-fe/shared/core-api/domain/product.ts';
import ProductPackageCard from '@mgp-fe/shared/modules/shop/components/ProductPackageCard.tsx';

export default function ProductsList({productsQuery}: ProductsListProps) {

	const fullCustomGardTypeProducts = productsQuery.data?.data['hydra:member']
		.filter(product => product.productType === 'full_custom_mouthguard') || [];

	const packageTypeProducts = productsQuery.data?.data['hydra:member']
		.filter(product => product.productType === 'package') || [];

	return <section>
		{fullCustomGardTypeProducts.length > 0 && <div className='flex flex-col gap-12'>
			{fullCustomGardTypeProducts.map(product => <ProductHeroCard key={product.id} product={product}/>)}
		</div>}
		{productsQuery.isFetching && <ProductHeroCardSkeleton/>}

		{packageTypeProducts.length ? <div>
			{/*<p className='text-muted/90 my-medium'>*/}
			{/*Introducing our Mouthguard  Packages – the ideal solution for dental professionals, partners, and resellers prioritizing oral health.*/}
			{/*Available in three convenient sizes – 10-pack, 30-pack, and 50-pack – these packages offer comprehensive protection with Mouthguards designed for comfort and durability.*/}
			{/*</p>*/}

			{packageTypeProducts.map(product => <ProductPackageCard
				key={product.id}
				product={product}/>)}
		</div> : ''}

		<div
			className='grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-6 md:gap-8 lg:gap-10 xl:gap-12 content-between w-full'>
			{productsQuery.data?.data['hydra:member']
				.filter(product => product.productType !== 'full_custom_mouthguard' && product.productType !== 'package' && product.productType !== 'clear_mouthguard')
				.map(product => <ProductGridCard
					key={product.id}
					product={product}/>)}
			{productsQuery.isFetching && [...new Array(6)].map((_, index) => <ProductGridCardSkeleton key={index}/>)}
		</div>
	</section>;
}

interface ProductsListProps {
	productsQuery: UseQueryResult<AxiosResponse<HydraListResponse<ProductCollectionModel>, any>, unknown>;
}