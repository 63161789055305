import React from 'react';
import {FormProvider, useForm} from 'react-hook-form';
import {Button} from '@mgp-fe/shared/ui/button.tsx';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {TextInput} from '@mgp-fe/shared/ui/form/TextInput.tsx';
import useForgotPasswordMutation, {
	ForgotPasswordMutationData,
} from '@mgp-fe/shared/core-api/mutations/auth/forgot-password.ts';
import {AxiosResponse} from 'axios';
import {Alert} from '@mgp-fe/shared/ui/alert.tsx';

export default function ForgotPasswordForm(props: ForgotPasswordFormProps) {
	const form = useForm<ForgotPasswordMutationData>();
	const forgotPasswordMutation = useForgotPasswordMutation({
		onSuccess: (data, variables, context) => {
			props.onSuccess?.(data, variables, context);
		},
	});

	return <FormProvider {...form}>
		<h4 className='text-primary'>
			Forgot password?
		</h4>
		<p className='text-base'>
			Enter e-mail and we will send you reset link.
		</p>
		{forgotPasswordMutation.isSuccess
			? <Alert variant='success'>
				We have sent you an e-mail with instructions to reset your password.
			</Alert>
			: <form
				onSubmit={form.handleSubmit(d => forgotPasswordMutation.mutate(d))}
				className='flex flex-col horizontal-labels w-full gap-12 relative'>
				<TextInput name='email' label='E-mail' type='email' required/>

				{forgotPasswordMutation.isError && <Alert variant='destructive' size='xs' className='absolute top-24'>
					{forgotPasswordMutation.error?.response?.data.message || 'Unknown error'}
				</Alert>}
				<Button
					state={forgotPasswordMutation.status}
					icon={<FontAwesomeIcon icon='paper-plane'/>}>
					Send
				</Button>
			</form>}
	</FormProvider>;
}

interface ForgotPasswordFormProps {
	onSuccess?: (data: AxiosResponse, variables: ForgotPasswordMutationData, context: unknown) => void;
}