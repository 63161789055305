import {PartnerLocationPublicCollectionModel} from '@mgp-fe/shared/core-api/domain/partner/location.ts';
import {
	PartnerLocationsSearchFormValues,
} from '@mgp-fe/shared/modules/partner-locator/components/PartnerLocationsSearchForm.tsx';
import loader from '@mgp-fe/shared/utils/google-maps-loader.ts';

export default async function filterLocations(
	partnerLocations?: PartnerLocationPublicCollectionModel[],
	filterValues?: PartnerLocationsSearchFormValues,
): Promise<PartnerLocationPublicCollectionModel[] | undefined> {
	if ((partnerLocations || []).length === 0) {
		return undefined;
	}

	if (!filterValues || !filterValues.zipCode || !filterValues.radius) {
		return partnerLocations;
	}

	const distanceMultiplier = 0.0006213712;
	const radiusCal = (Math.round(filterValues!.radius) / distanceMultiplier);

	const geocoder = await loader.importLibrary('geocoding') as google.maps.GeocodingLibrary;
	const geometry = await loader.importLibrary('geometry') as google.maps.GeometryLibrary;

	try {
		const result = await (new geocoder.Geocoder).geocode({
			address: filterValues!.zipCode,
			componentRestrictions: {country: 'US'},
		});
		const results = result.results;

		const {lat, lng} = results![0].geometry.location;


		if(!partnerLocations) return undefined;

		const filtered = partnerLocations
			.filter(p => {
				if (!p.address.lat || !p.address.long) return false;
				const distance = geometry.spherical.computeDistanceBetween(
					new google.maps.LatLng(lat(), lng()),
					new google.maps.LatLng(p.address.lat, p.address.long),
				);
				return distance <= radiusCal;
			});

		return filtered?.length > 0 ? filtered : undefined;
	} catch (error) {
		return partnerLocations;
	}
}