'use client';

import React from 'react';
import {Button, ButtonProps} from '@mgp-fe/shared/ui/button.tsx';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {sumBy} from 'lodash';
import useCartMyQuery from '@mgp-fe/shared/core-api/queries/cart/my.tsx';
import {useIsMutating} from '@tanstack/react-query';
import keysResolver from '@mgp-fe/shared/core-api/keysResolver.ts';
import endpoints from '@mgp-fe/shared/core-api/endpoints.ts';
import {CreateCartItemMutationRequest} from '@mgp-fe/shared/core-api/mutations/cart/my-items.ts';
import {OrderItemModel} from '@mgp-fe/shared/core-api/domain/order.ts';
import {ProductCollectionModel} from '@mgp-fe/shared/core-api/domain/product.ts';
import useCart from '@mgp-fe/shared/modules/shop/hooks/useCart.tsx';
import {MouthguardDesignCollectionModel} from '@mgp-fe/shared/core-api/domain/mouthguard.ts';

export default function ProductCartControls({product, design, orderButtonProps, quantityButtonProps, addToCartHandler}: ProductCartControlsProps) {
	const cart = useCart();
	const myCartQuery = useCartMyQuery();
	const isAddMutating = useIsMutating({
		mutationKey: keysResolver(endpoints.cart.my.items.list),
		predicate: m => (m.state.variables as CreateCartItemMutationRequest).product === product?.['@id']
			&& (!design || design['@id'] === (m.state.variables as CreateCartItemMutationRequest).mouthguardDesign),
	});
	const isDecreaseMutating = useIsMutating({
		mutationKey: keysResolver(endpoints.cart.my.items.list),
		predicate: m => (m.state.variables as OrderItemModel).product?.id === product.id
			&& (!design || design['@id'] === (m.state.variables as OrderItemModel).mouthguard?.mouthguardDesign?.['@id']),
	});

	const quantity = sumBy(
		myCartQuery.data?.data.order.orderItems
			.filter(i => !design || i.mouthguard?.mouthguardDesign?.['@id'] === design['@id']
				|| i.mouthguard?.mouthguardDesign?.parent?.['@id'] === design['@id'])
			.filter(i => i.product.id === product.id) || [],
		'quantity',
	);

	if (quantity > 0) {
		return <div className='bg-primary-400/10 rounded-full w-full h-fit flex items-center justify-stretch py-2 px-2'>
			<Button size='icon' onClick={() => cart.reduceProductInCart(product, design)}
				state={isDecreaseMutating > 0 ? 'loading' : 'idle'}
				disabled={isDecreaseMutating > 0 || isAddMutating > 0}
				{...quantityButtonProps}>
				<FontAwesomeIcon icon={product.minimumNumber === quantity ? 'trash-can' : 'minus'}/>
			</Button>

			<span className='text-foreground text-2xl text-center grow px-6'>
				{quantity}
			</span>

			<Button
				size='icon'
				onClick={() => addToCartHandler ? addToCartHandler(design!) : cart.addProductToCart(product, design)}
				state={isAddMutating ? 'loading' : 'idle'}
				disabled={isDecreaseMutating > 0 || isAddMutating > 0}
				{...quantityButtonProps}>
				<FontAwesomeIcon icon='plus'/>
			</Button>
		</div>;
	}

	return <Button
		state={isAddMutating > 0 ? 'loading' : 'idle'}
		disabled={isDecreaseMutating > 0 || isAddMutating > 0}
		onClick={() => addToCartHandler ? addToCartHandler(design!) : cart.addProductToCart(product, design)}
		size='lg'
		className='uppercase grow'
		icon={<FontAwesomeIcon icon='cart-plus'/>}
		{...orderButtonProps}>
		Order
	</Button>;
}

interface ProductCartControlsProps {
	product: ProductCollectionModel;
	design?: MouthguardDesignCollectionModel;
	orderButtonProps?: ButtonProps;
	quantityButtonProps?: ButtonProps;
	addToCartHandler?: (design: MouthguardDesignCollectionModel) => void;
}