import React, {useEffect, useState} from 'react';
import {UseFormReturn} from 'react-hook-form';
import useHubspotTimezonesListQuery from '@mgp-fe/shared/core-api/queries/hubspot-timezones/list.ts';
import {useGetRecaptchaToken} from '@mgp-fe/shared/hooks/useGetRecaptchaToken.ts';
import {Combobox} from '@mgp-fe/shared/ui/form/Combobox.tsx';

const ConsentSettingsLanguages = [
	{label: 'English', value: 'en'},
	{label: 'French', value: 'fr'},
	{label: 'Spanish', value: 'es'},
];

export default function PrivacySettingsForm({form}: PrivacySettingsFormProps) {
	const recaptchaToken = useGetRecaptchaToken('hubspotTimezonesToken');
	const [reToken, setReToken] = useState('');
	const {data} = useHubspotTimezonesListQuery({
		params: {
			recaptchaToken: reToken,
		},
		options: {
			enabled: !!reToken && reToken !== 'RECAPTCHA_NOT_READY',
		},
	});

	useEffect(() => {
		const getToken = async () => {
			setReToken(await recaptchaToken());
		};
		getToken();

	}, [recaptchaToken]);


	const options = data?.data.options;

	return <div className='flex flex-col gap-y-5'>
		<section className='mt-small flex flex-col gap-y-3'>
			<h2>Privacy settings</h2>
			<h3>Transaction</h3>
			<p>To ensure a smooth experience and keep you updated regarding your account, purchases, and
                    shipments,
                    please select at least one contact method below:</p>
			<p className='text-destructive-500'>*At least 1 transactional contact option is required</p>
			<div className='ml-medium flex flex-col gap-y-3 my-small'>
				<label className='!flex-row !items-center !gap-small !mt-0'>
					<input type='checkbox' {...form.register('contactConsent.transactionalEmails')}/>
					<span className='ml-3'>Receive transactional notification via Email</span>
				</label>
				<label className='!flex-row !items-center !gap-small !mt-0 '>
					<input type='checkbox' {...form.register('contactConsent.transactionalSms')}/>
					<span className='ml-3'>Receive transactional notification via SMS</span>
				</label>
				<label className='!flex-row !items-center !gap-small !mt-0 '>
					<input type='checkbox' {...form.register('contactConsent.transactionalCalls')}/>
					<span className='ml-3'>Receive transactional notification via Call</span>
				</label>
			</div>
			<p>By choosing at least one option, you will stay informed about your account activities, order
                    status, and
                    important updates regarding your purchases and shipments.</p>
			<p>Your preferences matter! Please select a contact method that suits you best, especially if you
                    prefer quick updates via SMS, also we have an experience from <a className='text-primary-50/70'
				href='https://www.v2.retainerclub.com/'>Retainer
                        club</a> that updates via SMS are best choice.</p>
		</section>

		<section className='mt-small flex flex-col gap-y-3'>
			<h3>Marketing</h3>
			<p>Stay updated on exciting promotions, special events, and the latest news! Select any
                    preferred contact
                    method below:</p>
			<div className='ml-medium flex flex-col gap-y-3 my-small'>
				<label className='!flex-row !items-center !gap-small !mt-0 '>
					<input type='checkbox' {...form.register('contactConsent.marketingEmails')}/>
					<span className='ml-3'>Receive marketing notification via Email</span>
				</label>
				<label className='!flex-row !items-center !gap-small !mt-0 '>
					<input type='checkbox' {...form.register('contactConsent.marketingSms')}/>
					<span className='ml-3'>Receive marketing notification via SMS</span>
				</label>
				<label className='!flex-row !items-center !gap-small !mt-0 '>
					<input type='checkbox' {...form.register('contactConsent.marketingCalls')}/>
					<span className='ml-3'>Receive marketing notification via Call</span>
				</label>
			</div>
			<p>Choose any option you are comfortable with to get exclusive offers, news about special
                    events, and updates on our latest promotions and happenings.</p>
			<p>Your preferences matter! Select your desired contact method(s) to stay connected with our
                    exciting offers and updates.</p>
			<p>Thank you for managing your privacy settings.</p>
		</section>
		<section className='mt-small flex flex-col gap-y-3'>
			<h3>Language & Timezone</h3>
			<p>Select your preferred language to communicate with our support.</p>
			<p>Choose the language in which you would like to communicate with our support team for seamless
                    assistance.</p>
			<Combobox
				form={form}
				options={ConsentSettingsLanguages}
				name={'contactConsent.preferredLanguage'}
				placeholder='select language'
				defaultValue={form.getValues('contactConsent.preferredLanguage')}
			/>
			<p>Select your preferred time zone to communicate with our support.</p>
			<p>Choose the time zone that aligns with your location for efficient support communication.</p>
			<Combobox
				form={form}
				options={options}
				name={'contactConsent.timezone'}
				placeholder='select prefered timezone'
				defaultValue={form.getValues('contactConsent.timezone')}
			/>
		</section>
	</div>;
}

interface PrivacySettingsFormProps {
	// @ts-ignore
	form: UseFormReturn<any>;
}