import React from 'react';
import {Button} from '@mgp-fe/shared/ui/button.tsx';
import RegisterCustomerForm from '@mgp-fe/shared/modules/auth/components/RegisterCustomerForm.tsx';
import {CreateCustomerMutationData} from '@mgp-fe/shared/core-api/mutations/user/customer/create.ts';
import useAuth from '@mgp-fe/shared/modules/auth/hooks/useAuth.ts';
import {Customer} from '@mgp-fe/shared/core-api/domain/user.ts';
import { AxiosResponse } from 'axios';

export default function AuthSection({registerDefaultValues, registerHandler, registerStatus}: AuthSectionProps) {
	const {user, displayLoginModal} = useAuth<Customer>();

	if(user) return <div className='flex flex-col h-full items-center'>
		Registration / login successful, please wait...
	</div>;

	return <>
		<div className='w-full'>
			<h4 className='text-center text-muted'>Create your account</h4>
			<p>If you have an account, <Button
				variant='link' className='inline-block p-0' size='sm'
				onClick={displayLoginModal.on}> sign in here.</Button></p>
			<RegisterCustomerForm
				defaultValues={registerDefaultValues}
				onSubmit={registerHandler}
				onSubmitStatus={registerStatus}/>
		</div>
	</>;
}

interface AuthSectionProps {
	registerDefaultValues?: CreateCustomerMutationData;
	registerHandler?: (data: CreateCustomerMutationData) => Promise<AxiosResponse>;
	registerStatus?: 'idle' | 'loading' | 'success' | 'error';
}