import React from 'react';
import OnboardingBackground from '@mgp-fe/customer/assets/onboarding-bg.png';
import {OnboardingIndexPage, OnboardingProvider} from '@mgp-fe/shared/modules/onboarding';
import Logo from '@mgp-fe/shared/assets/logo/text-logo.svg';
import {Button} from '@mgp-fe/shared/ui/button.tsx';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faRightFromBracket} from '@fortawesome/free-solid-svg-icons';
import useAuth from '@mgp-fe/shared/modules/auth/hooks/useAuth.ts';
import {Customer} from '@mgp-fe/shared/core-api/domain/user.ts';
import {Navigate, useSearchParams} from 'react-router-dom';
import routes from '@mgp-fe/customer/router/routes.ts';

export default function CustomerOnboardingWrapper() {
	const [query] = useSearchParams();
	const {user, logout} = useAuth<Customer>();

	return <OnboardingProvider initialMode={{
		invitation: query.get('invitation') ?? undefined,
		signUpLink: query.get('sign_up_link') ?? undefined,
		voucherId: query.get('voucher') ?? undefined,
		voucherCode: query.get('code') ?? undefined,
		emailSignToken: query.get('emailSignToken') ?? undefined,
		designer: query.get('designer') ?? undefined,
	}} usingOauth={!!(parseInt(query.get('usingOauth') ?? '0') ?? undefined)}>
		<Navigate to={routes.onboarding.index}/>
		<div
			style={{backgroundImage: `url(${OnboardingBackground})`}}
			className='bg-center bg-cover grow min-h-screen overflow-hidden relative'>
			<h1 className='flex flex-wrap gap-small justify-center items-center mt-2 md:mt-6 lg:mt-10 uppercase text-primary !text-2xl'>
				<span>Order your</span>
				<Logo className='fill-primary max-w-[240px] h-[50px]'/>
			</h1>
			<OnboardingIndexPage/>
			<footer
				className='text-center mb-2 lg:mb-medium flex flex-col-reverse md:flex-row md:justify-between md:items-center md:mx-2 lg:w-[970px] lg:mx-auto lg:px-2 text-sm gap-2'>
				<p className='text-muted font-semibold'>
				&copy; {(new Date()).getFullYear().toString()} GARD. All rights reserved.
				</p>
				<nav className='flex flex-wrap items-center gap-mini justify-center md:justify-normal'>
					<Button asChild variant='ghost' size='sm' className='text-muted'><a href='https://mouthguardclub.com/legal' target='_blank' rel='noreferrer'>Terms & Conditions</a></Button>
					{user && <Button onClick={() => logout()} variant='outline' size='sm'>
						<FontAwesomeIcon icon={faRightFromBracket}/>
					Logout
					</Button>}
				</nav>
			</footer>
		</div>
	</OnboardingProvider>;
}