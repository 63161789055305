import React from 'react';
import {Card, CardContent, CardFooter} from '@mgp-fe/shared/ui/card.tsx';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Badge, badgeVariants} from '@mgp-fe/shared/ui/badge.tsx';
import {ImpressionKitStateMap, ScanCollectionModel, ScanState} from '@mgp-fe/shared/core-api/domain/scan.ts';
import {humanizeEnumValue} from '@mgp-fe/shared/utils/humanize-string.ts';
import DateFormatted from '@mgp-fe/shared/modules/formatters/DateFormatted.tsx';
import {cn} from '@mgp-fe/shared/utils';
import Skeleton from '@mgp-fe/shared/ui/skeleton';

export default function ScanCard({scan}: ScanCardProps) {
	return <Card variant='secondary'>
		<CardContent className='pt-6 flex flex-row flex-wrap gap-small text-xl font-medium items-start'>
			<p className={cn(
				'max-w-full flex items-center gap-3 grow',
				scan.dateOfScan ? 'text-secondary-50' : 'text-destructive-400 text-sm',
			)}>
				<FontAwesomeIcon icon='calendar'/>
				{scan.dateOfScan ? <DateFormatted date={scan.dateOfScan} showDateOnly={true}/>
					: <span>Missing date of scan</span>}
			</p>
			<Badge variant={ScanStateBadgeVariantMap[scan.state]} className='max-w-fit ml-auto'>
				{humanizeEnumValue(scan.provider === 'impression_kit' ? ImpressionKitStateMap[scan.state] : scan.state)}
			</Badge>

			<h5 className='col-span-2 w-full text-secondary-200'>
				{scan.provider ? humanizeEnumValue(scan.provider)
					: <span className='text-destructive'>Provider not set</span>}
			</h5>
		</CardContent>

		<CardFooter>
			<span className='w-1/2 text-xs'>
						Created <br/> <DateFormatted date={scan.createdAt!}/>
			</span>
			<span className='w-[calc(50%-1rem)] text-xs'>
				{scan.updatedAt ? <>Updated <br/> <DateFormatted date={scan.updatedAt}/></>
					: <span className='text-warning'>any updates yet</span>}
			</span>
		</CardFooter>
	</Card>;
}

export function ScanCardSkeleton() {
	return (
		<Card variant='secondary'>
			<CardContent className='pt-6 flex flex-row flex-wrap gap-small text-xl font-medium items-start'>
				{/* Calendar and Date of Scan Placeholder */}
				<div className='max-w-full flex items-center gap-3 grow'>
					<Skeleton className='w-6 h-6' /> {/* Calendar Icon Placeholder */}
					<Skeleton className='ml-3 w-32 h-5' /> {/* Date Placeholder */}
				</div>
				{/* Badge Placeholder */}
				<Skeleton className='max-w-fit ml-auto w-24 h-6' />

				{/* Provider Placeholder */}
				<Skeleton className='col-span-2 w-full mt-2 h-5' />
			</CardContent>

			<CardFooter>
				<div className='w-1/2 text-xs'>
					<Skeleton className='w-16 h-4' /> {/* Created Date Placeholder */}
				</div>
				<div className='w-[calc(50%-1rem)] text-xs'>
					<Skeleton className='w-16 h-4' /> {/* Updated Date Placeholder */}
				</div>
			</CardFooter>
		</Card>
	);
}

interface ScanCardProps {
	scan: ScanCollectionModel;
}

const ScanStateBadgeVariantMap = {
	requested: 'warning',
	waiting: 'outline',
	synced: 'success',
	outdated: 'destructive',
	cancelled: 'destructive',
} as Record<ScanState, keyof typeof badgeVariants>;
