import React, {useEffect, useState} from 'react';
import {useZxing} from 'react-zxing';


export default function QRScanner(props: QRScannerProps) {
	const [error, setError] = useState<string | undefined | null>(undefined);

	const {ref} = useZxing({
		onDecodeResult(result) {
			props.setValue(result.getText());
		},
		constraints: {
			video: {
				facingMode: 'environment',
			},
		},
	});

	useEffect(() => {
		if (error !== undefined) return;
		setError(null);

		// @ts-ignore
		navigator.permissions.query({name: 'camera'}).then((result) => {
			if (result.state === 'denied') {
				setError('Camera access denied');
			}
		});
	}, [error]);

	if (error && error.length > 0) {
		return <div
			className='text-danger font-semibold  aspect-ratio w-full h-40 flex items-center justify-center border-2 border-danger rounded-xl'>{error}</div>;
	}

	return <video ref={ref}/>;
}


interface QRScannerProps {
	setValue: (v: string) => void;
}
