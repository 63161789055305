import {useQueryClient} from "@tanstack/react-query";
import useNotify from "@mgp-fe/shared/ui/notifications/use-notify.ts";
import React, {useContext} from "react";
import AuthContext from "@mgp-fe/shared/modules/auth/state/AuthContext.ts";
import useToggle from "@mgp-fe/shared/hooks/useToggle.ts";
import useRemoveOAuthMutation from "@mgp-fe/shared/core-api/mutations/oauth/remove.ts";
import keysResolver from "@mgp-fe/shared/core-api/keysResolver.ts";
import endpoints from "@mgp-fe/shared/core-api/endpoints.ts";
import useCreateOAuthMutation from "@mgp-fe/shared/core-api/mutations/oauth/create.ts";
import {CodeResponse, useGoogleLogin} from "@react-oauth/google";
import {OAuthModel, OAuthType} from "@mgp-fe/shared/core-api/domain/oauth.ts";
import {Card, CardContent, CardTitle} from "@mgp-fe/shared/ui/card.tsx";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faApple, faGoogle} from "@fortawesome/free-brands-svg-icons";
import {Button} from "@mgp-fe/shared/ui/button.tsx";
import {faChevronLeft, faXmarkCircle} from "@fortawesome/free-solid-svg-icons";
import Modal from "@mgp-fe/shared/ui/Modal.tsx";
import ChangePasswordForm from "@mgp-fe/shared/modules/account/components/profile/ChangePasswordForm.tsx";
import Skeleton from "@mgp-fe/shared/ui/skeleton.tsx";

export default function OAuthCard({oauth, oauthType}: OAuthCardProps) {
    const queryClient = useQueryClient();
    const {notifyError, notifySuccess} = useNotify();
    const {user, loginWithToken} = useContext(AuthContext);
    const displayRemoveOauthModal = useToggle({initialState: false});

    const oauthRemoveMutation = useRemoveOAuthMutation({
        onSuccess: () => {
            queryClient.invalidateQueries(keysResolver(endpoints.me.oauth.index));
            notifySuccess({
                message: `${oauthType} account successfully removed.`
            });
        },
        onError: (error) => {
            notifyError({
                message: error.response?.data["hydra:description"] ?? error.response?.data.detail ?? 'Error while removing OAuth account',
            });
        }
    });
    const oauthAddMutation = useCreateOAuthMutation({
        onSuccess: (data) => {
            queryClient.invalidateQueries(keysResolver(endpoints.me.oauth.index));
            data.data.signToken && loginWithToken(data.data.signToken);
            notifySuccess({
                message: `${oauthType} account successfully connected.`
            });
        },
        onError: (error) => {
            console.log(error);

            notifyError({
                message: error.response?.data["hydra:description"] ?? error.response?.data.detail ?? 'Error while connecting OAuth account',
            });
        }
    });

    const googleLogin = useGoogleLogin({
        onSuccess: (tokenResponse: Omit<CodeResponse, "error" | "error_description" | "error_uri">) => oauthAddMutation.mutate({
            type: 'google',
            googleCredential: tokenResponse.code,
        }),
        flow: 'auth-code',

    });

    const connectOauthAccount = (oathType: OAuthType) => {
        if (oathType === 'google') googleLogin();
    }

    return <>
        <Card variant='secondary' spacing='sm' className='flex flex-col'>
            <CardContent className='flex flex-row h-full relative pb-0'>
                <CardTitle className='leading-0'>
                    {{
                        'google': <FontAwesomeIcon icon={faGoogle}></FontAwesomeIcon>,
                        'apple': <FontAwesomeIcon icon={faApple}></FontAwesomeIcon>
                    }[oauth?.type || oauthType]}
                </CardTitle>
                <div className="grow"/>
                <div>
                    {oauth?.oauthId ? <div className='flex'>
                        <p className='leading-[2]'>{oauth.oauthId}</p>
                        <Button
                            variant='link'
                            size='icon'
                            icon={<FontAwesomeIcon icon={faXmarkCircle}/>}
                            type='button'
                            className='h-7 ml-4'
                            onClick={() => {
                                displayRemoveOauthModal.on();
                            }}
                        />
                    </div>: <Button variant='link' size='sm' onClick={() => connectOauthAccount(oauthType)}>
                        Connect
                    </Button>}
                </div>
            </CardContent>
        </Card>

        <Modal
            title='Remove oauth'
            isOpen={displayRemoveOauthModal.state}
            onClose={displayRemoveOauthModal.off}>
            {user?.passwordSet ? <>
                <p className='mb-8'>Are you sure you want to remove this OAuth connection?</p>
                <div className='flex justify-between'>
                    <Button
                        onClick={() => {
                            oauthRemoveMutation.mutate({id: oauth?.id!});
                            displayRemoveOauthModal.off();
                        }}
                        size='md'>
                        Yes, remove
                    </Button>
                    <Button
                        onClick={() => {
                            displayRemoveOauthModal.off();
                        }}
                        variant='ghost'
                        className='pt-2 pb-3 leading-7'
                        size='md'>
                        <FontAwesomeIcon icon={faChevronLeft}/> Back
                    </Button>
                </div>
            </> : <>
                <p className='mb-8'>Before removing oauth you will need to set a password.</p>
                <ChangePasswordForm onSuccess={() => {return}}/>
            </>}
        </Modal>
    </>;
}

export function OAuthCardSkeleton() {
    return (
        <Card variant='secondary' spacing='sm' className='flex flex-col'>
            <CardContent className='flex flex-row h-full relative pb-0'>
                <div className='w-6 h-6'>
                    <Skeleton className='rounded-full'/>
                </div>
                <div className="grow"/>
                <div>
                    <div className='flex items-center'>
                        <Skeleton className='w-20 h-6'/>
                        <Skeleton className='w-8 h-8 ml-4'/>
                    </div>
                </div>
            </CardContent>
        </Card>
    );
}

type OAuthCardProps = {
    oauth?: OAuthModel;
    oauthType: OAuthType;
}