import React, {Fragment, useCallback, useEffect, useState} from 'react';
import {MouthguardDesignCollectionModel} from '@mgp-fe/shared/core-api/domain/mouthguard.ts';
import {Dialog, Transition} from '@headlessui/react';
import getClientEnv from '@mgp-fe/shared/utils/env-var-resolver.ts';
import {AUTH_LOCAL_STORAGE_KEY} from '@mgp-fe/shared/core-api/domain/base.ts';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button} from '@mgp-fe/shared/ui/button.tsx';
import {useQueryClient} from '@tanstack/react-query';
import keysResolver from '@mgp-fe/shared/core-api/keysResolver.ts';
import endpoints from '@mgp-fe/shared/core-api/endpoints.ts';
import useAuth from '@mgp-fe/shared/modules/auth/hooks/useAuth.ts';
import useCartMyQuery from '@mgp-fe/shared/core-api/queries/cart/my.tsx';
import {useRemoveItemFromCartMutation} from '@mgp-fe/shared/core-api/mutations/cart/my-items.ts';
import useLocalStorage from '@mgp-fe/shared/hooks/useLocalStorage.ts';
import useMeQuery from '@mgp-fe/shared/core-api/queries/user/me.ts';
import {Affiliate, User} from '@mgp-fe/shared/core-api/domain/user.ts';
import {TeamCollectionModel} from '@mgp-fe/shared/core-api/domain/team.ts';

export default function DesignerModal({design, onClose, team, affiliate, signUpLink}: DesignerModalProps) {
	const queryClient = useQueryClient();
	const [authKey, setToken] = useLocalStorage<string | null>(AUTH_LOCAL_STORAGE_KEY, null);
	const {user} = useAuth();
	const userQuery = useMeQuery<User>();
	const removeCartItemMutation = useRemoveItemFromCartMutation();
	const cartQuery = useCartMyQuery();

	const setupUrl = useCallback(() => {
		const designerUrl = new URL('/', getClientEnv('designerAppUrl'));
		const itemQuantity = cartQuery.data?.data.order.orderItems.find(i => i.mouthguard?.mouthguardDesign?.id === design?.id)?.quantity.toString() || '';

		const designId = design?.parent?.id ?? design?.id;

		designerUrl.pathname = designId ? `/athlete-designer/${designId}` : '/athlete-designer/';

		if (design?.type === 'clear_custom_design' ) {
			designerUrl.pathname = `/clear-designer/${designId}`;
		}

		if ((design?.team && (!user || user['@type'] === 'Customer')) || design?.parent?.team) {
			designerUrl.pathname = `/athlete-team-designer/${designId}`;
		}

		if ((design?.affiliate && (!user || user['@type'] === 'Customer')) || design?.parent?.affiliate) {
			designerUrl.pathname = `/affiliate-athlete-designer/${designId}`;
		}

		if (team) {
			designerUrl.pathname = designId ? `/team-designer/${designId}` : '/team-designer/';
			designerUrl.searchParams.set('teamId', team['@id']);
		}

		if (affiliate) {
			designerUrl.pathname = designId ? `/affiliate-designer/${designId}` : '/affiliate-designer/';
			designerUrl.searchParams.set('affiliateId', affiliate['@id']);
		}

		if (signUpLink) {
			designerUrl.searchParams.set('signUpLink', signUpLink);
		}

		designerUrl.searchParams.set('modal', '');

		authKey && designerUrl.searchParams.set('token', authKey || '');
		design?.parent && designerUrl.searchParams.set('quantity', itemQuantity);

		return designerUrl;
	}, [design, user, team]);
	const [designerUrl, setDesignerUrl] = useState(setupUrl);

	useEffect(() => {
		const newUrl = setupUrl();
		if (newUrl.toString() === designerUrl.toString()) return;
		setDesignerUrl(newUrl);
	}, [setupUrl]);

	useEffect(() => {
		const closeDesigner = async (e: MessageEvent) => {
			if (e.data === 'close-iframe') {
				if (design?.parent) {
					//1. find the cart item with the team design
					const oldCartItem = cartQuery.data?.data.order.orderItems
						.filter(i => i.mouthguard?.mouthguardDesign?.id === design?.id);
					if (oldCartItem?.length === 0) return;
					//2. remove the cart item
					await removeCartItemMutation.mutateAsync(oldCartItem![0]);
					// notice: adding mouthguard with team design is in designer app
				}
				await Promise.all([
					queryClient.refetchQueries({predicate: p => p.isActive()}),
					new Promise(() => onClose()),
				]);
			}

			if (e.data.event === 'login' && e.data.accessToken) {
				setToken(e.data.accessToken);
				await userQuery.refetch();
				await queryClient.invalidateQueries(keysResolver(endpoints.mouthguardDesigns.list));
				onClose();
			}
		};
		window.addEventListener('message', closeDesigner);

		return () => window.removeEventListener('message', closeDesigner);
	}, [design?.team, onClose, queryClient, user]);

	return <Transition appear show={true} as={Fragment}>
		<Dialog as='div' className='relative z-[60]' onClose={onClose}>
			<Transition.Child
				as={Fragment} enter='ease-out duration-300' enterFrom='opacity-0' enterTo='opacity-100'
				leave='ease-in duration-200' leaveFrom='opacity-100' leaveTo='opacity-0'>
				<div className='fixed inset-0 bg-black bg-opacity-25'/>
			</Transition.Child>

			<div className='fixed inset-0 overflow-y-auto'>

				<div className='flex h-full items-center justify-center md:p-3 lg:p-4 text-center'>
					<Transition.Child
						as={Fragment} enter='ease-out duration-300' enterFrom='opacity-0 scale-95'
						enterTo='opacity-100 scale-100' leave='ease-in duration-200'
						leaveFrom='opacity-100 scale-100' leaveTo='opacity-0 scale-95'>
						<Dialog.Panel
							className='relative z-100 max-w-[83rem] w-full h-full transform overflow-hidden md:rounded-lg bg-white text-left align-middle shadow-xl transition-all'>
							<Button onClick={onClose} size='icon' className='absolute right-1 top-1 z-50'>
								<FontAwesomeIcon icon='times' className='h-5 w-5 z-50'/>
							</Button>
							<iframe src={designerUrl.toString()} className='relative max-w-[83rem] w-full h-full'/>
						</Dialog.Panel>
					</Transition.Child>
				</div>
			</div>
		</Dialog>
	</Transition>;
}

interface DesignerModalProps {
    design?: Partial<MouthguardDesignCollectionModel> | null; //'id' | 'parent' | 'team' are optional
    team?: TeamCollectionModel | null;
    affiliate?: Affiliate | null;
    signUpLink?: string;
    onClose: () => void;
}