import React from 'react';
import useAuth from '@mgp-fe/shared/modules/auth/hooks/useAuth.ts';
import DashboardIndexPage from '@mgp-fe/customer/modules/dashboard/components/IndexPage.tsx';
import AuthPage from '@mgp-fe/shared/modules/auth/components/IndexPage.tsx';
import Logo from '@mgp-fe/shared/assets/logo/text-logo.svg';
import AuthLayout from '@mgp-fe/shared/modules/auth/components/AuthLayout.tsx';
import {Button} from '@mgp-fe/shared/ui/button.tsx';
import {Customer} from '@mgp-fe/shared/core-api/domain/user.ts';
import {Link} from 'react-router-dom';
import routes from '@mgp-fe/customer/router/routes.ts';
import BadUserTypeInAppAlert from '@mgp-fe/shared/modules/auth/components/BadUserTypeInAppAlert.tsx';


export default function IndexProxy() {
	const {user, isUserLoading} = useAuth<Customer>();

	if (isUserLoading) {
		return <AuthLayout>
			<div className='w-screen h-screen flex flex-col items-center justify-center animate-pulse gap-8'>
				<Logo className='fill-primary w-52 md:w-72 lg:w-80'/>
				<h1 className='h3 md:h2 lg:h3 uppercase text-secondary text-4xl font-semibold'>Loading...</h1>
			</div>
		</AuthLayout>;
	}

	if (user && user['@type'] !== 'Customer') {
		return <AuthLayout>
			<BadUserTypeInAppAlert app='customer'/>
		</AuthLayout>;
	}

	if (user) return <DashboardIndexPage/>;

	return <AuthPage
		app='customer'
		registerButton={<Button
			variant='outline'
			size='md'
			asChild>
			<Link to={routes.onboarding.index}>
				Don&apos;t have an account yet? Register now!
			</Link>
		</Button>}
	/>;
}
