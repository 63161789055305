import React from 'react';
import {FormProvider, useForm} from 'react-hook-form';
import useUpdateMeMutation, {
	UpdateMeMutationData,
	UpdateMeMutationDataSchema,
} from '@mgp-fe/shared/core-api/mutations/user/me.ts';
import {zodResolver} from '@hookform/resolvers/zod';
import { TextInput } from '@mgp-fe/shared/ui/form/TextInput';
import {Button} from '@mgp-fe/shared/ui/button.tsx';
import {Alert, AlertDescription, AlertTitle} from '@mgp-fe/shared/ui/alert';
import {AlertCircle} from 'lucide-react';
import useAuth from '@mgp-fe/shared/modules/auth/hooks/useAuth';
import DateInput from '@mgp-fe/shared/ui/form/DateInput.tsx';
import PhoneNumberInput from '@mgp-fe/shared/ui/form/PhoneNumberInput.tsx';
import {Customer} from '@mgp-fe/shared/core-api/domain/user.ts';

export default function UpdateProfileForm({onSuccess}: UpdateProfileFormProps) {
	const {user} = useAuth();
	const form = useForm<UpdateMeMutationData>({
		...(user ? {defaultValues: {
			name: user.name,
			email: user.email,
			phone: user.phone,
			dateOfBirth: (user as Customer).dateOfBirth ? (user as Customer).dateOfBirth?.toISOString().split('T')[0] : undefined,
		}} : {}),
		resolver: zodResolver(UpdateMeMutationDataSchema),
	});
	const updateMe = useUpdateMeMutation({
		onSuccess: onSuccess,
	});

	return <FormProvider {...form}>
		<form
			onSubmit={form.handleSubmit(function (d) {
				return updateMe.mutate({
					...d,
					dateOfBirth: d.dateOfBirth === '' ? undefined : d.dateOfBirth,
				});
			})}
			className='flex flex-col horizontal-labels w-full'>

			<TextInput label='Name' name='name' />
			<TextInput label='E-mail address' name='email' type='email' />
			<PhoneNumberInput label='Phone number' name='phone' />
			{user?.['@type'] === 'Customer' ? <DateInput label='Date of birth' name='dateOfBirth' /> : ''}

			<Button
				className='mt-6'
				size='lg'
				type='submit'
				state={updateMe.status}>
				Update profile
			</Button>

			{updateMe.isError ? <Alert variant='destructive' className='mb-0'>
				<AlertTitle className='flex gap-2 items-center justify-start'>
					<AlertCircle className='h-4 w-4'/>
					<span>{updateMe.error.response?.data['hydra:title'] || 'Error'}</span>
				</AlertTitle>
				<AlertDescription>
					{updateMe.error.response?.data['hydra:description'] || 'Unknown error'}
				</AlertDescription>
			</Alert> : ''}
		</form>
	</FormProvider>;
}

interface UpdateProfileFormProps {
	onSuccess?: () => void;
}