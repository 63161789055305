import React, {useContext} from 'react';
import useInvitationDetailQuery from '@mgp-fe/shared/core-api/queries/invitation/detail.ts';
import CustomerDesignCard from '@mgp-fe/shared/modules/mouthguard-design/components/CustomerDesignCard.tsx';
import {Alert} from '@mgp-fe/shared/ui/alert.tsx';
import Skeleton from '@mgp-fe/shared/ui/skeleton.tsx';
import useAuth from '@mgp-fe/shared/modules/auth/hooks/useAuth.ts';
import {Customer} from '@mgp-fe/shared/core-api/domain/user.ts';
import AuthSection from '@mgp-fe/shared/modules/onboarding/components/welcome/AuthSection.tsx';
import useAcceptInvitationMutation from '@mgp-fe/shared/core-api/mutations/invitation/accept.ts';
import useNotify from '@mgp-fe/shared/ui/notifications/use-notify.ts';
import {useQueryClient} from '@tanstack/react-query';
import useLocalStorage from '@mgp-fe/shared/hooks/useLocalStorage.ts';
import {AUTH_LOCAL_STORAGE_KEY} from '@mgp-fe/shared/core-api/domain/base.ts';
import InvitationCard from '@mgp-fe/shared/modules/invitations/components/InvitationCard.tsx';
import {OnboardingContext} from '@mgp-fe/shared/modules/onboarding';
import {CreateCustomerMutationData} from '@mgp-fe/shared/core-api/mutations/user/customer/create.ts';

export default function InvitationMode() {
	const onboardingContext = useContext(OnboardingContext);
	const queryClient = useQueryClient();
	const {user} = useAuth<Customer>();
	const {notifySuccess, notifyError} = useNotify();
	const [, setToken] = useLocalStorage<string | null>(AUTH_LOCAL_STORAGE_KEY, null);
	const invitationDetailQuery = useInvitationDetailQuery({
		invitationId: onboardingContext.mode.invitation || '',
		options: {enabled: !!onboardingContext.mode.invitation},
	});

	const successHandler = async (newUser = false) => {
		notifySuccess({message: newUser ? 'Invitation accepted.' : 'Account created.'});
		await queryClient.refetchQueries({predicate: p => p.isActive()});
		onboardingContext.setMode({});
	};

	const acceptInvitation = useAcceptInvitationMutation({
		onSuccess: async (rs) => {
			const createdUser = rs.data.user;
			createdUser && createdUser.signToken && setToken(createdUser.signToken);
			await successHandler(true);
		},
		onError: (e) => {
			if(e.status === 404) {
				notifyError({message: 'Invitation not found or has been already used.'});
			}  else {
				notifyError({
					title: e.response?.data['hydra:title'] || 'Error occured',
					message: e.response?.data['hydra:description'] || 'Unknown error.',
				});
			}
		},
	});

	const invitation = invitationDetailQuery.data?.data;
	const gardDesigns = invitation?.team?.designs || [];

	if (invitationDetailQuery.isFetching) return <div className='grid md:grid-cols-2 gap-large items-center'>
		<section className='text-center'>
			<h5 className='text-secondary'>
				Welcome, <br/> <span className='text-muted/90'>You have been invited</span>
			</h5>

			<Skeleton className='w-full h-10 mx-auto mt-medium'/>
		</section>

		<section className='flex flex-col md:flex-row'>
			<span
				className='h-0.5 md:h-96 w-full md:w-0.5 bg-gradient-to-r md:bg-gradient-to-b from-transparent via-primary to-transparent md:mr-medium my-medium md:my-auto'/>
			<Skeleton className='w-full h-80 mx-auto mt-medium'/>
		</section>
	</div>;
	if (!invitation) return <Alert variant='destructive'>Invitation not found</Alert>;

	if(user) return <div className='flex flex-col'>
		<InvitationCard invitation={invitation} successHandler={successHandler} />
	</div> ;

	return <div className='grid md:grid-cols-2 items-center'>
		<section className='mx-auto text-center md:pr-medium my-auto'>
			<h5 className='text-secondary'>
				Welcome, <br/> <span className='text-muted/90'>You have been invited</span>
			</h5>

			{invitation.team?.name && <> <br/>
				join team <br/><h4>{invitation.team?.name}</h4></>}

			<div className='grid md:grid-cols-2 gap-mini md:gap-small lg:gap-medium mt-medium'>
				{gardDesigns.map(d => <CustomerDesignCard
					design={d}
					key={d.id}
					cartControls={false}
					onEdit={undefined}/>)}
			</div>

			{invitation.partnerLocation?.name && <div className='mt-6'>
				set up your location<br/><h4>{invitation.partnerLocation?.name}</h4>
			</div>}

			{invitation.partnerLocation?.logoUrl && <div className='hover:bg-light/60'><img
				src={invitation.partnerLocation.logoUrl}
				alt='Partner logo'
				className='object-contain object-center mx-auto h-fit w-4/5 md:w-3/4 rounded-2xl'
				draggable={false}/></div>}
		</section>

		<section className='w-full flex flex-col md:flex-row'>
			<span
				className='h-0.5 md:h-96 w-full md:w-0.5 bg-gradient-to-r
				md:bg-gradient-to-b from-transparent via-primary to-transparent
				md:mr-medium my-medium md:my-auto'/>

			<AuthSection
				registerDefaultValues={invitation as CreateCustomerMutationData}
				registerHandler={user => acceptInvitation.mutateAsync({
					invitation,
					user,
				})}
				registerStatus={acceptInvitation.status}
			/>
		</section>
	</div>;
}
