import React from 'react';
import {Card, CardContent, CardHeader, CardTitle} from '@mgp-fe/shared/ui/card.tsx';
import {Badge} from '@mgp-fe/shared/ui/badge.tsx';
import {PaymentStateBadgeVariantMap} from '@mgp-fe/shared/modules/orders/ui/constants.ts';
import {humanizeEnumValue} from '@mgp-fe/shared/utils/humanize-string.ts';
import MoneyFormatted from '@mgp-fe/shared/modules/formatters/MoneyFormatted.tsx';
import {Button} from '@mgp-fe/shared/ui/button.tsx';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import useStripeMyCardsListQuery from '@mgp-fe/shared/core-api/queries/stripe/my-cards.ts';
import {PaymentMethod} from '@stripe/stripe-js/types/api/payment-methods';
import {PaymentMethodType, PaymentModel} from '@mgp-fe/shared/core-api/domain/payment.ts';
import {Alert} from '@mgp-fe/shared/ui/alert';

export default function PaymentDetailCard({payment, paymentMethodType}: PaymentCardProps) {
	const myCardsQuery = useStripeMyCardsListQuery();
	const stripeCard: PaymentMethod.Card | null = (myCardsQuery.data?.data.paymentMethods
		.filter(pm => pm.type === 'card')
		.find(pm => pm.id === payment?.paymentMethodId)?.card || null) as PaymentMethod.Card | null;

	if (!payment) return <Alert variant='warning' className='flex items-center justify-center text-2xl w-full'>Missing payment</Alert>;

	return <Card>
		<CardHeader>
			<CardTitle className='inline-flex items-center justify-between gap-medium'>
				Payment

				<Badge variant={PaymentStateBadgeVariantMap[payment.paymentState]}>
					{humanizeEnumValue(payment.paymentState)}
				</Badge>
			</CardTitle>
		</CardHeader>
		<CardContent className='flex flex-col gap-small justify-center'>
			<h3 className='text-muted inline-flex items-end'>
				<span className='!text-muted'><MoneyFormatted money={payment.amountToPay}/></span>
				{payment.refundedAmount.amount > 0 ? <span className='text-warning'>
									(Refunded: <MoneyFormatted money={payment.refundedAmount}/>)
				</span> : ''}
				{payment.refundedAmount.amount === 0 && payment.paidAmount && payment.paidAmount?.amount !== payment.amountToPay.amount
					? <span className='text-warning pl-4 pb-2'>
										(partially paid: <MoneyFormatted money={payment.paidAmount}/>)
					</span> : <span className='text-secondary text-xs pl-4 pb-2'>fully paid</span>}
			</h3>
			<div className='capitalize'>
				<h5>Method</h5>
				<p className='flex justify-between items-center'>
					<span>
						{paymentMethodType ? humanizeEnumValue(paymentMethodType) : 'Unknown method'}
						{payment?.['@type'].replace('Payment', '').toLocaleLowerCase() !== paymentMethodType?.toLocaleLowerCase()
							? <> ({payment?.['@type'].replace('Payment', '')})</> : ''}
					</span>

					{stripeCard ? <>
						<span className='text-muted text-xs'>{stripeCard.brand}</span>
						<span className='text-muted text-xs'>**** {stripeCard.last4}</span>
						<span
							className='text-muted text-xs'>Expires {stripeCard.exp_month}/{stripeCard.exp_year}</span>
					</> : ''}
				</p>
			</div>
			<section>
				<h5>Accounting</h5>
				<div
					className='flex flex-col lg:flex-row gap-small justify-between lg:items-center mt-2'>
					{payment.receiptUrl
						? <Button asChild size='sm' variant='outline'>
							<a href={payment.receiptUrl} target='_blank' rel='noreferrer'>
								<FontAwesomeIcon icon='download'/> Download receipt
							</a>
						</Button>
						: <p className='text-warning'>
							<FontAwesomeIcon icon='circle-exclamation' className=' mr-2'/>
							Receipt is not available.
						</p>}

					<p>
						<FontAwesomeIcon icon='file-invoice' className='mr-2'/>
						Billed to <span className='text-secondary'>
							{humanizeEnumValue(payment.paymentBilledTo).toLocaleLowerCase()}
						</span>
					</p>
				</div>
			</section>
		</CardContent>
	</Card>;
}

interface PaymentCardProps {
	payment?: PaymentModel;
	paymentMethodType?: PaymentMethodType;
}