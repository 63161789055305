import React, {useContext, useEffect, useMemo, useState} from 'react';
import useAuth from '@mgp-fe/shared/modules/auth/hooks/useAuth.ts';
import {Customer} from '@mgp-fe/shared/core-api/domain/user.ts';
import {OnboardingContext} from '@mgp-fe/shared/modules/onboarding';
import useCartMyQuery from '@mgp-fe/shared/core-api/queries/cart/my.tsx';
import useMouthguardDesignsListQuery from '@mgp-fe/shared/core-api/queries/mouthguard-design/list.ts';
import useTeamsListQuery from '@mgp-fe/shared/core-api/queries/team/list.ts';
import useAthleteTeamsDesigns from '@mgp-fe/shared/core-api/queries/mouthguard-design/customer-team-designs.ts';
import {useVoidCartMutation} from '@mgp-fe/shared/core-api/mutations/cart/void.ts';
import useProductsListQuery from '@mgp-fe/shared/core-api/queries/product/list.ts';
import useCart from '@mgp-fe/shared/modules/shop/hooks/useCart.tsx';
import {MouthguardDesignCollectionModel, MouthguardDesignModel} from '@mgp-fe/shared/core-api/domain/mouthguard.ts';
import {ProductCollectionModel} from '@mgp-fe/shared/core-api/domain/product.ts';
import {Button} from '@mgp-fe/shared/ui/button.tsx';
import {cn} from '@mgp-fe/shared/utils';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import CustomerDesignCard from '@mgp-fe/shared/modules/mouthguard-design/components/CustomerDesignCard.tsx';
import DesignerModal from '@mgp-fe/shared/modules/mouthguard-design/components/DesignerModal.tsx';
import {Alert} from '@mgp-fe/shared/ui/alert.tsx';
import {useQueryClient} from '@tanstack/react-query';
import keysResolver from '@mgp-fe/shared/core-api/keysResolver.ts';
import endpoints from '@mgp-fe/shared/core-api/endpoints.ts';
import getClientEnv from '@mgp-fe/shared/utils/env-var-resolver.ts';

export default function DefaultMode() {
	const queryClient = useQueryClient();
	const {user} = useAuth<Customer>();
	const onboardingContext = useContext(OnboardingContext);

	const cartQuery = useCartMyQuery();
	const designsQuery = useMouthguardDesignsListQuery({
		params: {
			pagination: false,
			customer: user?.id,
		},
		options: {enabled: !!user},
	});
	const teamsQuery = useTeamsListQuery({params: {pagination: false}});
	const teamDesignsQuery = useAthleteTeamsDesigns({
		params: {
			team: user?.teams.map(t => t.id),
		},
		options: {enabled: !!user},
	});
	const voidCartMutation = useVoidCartMutation();
	const getProductsQuery = useProductsListQuery({
		params: {'channels.name': 'customer'},
	});
	const {addProductToCart} = useCart();

	// null means create new design
	const [teamDesign, setTeamDesign] = useState<MouthguardDesignCollectionModel | null | undefined>(undefined);
	const [nameOnDesign, setNameOnDesign] = useState<string | undefined>();
	const [openedDesign, setOpenedDesign] = useState<MouthguardDesignCollectionModel | null | undefined>(undefined);

	const selectedDesign = designsQuery.data?.data['hydra:member'].find(d => d['@id'] === onboardingContext.selectedDesign);

	const mouthguardProducts = useMemo(
		() => getProductsQuery.data?.data['hydra:member'].filter((product: ProductCollectionModel) => product.productType === 'full_custom_mouthguard' || product.productType === 'clear_mouthguard'),
		[getProductsQuery.data?.data],
	);

	useEffect(() => {
		const design = cartQuery.data?.data.order.orderItems[0]?.mouthguard?.mouthguardDesign;
		if (design && design.parent?.['@id']) {
			onboardingContext.setSelectedDesign(design.parent['@id']);
			setNameOnDesign((design as MouthguardDesignModel)?.canvasData?.data?.find(d => d.cacheKey === 'playerName')?.text || 'No name');
		}
	}, []);

	const teamWithMissingDesign = useMemo(() => teamsQuery.data?.data['hydra:member'].filter((team) => {
		return teamDesignsQuery.data?.data['hydra:member'].filter(d => d.team?.id === team.id).length === 0;
	}) || [], [teamDesignsQuery.data?.data, teamsQuery.data?.data]);


	const onSelectMouthguard = async (design: MouthguardDesignCollectionModel) => {
		const productToAdd = {
			'clear_custom_design': 'clear_mouthguard',
			'full_custom_design': 'full_custom_mouthguard',
		}[design.type];
		const product = mouthguardProducts?.find(p => p.productType === productToAdd);

		voidCartMutation.mutate();
		product && await addProductToCart(product, design);
	};

	if (!user) return <></>; // Unreachable code for types safety

	return <>
		<h2 className='text-primary text-center mb-2 leading-[1.15] truncate'><span
			className='h2 !text-muted'>Welcome</span>{user?.name ?
			<span>&nbsp;{user?.name && user.name.split(' ')[0] || ''}</span> : ''}, <br/>
		<span className='text-xl mt-medium mb-small text-primary'>select a design for order</span>
		</h2>

		<div className='flex flex-col items-start md:flex-row'>
			{(teamsQuery.isFetched && (teamsQuery.data?.data['hydra:totalItems'] || 0) > 0) ?
				<div className='grow w-full md:w-1/2 my-6 md:my-0  flex flex-col items-center justify-start'>
					<h3 className='mb-4 text-center !font-medium text-muted'>Teams</h3>
					<div className='flex w-full gap-[1rem] flex-wrap justify-center'>
						{teamDesignsQuery.data?.data['hydra:member'].map(d => <Button
							className={cn(
								'rounded-lg p-0 h-full hover:no-underline relative shrink-0 w-[calc(50%-1rem)] max-w-[195px] cursor-pointer',
								cartQuery.data?.data.order.orderItems[0]?.mouthguard?.mouthguardDesign?.parent?.['@id'] === d['@id'] && 'border-2 border-primary',
							)}
							variant='link'
							key={d.id}
							onClick={() => {
								voidCartMutation.mutate();
								onboardingContext.setSelectedDesign(d['@id']);
								setTeamDesign(d);
							}}
							asChild>
							<div className='w-fit h-fit'>
								<div
									className='absolute top-40 left-small z-10 text-sm flex items-center gap-mini text-muted'>
									{nameOnDesign && d.id === selectedDesign?.id
										? <>
											<FontAwesomeIcon icon='edit' className='mr-1'/>
											{nameOnDesign}
										</> : ''}

								</div>
								<CustomerDesignCard
									cartControls={false}
									className={cartQuery.data?.data.order.orderItems[0]?.mouthguard?.mouthguardDesign?.parent?.['@id'] === d['@id'] ? 'bg-primary [&x4]:!text-primary-foreground [&>h4]:leading-5 [&>h4>span]:!text-primary-foreground/70' : ''}
									key={d.id}
									design={d}/>
							</div>
						</Button>)}

						{teamWithMissingDesign.map(t => <Alert variant='warning' key={t.id}
															   className='w-full text-secondary'>
							Team <span className='text-muted'>{t.name}</span> doesn&apos;t have design.
						</Alert>)}
					</div>

					{teamDesign !== undefined
						? <DesignerModal onClose={() => setTeamDesign(undefined)} design={teamDesign}/>
						: ''}
				</div> : ''}

			{(teamsQuery.isFetched && (teamsQuery.data?.data['hydra:totalItems'] || 0) > 0) && <span
				className='h-0.5 md:h-72 w-full md:w-0.5 bg-gradient-to-r md:bg-gradient-to-b from-transparent via-primary-400 to-transparent  my-6 md:mx-8 lg:mx-10 z-40'/>}

			<div className='grow w-full md:w-1/2 my-6 md:my-0  flex flex-col items-center'>
				{(teamsQuery.isFetched && (teamsQuery.data?.data['hydra:totalItems'] || 0) > 0) ?
					<h3 className=' mb-4 text-center text-muted'>Your designs</h3> : ''}
				<div className={cn(
					'flex w-full gap-[1rem] flex-wrap justify-center items-stretch',
				)}>
					{designsQuery.isSuccess && designsQuery.data?.data['hydra:member']
						.filter((design) => !('team' in design))
						.map(d => <Button
							className={cn(
								'shrink-0 w-[calc(50%-1rem)] max-w-[195px] h-auto rounded-lg p-0 hover:no-underline cursor-pointer',
								cartQuery.data?.data.order.orderItems[0]?.mouthguard?.mouthguardDesign['@id'] === d['@id'] && 'border-2 border-primary',
							)}
							variant='link'
							key={d.id}
							onClick={() => {
								onSelectMouthguard(d);
								onboardingContext.setSelectedDesign(d['@id']);
							}}
							asChild>
							<div>
								<CustomerDesignCard
									cartControls={false}
									className={cn('w-full h-full', cartQuery.data?.data.order.orderItems[0]?.mouthguard?.mouthguardDesign['@id'] === d['@id'] ? 'bg-primary [&>h4]:!text-primary-foreground' : '')}
									design={d}
									onEdit={() => setOpenedDesign(d || null)}
								/>
							</div>
						</Button>)}

					<div className='w-[calc(50%-1rem)] max-w-[250px] self-stretch'>
						<div
							className='h-full text-center hover:scale-95 min-h-[220px] shrink-0 p-4 transition-all duration-200 rounded-lg backdrop-blur-xl overflow-hidden cursor-pointer group  border-2 border-primary-500/50 max-h-full  justify-center hover:bg-primary-500/10 items-center flex flex-col '
							onClick={() => setOpenedDesign(null)}>
							<p className='text-primary text-2xl font-semibold'>NEW DESIGN</p>
							<p className='mt-mini'>Click here to open the designer and create your own design</p>
						</div>
					</div>
				</div>
			</div>
		</div>


		<div className='flex flex-col-reverse md:flex-row justify-between items-center mt-6 mx-2'>
			<Button variant='link' className='text-muted/80 md:pl-0' asChild>
				<a href={getClientEnv('customerAppUrl') + '/shop'}>
					<FontAwesomeIcon icon='chevron-left' className='mr-2'/>
					Skip onboarding
				</a>
			</Button>
			<Button
				onClick={() => onboardingContext.setOnboardingStep('account')}
				className='w-full md:w-fit'
				state={queryClient.isMutating({
					mutationKey: keysResolver(endpoints.cart.my.items.list),
				}) || cartQuery.isLoading || designsQuery.isLoading || teamDesignsQuery.isLoading ? 'loading' : 'idle'}
				disabled={!cartQuery.data?.data.order.orderItems.length}
				icon={<FontAwesomeIcon icon='chevron-right' className='mr-2'/>}
				iconPosition='right'>
				Account
			</Button>
		</div>

		{openedDesign !== undefined ?
			<DesignerModal onClose={() => setOpenedDesign(undefined)} design={openedDesign}/> : ''}
	</>;
}
