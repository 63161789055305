import React from 'react';
import useResetPasswordMutation, {
	ResetPasswordMutationData,
} from '@mgp-fe/shared/core-api/mutations/auth/reset-password.ts';
import {FormProvider, useForm} from 'react-hook-form';
import {TextInput} from '@mgp-fe/shared/ui/form/TextInput.tsx';
import {Button} from '@mgp-fe/shared/ui/button.tsx';
import {Alert, AlertDescription, AlertTitle} from '@mgp-fe/shared/ui/alert.tsx';
import {zodResolver} from '@hookform/resolvers/zod';
import {z} from 'zod';
import {passwordSchema} from '@mgp-fe/shared/core-api/schemas/user.ts';

export default function ResetPasswordForm() {
	const resetPasswordMutation = useResetPasswordMutation();
	const form = useForm<ResetPasswordMutationData>({
		resolver: zodResolver(z.object({
			password: passwordSchema,
			tokenId: z.string(),
		})),
		defaultValues: {
			tokenId: (new URLSearchParams(window.location.search)).get('token') || '',
		},
	});

	if(resetPasswordMutation.isSuccess) {
		return <Alert variant='success' className='max-w-lg'>
			<AlertTitle>
				Password reset successfully!
			</AlertTitle>
			<AlertDescription>
				You can now login with your new password.

				<Button variant='outline' className='mt-medium' onClick={() => window.location.href = '/'}>
					Go to login page
				</Button>
			</AlertDescription>
		</Alert>;
	}

	return <FormProvider {...form}>
		<form
			onSubmit={form.handleSubmit(d => resetPasswordMutation.mutate(d))}
			className='horizontal-labels flex flex-col gap-small max-w-lg'>
			<h3>Setup new password</h3>

			<p>Please enter your new password. Click on icon on right side of input to see value.</p>

			<TextInput name='password' label='Password' type='password'/>

			<Button state={resetPasswordMutation.status}>
				Reset Password
			</Button>

			{resetPasswordMutation.isError ? <Alert variant='destructive' className='mb-0'>
				<AlertDescription>
					{resetPasswordMutation.error.response?.data.message || 'Unknown error'}
				</AlertDescription>
			</Alert> : ''}
		</form>
	</FormProvider>;
}
