import React from 'react';
import {OrderCollectionModel} from '@mgp-fe/shared/core-api/domain/order.ts';
import MoneyFormatted from '@mgp-fe/shared/modules/formatters/MoneyFormatted.tsx';
import {Card, CardProps} from '@mgp-fe/shared/ui/card.tsx';
import {cn} from '@mgp-fe/shared/utils';
import Skeleton from '@mgp-fe/shared/ui/skeleton.tsx';

export default function OrderAmountSummaryCard({
	order,
	className,
	cardProps,
}: OrderAmountSummaryCardProps) {

	return <Card
		className={cn('flex flex-col gap-mini text-large', className)}
		variant='secondary'
		spacing='sm'
		{...cardProps}>
		<>
			<p className='flex flex-wrap gap-mini md:gap-x-xlarge justify-between'>
                Subtotal
				<span className='text-xl text-muted'><MoneyFormatted money={order.subTotalAmount}/></span>
			</p>
			{!!order.discountAmount.amount &&
                <p className='flex flex-wrap gap-mini md:gap-x-xlarge justify-between'>
                    Discount<span className='text-xl text-muted'>-<MoneyFormatted money={order.discountAmount}/></span>
                </p>}
			<p className='flex flex-wrap gap-mini md:gap-x-xlarge justify-between'>
                Shipping
				<span className='text-xl text-muted'><MoneyFormatted money={order.shippingCost}/></span>
			</p>
			<p className='flex flex-wrap gap-mini md:gap-x-xlarge justify-between'>
                Tax
				<span className='text-xl text-muted'><MoneyFormatted money={order.taxAmount}/></span>
			</p>
			<hr/>
			<p className='flex flex-wrap items-center gap-mini total'>
                Total<span className='text-sm mt-1.5 mr-auto'>(tax inc.)</span>
				<span className='text-xl text-muted'><MoneyFormatted money={order.totalAmount}/></span>
			</p>
		</>
	</Card>;
}

export function OrderSummaryLoadingSkeleton() {
	return <Card
		className={cn('flex flex-col gap-mini text-large')}
		variant='ghost'
		spacing='none'>
		<>
			<div className='flex flex-wrap gap-mini md:gap-x-xlarge justify-between'>
				<Skeleton className='w-1/4 h-6'/>
				<Skeleton className='w-1/4 h-6'/>
			</div>

			<div className='flex flex-wrap gap-mini md:gap-x-xlarge justify-between'>
				<Skeleton className='w-1/4 h-6'/>
				<Skeleton className='w-1/4 h-6'/>
			</div>

			<div className='flex flex-wrap gap-mini md:gap-x-xlarge justify-between'>
				<Skeleton className='w-1/4 h-6'/>
				<Skeleton className='w-1/4 h-6'/>
			</div>

			<div className='flex flex-wrap gap-mini md:gap-x-xlarge justify-between'>
				<Skeleton className='w-1/4 h-6'/>
				<Skeleton className='w-1/4 h-6'/>
			</div>

			<Skeleton className='w-full h-1'/>

			<div className='flex flex-wrap items-center gap-mini total'>
				<Skeleton className='w-1/4 h-8'/>
				<Skeleton className='w-1/4 h-6 mt-1.5'/>
				<div className='ml-auto'/>
				<Skeleton className='w-1/4 h-8'/>
			</div>
		</>
	</Card>;
}

interface OrderAmountSummaryCardProps {
    order: OrderCollectionModel;
    className?: string;
    cardProps?: CardProps;
}


