import {useMutation, UseMutationOptions, useQueryClient} from '@tanstack/react-query';
import keysResolver from '@mgp-fe/shared/core-api/keysResolver.ts';
import endpoints from '@mgp-fe/shared/core-api/endpoints.ts';
import {CartModel} from '@mgp-fe/shared/core-api/domain/cart.ts';
import {AxiosError, AxiosResponse} from 'axios';
import {HydraError, Iri} from '@mgp-fe/shared/core-api/domain/base.ts';
import coreApiClient from '@mgp-fe/shared/core-api/client.ts';
import {OrderItemModel} from '@mgp-fe/shared/core-api/domain/order.ts';

export default function useRemoveVoucherMutation(props?: UseRemoveVoucherMutationProps) {
	const queryClient = useQueryClient();

	return useMutation({
		mutationKey: keysResolver(endpoints.cart.my.items.removeVoucher('')),
		mutationFn: (data) => coreApiClient.patch<CartModel>(endpoints.cart.my.items.removeVoucher(data.orderItem.id), data.request),
		...props,
		onSuccess: async (data, variables, context) => {
			await queryClient.invalidateQueries(keysResolver(endpoints.cart.my.item));
			await queryClient.invalidateQueries(keysResolver(endpoints.vouchers.list));
			props?.onSuccess?.(data, variables, context);
		},
	});
}

type UseRemoveVoucherMutationProps = UseMutationOptions<
	AxiosResponse<CartModel>,
	AxiosError<HydraError>,
	RemoveVoucherMutationData
>;

interface RemoveVoucherMutationData {
	orderItem: OrderItemModel;
	request: RemoveVoucherRequest;
}

interface RemoveVoucherRequest {
	voucher: Iri;
}