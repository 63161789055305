/* eslint-disable */
// @ts-nocheck
import type * as Types from '@mgp-frontend/shared/cms-graphql/domain/types';

import { gql } from '@apollo/client';
export type SpaceFragment = { __typename?: 'ComponentContentBlocksSpace', id: string, top?: Types.Enum_Componentcontentblocksspace_Top | null, bottom?: Types.Enum_Componentcontentblocksspace_Bottom | null, dividerColor?: Types.Enum_Componentcontentblocksspace_Dividercolor | null, cssClassNames?: string | null };

export const SpaceFragmentDoc = gql`
    fragment Space on ComponentContentBlocksSpace {
  id
  top
  bottom
  dividerColor
  cssClassNames
}
    `;