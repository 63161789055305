import React, {useContext, useEffect, useMemo, useState} from 'react';
import useAuth from '@mgp-fe/shared/modules/auth/hooks/useAuth.ts';
import {Customer} from '@mgp-fe/shared/core-api/domain/user.ts';
import {Button} from '@mgp-fe/shared/ui/button.tsx';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import RegisterCustomerForm from '@mgp-fe/shared/modules/auth/components/RegisterCustomerForm.tsx';
import {OnboardingContext} from '@mgp-fe/shared/modules/onboarding';
import {useQueryClient} from '@tanstack/react-query';
import useNotify from '@mgp-fe/shared/ui/notifications/use-notify.ts';
import useSendSignInLinkMutation, {
	SendSignInLinkMutationData,
} from '@mgp-fe/shared/core-api/mutations/user/send-sign-in-link.ts';
import {FormProvider, useForm} from 'react-hook-form';
import {TextInput} from '@mgp-fe/shared/ui/form/TextInput.tsx';
import {Alert} from '@mgp-fe/shared/ui/alert.tsx';
import {faPaperPlane} from '@fortawesome/free-solid-svg-icons';
import Modal from '@mgp-fe/shared/ui/Modal.tsx';
import keysResolver from '@mgp-fe/shared/core-api/keysResolver.ts';
import endpoints from '@mgp-fe/shared/core-api/endpoints.ts';
import useUtilizeSignUpLinkMutation from '@mgp-fe/shared/core-api/mutations/signup-link/create-customer.ts';
import useMouthguardDesignsListQuery from '@mgp-fe/shared/core-api/queries/mouthguard-design/list.ts';
import CustomerDesignCard from '@mgp-fe/shared/modules/mouthguard-design/components/CustomerDesignCard.tsx';
import {cn} from '@mgp-fe/shared/utils';
import {MouthguardDesignCollectionModel, MouthguardDesignModel} from '@mgp-fe/shared/core-api/domain/mouthguard.ts';
import DesignerModal from '@mgp-fe/shared/modules/mouthguard-design/components/DesignerModal.tsx';
import {useVoidCartMutation} from '@mgp-fe/shared/core-api/mutations/cart/void.ts';
import useCartMyQuery from '@mgp-fe/shared/core-api/queries/cart/my.tsx';
import getClientEnv from '@mgp-fe/shared/utils/env-var-resolver.ts';
import useCart from '@mgp-fe/shared/modules/shop/hooks/useCart.tsx';
import {ProductCollectionModel} from '@mgp-fe/shared/core-api/domain/product.ts';
import useProductsListQuery from '@mgp-fe/shared/core-api/queries/product/list.ts';
import useToggle from '@mgp-fe/shared/hooks/useToggle.ts';

export default function AffiliatePathMode() {
	const onboardingContext = useContext(OnboardingContext);
	const queryClient = useQueryClient();
	const {user, displayLoginModal} = useAuth<Customer>();
	const {notifySuccess} = useNotify();
	const signInLinkMutation = useSendSignInLinkMutation();
	const signLinkForm = useForm<SendSignInLinkMutationData>();
	const [signInLinkModalOpened, setSignInLinkModalOpened] = useState(false);
	const [openedDesign, setOpenedDesign] = useState<MouthguardDesignCollectionModel | null | undefined>(undefined);
	const voidCartMutation = useVoidCartMutation();
	const [affiliateDesign, setAffiliateDesign] = useState<MouthguardDesignCollectionModel | null | undefined>(undefined);
	const [nameOnDesign, setNameOnDesign] = useState<string | undefined>();
	const displayDesignerModal = useToggle();
	const {addProductToCart} = useCart();


	const cartQuery = useCartMyQuery();

	const getProductsQuery = useProductsListQuery({
		params: {'channels.name': 'customer'},
	});

	const affiliateDesignsQuery = useMouthguardDesignsListQuery({
		params: {
			pagination: false,
			order: {createdAt: 'desc'},
			affiliate: onboardingContext.mode.affiliateId!,
		},
	});

	const designsQuery = useMouthguardDesignsListQuery({
		params: {pagination: false},
		options: {enabled: !!user},
	});

	const onSuccess = async () => {
		notifySuccess('Account created.');

		await Promise.all([
			queryClient.invalidateQueries(keysResolver(endpoints.mouthguardDesigns.athleteTeams)),
			queryClient.invalidateQueries(keysResolver(endpoints.mouthguardDesigns.list)),
		]);
		onboardingContext.setOnboardingStep('design');
	};

	const utilizeSignUpLinkMutation = useUtilizeSignUpLinkMutation({
		onSuccess: onSuccess,
	});

	const selectedDesign = designsQuery.data?.data['hydra:member'].find(d => d['@id'] === onboardingContext.selectedDesign);

	const mouthguardProducts = useMemo(
		() => getProductsQuery.data?.data['hydra:member'].filter((product: ProductCollectionModel) => product.productType === 'full_custom_mouthguard' || product.productType === 'clear_mouthguard'),
		[getProductsQuery.data?.data],
	);

	const onSelectMouthguard = async (design: MouthguardDesignCollectionModel) => {
		const productToAdd = {
			'clear_custom_design': 'clear_mouthguard',
			'full_custom_design': 'full_custom_mouthguard',
		}[design.type];
		const product = mouthguardProducts?.find(p => p.productType === productToAdd);

		voidCartMutation.mutate();
		product && await addProductToCart(product, design);
	};

	useEffect(() => {
		const design = cartQuery.data?.data.order.orderItems[0]?.mouthguard?.mouthguardDesign;
		if (design && design.parent?.['@id']) {
			onboardingContext.setSelectedDesign(design.parent['@id']);
			setNameOnDesign((design as MouthguardDesignModel)?.canvasData?.data?.find(d => d.cacheKey === 'playerName')?.text || 'No name');
		}
	}, []);

	const MyAffiliateDesigns = () => <div className='grow my-6 md:my-0 md:w-[50%] flex flex-col md:items-center gap-2'>
		<h4 className='text-center mb-4 text-muted'>
			Choose my designs
		</h4>
		<p className='text-center mb-small text-muted/80'>
			You can use my designs and add your name or number.
		</p>
		<div className='grid md:grid-cols-2 gap-mini md:gap-small lg:gap-medium mt-small'>
			{affiliateDesignsQuery.data?.data['hydra:member'].map(d => <CustomerDesignCard
				design={d}
				key={d.id}
				cartControls={false}
				onEdit={undefined}
				size='small'
			/>)}
		</div>
	</div>;

	const DefaultAffiliateSection = () => <div
		className='grow my-6 md:my-0 md:w-[50%] flex flex-col md:items-center gap-2'>
		<h4 className='text-center mb-4 text-muted'>
			Design your GARD
		</h4>
		<p className='text-center mb-small text-muted/80'>
			You can make design before you create an account. If you like your design, you can save it. <br/>
		</p>
		{displayDesignerModal.state ?
			<DesignerModal onClose={displayDesignerModal.off} signUpLink={onboardingContext.mode.affiliatePath}/> : ''}

		<Button
			onClick={displayDesignerModal.on}
			variant='secondary'
			size='lg'
			icon={<FontAwesomeIcon icon='arrow-up-right-from-square'/>}>
			GARD designer
		</Button>
		<p className='text-center text-sm mt-small'>
			Have you saved design already?
			<Button size='sm' variant='link' className='normal-case'
				onClick={() => setSignInLinkModalOpened(true)}>
				Sign in with email link
			</Button>
		</p>
	</div>;


	if (!user && onboardingContext.mode.affiliatePath) {
		return <div className='flex flex-col items-center md:flex-row mx-3'>
			{
				onboardingContext.mode.isAffiliateDefault ?
					<DefaultAffiliateSection/>
					:
					<MyAffiliateDesigns/>
			}

			<span
				className='h-0.5 md:h-[35rem] w-full md:w-0.5 bg-gradient-to-r md:bg-gradient-to-b from-transparent via-primary-400 to-transparent  my-6 md:mx-8 lg:mx-10 z-40'/>

			<section className='grow md:w-[50%] relative flex flex-col'>
				<h4 className='text-center text-muted'>Create new account</h4>
				<RegisterCustomerForm
					onSubmit={(data) => onboardingContext.mode.affiliatePath && utilizeSignUpLinkMutation.mutate({
						id: onboardingContext.mode.affiliatePath,
						request: data,
					})}
					onSubmitStatus={utilizeSignUpLinkMutation.status}
				/>
				<p className='mt-medium text-center'>
					Have you saved design already? <span
						onClick={displayLoginModal.on}
						className='text-primary-500/80 font-semibold inline-block normal-case p-0 cursor-pointer'>Sign in here</span> or <span
						onClick={() => setSignInLinkModalOpened(true)}
						className='text-primary-500/80 font-semibold inline-block normal-case p-0 cursor-pointer'>
                request sign-in link <span className='paragraph'>(password-less)</span>
					</span>
				</p>
			</section>
			<Modal
				onClose={() => setSignInLinkModalOpened(false)}
				isOpen={signInLinkModalOpened}
				title='Send sign-in link'>
				<FormProvider {...signLinkForm}>
					<p>
						Enter your e-mail address and we will send you a link to sign in.
					</p>
					<form onSubmit={signLinkForm.handleSubmit((d) => {
						signInLinkMutation.reset();
						signInLinkMutation.mutate({
							...d,
							userType: getClientEnv('appName'),
						});
					})} className='horizontal-labels flex flex-col gap-medium'>
						<TextInput label='Your e-mail' name='email'/>

						{signInLinkMutation.isSuccess
							? <Alert variant='success'>Check your email for sign-in link</Alert>
							: <Button
								state={signInLinkMutation.status}
								disabled={signInLinkMutation.isLoading || signInLinkMutation.isSuccess}
								type='submit'
								iconPosition={'left'}
								icon={<FontAwesomeIcon icon={faPaperPlane} className='mr-2'/>}>
								Send link to e-mail
							</Button>}
					</form>

					{signInLinkMutation.isError && <Alert variant='destructive'>User does not exist.</Alert>}
				</FormProvider>
			</Modal>
		</div>;
	}


	return <>
		<div className='flex flex-col items-start md:flex-row'>
			{(affiliateDesignsQuery.isFetched && (affiliateDesignsQuery.data?.data['hydra:totalItems'] || 0) > 0) ?
				<div className='grow w-full md:w-1/2 my-6 md:my-0  flex flex-col items-center justify-start'>
					<h3 className='mb-4 text-center !font-medium text-muted'>Affiliate designs</h3>
					<div className='flex w-full gap-[1rem] flex-wrap justify-center'>
						{affiliateDesignsQuery.data?.data['hydra:member'].map(d => <Button
							className={cn(
								'rounded-lg p-0 h-full hover:no-underline relative shrink-0 w-[calc(50%-1rem)] max-w-[195px] cursor-pointer',
								cartQuery.data?.data.order.orderItems[0]?.mouthguard?.mouthguardDesign?.parent?.['@id'] === d['@id'] && 'border-2 border-primary',
							)}
							variant='link'
							key={d.id}
							onClick={() => {
								voidCartMutation.mutate();
								onboardingContext.setSelectedDesign(d['@id']);
								setAffiliateDesign(d);
							}}
							asChild>
							<div className='w-fit h-fit'>
								<div
									className='absolute top-40 left-small z-10 text-sm flex items-center gap-mini text-muted'>
									{nameOnDesign && d.id === selectedDesign?.id
										? <>
											<FontAwesomeIcon icon='edit' className='mr-1'/>
											{nameOnDesign}
										</> : ''}

								</div>
								<CustomerDesignCard
									cartControls={false}
									className={cartQuery.data?.data.order.orderItems[0]?.mouthguard?.mouthguardDesign?.parent?.['@id'] === d['@id'] ? 'bg-primary [&x4]:!text-primary-foreground [&>h4]:leading-5 [&>h4>span]:!text-primary-foreground/70' : ''}
									key={d.id}
									design={d}/>
							</div>
						</Button>)}

					</div>

					{affiliateDesign !== undefined
						? <DesignerModal onClose={() => setAffiliateDesign(undefined)} design={affiliateDesign}/>
						: ''}
				</div> : ''}

			{(affiliateDesignsQuery.isFetched && (affiliateDesignsQuery.data?.data['hydra:totalItems'] || 0) > 0) &&
				<span
					className='h-0.5 md:h-72 w-full md:w-0.5 bg-gradient-to-r md:bg-gradient-to-b from-transparent via-primary-400 to-transparent  my-auto md:mx-8 lg:mx-10 z-40'/>}

			<div className='grow w-full md:w-1/2 my-6 md:my-0  flex flex-col items-center'>
				{(affiliateDesignsQuery.isFetched && (affiliateDesignsQuery.data?.data['hydra:totalItems'] || 0) > 0) ?
					<h3 className=' mb-4 text-center text-muted'>Your designs</h3> : ''}
				<div className={cn(
					'flex w-full gap-[1rem] flex-wrap justify-center items-stretch',
				)}>
					{designsQuery.isSuccess && designsQuery.data?.data['hydra:member']
						.filter((design) => ('customer' in design))
						.map(d => <Button
							className={cn(
								'shrink-0 w-[calc(50%-1rem)] max-w-[195px] h-auto rounded-lg p-0 hover:no-underline cursor-pointer',
								cartQuery.data?.data.order.orderItems[0]?.mouthguard?.mouthguardDesign['@id'] === d['@id'] && 'border-2 border-primary',
							)}
							variant='link'
							key={d.id}
							onClick={() => onSelectMouthguard(d)}
							asChild>
							<div>
								<CustomerDesignCard
									cartControls={false}
									className={cn('w-full h-full', cartQuery.data?.data.order.orderItems[0]?.mouthguard?.mouthguardDesign['@id'] === d['@id'] ? 'bg-primary [&>h4]:!text-primary-foreground' : '')}
									design={d}
									onEdit={() => setOpenedDesign(d || null)}
								/>
							</div>
						</Button>)}

					<div className='w-[calc(50%-1rem)] max-w-[250px] self-stretch'>
						<div
							className='h-full text-center hover:scale-95 min-h-[220px] shrink-0 p-4 transition-all duration-200 rounded-lg backdrop-blur-xl overflow-hidden cursor-pointer group  border-2 border-primary-500/50 max-h-full  justify-center hover:bg-primary-500/10 items-center flex flex-col '
							onClick={() => setOpenedDesign(null)}>
							<p className='text-primary text-2xl font-semibold'>NEW DESIGN</p>
							<p className='mt-mini'>Click here to open the designer and create your own design</p>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div className='flex flex-col-reverse md:flex-row justify-between items-center mt-6 mx-2'>
			<Button variant='link' className='text-muted/80 md:pl-0' asChild>
				<a href={getClientEnv('customerAppUrl') + '/shop'}>
					<FontAwesomeIcon icon='chevron-left' className='mr-2'/>
					Skip onboarding
				</a>
			</Button>
			<Button
				onClick={() => onboardingContext.setOnboardingStep('account')}
				className='w-full md:w-fit'
				state={queryClient.isMutating({
					mutationKey: keysResolver(endpoints.cart.my.items.list),
				}) || cartQuery.isLoading || designsQuery.isLoading || affiliateDesignsQuery.isLoading ? 'loading' : 'idle'}
				disabled={!cartQuery.data?.data.order.orderItems.length}
				icon={<FontAwesomeIcon icon='chevron-right' className='mr-2'/>}
				iconPosition='right'>
				Account
			</Button>
		</div>

		{openedDesign !== undefined ?
			<DesignerModal onClose={() => setOpenedDesign(undefined)} design={openedDesign}/> : ''}
	</>;
}