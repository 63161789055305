import React from 'react';
import {AddressModel} from '@mgp-fe/shared/core-api/domain/address.ts';
import {cn} from '@mgp-fe/shared/utils';

export default function AddressFormatted({address, className}: AddressFormatedProps) {
	return <address className={cn('not-italic', className)}>
		{address.lineOne} <br/>
		{address.lineTwo ? <i className='opacity-70'>{address.lineTwo}</i>
			: <i className='opacity-20'>Empty 2nd line</i>}<br/>
		{address.city}, {address.region} {address.postCode} <br/>
		{address.countryCode}
	</address>;
}

interface AddressFormatedProps {
	address: AddressModel;
	className?: string;
}