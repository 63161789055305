import {useQuery, UseQueryOptions} from '@tanstack/react-query';
import {AxiosResponse} from 'axios';
import keysResolver from '@mgp-fe/shared/core-api/keysResolver.ts';
import endpoints from '@mgp-fe/shared/core-api/endpoints.ts';
import coreApiClient from '@mgp-fe/shared/core-api/client.ts';
import {CardsListResponse} from '@mgp-fe/shared/core-api/domain/stripe.ts';

export default function useStripeMyCardsListQuery(props?: ScanRequestsListQueryProps) {
	return useQuery<AxiosResponse<CardsListResponse>, {error: string}>({
		queryKey: keysResolver(endpoints.stripe.myCards),
		queryFn: () => coreApiClient.get(endpoints.stripe.myCards),
		...props?.options,
	});
}

interface ScanRequestsListQueryProps {
	options?: UseQueryOptions<AxiosResponse<CardsListResponse>, {error: string}>;
}