import {
	ArchivableListParams,
	CollectionParams,
	HydraError,
	HydraListResponse,
	Iri,
} from '@mgp-fe/shared/core-api/domain/base.ts';
import {useQuery, UseQueryOptions} from '@tanstack/react-query';
import {AxiosError, AxiosResponse} from 'axios';
import {MouthguardDesignCollectionModel} from '@mgp-fe/shared/core-api/domain/mouthguard.ts';
import keysResolver from '@mgp-fe/shared/core-api/keysResolver.ts';
import endpoints from '@mgp-fe/shared/core-api/endpoints.ts';
import coreApiClient from '@mgp-fe/shared/core-api/client.ts';

export default function useAthleteTeamsDesigns(props?: AthleteTeamsDesignsQueryProps) {
	return useQuery({
		queryKey: keysResolver(endpoints.mouthguardDesigns.athleteTeams, 'get', props?.params),
		queryFn: () => coreApiClient.get<HydraListResponse<MouthguardDesignCollectionModel>>(endpoints.mouthguardDesigns.athleteTeams, {
			params: props?.params,
		}),
		...(props?.options ? props.options : {}),
	});
}

interface AthleteTeamsDesignsQueryProps {
	params?: CollectionParams & ArchivableListParams & {
		name?: string;
		team?: string | string[];
		customer?: string | string[];
		affiliate?: Iri | Iri[];
		exists?: {
			team: boolean;
		};
		order?: {
			createdAt?: 'asc' | 'desc';
		},
		archive?: boolean;
	};
	options?: UseQueryOptions<
		AxiosResponse<HydraListResponse<MouthguardDesignCollectionModel>>,
		AxiosError<HydraError>
	>;
}