import React, {useMemo} from 'react';
import useAddressBookCollectionQuery from '@mgp-fe/shared/core-api/queries/address-book/list.ts';
import DefaultAddressCard from '@mgp-fe/shared/modules/account/components/address-book/DefaultAddressCard.tsx';
import AddressesList from './AddressesList';
import {Button} from '@mgp-fe/shared/ui/button.tsx';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import useToggle from '@mgp-fe/shared/hooks/useToggle.ts';
import Modal from '@mgp-fe/shared/ui/Modal.tsx';
import AddressForm from '@mgp-fe/shared/modules/account/components/address-book/AddressForm.tsx';
import useAuth from '@mgp-fe/shared/modules/auth/hooks/useAuth.ts';
import {UserWithAddresses} from '@mgp-fe/shared/core-api/domain/user.ts';

export default function AddressBookPage() {
	const {user} = useAuth();
	const addressBookQuery = useAddressBookCollectionQuery({
		params: {pagination: false},
	});
	const displayModal = useToggle({initialState: false});

	const customer = user as UserWithAddresses | undefined | null;
	const otherAddresses = useMemo(
		() => addressBookQuery.data?.data['hydra:member'].filter(
			ab => ![customer?.defaultShippingAddress?.id, customer?.defaultBillingAddress?.id]
				.includes(ab.address.id),
		) || [],
		[addressBookQuery.data?.data, customer],
	);

	return <div className='flex flex-col gap-medium mb-large'>
		<section>
			<h2>Default addresses</h2>
			<div className='grid md:grid-cols-2 gap-medium mt-small'>
				<DefaultAddressCard
					addNewHandler={() => displayModal.on()}
					addressBook={addressBookQuery.data?.data['hydra:member'] || []}
					type='defaultShippingAddress'/>
				<DefaultAddressCard
					addNewHandler={() => displayModal.on()}
					addressBook={addressBookQuery.data?.data['hydra:member'] || []}
					type='defaultBillingAddress'/>
			</div>
		</section>

		{otherAddresses.length > 0 ? <section>
			<h3 className='mt-0 mb-mini w-full'>Other addresses</h3>
			<AddressesList addressBook={otherAddresses}/>
		</section> : ''}

		{(addressBookQuery.data?.data['hydra:totalItems'] ?? 1) > 0 ? <Button
			onClick={displayModal.on}
			size='md'
			className='md:w-fit'
			icon={<FontAwesomeIcon icon='plus'/>}>
			Add new address
		</Button> : ''}

		{displayModal.state ? <Modal isOpen={displayModal.state} onClose={displayModal.off} title='Add new address'>
			<AddressForm onSuccess={displayModal.off} />
		</Modal> : ''}
	</div>;
}
