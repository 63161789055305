import {useMutation, UseMutationOptions, useQueryClient} from '@tanstack/react-query';
import keysResolver from '@mgp-fe/shared/core-api/keysResolver.ts';
import coreApiClient from '@mgp-fe/shared/core-api/client.ts';
import {AxiosError, AxiosResponse} from 'axios';
import endpoints from '@mgp-fe/shared/core-api/endpoints.ts';
import {InvitationCollectionModel, InvitationModel} from '@mgp-fe/shared/core-api/domain/invitation.ts';
import {AUTH_LOCAL_STORAGE_KEY, HydraError} from '@mgp-fe/shared/core-api/domain/base.ts';
import {CreateCustomerMutationData} from '@mgp-fe/shared/core-api/mutations/user/customer/create.ts';
import useLocalStorage from '@mgp-fe/shared/hooks/useLocalStorage.ts';

export default function useAcceptInvitationMutation(props?: UseAcceptInvitationMutationProps) {
	const queryClient = useQueryClient();
	const [, setToken] = useLocalStorage<string | null>(AUTH_LOCAL_STORAGE_KEY, null);

	return useMutation<AxiosResponse<InvitationModel>, AxiosError<HydraError>, AcceptInvitationMutationData>({
		mutationKey: keysResolver(endpoints.invitations.accept(''), 'put'),
		mutationFn: ({user, invitation}) => coreApiClient.put(endpoints.invitations.accept(invitation.id), {
			...(user ? {user: user} : {}),
		}),
		...props,
		onSuccess: async (result, variables, context) => {
			await queryClient.invalidateQueries(keysResolver(endpoints.invitations.list));
			await queryClient.invalidateQueries(keysResolver(endpoints.teams.list));
			result.data.user?.signToken && setToken(result.data.user?.signToken);
			props?.onSuccess?.(result, variables, context);
		},
	});
}

type UseAcceptInvitationMutationProps = UseMutationOptions<
	AxiosResponse<InvitationModel>,
	AxiosError<HydraError>,
	AcceptInvitationMutationData
>;

interface AcceptInvitationMutationData {
	invitation: InvitationCollectionModel;
	user?: CreateCustomerMutationData;
}