import React from 'react';
import SmokeSquare from '@mgp-fe/shared/assets/background/background-footer.png';
import NewsletterSignUp from '@mgp-fe/shared/ui/layout/footer/NewsletterSignUp.tsx';
import {resolveEnvVar} from '@mgp-fe/shared/utils';
import {SocialsFragment} from '@mgp-fe/shared/cms-graphql/queries/components/generated/socials.ts';
import {FooterLinkItemsFragment} from '@mgp-fe/shared/cms-graphql/queries/components/generated/footerLinkItems.ts';

interface DefaultFooterProps {
	footerLinkItems?: FooterLinkItemsFragment[];
	socials?: SocialsFragment[];
	termsAndConditionsUrl?: string;
}

export default function DefaultFooter({footerLinkItems, socials, termsAndConditionsUrl}: DefaultFooterProps) {
	// we are using this hack as we have Next.JS and React mixed in the same repository
	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore
	const background = SmokeSquare.src || SmokeSquare || '';

	return <footer className='relative flex flex-col'>
		<div className='absolute -z-10 w-full h-full'>
			<div className='absolute -z-10 top-0 bottom-auto left-0 right-0 m-auto max-w-[1920px] w-full h-[464px] bg-bottom bg-auto' style={{
				backgroundImage: `url("${background}")`,
			}}/>
			<div
				className='absolute -z-10 top-0 bottom-auto left-0 right-0 m-auto max-w-[1920px] w-full h-[464px] bg-gradient-to-r from-background via-black/10 to-background to-95%'/>
			<div
				className='absolute -z-10 top-0 bottom-auto left-0 right-0 m-auto max-w-[1920px] w-full h-[464px] bg-gradient-to-b from-background to-black/0 to-50%'/>
		</div>

		<div className='container flex flex-col items-center mt-20 grow'>
			<div className='grid grid-cols-2 lg:grid-cols-3 items-center justify-between w-full'>
				<ul className='flex order-2 lg:order-1 flex-col gap-y-4'>
					{footerLinkItems?.map(link => <li key={link.id}>
						<a className='text-light font-medium' href={link.url}>{link.label}</a>
					</li>,
					)}
				</ul>
				<div className='flex order-1 col-span-2 lg:col-span-1 lg:order-2 flex-col items-center'>
					<p className='text-base text-light font-normal text-center'>Don&lsquo;t sleep on all the latest from
						Mouthguard
						Club</p>
					<p className='h3 text-primary'>Join our newsletter</p>
					<NewsletterSignUp/>
				</div>
				<ul className='flex order-3 shrink-0 gap-5'>
					{socials?.map(social => <li key={social.id} className='first:ml-auto'>
						<a href={social.url} className='text-primary flex w-fit h-fit gap-2 items-center'>
							<img alt={'icon number ' + social.id} className='h-8 w-8' src={(social?.icon?.data?.attributes?.url?.startsWith('http') ? '' : resolveEnvVar('cmsUrl')) + social?.icon?.data?.attributes?.url}/>
						</a>
					</li>)}
				</ul>
			</div>
		</div>

		<div className='container py-6 flex flex-col sm:flex-row gap-4 items-center justify-between mt-large'>
			<p className='text-sm font-normal'>
				&copy; 2023 Mouthguard Club
			</p>

			{termsAndConditionsUrl ? <p className='text-sm [&>a]:text-foreground font-normal'>
				<a href={termsAndConditionsUrl} className='text-sm font-normal'>Terms & Conditions</a>
			</p>: ''}
		</div>
	</footer>;
}
