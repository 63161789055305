'use client';

import {useMutation, UseMutationOptions, useQueryClient} from '@tanstack/react-query';
import {AxiosError, AxiosResponse} from 'axios';
import keysResolver from '@mgp-fe/shared/core-api/keysResolver.ts';
import endpoints from '@mgp-fe/shared/core-api/endpoints';
import coreApiClient from '@mgp-fe/shared/core-api/client.ts';
import {User} from '@mgp-fe/shared/core-api/domain/user.ts';
import {AUTH_LOCAL_STORAGE_KEY, HydraError} from '@mgp-fe/shared/core-api/domain/base.ts';
import {z} from 'zod';
import {phoneNumberSchema} from '@mgp-fe/shared/core-api/schemas/phone-number.ts';
import useLocalStorage from '@mgp-fe/shared/hooks/useLocalStorage.ts';

export default function useUpdateMeMutation(props?: UseUpdateMeMutationProps) {
	const queryClient = useQueryClient();
	const [, setToken] = useLocalStorage<string|null>(AUTH_LOCAL_STORAGE_KEY, null);

	return useMutation<AxiosResponse<User>, AxiosError<HydraError>, UpdateMeMutationData>(
		keysResolver(endpoints.me.index, 'patch'),
		d => coreApiClient.patch<User>(endpoints.me.index, d),
		{
			...props,
			onSuccess: async (result, variables, context) => {
				result.data.signToken && setToken(result.data.signToken);
				await queryClient.invalidateQueries(keysResolver(endpoints.me.index));
				props?.onSuccess?.(result, variables, context);
			},
		},
	);
}

type UseUpdateMeMutationProps = UseMutationOptions<
	AxiosResponse<User>,
	AxiosError<HydraError>,
	UpdateMeMutationData
>;

export interface UpdateMeMutationData {
	email?: string | null,
	phone?: string | null,
	name?: string | null,
	dateOfBirth?: string | null,
}

export const UpdateMeMutationDataSchema = z.object({
	email: z.string().email(),
	phone: phoneNumberSchema.optional(),
	name: z.string().refine(v => v.trim().split(' ').length > 1, {message: 'Please enter your full name'}),
	dateOfBirth: z.string().optional(),
});