/* eslint-disable */
// @ts-nocheck
import type * as Types from '@mgp-frontend/shared/cms-graphql/domain/types';

import { gql } from '@apollo/client';
export type SocialsFragment = { __typename?: 'ComponentSocialsSocials', id: string, url: string, icon: { __typename?: 'UploadFileEntityResponse', data?: { __typename?: 'UploadFileEntity', attributes?: { __typename?: 'UploadFile', url: string } | null } | null } };

export const SocialsFragmentDoc = gql`
    fragment Socials on ComponentSocialsSocials {
  id
  url
  icon {
    data {
      attributes {
        url
      }
    }
  }
}
    `;