import {CollectionParams, HydraListResponse, Iri} from '@mgp-fe/shared/core-api/domain/base.ts';
import {useQuery, UseQueryOptions} from '@tanstack/react-query';
import {AxiosResponse} from 'axios';
import keysResolver from '@mgp-fe/shared/core-api/keysResolver.ts';
import endpoints from '@mgp-fe/shared/core-api/endpoints.ts';
import coreApiClient from '@mgp-fe/shared/core-api/client.ts';
import {VoucherCollectionModel} from '@mgp-fe/shared/core-api/domain/voucher.ts';

export default function useVouchersListQuery(props?: VoucherListQueryProps) {
	return useQuery<VouchersListQueryResponse>({
		queryKey: keysResolver(endpoints.vouchers.list, 'get', props?.params),
		queryFn: () => coreApiClient.get(endpoints.vouchers.list, {
			params: props?.params,
		}),
		...props?.options,
	});
}

export interface VoucherListQueryProps {
	params?: CollectionParams & {
		email?: string;
		code?: string;
		search_voucher?: string;
		'product.product.id'?: string;
		'product.product'?: string[];
		partnerLocation?: Iri;
		exists?: {
			assignedTo?: boolean;
			orderItem?: boolean;
			usedAt?: boolean;
		}
	};
	options?: UseQueryOptions<VouchersListQueryResponse>;
}

export type VouchersListQueryResponse = AxiosResponse<HydraListResponse<VoucherCollectionModel>>;