import React, {useState} from 'react';
import {CardCvcElement, CardExpiryElement, CardNumberElement, useElements, useStripe} from '@stripe/react-stripe-js';
import {StripeCardNumberElementOptions, StripeError} from '@stripe/stripe-js';
import coreApiClient from '@mgp-fe/shared/core-api/client.ts';
import {useQueryClient} from '@tanstack/react-query';
import keysResolver from '@mgp-fe/shared/core-api/keysResolver.ts';
import endpoints from '@mgp-fe/shared/core-api/endpoints.ts';
import {SetupIntentResponse} from '@mgp-fe/shared/core-api/domain/stripe.ts';
import {Button} from '@mgp-fe/shared/ui/button';
import useSetDefaultCardMutation from '@mgp-fe/shared/core-api/mutations/stripe/set-default-card.ts';
import {PaymentMethod} from '@stripe/stripe-js/types/api/payment-methods';
import {Alert, AlertDescription, AlertTitle} from '@mgp-fe/shared/ui/alert.tsx';


export default function AddStripeCardForm({onSuccess, partnerId, partnerType}: AddStripeCardFormProps) {
	const queryClient = useQueryClient();
	const stripe = useStripe();
	const elements = useElements();
	const setDefaultCardMutation = useSetDefaultCardMutation();
	const [isSavingCard, setIsSavingCard] = useState(false);
	const [stripeError, setStripeError] = useState<StripeError | null>(null);
	const [setAsDefault, setSetAsDefault] = useState<boolean>(true);

	const saveCard = async () => {
		setIsSavingCard(true);
		try {
			const cardElement = elements?.getElement(CardNumberElement);
			if (!cardElement) throw new Error('Card element not found');

			const setupIntentResponse = await coreApiClient.get<SetupIntentResponse>(
				partnerId !== undefined && partnerType !== undefined ?
					endpoints.stripe.partnerSetupIntent(partnerType, partnerId)
					: endpoints.stripe.mySetupIntent,
			);

			const resp = await stripe!.confirmCardSetup(
				setupIntentResponse.data.setupIntent!.client_secret!,
				// eslint-disable-next-line camelcase
				{payment_method: {card: cardElement}},
			);
			if (resp.error) {
				console.log(resp.error);
				throw resp.error;
			}
			setStripeError(null);
			if (setAsDefault && resp.setupIntent && typeof resp.setupIntent.payment_method === 'string') {
				await setDefaultCardMutation.mutateAsync({
					cardId: resp.setupIntent.payment_method,
					partnerId,
					partnerType,
				});
			}
			// const partnerCheckoutQuery = queryClient.query;
			await Promise.all([
				queryClient.invalidateQueries(keysResolver(endpoints.stripe.myCards)),
				//as we do not have loc id if loc is billed to org, we need to invalidate all cards
				queryClient.refetchQueries({
					predicate: q => (q.queryKey as string[])[0].includes(endpoints.stripe.partnerCheckoutCards('')) && (q.queryKey as string[])[0].length > endpoints.stripe.partnerCheckoutCards('').length,
					type: 'active',
				}),
			]);

			onSuccess?.(resp?.setupIntent?.payment_method);
		} catch (error) {
			if (typeof error === 'string') {
				setStripeError({message: error} as StripeError);
			} else {
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				setStripeError(error);
			}
		} finally {
			setIsSavingCard(false);
		}
	};

	return <>
		<div className='flex flex-col gap-small'>
			<label>
				<span className='block mb-mini'>Card Number</span>

				<CardNumberElement
					className='bg-light rounded-lg p-small text-dark h-12'
					options={{...stripeStyleOptions, showIcon: true, iconStyle: 'solid'}}
					onReady={(e) => {
						e.focus();
					}}/>
			</label>

			<fieldset className='flex flex-col sm:flex-row gap-small'>
				<label className='grow'>
					<span className='block mb-mini'>Expiration Date</span>
					<CardExpiryElement
						className='bg-light rounded-lg p-small text-dark h-12'
						options={stripeStyleOptions}/>
				</label>

				<label className='grow ml-0'>
					<span className='block mb-mini'>Security Code (CVC)</span>
					<CardCvcElement className='bg-light rounded-lg p-small text-dark h-12'
						options={stripeStyleOptions}/>
				</label>
			</fieldset>

			<label className='inline-flex items-center gap-small text-muted '>
				<input type='checkbox' checked={setAsDefault} onChange={() => setSetAsDefault(!setAsDefault)}/>
				<span>Set as default card</span>
			</label>

			{stripeError && <Alert variant='destructive' className='my-0'>
				<AlertTitle>Error</AlertTitle>
				<AlertDescription>
					{stripeError.message || 'Unknown error'}
				</AlertDescription>
			</Alert>}
		</div>
		<Button onClick={saveCard} state={isSavingCard ? 'loading' : 'idle'} className='w-full mt-medium'>
			Save card
		</Button>
	</>;
}

interface AddStripeCardFormProps {
	partnerType?: 'location' | 'organization';
	partnerId?: string;
	onSuccess?: (paymentMethod?: PaymentMethod | null | string) => void;
}

const stripeStyleOptions: StripeCardNumberElementOptions = {
	style: {
		base: {fontSize: '16px'},
		empty: {fontSize: '16px'},
		complete: {fontSize: '16px'},
		invalid: {fontSize: '16px'},
	},
};