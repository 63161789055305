import React, {useEffect, useMemo, useState} from 'react';
import {PartnerLocationsMap, SearchForm} from '@mgp-fe/shared/modules/partner-locator';
import {
	PartnerLocationsSearchFormValues,
} from '@mgp-fe/shared/modules/partner-locator/components/PartnerLocationsSearchForm.tsx';
import {useNavigate, useSearchParams} from 'react-router-dom';
import FormCardBackground from '@mgp-fe/shared/assets/background/background-footer.png';
import routes from '@mgp-fe/customer/router/routes.ts';
import {PartnerLocationPublicCollectionModel} from '@mgp-fe/shared/core-api/domain/partner/location.ts';
import filterLocations from '@mgp-fe/shared/modules/partner-locator/utils/filterLocations.ts';
import usePartnerLocationsListPublicQuery from '@mgp-fe/shared/core-api/queries/partner-location/public.ts';
import PartnerLocationsList from '@mgp-fe/shared/modules/partner-locator/components/PartnerLocationsList.tsx';

export default function PartnerLocationsSection() {
	const navigate = useNavigate();
	const [getSearchParam] = useSearchParams();
	const partnerLocationsQuery = usePartnerLocationsListPublicQuery();

	const [filteredLocations, setFilteredLocations] = useState<PartnerLocationPublicCollectionModel[] | undefined>();

	const formValues = useMemo<PartnerLocationsSearchFormValues>(() => ({
		zipCode: getSearchParam.get('zipCode') || '',
		radius: parseInt(getSearchParam.get('radius') || '50'),
		useMyLocation: getSearchParam.get('useMyLocation') === '1',
	}), [getSearchParam]);

	useEffect(() => {
		filterLocations(partnerLocationsQuery.data?.data['hydra:member'], formValues)
			.then(v => setFilteredLocations(v));
	}, [partnerLocationsQuery.data?.data, formValues]);

	return <>
		<section className='flex flex-col-reverse md:flex-row items-center justify-stretch'>
			<div className='w-full md:w-1/2 max-h-fit mt-4 lg:mt-0 rounded-3xl overflow-hidden lg:rounded-none'>
				{<PartnerLocationsMap locations={filteredLocations} formValues={formValues}/>}
			</div>

			<div className='sm:w-2/3 md:w-1/2 relative z-30 rounded-3xl overflow-hidden'>
				<div
					style={{backgroundImage: `url(${FormCardBackground})`}}
					className='absolute z-0 opacity-10 top-0 left-0 w-full h-full bg-center bg-cover bg-no-repeat'/>
				<div className='absolute z-10 opacity-10 blur-3xl bg-secondary top-0 left-0 w-full h-full'/>
				<div className='nested-z-40 p-5 sm:p-6 md:p-12 xl:p-16 text-center text-white'>
					<SearchForm
						defaultValues={formValues}
						onSubmit={v => navigate(
							`${routes.scans.index}?zipCode=${v.zipCode}&radius=${v.radius}&useMyLocation=${v.useMyLocation ? 1 : 0}`,
						)}/>
				</div>
			</div>
		</section>

		<PartnerLocationsList partnerLocations={filteredLocations} />
	</>;
}
