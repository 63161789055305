'use client';

import {useQuery, UseQueryOptions} from '@tanstack/react-query';
import endpoints from '@mgp-fe/shared/core-api/endpoints.ts';
import keysResolver from '@mgp-fe/shared/core-api/keysResolver.ts';
import coreApiClient from '@mgp-fe/shared/core-api/client.ts';
import {AxiosError, AxiosResponse} from 'axios';
import {
	ArchivableListParams,
	CollectionParams,
	HydraError,
	HydraListResponse, Iri,
} from '@mgp-fe/shared/core-api/domain/base.ts';
import {MouthguardDesignCollectionModel} from '@mgp-fe/shared/core-api/domain/mouthguard.ts';

export default function useMouthguardDesignsListQuery(props?: MouthguardDesignsListQueryProps) {
	return useQuery(
		keysResolver(endpoints.mouthguardDesigns.list, 'get', props?.params),
		() => coreApiClient.get(endpoints.mouthguardDesigns.list, {
			params: props?.params,
		}),
		{
			staleTime: Infinity,
			...props?.options,
		},
	);
}

interface MouthguardDesignsListQueryProps {
	params?: CollectionParams & ArchivableListParams & {
		name?: string;
		team?: string | string[];
		customer?: string | string[];
		affiliate?: Iri | Iri[];
		exists?: {
			team: boolean;
		};
		order?: {
			createdAt?: 'asc' | 'desc';
		},
		archive?: boolean;
	};
	options?: UseQueryOptions<
		AxiosResponse<HydraListResponse<MouthguardDesignCollectionModel>>,
		AxiosError<HydraError>
	>;
}