import keysResolver from '@mgp-fe/shared/core-api/keysResolver.ts';
import {useMutation, UseMutationOptions} from '@tanstack/react-query';
import {AxiosError, AxiosResponse} from 'axios';
import {HydraError} from '@mgp-fe/shared/core-api/domain/base.ts';
import endpoints from '@mgp-fe/shared/core-api/endpoints.ts';
import coreApiClient from '@mgp-fe/shared/core-api/client.ts';
import {OAuthModel, OAuthType} from "@mgp-fe/shared/core-api/domain/oauth.ts";

export default function useCreateOAuthMutation(props?: UseCreateOAuthMutationProps) {
    return useMutation({
        mutationKey: keysResolver(endpoints.me.oauth.index, 'post'),
        mutationFn: rq => coreApiClient.post<OAuthModel>(endpoints.me.oauth.index, rq),
        ...props,
    });
}

type UseCreateOAuthMutationProps = UseMutationOptions<
    AxiosResponse<OAuthModel>,
    AxiosError<HydraError>,
    CreateOAuthRequest
>;

export type CreateOAuthRequest = {
    type: OAuthType,
    googleCredential: string;
};
