import React from 'react';
import MemberZoneLayout from '@mgp-fe/customer/modules/common/ui/MemberZoneLayout.tsx';
import {Button} from '@mgp-fe/shared/ui/button.tsx';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import CartContentTable from '@mgp-fe/shared/modules/shop/components/CartContentTable.tsx';
import useCartMyQuery from '@mgp-fe/shared/core-api/queries/cart/my.tsx';
import {Link} from 'react-router-dom';
import useToggle from '@mgp-fe/shared/hooks/useToggle.ts';
import ClaimVoucherForm from '@mgp-fe/shared/modules/vouchers/components/ClaimVoucherForm.tsx';
import Modal from '@mgp-fe/shared/ui/Modal.tsx';
import AvailableVouchersAlert from '@mgp-fe/customer/modules/shop/components/AvailableVouchersAlert.tsx';
import RedeemCouponForm from '@mgp-fe/shared/modules/promotions/RedeemCouponForm.tsx';
import AppliedCoupons from '@mgp-fe/shared/modules/promotions/AppliedCoupons.tsx';
import AvailablePromotions from '@mgp-fe/shared/modules/promotions/AvailablePromotions.tsx';

export default function CartPage() {
	const cartQuery = useCartMyQuery();
	const displayClaimVoucherModal = useToggle();
	const displayManageCouponsModal = useToggle();

	return <MemberZoneLayout>
		<main className='container flex flex-col gap-medium pb-large'>
			<div className='flex flex-col gap-small'>
				<AvailableVouchersAlert/>
				<h1>Your shopping cart</h1>
			</div>

			<CartContentTable/>
			<AppliedCoupons coupons={cartQuery.data?.data.order.coupons}/>
			<AvailablePromotions/>

			<div className='flex flex-wrap items-stretch justify-between w-full gap-small'>
				<Button
					onClick={displayClaimVoucherModal.on}
					size='lg'
					className='w-full sm:w-auto'
					variant='outline'>
                    Claim voucher
				</Button>
				<Button
					onClick={displayManageCouponsModal.on}
					size='lg'
					className='w-full sm:w-auto'
					variant='outline'>
                    Add promocode
				</Button>
				<div className='grow'/>
				<Button
					size='lg'
					className='w-full sm:w-auto'
					asChild
					disabled={cartQuery.data?.data.order.orderItems.length === 0}>
					<Link to='/checkout'>
                        Checkout
						<FontAwesomeIcon icon='chevron-right'/>
					</Link>
				</Button>
			</div>


			<Modal
				title='Claim voucher'
				isOpen={displayClaimVoucherModal.state}
				onClose={displayClaimVoucherModal.off}>
				<ClaimVoucherForm onSuccess={displayClaimVoucherModal.off}/>
			</Modal>
			<Modal
				title='Add Promocode'
				isOpen={displayManageCouponsModal.state}
				onClose={displayManageCouponsModal.off}>
				<div className='grid gap-medium'>
					<RedeemCouponForm onSuccess={displayManageCouponsModal.off}/>
				</div>
			</Modal>
		</main>
	</MemberZoneLayout>;
}
