import Skeleton from '@mgp-fe/shared/ui/skeleton.tsx';
import React from 'react';

export function IframeSkeleton() {
	return (
		<div className='w-full flex flex-col gap-y-medium'>
			<div className='flex justify-start overflow-x-scroll scrollbar-hide bg-transparent'>
				<Skeleton className='w-24 h-10 rounded mr-2'/>
				<Skeleton className='w-24 h-10 rounded mr-2'/>
				<Skeleton className='w-24 h-10 rounded mr-2'/>
			</div>

			<div>
				<div className='p-medium'>
					<Skeleton className='w-full h-4 mb-4'/>
					<Skeleton className='w-full h-4 mb-4'/>
					<Skeleton className='w-full h-4 mb-4'/>
					<Skeleton className='w-full h-4 mb-4'/>
				</div>
			</div>

			<section className='w-full'>
				<Skeleton className='w-1/2 h-8 mb-4'/>
				<div className='w-full'>
					<div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
						{[1, 2].map((_, index) => (
							<div className='!flex w-full place-items-center nowrap duration-300' key={index}>
								<div
									className='relative flex flex-col py-10 w-full h-full px-6 justify-between border border-secondary-500/20 rounded-lg shadow bg-background'>
									<Skeleton className='w-full h-6 mb-4'/>
									<Skeleton className='w-full h-20 mb-4'/>
									<Skeleton className='w-1/2 h-6'/>
								</div>
							</div>
						))}
					</div>
				</div>
			</section>
		</div>
	);
}