import {OrderModel} from '@mgp-fe/shared/core-api/domain/order.ts';
import {
	CartCheckoutCompleteRequest,
	CheckoutCompleteOption,
} from '@mgp-fe/shared/core-api/mutations/cart/checkout-complete.ts';
import useScansListQuery from '@mgp-fe/shared/core-api/queries/scan/list.ts';
import React, {useEffect, useMemo} from 'react';
import {humanizeEnumValue} from '@mgp-fe/shared/utils/humanize-string.ts';
import DateFormatted from '@mgp-fe/shared/modules/formatters/DateFormatted.tsx';
import {Tabs, TabsContent, TabsList, TabsTrigger} from '@mgp-fe/shared/ui/tabs.tsx';


export default function OrderScanOption({order, changeHandler, option}: {
	order?: OrderModel,
	changeHandler: (k: CartCheckoutCompleteRequest[keyof CartCheckoutCompleteRequest], v: CartCheckoutCompleteRequest[keyof CartCheckoutCompleteRequest]) => void,
	option: CheckoutCompleteOption,
}) {
	const scansQuery = useScansListQuery({
		params: {state: ['requested', 'waiting', 'synced'], itemsPerPage: 1},
	});
	const latestScan = useMemo(
		() => scansQuery.data?.data['hydra:member'][0],
		[scansQuery.data?.data],
	);

	useEffect(() => {
		if (!scansQuery.data) return;
		changeHandler('option', latestScan ? 'existing_scan' : 'new_impression_kit_scan');
		latestScan && changeHandler('scan', latestScan['@id']);
	}, [scansQuery.data, latestScan]);

	const scanType = latestScan?.provider === 'impression_kit'
		? 'impression'
		: `${humanizeEnumValue(latestScan?.provider || '')} scan`;
	const scanDate = latestScan?.dateOfScan
		? <>&nbsp;from your order on <DateFormatted date={latestScan.dateOfScan} showDateOnly/>&nbsp;</> : '';

	return <div
		className={order?.orderItems.filter(oi => !!oi.mouthguard).length === 0 ? 'invisible' : ''}>

		<h5 className='mb-small font-normal'>Select a scan for the mouthguard in your cart:</h5>

		<Tabs
			value={option}
			onValueChange={v => changeHandler('option', v as CheckoutCompleteOption)}
			className='w-fit'
			defaultValue={'new_impression_kit_scan'}>
			<TabsList className='w-fit h-auto flex-wrap'>
				<TabsTrigger value='new_impression_kit_scan'>Get new impression kit</TabsTrigger>
				<TabsTrigger value='existing_scan' disabled={!latestScan}>
					{latestScan ? `Use Existing ${latestScan.provider === 'impression_kit' ? 'impression' : 'scan'}` : '(No scan / impression available)'}
				</TabsTrigger>
			</TabsList>

			<TabsContent value='new_impression_kit_scan'>
				<p>You will receive an impression kit with instructions on how to take your dental impression at home.
					Once we receive the impression kit back we will proceed with production.</p>
			</TabsContent>

			<TabsContent value='existing_scan'>
				<p>We will use your <strong className='text-primary'>{scanType} {scanDate}</strong> for production of
					your new mouthguard.</p>

				{latestScan?.state !== 'synced' ? <p className='text-primary'>
					<strong>Note:</strong> We did not receive your <strong>{scanType}</strong> yet.&nbsp;
					{scanType === 'impression' ? 'Check your mailbox.'
						: 'You can contact your doctor if they already sent it to us.'}
				</p> : ''}
			</TabsContent>
		</Tabs>
	</div>;
}