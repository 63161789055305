import React, {useEffect, useMemo, useState} from 'react';
import CheckoutAddressesList from '@mgp-fe/shared/modules/shop/components/checkout/CheckoutAddressesList.tsx';
import {AddressModel} from '@mgp-fe/shared/core-api/domain/address.ts';
import {useNavigate} from 'react-router-dom';
import useAuth from '@mgp-fe/shared/modules/auth/hooks/useAuth.ts';
import {UserWithAddresses} from '@mgp-fe/shared/core-api/domain/user.ts';
import useCartMyQuery from '@mgp-fe/shared/core-api/queries/cart/my.tsx';
import useStripeMyCardsListQuery from '@mgp-fe/shared/core-api/queries/stripe/my-cards.ts';
import useToggle from '@mgp-fe/shared/hooks/useToggle.ts';
import Modal from '@mgp-fe/shared/ui/Modal.tsx';
import AddStripeCardForm from '@mgp-fe/shared/modules/account/components/payment-methods/AddStripeCardForm.tsx';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button} from '@mgp-fe/shared/ui/button.tsx';
import routes from '@mgp-fe/shared/modules/shop/components/checkout/routes.ts';
import useCartCheckoutPaymentMutation from '@mgp-fe/shared/core-api/mutations/cart/billing.ts';
import SmallPaymentMethodCard, {
	SmallPaymentMethodCardSkeleton,
} from '@mgp-fe/shared/modules/shop/components/checkout/SmallPaymentMethodCard.tsx';
import usePartnerLocationsListQuery from '@mgp-fe/shared/core-api/queries/partner-location/list.ts';
import useStripePartnerCheckoutCardsListQuery from '@mgp-fe/shared/core-api/queries/stripe/partner-checkout-cards.ts';
import ClaimVoucherForm from '@mgp-fe/shared/modules/vouchers/components/ClaimVoucherForm.tsx';
import {Alert, AlertDescription, AlertTitle} from '@mgp-fe/shared/ui/alert.tsx';
import MoneyFormatted from '@mgp-fe/shared/modules/formatters/MoneyFormatted.tsx';
import usePartnerOrganizationsListQuery from '@mgp-fe/shared/core-api/queries/partner-organization/list.ts';

export default function BillingStepPage() {
	const navigate = useNavigate();
	const {user} = useAuth<UserWithAddresses>();
	const organizationsQuery = usePartnerOrganizationsListQuery({
		options: {enabled: user?.['@type'] === 'Partner'},
	});
	const myCartQuery = useCartMyQuery();
	const order = useMemo(() => myCartQuery.data?.data.order, [myCartQuery.data?.data.order]);
	const myCardsQuery = useStripeMyCardsListQuery({
		options: {keepPreviousData: true},
	});
	const partnerLocationQuery = usePartnerLocationsListQuery();
	const paymentMutation = useCartCheckoutPaymentMutation({
		onSuccess: () => navigate(routes.summary.index, {replace: true}),
	});

	const [address, setAddress] = useState<AddressModel | null>(null);
	const [paymentMethod, setPaymentMethod] = useState<string | undefined>(undefined);
	const displayAddCardModal = useToggle({initialState: false});
	const displayClaimVoucherModal = useToggle();

	useEffect(() => {
		!address && setAddress(order?.shippingAddress || user?.defaultShippingAddress || null);
	}, [address, order?.shippingAddress, user?.defaultShippingAddress]);
	useEffect(() => {
		if (order && myCardsQuery.data?.data && !paymentMethod) {
			// initialize payment method with order payment method
			setPaymentMethod(order?.payment?.paymentMethodId || myCardsQuery.data?.data.defaultPaymentMethod || undefined);
		}
	}, [myCardsQuery.data?.data, order, paymentMethod]);

	const selectedLocation = partnerLocationQuery.data?.data['hydra:member'].find(
		l => myCartQuery.data?.data.order.partnerLocation?.id === l.id,
	);
	const partnerCardsListQuery = useStripePartnerCheckoutCardsListQuery({
		locationId: selectedLocation?.id,
	});
	const billedPersonally = useMemo(() => !myCartQuery.data?.data.order.partnerLocation?.id, [myCartQuery.data?.data.order.partnerLocation?.id]);
	const canAddCard = useMemo(() => billedPersonally || !selectedLocation?.billToOrganization
			|| organizationsQuery.data?.data['hydra:member'].find(o => o.id === myCartQuery.data?.data.order.partnerOrganization?.id),
	[billedPersonally, myCartQuery.data?.data.order.partnerOrganization?.id, organizationsQuery.data?.data, selectedLocation?.billToOrganization]);

	const confirmHandler = () => {
		address && paymentMutation.mutate({
			billingAddress: address['@id'],
			paymentMethodType: 'card',
			cardId: (myCartQuery.data?.data.order.totalAmount.amount ?? 0) > 0 ? paymentMethod : null,
		});
	};

	return <div className='grid md:grid-cols-4 gap-medium'>
		<section className='col-span-4 md:col-span-2 flex flex-col gap-small'>
			<h3>Billing Address</h3>
			<CheckoutAddressesList
				selectAddress={setAddress}
				selectedAddress={address || order?.billingAddress || user?.defaultBillingAddress}/>
		</section>

		<section className='col-span-4 md:col-span-2 flex flex-col gap-small'>
			<h3>Payment Method</h3>

			{(myCardsQuery.isFetching || partnerCardsListQuery.isFetching) && (!myCardsQuery.data || !partnerCardsListQuery.data)
				? [...Array(3)].map((_, i) => <SmallPaymentMethodCardSkeleton key={i}/>)
				: ''}

			{(myCartQuery.data?.data.order.totalAmount.amount ?? 1) === 0
				? <Alert className='mt-0'>
					<AlertTitle>
						No payment method required
					</AlertTitle>
					<AlertDescription>
						Payment method is not required as you used voucher / promo code and amount to pay
						is <MoneyFormatted money={{amount: 0, currency: 'USD'}}/>.
					</AlertDescription>
				</Alert>
				: <>
					{billedPersonally
						? myCardsQuery.data?.data.paymentMethods.map(pm => <SmallPaymentMethodCard
							key={pm.id}
							paymentMethod={pm}
							onClick={() => setPaymentMethod(pm.id)}
							isSelected={paymentMethod === pm.id}/>)
						: ''}
					{!billedPersonally && !selectedLocation?.billToOrganization && (partnerCardsListQuery.data?.data.partnerLocation?.paymentMethods || []).length > 0 ?
						<h4 className='text-secondary'>Location cards</h4> : ''}
					{!selectedLocation?.billToOrganization && partnerCardsListQuery.data?.data.partnerLocation?.paymentMethods.map(pm =>
						<SmallPaymentMethodCard
							key={pm.id}
							paymentMethod={pm}
							onClick={() => setPaymentMethod(pm.id)}
							isSelected={paymentMethod === pm.id}/>)}
					{!billedPersonally && (partnerCardsListQuery.data?.data.partnerOrganization?.paymentMethods || []).length > 0 ?
						<h4 className='text-secondary'>Organization cards</h4> : ''}
					{partnerCardsListQuery.data?.data.partnerOrganization?.paymentMethods.map(pm =>
						<SmallPaymentMethodCard
							key={pm.id}
							paymentMethod={pm}
							onClick={() => setPaymentMethod(pm.id)}
							isSelected={paymentMethod === pm.id}/>)}

					{canAddCard
						? <Button className='w-fit' size='md' variant='outline' onClick={displayAddCardModal.on}>
							<FontAwesomeIcon icon='plus'/>
							add card
						</Button>
						: <Alert variant='warning'>
							<AlertDescription>
								You do not have permissions to add cards to this location. Please contact your
								organization manager.
							</AlertDescription>
						</Alert>}

					{user?.['@type'] === 'Customer' ? <Button
						onClick={displayClaimVoucherModal.on}
						size='md'
						className='w-fit !pl-0'
						variant='link'>
						Claim voucher
					</Button> : ''}
				</>}

			<Modal onClose={displayAddCardModal.off} isOpen={displayAddCardModal.state} title='Add new card'>
				<AddStripeCardForm
					partnerId={selectedLocation?.billToOrganization ? myCartQuery.data?.data.order.partnerOrganization?.id : myCartQuery.data?.data.order.partnerLocation?.id}
					partnerType={selectedLocation?.billToOrganization ? 'organization' : 'location'}
					onSuccess={(pm) => {
						displayAddCardModal.off();
						if (!pm) return;
						setPaymentMethod(typeof pm === 'string' ? pm : pm.id);
					}}/>
			</Modal>
			<Modal title='Claim voucher' isOpen={displayClaimVoucherModal.state} onClose={displayClaimVoucherModal.off}>
				<ClaimVoucherForm onSuccess={displayClaimVoucherModal.off}/>
			</Modal>

		</section>

		<Button
			onClick={() => navigate(routes.shipping.index, {replace: true})}
			variant='link'
			icon={<FontAwesomeIcon icon='chevron-left' className='mr-2'/>}
			className='w-full sm:w-fit md:text-left pl-0 text-muted/70 col-span-4 sm:col-span-2 sm:mt-medium'>
			Shipping
		</Button>
		<Button
			onClick={confirmHandler}
			disabled={!address || (!paymentMethod && (myCartQuery.data?.data.order.totalAmount.amount ?? 1) > 0) || myCardsQuery.isFetching}
			state={paymentMutation.status}
			className='w-full sm:w-fit flex-row-reverse gap-mini col-span-4 sm:col-span-2 sm:mt-medium sm:ml-auto'
			icon={<FontAwesomeIcon icon='chevron-right' className='mr-2'/>}>
			Confirm
		</Button>
	</div>;
}
