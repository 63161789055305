import React from 'react';
import {NavigationItem} from './index.ts';
import {Card} from '@mgp-fe/shared/ui/card.tsx';
import {cn} from '@mgp-fe/shared/utils';

export default function SideBarNavigation({navigationItems, renderItem, currentPath}: SubNavigationProps) {
	return <Card spacing='default'>
		<nav className='flex flex-col gap-medium'>
			{navigationItems.map((item, index) => {
				const className = cn(
					'flex items-center gap-small !text-large',
					currentPath.includes(item.url || '') ? 'text-primary underline underline-offset-8' : 'text-muted opacity-70',
				);

				return <React.Fragment key={index}>
					{renderItem(item, className)}
				</React.Fragment>;
			})}
		</nav>
	</Card>;
}

interface SubNavigationProps {
	currentPath: string;
	navigationItems: NavigationItem[];
	renderItem: (item: NavigationItem, className: string) => React.ReactNode;
}