import React from 'react';
import {parsePhoneNumber} from 'react-phone-number-input';
import flags from 'react-phone-number-input/flags';

export default function PhoneNumberFormatted({phoneNumber, missingLabel, showCountryFlag = false, link = false}: PhoneNumberFormattedProps) {
	const numberObject = phoneNumber ? parsePhoneNumber(phoneNumber) : null;
	const PhoneNumberCountryFlag = numberObject ? flags[numberObject.country || 'US'] as unknown as React.FC<React.SVGProps<SVGSVGElement> & { title: string }> : null;

	const phoneNumberView = <>
		{(showCountryFlag && PhoneNumberCountryFlag) ? <PhoneNumberCountryFlag title={numberObject?.countryCallingCode || ''} className='h-5 w-auto'/> : ''}
		{numberObject
			? (numberObject.countryCallingCode === '1' ? numberObject.formatNational() : numberObject.formatInternational())
			: <i>{missingLabel || 'Missing number'}</i>}
	</> ;

	if(!link) return phoneNumberView;

	return <a href={`tel:${numberObject?.formatInternational()}`} className='flex gap-2 items-center'>
		{phoneNumberView}
	</a>;
}

interface PhoneNumberFormattedProps {
	phoneNumber?: string | null;
	missingLabel?: string;
	showCountryFlag?: boolean;
	link?: boolean;
}