import {CollectionParams, HydraListResponse} from '@mgp-fe/shared/core-api/domain/base.ts';
import coreApiClient from '@mgp-fe/shared/core-api/client.ts';
import {useQuery, UseQueryOptions} from '@tanstack/react-query';
import {AxiosResponse} from 'axios';
import {AddressBookCollectionModel} from '@mgp-fe/shared/core-api/domain/address.ts';
import keysResolver from '@mgp-fe/shared/core-api/keysResolver.ts';
import endpoints from '@mgp-fe/shared/core-api/endpoints.ts';

export default function useAddressBookCollectionQuery(props?: AddressBookCollectionProps) {
	return useQuery<AxiosResponse<HydraListResponse<AddressBookCollectionModel>>>({
		queryKey: keysResolver(endpoints.addressBook.list, 'get', props?.params),
		queryFn: () => coreApiClient.get(endpoints.addressBook.list),
		staleTime: Infinity,
		...props?.options,
	});
}

interface AddressBookCollectionProps {
	params?: CollectionParams;
	options?: UseQueryOptions<AxiosResponse<HydraListResponse<AddressBookCollectionModel>>>;
}