import {HydraModel} from '@mgp-fe/shared/core-api/domain/base.ts';
import {z} from 'zod';

export interface AddressModel extends HydraModel {
	lineOne: string;
	lineTwo?: string;
	city: string;
	region: string;
	countryCode: string;
	postCode: string;
	lat?: number | null;
	long?: number | null;
}

export interface AddressValidation {
	confirmed: boolean;
	unconfirmedComponents: string[];
	missingComponents: string[];
}

export const AddressModelSchema = z.object({
	lineOne: z.string().min(1, {message: 'Line one is required'}),
	lineTwo: z.string().optional(),
	city: z.string().min(1, {message: 'Enter a city'}),
	region: z.string().min(1, {message: 'Choose a region'}),
	countryCode: z.string().min(1),
	postCode: z.string().min(1, {message: 'Required'}),
	lat: z.number().optional(),
	long: z.number().optional(),
});

export interface AddressBookCollectionModel extends HydraModel {
	address: AddressModel;
}