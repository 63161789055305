import {useMutation, UseMutationOptions, useQueryClient} from '@tanstack/react-query';
import keysResolver from '@mgp-fe/shared/core-api/keysResolver.ts';
import endpoints from '@mgp-fe/shared/core-api/endpoints.ts';
import {CartModel} from '@mgp-fe/shared/core-api/domain/cart.ts';
import {AxiosError, AxiosResponse} from 'axios';
import {HydraError, Iri} from '@mgp-fe/shared/core-api/domain/base.ts';
import coreApiClient from '@mgp-fe/shared/core-api/client.ts';

export default function useCartCheckoutShippingMutation(props?: UseApplyVoucherMutationProps) {
	const queryClient = useQueryClient();

	return useMutation({
		mutationKey: keysResolver(endpoints.cart.my.applyVoucher),
		mutationFn: (rq) => coreApiClient.patch<CartModel>(endpoints.cart.my.shipping, rq),
		...props,
		onSuccess: async (data, variables, context) => {
			await queryClient.invalidateQueries(keysResolver(endpoints.cart.my.item));
			props?.onSuccess?.(data, variables, context);
		},
	});
}

type UseApplyVoucherMutationProps = UseMutationOptions<
	AxiosResponse<CartModel>,
	AxiosError<HydraError>,
	CartCheckoutShippingRequest
>;

export interface CartCheckoutShippingRequest {
	shippingAddress: Iri;
	shippingMethod: Iri;
}