'use client';

import React from 'react';
import {ProductCollectionModel} from '@mgp-fe/shared/core-api/domain/product.ts';
import MoneyFormatted from '@mgp-fe/shared/modules/formatters/MoneyFormatted.tsx';
import ProductCartControls from '@mgp-fe/shared/modules/shop/components/ProductCartControls.tsx';
import {Card} from '@mgp-fe/shared/ui/card.tsx';
import Skeleton from '@mgp-fe/shared/ui/skeleton.tsx';
import {cn} from '@mgp-fe/shared/utils';

export default function ProductGridCard(props: ProductGridCardProps) {
	// const [isWebEnv, setIsWebEnv] = useState(false);

	// useEffect(() => {
	// 	const webAppUrl = resolveEnvVar('webAppUrl');
	// 	const currentUrl = window.location.href;
	// 	setIsWebEnv(currentUrl.replace('www.', '').includes(webAppUrl));
	// }, []);

	// const detailsUrl = isWebEnv ? `pricing/${props.product.id}` : `product-details/${props.product.id}`;

	return <Card className='flex flex-col'>
		<div
			className='relative overflow-hidden h-80 flex items-center'
			style={{boxShadow: '-5px -105px 100% rgba(255, 255, 255, 0.02) inset'}}>
			<div
				className='z-10 absolute w-[200%] h-full top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 aspect-square blur-lg rotate-12'
				style={{
					background: 'radial-gradient(ellipse farthest-corner at 50%, rgb(50,50,50), rgba(50,50,50,0.15), black)',
				}}/>
			<div className='h-5/6 w-full flex items-center'>
				{props.product.titleImage ? <img
					src={props.product.titleImage.replace('original', 'large')}
					alt={props.product.name}
					className='m-auto max-w-full max-h-full object-contain z-10'/>
					: <div className='m-auto max-w-full max-h-full object-contain z-10'></div>}
			</div>

			<div className='flex flex-wrap absolute z-50 top-2 right-2 w-fit gap-mini'>
				{props.product.promotionActions.map(pa => <div
					key={pa['@id']}
					className='w-fit bg-secondary text-muted/90 py-mini px-small rounded-full'>
					-{pa.percentageDiscount}% Off
				</div>)}
			</div>
		</div>

		<article className='mx-4 md:mx-6 lg:mx-8 mt-6 md:mt-8 lg:mt-10 flex flex-col'>
			<h3 className='text-light text-3xl uppercase font-semibold break-words'>{props.product.name}</h3>
			<p className='mt-6 text-foreground text-lg leading-loose'>{props.product.description}</p>
			<div className='grow'/>
		</article>

		<div className='grow'/>

		{/*<div className='mx-4 md:mx-6 lg:mx-8 mt-6'>*/}
		{/*	<a className='text-secondary' href={detailsUrl}>show details</a>*/}
		{/*</div>*/}

		<div className='mx-4 my-8 flex flex-wrap sm:flex-nowrap gap-4 md:gap-6 lg:gap-8 items-center justify-between'>
			<div className='grow-0'>
				<ProductCartControls product={props.product}/>
			</div>

			<p className='flex flex-col items-center justify-center shrink-0 w-fit'>
				{props.product.minimumNumber > 1 ?
					<span className='text-md text-muted/70'>min. {props.product.minimumNumber}x</span> : ''}
				<span>
					<i className='text-2xl grid font-medium shrink-0 text-muted/90'>
						{props.product.discountedPrice.amount !== props.product.price.amount ?
							<span className={cn('line-through ml-auto text-sm text-muted/50')}>
								<MoneyFormatted money={props.product.price}/>
								<br/>
							</span> : ''}
						<MoneyFormatted money={props.product.discountedPrice}/>
					</i>
					{props.product.minimumNumber > 1 ? <span className='text-md text-muted/70'> / pc.</span> : ''}
				</span>
			</p>
		</div>
	</Card>;
}

interface ProductGridCardProps {
	product: ProductCollectionModel;
}

export function ProductGridCardSkeleton() {
	return <Card className='flex flex-col'>
		<div className='relative overflow-hidden h-80 flex items-center'>
			{/* Placeholder for the Image or Colored Div */}
			<Skeleton className='m-auto max-w-full max-h-full object-contain z-10 h-3/4 w-3/4'/>
		</div>

		<article className='mx-4 md:mx-6 lg:mx-8 mt-6 md:mt-8 lg:mt-10 flex flex-col'>
			{/* Placeholder for the Product Name */}
			<Skeleton className='h-8 w-3/4 mb-4'/>

			{/* Placeholder for the Product Description */}
			<Skeleton className='h-6 w-full mb-2'/>
			<Skeleton className='h-6 w-5/6 mb-2'/>
			<Skeleton className='h-6 w-2/3'/>
		</article>

		<div className='grow'/>

		<div
			className='mx-4 my-8 flex flex-wrap sm:flex-nowrap gap-4 md:gap-6 lg:gap-8 items-center justify-between'>
			{/* Placeholder for ProductCartControls */}
			<Skeleton className='h-10 w-40'/>

			{/* Placeholder for Price and Minimum Number */}
			<div className='flex flex-col items-center justify-center shrink-0 w-fit'>
				<Skeleton className='h-4 w-24 mb-2'/>
				<Skeleton className='h-6 w-20'/>
			</div>
		</div>
	</Card>;
}