import keysResolver from '@mgp-fe/shared/core-api/keysResolver.ts';
import {useMutation, UseMutationOptions} from '@tanstack/react-query';
import {AxiosError, AxiosResponse} from 'axios';
import {HydraError} from '@mgp-fe/shared/core-api/domain/base.ts';
import endpoints from '@mgp-fe/shared/core-api/endpoints.ts';
import coreApiClient from '@mgp-fe/shared/core-api/client.ts';

export default function useRemoveOAuthMutation(props?: UseRemoveOAuthMutationProps) {
    return useMutation({
        mutationKey: keysResolver(endpoints.me.oauth.index, 'delete'),
        mutationFn: (rq) => coreApiClient.delete(endpoints.me.oauth.item(rq.id)),
        ...props,
    });
}

type UseRemoveOAuthMutationProps = UseMutationOptions<
    AxiosResponse<void>,
    AxiosError<HydraError>,
    RemoveOAuthMutationRequest
>;

type RemoveOAuthMutationRequest = {
    id: string;
}