import React from 'react';
import {MouthguardDesignCollectionModel} from '@mgp-fe/shared/core-api/domain/mouthguard.ts';
import {Card} from '@mgp-fe/shared/ui/card.tsx';
import useAuth from '@mgp-fe/shared/modules/auth/hooks/useAuth.ts';
import {Button} from '@mgp-fe/shared/ui/button.tsx';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import ProductCartControls from '@mgp-fe/shared/modules/shop/components/ProductCartControls.tsx';
import useProductsListQuery from '@mgp-fe/shared/core-api/queries/product/list.ts';
import {Alert} from '@mgp-fe/shared/ui/alert.tsx';
import useCartMyQuery from '@mgp-fe/shared/core-api/queries/cart/my.tsx';
import {cn} from '@mgp-fe/shared/utils';
import {Customer} from '@mgp-fe/shared/core-api/domain/user.ts';
import Skeleton from '@mgp-fe/shared/ui/skeleton.tsx';
import InfoTooltip from '@mgp-fe/shared/modules/common/components/InfoTooltip.tsx';

export default function CustomerDesignCard({
	design,
	onEdit,
	cartControls = true,
	className,
	size = 'normal',
}: DesignCardProps) {
	const {user} = useAuth<Customer>();
	const cartQuery = useCartMyQuery();
	const productQuery = useProductsListQuery({
		params: {
			productType: ['full_custom_mouthguard', 'clear_mouthguard'],
		},
	});

	const productTypeMap = {
		'clear_custom_design': 'clear_mouthguard',
		'full_custom_design': 'full_custom_mouthguard',
	};

	const editable = !(user?.['@type'] === 'Customer' && design.team);

	const screenshotUrl = new URL(design.screenshotUrl);
	design.updatedAt && screenshotUrl.searchParams.set('v', design.updatedAt.toString());

	const isTeamDesignInCart = cartQuery.data?.data.order.orderItems
		.some(i => i.mouthguard?.mouthguardDesign?.parent?.id === design.id);

	const EditButton = () => <Button
		variant='ghost'
		size='sm'
		icon={<FontAwesomeIcon icon='pencil'/>}
		onClick={() => onEdit?.(design)}>
		Edit
	</Button>;

	return <Card
		className={cn('max-w-lg flex flex-col relative', className)}
		key={design.id}>
		{isTeamDesignInCart && <Alert variant='success' size='xs' className='absolute top-2 right-2 max-w-fit'>
			Preview only
		</Alert>}
		<InfoTooltip className='absolute right-1 top-1'>
			<p>
				{designDescription[design.type]}<br/>
				{design?.thickness && thicknessDescription[design.thickness]}
			</p>
		</InfoTooltip>
		<img src={screenshotUrl.toString()} alt={design.name} className={cn(
			'object-cover w-full rounded-t-lg bg-background',
			size === 'normal' ? 'h-48' : '',
			size === 'small' ? 'h-32' : '',
		)}/>
		{!cartControls && editable && onEdit ?
			<div className='absolute top-40 left-1 bottom-1'><EditButton/></div> : ''}

		<h4 className={cn(
			'text-light/90 px-small py-mini grow flex flex-col justify-center break-all',
			size === 'small' ? 'text-xl' : '',
		)}>
			<span>{design.name}</span>
			{design.team ? <span className='text-lg text-foreground'>{design.team.name}</span> : ''}
		</h4>

		{cartControls ? <div className='grow'/> : ''}

		{cartControls ? <div className='flex items-center gap-4 w-full pt-mini p-small'>
			{productQuery.isLoading || productQuery.isFetching ? <FontAwesomeIcon icon='spinner' spin/> : ''}

			{productQuery.data?.data['hydra:member'].length
				? <ProductCartControls
					product={productQuery.data?.data['hydra:member'][productQuery.data?.data['hydra:member'].findIndex(p => p.productType === productTypeMap[design.type])]}
					addToCartHandler={design.team && user?.['@type'] === 'Customer' ? (design) => onEdit?.(design) : undefined}
					design={design}
					orderButtonProps={{size: 'md'}}
					quantityButtonProps={{size: 'iconMd'}}/>
				: ''}

			{editable ? <EditButton/> : ''}
		</div> : ''}
	</Card>;
}

export function DesignCardSkeleton({ cartControls = true, onEdit, className }: Partial<DesignCardProps>) {
	return <Card className={`max-w-lg flex flex-col relative ${className || ''}`}>
		{/* Alert Placeholder */}
		<Skeleton className='absolute top-2 right-2 w-24 h-4' />

		{/* Image Placeholder */}
		<Skeleton className='w-full h-48' />

		{/* Edit Button Placeholder */}
		{!cartControls && onEdit &&
			<Skeleton className='absolute top-40 left-1 w-16 h-6' />
		}

		{/* Design Name Placeholder */}
		<Skeleton className='m-small w-3/4 h-5' />

		{/* Team Name Placeholder */}
		<Skeleton className='mx-small w-2/3 h-5' />

		{/* Spacer */}
		<div className='grow' />

		{/* Cart Controls Placeholder */}
		{cartControls ?
			<div className='flex items-center gap-4 w-full p-small'>
				<Skeleton className='w-16 h-6 mr-2' /> {/* Loading Icon Placeholder */}
				<Skeleton className='flex-grow h-6' />  {/* Product Cart Controls Placeholder */}
				{onEdit && <Skeleton className='w-16 h-6 ml-2' />} {/* Edit Button Placeholder */}
			</div>
			: ''}
	</Card>;
}

interface DesignCardProps {
	design: MouthguardDesignCollectionModel;
	onEdit?: (design: MouthguardDesignCollectionModel) => void;
	cartControls?: boolean;
	className?: string;
	size?: 'small' | 'normal';
}

const designDescription = {
	'clear_custom_design': 'Clear Design with translucent sides & custom design in front.',
	'full_custom_design': 'Full custom Design with custom color scheme and both Logos & Texts.',
};

const thicknessDescription = {
	'thin': 'Thickness of this design is 4 mm.',
	'normal': 'Thickness of this design is 5 mm.',
	'thick': 'Thickness of this design is 6 mm.',
};