import {useMutation, UseMutationOptions, useQueryClient} from '@tanstack/react-query';
import keysResolver from '@mgp-fe/shared/core-api/keysResolver.ts';
import endpoints from '@mgp-fe/shared/core-api/endpoints.ts';
import coreApiClient from '@mgp-fe/shared/core-api/client.ts';
import {AxiosError, AxiosResponse} from 'axios';
import {OrderModel} from '@mgp-fe/shared/core-api/domain/order.ts';
import {HydraError} from '@mgp-fe/shared/core-api/domain/base.ts';

export default function useRemovePromotionsFromCartMutation(props?: UseRemovePromotionsFromCartMutationProps){
	const queryClient = useQueryClient();

	return useMutation({
		mutationKey: keysResolver(endpoints.cart.my.removePromotions, 'patch'),
		mutationFn: data => coreApiClient.patch(endpoints.cart.my.removePromotions, {
			promotions: data.promotions,
		}),
		onSuccess: async () => {
			await queryClient.invalidateQueries(keysResolver(endpoints.cart.my.item));

		},
		...props,
	});
}

type UseRemovePromotionsFromCartMutationProps = UseMutationOptions<
    AxiosResponse<OrderModel>,
    AxiosError<HydraError>,
    RemovePromotionsFromCartMutationRequest
>

export type RemovePromotionsFromCartMutationRequest = {
	promotions: string[];
}