'use client';

import {useQuery, UseQueryOptions} from '@tanstack/react-query';
import endpoints from '@mgp-fe/shared/core-api/endpoints.ts';
import keysResolver from '@mgp-fe/shared/core-api/keysResolver.ts';
import coreApiClient from '@mgp-fe/shared/core-api/client.ts';
import {AxiosResponse} from 'axios';
import {CartModel} from '@mgp-fe/shared/core-api/domain/cart.ts';
import useAuth from '@mgp-fe/shared/modules/auth/hooks/useAuth';


export default function useCartMyQuery(props?: CartMyQueryProps) {
	const {user} = useAuth();

	return useQuery<AxiosResponse<CartModel>>(
		keysResolver(endpoints.cart.my.item),
		() => coreApiClient.get(endpoints.cart.my.item),
		{
			staleTime: Infinity,
			enabled: user !== null,
			...props?.options,
		},
	);
}

interface CartMyQueryProps {
	options?: UseQueryOptions<AxiosResponse<CartModel>>;
}