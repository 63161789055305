/* eslint-disable */
// @ts-nocheck
import type * as Types from '@mgp-frontend/shared/cms-graphql/domain/types';

import { gql } from '@apollo/client';
import { HeaderNavigationItemsFragmentDoc } from '../components/generated/headerNavigationItems';
import { FooterLinkItemsFragmentDoc } from '../components/generated/footerLinkItems';
import { SocialsFragmentDoc } from '../components/generated/socials';
import { BannerFragmentDoc } from '../components/generated/banner';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MouthguardClubComQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type MouthguardClubComQuery = { __typename?: 'Query', mouthguardClubCom?: { __typename: 'MouthguardClubComEntityResponse', data?: { __typename?: 'MouthguardClubComEntity', attributes?: { __typename?: 'MouthguardClubCom', termsAndConditionsUrl?: string | null, headerNavigationItems?: Array<{ __typename?: 'ComponentNavigationNavigationItem', id: string, label?: string | null, url?: string | null } | null> | null, footerLinkItems?: Array<{ __typename?: 'ComponentFooterFooterLinkItems', id: string, label: string, url: string } | null> | null, socials?: Array<{ __typename?: 'ComponentSocialsSocials', id: string, url: string, icon: { __typename?: 'UploadFileEntityResponse', data?: { __typename?: 'UploadFileEntity', attributes?: { __typename?: 'UploadFile', url: string } | null } | null } } | null> | null, banner?: { __typename?: 'ComponentCommonBanner', id: string, messageText: string, code?: string | null } | null } | null } | null } | null };


export const MouthguardClubComDocument = gql`
    query mouthguardClubCom {
  mouthguardClubCom {
    __typename
    data {
      attributes {
        headerNavigationItems {
          ...HeaderNavigationItems
        }
        footerLinkItems {
          ...FooterLinkItems
        }
        socials {
          ...Socials
        }
        termsAndConditionsUrl
        banner {
          ...Banner
        }
      }
    }
  }
}
    ${HeaderNavigationItemsFragmentDoc}
${FooterLinkItemsFragmentDoc}
${SocialsFragmentDoc}
${BannerFragmentDoc}`;

/**
 * __useMouthguardClubComQuery__
 *
 * To run a query within a React component, call `useMouthguardClubComQuery` and pass it any options that fit your needs.
 * When your component renders, `useMouthguardClubComQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMouthguardClubComQuery({
 *   variables: {
 *   },
 * });
 */
export function useMouthguardClubComQuery(baseOptions?: Apollo.QueryHookOptions<MouthguardClubComQuery, MouthguardClubComQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MouthguardClubComQuery, MouthguardClubComQueryVariables>(MouthguardClubComDocument, options);
      }
export function useMouthguardClubComLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MouthguardClubComQuery, MouthguardClubComQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MouthguardClubComQuery, MouthguardClubComQueryVariables>(MouthguardClubComDocument, options);
        }
export function useMouthguardClubComSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<MouthguardClubComQuery, MouthguardClubComQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<MouthguardClubComQuery, MouthguardClubComQueryVariables>(MouthguardClubComDocument, options);
        }
export type MouthguardClubComQueryHookResult = ReturnType<typeof useMouthguardClubComQuery>;
export type MouthguardClubComLazyQueryHookResult = ReturnType<typeof useMouthguardClubComLazyQuery>;
export type MouthguardClubComSuspenseQueryHookResult = ReturnType<typeof useMouthguardClubComSuspenseQuery>;
export type MouthguardClubComQueryResult = Apollo.QueryResult<MouthguardClubComQuery, MouthguardClubComQueryVariables>;