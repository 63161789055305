import {useMutation, UseMutationOptions, useQueryClient} from '@tanstack/react-query';
import {AxiosError, AxiosResponse} from 'axios';
import {AddressBookCollectionModel, AddressModel, AddressModelSchema} from '@mgp-fe/shared/core-api/domain/address.ts';
import {HydraError} from '@mgp-fe/shared/core-api/domain/base.ts';
import keysResolver from '@mgp-fe/shared/core-api/keysResolver.ts';
import endpoints from '@mgp-fe/shared/core-api/endpoints.ts';
import coreApiClient from '@mgp-fe/shared/core-api/client.ts';
import {z} from 'zod';

export function useAddressBookMutation(props?: UseUpdateAddressBookMutationOption) {
	const queryClient = useQueryClient();

	return useMutation<AxiosResponse<AddressBookCollectionModel>, AxiosError<HydraError>, AddressBookMutationData>({
		mutationKey: keysResolver(endpoints.addressBook.list, 'post'),
		mutationFn: ({data, update}) => coreApiClient.request<AddressBookCollectionModel>({
			url: update ? endpoints.addressBook.item(update.id) : endpoints.addressBook.list,
			method: update ? 'put' : 'post',
			data: {
				address: data.address,
				setAsDefaultShipping: data.setAsDefaultShipping,
				setAsDefaultBilling: data.setAsDefaultBilling,
			},
		}),
		...props,
		onSuccess: async (data, variables, context) => {
			await queryClient.refetchQueries(keysResolver(endpoints.addressBook.list));
			await queryClient.refetchQueries(keysResolver(endpoints.me.index));

			props?.onSuccess?.(data, variables, context);
		},
	});
}

export interface AddressBookMutationData {
	data: AddressBookRequest;
	update?: AddressBookCollectionModel;
}

export interface AddressBookRequest {
	address?: AddressModel;
	validationExecuted?: boolean;
	setAsDefaultShipping?: boolean;
	setAsDefaultBilling?: boolean;
}

export const AddressBookRequestSchema = z.object({
	validationExecuted: z.boolean().optional(),
	address: AddressModelSchema,
	setAsDefaultShipping: z.boolean().optional(),
	setAsDefaultBilling: z.boolean().optional(),
});

export type UseUpdateAddressBookMutationOption = UseMutationOptions<
	AxiosResponse<AddressBookCollectionModel>,
	AxiosError<HydraError>,
	AddressBookMutationData
>;

export function useDeleteAddressBookMutation(props?: UseDeleteAddressBookMutationOption) {
	const queryClient = useQueryClient();

	return useMutation<AxiosResponse<null | undefined>, AxiosError<HydraError>, AddressBookCollectionModel>({
		mutationKey: keysResolver(endpoints.addressBook.list, 'delete'),
		mutationFn: (d: AddressBookCollectionModel) => coreApiClient.delete<null | undefined>(endpoints.addressBook.item(d.id)),
		...props,
		onSuccess: async (data, variables, context) => {
			await queryClient.refetchQueries(keysResolver(endpoints.addressBook.list));
			await queryClient.refetchQueries(keysResolver(endpoints.me.index));

			props?.onSuccess?.(data, variables, context);
		},
	});
}

export type UseDeleteAddressBookMutationOption = UseMutationOptions<
	AxiosResponse<null | undefined>,
	AxiosError<HydraError>,
	AddressBookCollectionModel
>;