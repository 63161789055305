import React from 'react';
import useTeamsListQuery from '@mgp-fe/shared/core-api/queries/team/list.ts';
import {Card} from '@mgp-fe/shared/ui/card.tsx';
import Skeleton from '@mgp-fe/shared/ui/skeleton.tsx';
import {Alert, AlertDescription, AlertTitle} from '@mgp-fe/shared/ui/alert.tsx';
import {Link} from 'react-router-dom';
import routes from '@mgp-fe/customer/router/routes.ts';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

export default function TeamsSection() {
	const teamsQuery = useTeamsListQuery({
		options: {keepPreviousData: true, staleTime: Infinity},
	});

	return <section className='flex flex-col gap-small'>
		<h3 className='text-secondary mb-0'>My teams</h3>
		{teamsQuery.isFetching ? <Card spacing='lg' className='mb-medium'>
			<h4 className='flex items-center justify-between w-full'>
				<Skeleton className='w-1/2 h-11' />
				<Skeleton className='w-24 h-6' />
			</h4>
		</Card> : ''}
		{!teamsQuery.isFetching && teamsQuery.data?.data['hydra:member'].length === 0 ?
			<Alert className='mt-0'>
				<AlertTitle className='text-muted/80'>
					You are not a member of any team.
				</AlertTitle>
				<AlertDescription>
					Check invitations or contact your team leader to invite you.
				</AlertDescription>
			</Alert> : ''}

		{teamsQuery.data?.data['hydra:member'].map(team => <Card spacing='lg' key={team.id}>
			<h4 className='flex items-center justify-between'>
				{team.name}

				{team.designs.length > 0 ? <Link to={`${routes.gardDesigns.index}#team-design-${team.id}`} className='text-muted/80 text-sm'>
					{team.designs.length} team design{team.designs.length > 1 && 's'} <FontAwesomeIcon icon='chevron-right' className='ml-2'/>
				</Link> : <span className='text-foreground text-sm'>
								No team designs
				</span>}
			</h4>
		</Card>)}
	</section>;
}