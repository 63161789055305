'use client';

import {useQuery, UseQueryOptions} from '@tanstack/react-query';
import keysResolver from '@mgp-fe/shared/core-api/keysResolver.ts';
import endpoints from '@mgp-fe/shared/core-api/endpoints.ts';
import {ArchivableListParams, CollectionParams, HydraListResponse} from '@mgp-fe/shared/core-api/domain/base.ts';
import {AxiosResponse} from 'axios';
import coreApiClient from '@mgp-fe/shared/core-api/client.ts';
import {PartnerLocationCollectionModel} from '@mgp-fe/shared/core-api/domain/partner/location.ts';

export default function usePartnerLocationsListQuery(props?: ProductListQueryProps) {
	return useQuery({
		queryKey: keysResolver(endpoints.partnerLocations.list, 'get', props?.params),
		queryFn: () => coreApiClient.get<HydraListResponse<PartnerLocationCollectionModel>>(endpoints.partnerLocations.list, {
			params: props?.params,
		}),
		...props?.options,
	});
}

interface ProductListQueryProps {
	params?: CollectionParams & ArchivableListParams;
	options?: UseQueryOptions<AxiosResponse<HydraListResponse<PartnerLocationCollectionModel>>>;
}