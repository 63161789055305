import {useMutation, UseMutationOptions, useQueryClient} from '@tanstack/react-query';
import keysResolver from '@mgp-fe/shared/core-api/keysResolver.ts';
import endpoints from '@mgp-fe/shared/core-api/endpoints.ts';
import coreApiClient from '@mgp-fe/shared/core-api/client.ts';
import {AxiosError, AxiosResponse} from 'axios';

export default function useSetDefaultCardMutation(props?: SetDefaultCardRequestMutationProps) {
	const queryClient = useQueryClient();


	return useMutation<AxiosResponse<SetDefaultCardResponse>, AxiosError<SetDefaultCardResponse>, SetDefaultCardMutationData>({
		mutationKey: keysResolver(endpoints.stripe.setMyDefaultCard('')),
		mutationFn: data => coreApiClient.put(
			data.partnerType !== undefined && data.partnerId !== undefined
				? endpoints.stripe.setPartnerDefaultCard(data.partnerType, data.partnerId)
				: endpoints.stripe.setMyDefaultCard(data.cardId),
			{
				id: data.cardId,
			},
		),
		...props,
		onSuccess: async (result, variables, context) => {
			await queryClient.invalidateQueries(keysResolver(endpoints.stripe.myCards));
			await queryClient.invalidateQueries(keysResolver(endpoints.stripe.partnerCards(
				variables.partnerType || 'location',
				variables.partnerId || '',
			)));
			await queryClient.invalidateQueries(keysResolver(endpoints.stripe.partnerCheckoutCards('')));
			props?.onSuccess?.(result, variables, context);
		},
	});
}

type SetDefaultCardRequestMutationProps = UseMutationOptions<
	AxiosResponse<SetDefaultCardResponse>,
	AxiosError<SetDefaultCardResponse>,
	SetDefaultCardMutationData
>;

export interface SetDefaultCardMutationData {
	cardId: string;
	partnerType?: 'location' | 'organization';
	partnerId?: string;
}

export interface SetDefaultCardResponse {
	success: boolean;
	error?: string;
}