import {useMutation, UseMutationOptions, useQueryClient} from '@tanstack/react-query';
import keysResolver from '@mgp-fe/shared/core-api/keysResolver.ts';
import endpoints from '@mgp-fe/shared/core-api/endpoints.ts';
import {CartModel} from '@mgp-fe/shared/core-api/domain/cart.ts';
import {AxiosError, AxiosResponse} from 'axios';
import {HydraError} from '@mgp-fe/shared/core-api/domain/base.ts';
import coreApiClient from '@mgp-fe/shared/core-api/client.ts';

export default function useApplyVoucherMutation(props?: UseApplyVoucherMutationProps) {
	const queryClient = useQueryClient();

	return useMutation({
		mutationKey: keysResolver(endpoints.cart.my.applyVoucher),
		mutationFn: (rq) => coreApiClient.patch<CartModel>(endpoints.cart.my.applyVoucher, rq),
		...props,
		onSuccess: async (data, variables, context) => {
			await queryClient.invalidateQueries(keysResolver(endpoints.cart.my.item));
			await queryClient.invalidateQueries(keysResolver(endpoints.vouchers.list));
			props?.onSuccess?.(data, variables, context);
		},
	});
}

type UseApplyVoucherMutationProps = UseMutationOptions<
	AxiosResponse<CartModel>,
	AxiosError<HydraError>,
	ApplyVoucherRequest
>;

export interface ApplyVoucherRequest {
	voucherCode: string;
}