import React from 'react';
import {InvitationCollectionModel} from '@mgp-fe/shared/core-api/domain/invitation.ts';
import useAcceptInvitationMutation from '@mgp-fe/shared/core-api/mutations/invitation/accept.ts';
import useDeclineInvitationMutation from '@mgp-fe/shared/core-api/mutations/invitation/decline.ts';
import {Card, CardContent, CardFooter, CardHeader, CardTitle} from '@mgp-fe/shared/ui/card.tsx';
import {Button} from '@mgp-fe/shared/ui/button.tsx';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {humanizeEnumValue} from '@mgp-fe/shared/utils/humanize-string.ts';
import DateFormatted from '@mgp-fe/shared/modules/formatters/DateFormatted.tsx';
import Skeleton from '@mgp-fe/shared/ui/skeleton';

export default function InvitationCard({invitation, successHandler}: InvitationCardProps) {
	const acceptInvitation = useAcceptInvitationMutation({
		onSuccess: successHandler,
	});
	const declineInvitation = useDeclineInvitationMutation({
		onSuccess: successHandler,
	});

	return <Card className='flex flex-col'>
		<CardHeader>
			<CardTitle className='leading-relaxed text-muted/80'>
				You have been invited to join
				{['athlete_invitation', 'coach_invitation', 'team_athlete_invitation'].includes(invitation.type) ?
					<span className='text-primary'>
						{invitation.team ? <> team {invitation.team.name}</> : ''}
						{invitation.team && invitation.partnerLocation ?
							<span className='text-muted/80'> and </span> : ''}
						{invitation.partnerLocation ? <> partner location {invitation.partnerLocation.name}</> : ''}
					</span> : ''}

				{['partner_location_invitation', 'partner_to_organization_invitation'].includes(invitation.type) ?
					<span className='text-primary'>
					partner location {invitation.partnerLocation ? invitation.partnerLocation.name : ''}
						{invitation.partnerLocation && invitation.partnerOrganization ?
							<span className='text-muted/80'> and </span> : ''}
						{invitation.partnerOrganization ? `partner organization ${invitation.partnerOrganization.name}` : ''}
					</span> : ''}

				{['admin_invitation', 'super_admin_invitation'].includes(invitation.type) ?
					<span className='text-primary'>
					administration
						{invitation.adminGroups.length > 0 ? ' of ' : ''}
						{invitation.adminGroups.map(g => g.name).join(', ')}
					</span> : ''}
			</CardTitle>
		</CardHeader>

		<CardContent className='grow'>
			This is <strong>{humanizeEnumValue(invitation.type)}</strong> created at <i><DateFormatted
				date={invitation.createdAt!}/></i>.
		</CardContent>

		<CardFooter className='flex items-center justify-between'>
			{['accepted', 'declined'].includes(invitation.status)
				? <p className='italic text-xl py-small w-full text-center'>
					Invitation has been <span className={invitation.status === 'accepted' ? 'text-primary' : 'text-warning'}>{invitation.status}</span> at <span><DateFormatted date={invitation.updatedAt!}/></span>.
				</p>
				: <>
					<Button
						onClick={() => declineInvitation.mutate(invitation)}
						state={declineInvitation.status}
						size='lg'
						variant='destructive'
						icon={<FontAwesomeIcon icon='times'/>}>
						Decline
					</Button>

					<Button
						onClick={() => acceptInvitation.mutate({invitation})}
						state={acceptInvitation.status}
						size='lg'
						icon={<FontAwesomeIcon icon='check'/>}>
						Accept
					</Button>
				</>}
		</CardFooter>
	</Card>;
}

export function InvitationCardSkeleton() {
	return <Card>
		<CardHeader>
			<CardTitle className='leading-relaxed text-muted/80'>
				<Skeleton className='w-2/3 h-4'/>
			</CardTitle>
		</CardHeader>

		<CardContent>
			<Skeleton className='w-full h-4 my-1'/>
			<Skeleton className='w-3/4 h-4 my-1'/>
		</CardContent>

		<CardFooter className='flex items-center justify-between'>
			<Skeleton className='w-1/3 h-10 mr-2'/>
			<Skeleton className='w-1/3 h-10'/>
		</CardFooter>
	</Card>;
}

interface InvitationCardProps {
	invitation: InvitationCollectionModel;
	successHandler?: () => void;
}