'use client';

import React, {useCallback, useMemo, useState} from 'react';
import CartContext from '@mgp-fe/shared/modules/shop/state/CartContext.ts';
import {CartContextValue} from '@mgp-fe/shared/modules/shop/domain.ts';
import Modal from '@mgp-fe/shared/ui/Modal.tsx';
import {
	useCreateCartItemMutation,
	useReduceCartItemMutation,
} from '@mgp-fe/shared/core-api/mutations/cart/my-items.ts';
import {Button} from '@mgp-fe/shared/ui/button.tsx';
import {ProductOptionColor, ProductType} from '@mgp-fe/shared/core-api/domain/product.ts';
import useCartMyQuery from '@mgp-fe/shared/core-api/queries/cart/my.tsx';
import endpoints from '@mgp-fe/shared/core-api/endpoints.ts';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {sumBy} from 'lodash';
import useAuth from '@mgp-fe/shared/modules/auth/hooks/useAuth.ts';
import {
	MouthguardDesignCollectionModel,
	MouthguardDesignModel,
	ThicknessType,
} from '@mgp-fe/shared/core-api/domain/mouthguard.ts';
import {cn} from '@mgp-fe/shared/utils';
import MouthguardDesignAddModal from '@mgp-fe/shared/modules/shop/components/cart/MouthguardDesignAddModal.tsx';
import DesignerThicknessModalContent from '@mgp-fe/shared/modules/shop/components/DesignerThicknessModalContent.tsx';

export default function CartContextProvider({children}: {
    children: React.ReactNode
}) {
	const {user, displayLoginModal} = useAuth();
	const cartQuery = useCartMyQuery();
	const addItemToCartMutation = useCreateCartItemMutation({
		onSuccess: () => {
			setSelectProductOption(undefined);
			setProductOptions(undefined);
		},
	});
	const reduceCartItem = useReduceCartItemMutation({
		onSuccess: () => {
			setSelectProductToReduce(undefined);
			setSelectProductOptionToReduce(undefined);
		},
	});
	const [productOptions, setProductOptions] = useState<ProductOptionColor[] | undefined>();
	const [selectProductOption, setSelectProductOption] = useState<string | undefined>();
	const [selectedOption, setSelectedOption] = useState<ProductOptionColor | undefined>();
	const [selectProductOptionToReduce, setSelectProductOptionToReduce] = useState<string | undefined>();
	const [selectDesignForMouthguard, setSelectDesignForMouthguard] = useState<string | undefined>();
	const [selectProductThicknessToReduce, setSelectProductThicknessToReduce] = useState<string | undefined>();
	const [thicknessDesign, setThicknessDesign] = useState< MouthguardDesignCollectionModel | undefined>(undefined);
	const [productThickness, setProductThickness] = useState<ThicknessType | undefined>(undefined);
	const [selectProductToReduce, setSelectProductToReduce] = useState<string | undefined>();

	const addProductToCart = useCallback<CartContextValue['addProductToCart']>(
		async (product, design) => {

			if (!user) {
				displayLoginModal.on();
				return Promise.resolve(undefined);
			}

			if (!design && (['clear_mouthguard', 'full_custom_mouthguard'] as ProductType[]).includes(product.productType)) {
				setSelectDesignForMouthguard(product.id);

				return Promise.resolve(undefined);
			}

			if ((['full_custom_mouthguard'] as ProductType[]).includes(product.productType)) {
				setSelectProductThicknessToReduce(product.id);
				setThicknessDesign(design);
				return Promise.resolve(undefined);
			}

			if (product.options?.colors && (product.options?.colors.length || 0) > 1) {
				setSelectProductOption(product.id);
				setProductOptions(product.options.colors);
				setSelectedOption(undefined);

				return Promise.resolve(undefined);
			}

			if (product.options?.colors && (product.options.colors.length || 0) === 1) {
				addItemToCartMutation.mutate({
					product: endpoints.products.item(product.id),
					selectedProductOptions: product.options.colors[0],
				});

				return Promise.resolve(undefined);
			}


			return await addItemToCartMutation.mutateAsync({
				product: product['@id'],
				mouthguardDesign: design?.['@id'],
			}).then(r => r.data);
		}, [addItemToCartMutation, displayLoginModal, user]);

	const reduceProductInCart = useCallback<CartContextValue['reduceProductInCart']>(
		async (product, design) => {
			const orderItems = await cartQuery.data?.data.order.orderItems.filter(i => i.product.id === product.id);

			if (!user) {
				displayLoginModal.on();
				return Promise.resolve(undefined);
			}
			const cartItemsOfProduct = cartQuery.data?.data.order.orderItems
				.filter(i => !design || i.mouthguard?.mouthguardDesign?.['@id'] === design['@id']
                    || i.mouthguard?.mouthguardDesign?.parent?.['@id'] === design['@id'])
				.filter(i => i.product.id === product.id) || [];

			if (!product.options?.colors && cartItemsOfProduct.length > 1) {
				setSelectProductToReduce(product.id);

				return Promise.resolve(undefined);
			}

			if (product.options?.colors && (product.options.colors.length === 1 || orderItems?.length === 1)) {
				orderItems && reduceCartItem.mutate(orderItems[0]);

				return Promise.resolve(undefined);
			}

			if (product.options?.colors && product.options.colors.length > 1) {
				setSelectProductOptionToReduce(product.id);
				setProductOptions(product.options.colors);
				setSelectedOption(undefined);

				return Promise.resolve(undefined);
			}

			return reduceCartItem.mutateAsync(cartItemsOfProduct[0]).then(r => r.data);
		}, [cartQuery.data?.data.order.orderItems, displayLoginModal, reduceCartItem, user]);

	const value = useMemo<CartContextValue>(() => ({
		totalItems: sumBy(cartQuery.data?.data.order.orderItems, 'quantity') || 0,
		totalAmount: cartQuery.data?.data.order.totalAmount || {amount: 0, currency: 'USD'},
		addProductToCart,
		reduceProductInCart,
	}), [addProductToCart, cartQuery.data?.data.order.orderItems, cartQuery.data?.data.order.totalAmount, reduceProductInCart]);

	return <CartContext.Provider value={value}>
		{children}

		<Modal isOpen={selectProductToReduce !== undefined} onClose={() => setSelectProductToReduce(undefined)}>
			<div className='flex flex-col gap-2'>
				<h4 className='text-primary'>
					<span className='text-foreground'>Select </span>
					{cartQuery.data?.data.order.orderItems.find(i => i.product.id === selectProductToReduce)?.product.name || 'Missing name'}
					<span className='text-foreground'> to remove:</span>
				</h4>
				{cartQuery.data?.data.order.orderItems.filter(i => i.product.id === selectProductToReduce)
					.map(item => <div
						className='flex items-center gap-4 border-primary border-2 rounded-xl px-3 py-2'
						key={item.id}>

						<img
							src={item.mouthguard?.mouthguardDesign.screenshotUrl || item.product.titleImage || ''}
							alt={item.product.name}
							className='w-16 h-16 object-contain'/>

						<div className='flex flex-col grow'>
							{item.mouthguard?.mouthguardDesign.parent ? <h5 className='text-primary'>
								{(item.mouthguard?.mouthguardDesign as MouthguardDesignModel)
									.canvasData?.data.find(cd => cd.cacheKey === 'playerName')?.text
                                    || 'Empty name'}
							</h5> : ''}
							<h5 className={cn(
								item.mouthguard?.mouthguardDesign.parent ? 'text-foreground text-sm' : 'text-primary',
							)}>
								{item.mouthguard?.mouthguardDesign.name}
								{item.mouthguard?.mouthguardDesign.parent?.team ?
									<span className='text-foreground '> (Team)</span> : ''}
							</h5>
						</div>
						<span className='shrink-0 text-foreground'>
							{item.quantity}x
						</span>
						<Button variant='destructive' className='shrink-0 rounded-full' size='icon'
							onClick={() => reduceCartItem.mutate(item)} state={
								reduceCartItem.variables?.id === item.id ? reduceCartItem.status : 'idle'
							}>
							<FontAwesomeIcon
								icon={item.product.minimumNumber === item.quantity ? 'trash-can' : 'minus'}/>
						</Button>
					</div>)}
			</div>
		</Modal>

		<MouthguardDesignAddModal useDisplayState={[selectDesignForMouthguard, setSelectDesignForMouthguard]}/>

		<Modal
			isOpen={selectProductOption !== undefined} onClose={() => setSelectProductOption(undefined)}
			title='Select color option'>
			<div className='grid gap-mini w-full mx-auto'>
				{productOptions?.map(c => <div
					key={c.label}
					onClick={() => setSelectedOption(c)}
					className={cn('flex gap-small capitalize w-full px-medium py-small border border-secondary-500 rounded-lg cursor-pointer',
						c.color === selectedOption?.color ? 'bg-secondary-500/30' : 'hover:bg-secondary-500/10')}>
					<div
						className='w-5 h-5 my-auto rounded-full border border-primary/30'
						style={{
							backgroundColor: c.color,
						}}/>
					{c.label.replaceAll('_', ' ')}
				</div>)}
			</div>
			<Button
				onClick={() => addItemToCartMutation.mutate({
					product: endpoints.products.item(selectProductOption!),
					selectedProductOptions: selectedOption,
				})}
				className='mt-small w-full'
				disabled={!selectedOption}
				state={addItemToCartMutation.isLoading ? 'loading' : 'idle'}
				icon={<FontAwesomeIcon icon='cart-plus'/>}>
                Order
			</Button>
		</Modal>

		<Modal
			isOpen={selectProductOptionToReduce !== undefined} onClose={() => setSelectProductOptionToReduce(undefined)}
			title='Select color option'>
			<div className='grid gap-mini w-full mx-auto items-center'>
				{cartQuery.data?.data.order.orderItems.filter(i => i.product.id === selectProductOptionToReduce)?.map(i =>
					<div
						key={i.id}
						className={cn('flex gap-small capitalize w-full px-medium py-small border border-secondary-500 rounded-lg cursor-pointer',
							i.selectedProductOptions.color === selectedOption?.color ? 'bg-secondary-500/30' : 'hover:bg-secondary-500/10')}>
						<div
							className='w-8 h-8 rounded-full border border-primary/30'
							style={{
								backgroundColor: i.selectedProductOptions.color,
							}}/>
						<span
							className='shrink-0 text-foreground my-auto'>{i.selectedProductOptions.label.replaceAll('_', ' ')}</span>
						<span className='shrink-0 text-foreground my-auto ml-auto'>
							{i.quantity}x
						</span>
						<Button variant='destructive' className='shrink-0 rounded-full' size='icon'
							onClick={() => reduceCartItem.mutate(i)} state={
								reduceCartItem.variables?.id === i.id ? reduceCartItem.status : 'idle'
							}>
							<FontAwesomeIcon
								icon={i.product.minimumNumber === i.quantity ? 'trash-can' : 'minus'}/>
						</Button>
					</div>)}
			</div>
		</Modal>

		<Modal
			isOpen={selectProductThicknessToReduce !== undefined} onClose={() => setSelectProductThicknessToReduce(undefined)}
			title='Select thickness'>
			<div>
				<DesignerThicknessModalContent onChange={value => setProductThickness(value)}/>
				<Button
					onClick={() => addItemToCartMutation.mutate({
						product: endpoints.products.item(selectProductThicknessToReduce!),
						mouthguardDesign: thicknessDesign?.['@id'],
						mouthguardThickness: productThickness,
					}, {
						onSuccess: () => setSelectProductThicknessToReduce(undefined),
					})}
					className='mt-small w-full'
					disabled={!productThickness}
					state={addItemToCartMutation.isLoading ? 'loading' : 'idle'}
					icon={<FontAwesomeIcon icon='cart-plus'/>}>
					Order
				</Button>
			</div>
		</Modal>
	</CartContext.Provider>;
}

