import React, {useMemo, useState} from 'react';
import {cn} from '@mgp-fe/shared/utils';
import {
	MouthguardDesignCollectionModel,
	MouthguardDesignModel,
	ThicknessType,
} from '@mgp-fe/shared/core-api/domain/mouthguard.ts';
import {Button} from '@mgp-fe/shared/ui/button.tsx';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {TableCell} from '@mgp-fe/shared/ui/table.tsx';
import DesignerModal from '@mgp-fe/shared/modules/mouthguard-design/components/DesignerModal.tsx';
import {OrderItemModel} from '@mgp-fe/shared/core-api/domain/order.ts';
import useRemoveVoucherMutation from '@mgp-fe/shared/core-api/mutations/cart/remove-voucher.ts';
import AlertConfirmDialog from '@mgp-fe/shared/ui/AlertConfirmDialog.tsx';
import useVouchersListQuery from '@mgp-fe/shared/core-api/queries/voucher/list.ts';
import useApplyVoucherMutation from '@mgp-fe/shared/core-api/mutations/cart/apply-voucher.ts';
import {humanizeEnumValue} from '@mgp-fe/shared/utils/humanize-string.ts';
import useCartMyQuery from '@mgp-fe/shared/core-api/queries/cart/my.tsx';
import useCartItemChangeThicknessMutation from '@mgp-fe/shared/core-api/mutations/cart/change-thickness.ts';
import Modal from '@mgp-fe/shared/ui/Modal.tsx';
import useToggle from '@mgp-fe/shared/hooks/useToggle.ts';
import DesignerThicknessModalContent from '@mgp-fe/shared/modules/shop/components/DesignerThicknessModalContent.tsx';


export default function CartContentTableProductCell({item, controls = true}: CartContentTableProductCellProps) {
	const availableVouchers = useVouchersListQuery({
		params: {exists: {orderItem: false, usedAt: false}},
	});
	const applyVoucher = useApplyVoucherMutation();
	const removeVoucher = useRemoveVoucherMutation();
	const changeThickness = useCartItemChangeThicknessMutation();
	const [editDesign, setEditDesign] = useState<MouthguardDesignCollectionModel | undefined>(undefined);

	const vouchersForProduct = useMemo(
		() => (availableVouchers.data?.data['hydra:member'] || []).filter(v => v.product.product?.id === item.product.id),
		[availableVouchers.data?.data, item.product.id],
	);

	const cartQuery = useCartMyQuery();

	const editThickness = useToggle({initialState: false});
	const [selectedThickness, setSelectedThickness] = useState<ThicknessType>();

	const matchingDesigns = cartQuery.data?.data.order.orderItems.filter(i => i.mouthguard?.mouthguardDesign?.id === item.mouthguard?.mouthguardDesign?.id);
	const unpickableThicknesses = matchingDesigns?.map(i => i.mouthguard?.thickness);

	return <TableCell>
		<div className='flex flex-wrap gap-4 items-center'>
			<img
				src={(item.mouthguard?.mouthguardDesign?.screenshotUrl || item.product.titleImage || '') + `?v=${item.mouthguard?.mouthguardDesign?.updatedAt}`}
				className={cn(
					'bg-transparent border-2 border-secondary-500/30 w-20 h-20 object-contain object-center aspect-auto rounded-lg',
					item.mouthguard?.mouthguardDesign?.screenshotUrl ? 'object-cover' : 'object-contain',
				)}
				alt={item.product.name}
			/>
			<ul>
				<li className='text-xl font-semibold text-primary'>{item.product.name}</li>
				{item.selectedProductOptions?.color ? <div className='flex items-center gap-mini capitalize'>
					<div
						className='w-4 h-4 rounded-full border border-primary/30'
						style={{
							backgroundColor: item.selectedProductOptions.color,
						}}/>
					{item.selectedProductOptions.label.replaceAll('_', ' ')}</div> : ''}
				{item.mouthguard?.mouthguardDesign ? <>
					<li className='text-secondary'>
						{(item.mouthguard?.mouthguardDesign as MouthguardDesignModel)?.canvasData?.data
							.find(cd => (cd.cacheKey === 'playerName' || cd.athleteContent))?.text || item.mouthguard?.mouthguardDesign?.name || 'Untitled'}
						{item.mouthguard?.mouthguardDesign?.parent?.team ? <span className='text-foreground pl-1'>
									(team design <span
								className='text-foreground/10'>{item.mouthguard.mouthguardDesign.parent.name}</span>)
						</span> : null}
						{item.mouthguard?.mouthguardDesign?.parent?.affiliate ? <span className='text-foreground pl-1'>
									(affiliate design <span
								className='text-foreground/10'>{item.mouthguard.mouthguardDesign.parent.name}</span>)
						</span> : null}
					</li>
					<li>
						<Button
							onClick={() => setEditDesign(item.mouthguard?.mouthguardDesign)}
							variant='link'
							size='sm'
							className='pl-0 text-foreground'
							icon={<FontAwesomeIcon icon='edit'/>}>
                            Edit
						</Button>
						{
							item.mouthguard?.mouthguardDesign.type === 'full_custom_design' && <Button
								onClick={editThickness.toggle}
								variant='link'
								size='sm'
								className='pl-0 text-foreground'
								icon={<FontAwesomeIcon icon='edit'/>}>
								{humanizeEnumValue(item.mouthguard?.thickness ?? 'Thickness')}
							</Button>
						}
					</li>
				</> : ''}
			</ul>

			<div className='hidden md:block grow'/>

			{item.vouchers?.length && item.vouchers.length > 0
				? <AlertConfirmDialog
					title='Do you want to unapply the voucher?'
					description={null}
					onConfirm={() => item.vouchers?.[0] && removeVoucher.mutate({
						orderItem: item,
						request: {voucher: item.vouchers[0]['@id']},
					})}
					triggerButton={<Button
						state={removeVoucher.status}
						size='sm'
						variant='outline'
						className='w-fit'
						disabled={!controls}
						icon={controls ? <FontAwesomeIcon icon='times'/> : undefined}
						iconPosition='right'>
						<span
							className='lowercase'>{item.vouchers.length}x</span> voucher{item.vouchers.length > 1 ? 's' : ''} applied
					</Button>}/>
				: ''}
			{controls && vouchersForProduct.length > 0 ? <Button
				onClick={() => applyVoucher.mutate({voucherCode: vouchersForProduct[0].code})}
				state={applyVoucher.status}
				size='sm'>
                Apply voucher
			</Button> : ''}
		</div>

		{editDesign !== undefined
			? <DesignerModal onClose={() => setEditDesign(undefined)} design={editDesign}/>
			: ''}
		<Modal
			isOpen={editThickness.state} onClose={editThickness.toggle}
			title='Edit thickness'>
			<div>
				<DesignerThicknessModalContent defaultValue={item.mouthguard?.thickness}
					onChange={value => setSelectedThickness(value)}/>
				<Button
					onClick={() => {
						selectedThickness && changeThickness.mutate({
							id: item.id,
							mouthguardThickness: selectedThickness,
						});
						editThickness.toggle();
					}}
					disabled={!selectedThickness || unpickableThicknesses?.includes(selectedThickness)}
					className='mt-medium w-full'
					state={changeThickness.status}>
                        Select
				</Button>
			</div>
		</Modal>
	</TableCell>;
}

interface CartContentTableProductCellProps {
    item: OrderItemModel;
    controls?: boolean;
}