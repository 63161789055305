import {CollectionParams, HydraListResponse} from '@mgp-fe/shared/core-api/domain/base.ts';
import {useQuery, UseQueryOptions} from '@tanstack/react-query';
import {AxiosResponse} from 'axios';
import keysResolver from '@mgp-fe/shared/core-api/keysResolver.ts';
import endpoints from '@mgp-fe/shared/core-api/endpoints.ts';
import coreApiClient from '@mgp-fe/shared/core-api/client.ts';
import {InvitationCollectionModel, InvitationStatus} from '@mgp-fe/shared/core-api/domain/invitation.ts';

export default function useInvitationsListQuery(props?: InvitationListQueryProps) {
	return useQuery<InvitationQuery>({
		queryKey: keysResolver(endpoints.invitations.list, 'get', props?.params),
		queryFn: () => coreApiClient.get(endpoints.invitations.list, {
			params: props?.params,
		}),
		...props?.options,
	});
}

export type InvitationsListQueryParams =  CollectionParams & {
	name?: string;
	status?: InvitationStatus | InvitationStatus[];
	search_invitations?: string;
	partnerLocation?: string;
};

interface InvitationListQueryProps {
	params?: InvitationsListQueryParams;
	options?: UseQueryOptions<InvitationQuery>;
}


type InvitationQuery = AxiosResponse<HydraListResponse<InvitationCollectionModel>>;