/* eslint-disable */
// @ts-nocheck
import type * as Types from '@mgp-frontend/shared/cms-graphql/domain/types';

import { gql } from '@apollo/client';
export type FooterLinkItemsFragment = { __typename?: 'ComponentFooterFooterLinkItems', id: string, label: string, url: string };

export const FooterLinkItemsFragmentDoc = gql`
    fragment FooterLinkItems on ComponentFooterFooterLinkItems {
  id
  label
  url
}
    `;