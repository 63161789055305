'use client';

import {useMutation, UseMutationOptions, useQueryClient} from '@tanstack/react-query';
import {AxiosError, AxiosResponse} from 'axios';
import keysResolver from '@mgp-fe/shared/core-api/keysResolver.ts';
import endpoints from '@mgp-fe/shared/core-api/endpoints';
import coreApiClient from '@mgp-fe/shared/core-api/client.ts';
import {Customer} from '@mgp-fe/shared/core-api/domain/user.ts';
import {HydraError} from '@mgp-fe/shared/core-api/domain/base.ts';
import {z} from 'zod';
import {passwordSchema} from '@mgp-fe/shared/core-api/schemas/user.ts';

export default function useChangePasswordMutation(props?: UseChangePasswordMutationProps) {
	const queryClient = useQueryClient();

	return useMutation<AxiosResponse<Customer>, AxiosError<HydraError>, ChangePasswordMutationData>(
		keysResolver(endpoints.me.changePassword, 'put'),
		d => coreApiClient.patch<Customer>(endpoints.me.changePassword, d),
		{
			...props,
			onSuccess: async (result, variables, context) => {
				await queryClient.invalidateQueries(keysResolver(endpoints.me.index));
				props?.onSuccess?.(result, variables, context);
			},
		},
	);
}

type UseChangePasswordMutationProps = UseMutationOptions<
	AxiosResponse<Customer>,
	AxiosError<HydraError>,
	ChangePasswordMutationData
>;

export interface ChangePasswordMutationData {
	currentPassword?: string;
	newPassword: string;
}

export const ChangePasswordMutationDataSchema = z.object({
	currentPassword: z.string().optional(),
	newPassword: passwordSchema,
});