import React, {Dispatch, SetStateAction} from 'react';
import useAuth from '@mgp-fe/shared/modules/auth/hooks/useAuth.ts';
import {Customer} from '@mgp-fe/shared/core-api/domain/user.ts';
import DefaultAddressCard from '@mgp-fe/shared/modules/account/components/address-book/DefaultAddressCard.tsx';
import useToggle from '@mgp-fe/shared/hooks/useToggle.ts';
import useAddressBookCollectionQuery from '@mgp-fe/shared/core-api/queries/address-book/list.ts';
import Modal from '@mgp-fe/shared/ui/Modal.tsx';
import AddressForm, {AddressFormFields} from '@mgp-fe/shared/modules/account/components/address-book/AddressForm.tsx';
import {useFormContext} from 'react-hook-form';
import {AccountFormValues} from '@mgp-fe/shared/modules/onboarding/components/account/IndexPage.tsx';
import {Alert, AlertDescription} from '@mgp-fe/shared/ui/alert.tsx';

export default function DefaultAddressesSection({setEditingAddress}: {setEditingAddress: Dispatch<SetStateAction<boolean>>}) {
	const {user} = useAuth<Customer>();
	const addressBookQuery = useAddressBookCollectionQuery({
		params: {pagination: false},
	});
	const form = useFormContext<AccountFormValues>();
	const displayModal = useToggle();

	// @ts-ignore
	const billingIsSameAsShipping = form.watch('billingIsSameAsShipping');

	if (!user) return <>Loading</>;

	if (user.defaultBillingAddress || user.defaultShippingAddress) {
		return <>
			<h3 className='text-muted mb-small'>Shipping address</h3>
			<DefaultAddressCard
				addressControls={['edit']}
				addNewHandler={() => displayModal.on()}
				addressBook={addressBookQuery.data?.data['hydra:member'] || []}
				type='defaultShippingAddress'/>


			<h3 className='my-small text-muted'>Billing address</h3>
			<DefaultAddressCard
				addressControls={['edit']}
				addNewHandler={() => displayModal.on()}
				addressBook={addressBookQuery.data?.data['hydra:member'] || []}
				type='defaultBillingAddress'/>

			<Modal isOpen={displayModal.state} onClose={displayModal.off} title='Add new address'>
				<AddressForm onSuccess={displayModal.off}/>
			</Modal>
		</>;
	}

	return <>
		<h3 className='text-muted mb-small'>Shipping address</h3>
		<AddressFormFields setEditingAddress={setEditingAddress} addressProperty='defaultShippingAddress'/>

		<label className='flex gap-small items-center text-secondary mt-medium'>
			<input type='checkbox' {...form.register('billingIsSameAsShipping')} />
			I have same billing address
		</label>

		<div className={billingIsSameAsShipping ? 'hidden' : ''}>
			<h3 className='my-small text-muted'>Billing address</h3>
			{form.formState.errors.defaultBillingAddress?.root?.message ? <Alert variant='destructive'>
				<AlertDescription>{form.formState.errors.defaultBillingAddress.root.message || 'Enter billing address'}</AlertDescription>
			</Alert> : ''}
			<AddressFormFields setEditingAddress={setEditingAddress} addressProperty='defaultBillingAddress'/>
		</div>
	</>;
}
