import React from 'react';
import MemberZoneLayout from '@mgp-fe/customer/modules/common/ui/MemberZoneLayout.tsx';
import useScansListQuery from '@mgp-fe/shared/core-api/queries/scan/list.ts';
import ScanCard from '@mgp-fe/shared/modules/scans/components/ScanCard.tsx';
import ScanRequestCard from '@mgp-fe/customer/modules/scans/components/ScanRequestCard.tsx';
import useScanRequestsListQuery from '@mgp-fe/shared/core-api/queries/scan/scan-request.ts';
import {Alert} from '@mgp-fe/shared/ui/alert.tsx';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import useAuth from '@mgp-fe/shared/modules/auth/hooks/useAuth.ts';
import useSettingItemQuery from '@mgp-fe/shared/core-api/queries/setting/detail.ts';
import PartnerLocationsSection from '@mgp-fe/customer/modules/scans/components/PartnerLocationsSection.tsx';
import {Button} from '@mgp-fe/shared/ui/button.tsx';
import {useNavigate} from 'react-router-dom';
import CustomerPartnerLocationCard
	from '@mgp-fe/shared/modules/partner-locator/components/CustomerPartnerLocationCard.tsx';
import {Customer} from '@mgp-fe/shared/core-api/domain/user.ts';

export default function ScansIndexPage() {
	const {user} = useAuth<Customer>();
	const navigate = useNavigate();
	const impressionKitLocationQuery = useSettingItemQuery({key: 'mgc_impression_kit_location'});
	const scansQuery = useScansListQuery();
	const scanRequestsQuery = useScanRequestsListQuery({
		params: {
			state: ['requested', 'accepted', 'rejected', 'canceled_by_customer'],
			pagination: false,
		},
	});

	return <MemberZoneLayout>
		<main className='container grid gap-large mb-xlarge'>
			<section>
				<div className='flex flex-row items-center justify-between'>
					<h2>Bookings</h2>
					<Button onClick={() => navigate('/scans-requests')} variant='ghost' size='sm'>
						Show all
					</Button>
				</div>
				<div className='grid md:grid-cols-2 lg:grid-cols-3 gap-medium mt-small'>
					{!scanRequestsQuery.isFetching && (scanRequestsQuery.data?.data['hydra:totalItems'] ?? 0) === 0
						? <Alert variant='default' className='flex gap-2 mt-4 md:col-span-2 lg:col-span-3'>
							<FontAwesomeIcon icon='info-circle' className='mt-1'/>
							You have no requested or upcoming bookings.
						</Alert>
						: ''}

					{scanRequestsQuery.data?.data['hydra:member'].map(s => <ScanRequestCard
						key={s.id}
						scanRequest={s}
						scansRequestsRefetch={scanRequestsQuery.refetch}/>)}
				</div>
			</section>

			<section className='grid md:grid-cols-2 gap-medium'>
				<div>
					<h2>My scans</h2>
					<p className='mb-4'>You can see all your scans including impression kits here.</p>

					{(scansQuery.data?.data['hydra:totalItems'] ?? 1) > 0 ? null
						: <Alert variant='default' className='flex gap-2 mt-0'>
							<FontAwesomeIcon icon='info-circle' className='mt-1'/>
							You have no scans yet.
						</Alert>}
					<div className='relative'>
						<div
							className='absolute inset-0 bg-gradient-to-t from-background from-0% to-35% to-transparent z-10 pointer-events-none'/>

						<div className='grid gap-small max-h-[350px] overflow-auto pb-large pr-4'>
							{scansQuery.data?.data['hydra:member'].map(s => <ScanCard scan={s} key={s.id}/>)}
						</div>
					</div>
				</div>

				<div>
					<h2>Partner location</h2>
					<p className='mb-4'>You can see your partner location for your GARD account here.</p>
					<CustomerPartnerLocationCard/>
				</div>
			</section>

			{user?.partnerLocation && impressionKitLocationQuery.data?.data.value === user.partnerLocation.id ?
				<section>
					<h2 className='mt-medium text-center'>Find nearest partner</h2>
					<p className='text-center mb-4'>You can find nearest partner location for your GARD account
						here.</p>
					<PartnerLocationsSection/>
				</section> : ''}
		</main>
	</MemberZoneLayout>;
}

